import { HTTP } from "./http";
let API_SERVER = "";
if (window.location.href.indexOf("8080") > -1) {
  API_SERVER = "http://www.localhost:8888/back-end-endpoint-douments.php";
} else {
  API_SERVER = "/personal-training-BE/back-end-endpoint-douments.php";
}

const Documents = {
  find(params) {
    return HTTP.post(API_SERVER, params);
  },

  show(id) {
    return HTTP.get(`users/${id}`);
  },

  store(post) {
    return HTTP.post(API_SERVER, post);
  },

  update(post, id) {
    return HTTP.put(`users/${id}`, post);
  },

  delete(params) {
    return HTTP.delete(API_SERVER, params);
  },
};

export { Documents };
