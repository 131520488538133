<template>
  <div>
    <spinner-loader :loadingStuff="loadingStuff" />
    <app-template />
    <div class="uk-container uk-container-large container profile-page">
      <template v-if="selectedUserValue !== ''">
        <div v-if="userData.userType === 'Admin' || userData.userType === 'Coach'" class="select-user">
          <template v-if="allUserDataBeforFilterApplied.length > 1">
            <label class="form__label" for="userSelect">Select player </label>
            <select @change="selectedUseChanged($event, 'userChangeSelect')" id="userSelect">
              <option v-for="(player, index) in allUserDataBeforFilterApplied" :value="player.id"
                :key="player.id + index">
                {{ player.name }}
              </option>
            </select>
          </template>
        </div>

        <user-header :userDataForHeader="userData" :selectedUserData="selectedUserDataForHeader"
          @weekSelectedChanged="weekSelectedChanged" @calendarDateChanged="calendarDateChanged"
          @mondaySunDatesChanged="mondaySunDatesChanged" :coachesUsersData="coachesUsersData"
          @selectedUseChanged="selectedUseChanged" />
      </template>
      <div v-else>
        <h1>
          <span>Overview</span>
        </h1>
      </div>

      <b-button class="main-button create-academy-report-button" style="margin-right: 1rem"
        v-if="userData.userType === 'Admin' && selectedUserValue !== ''" @click="openCreateAcademyReportModal()"
        size="lg" variant="outline-success"><b-icon icon="file-text" aria-hidden="true"></b-icon> Academy
        report</b-button>

      <b-button class="main-button create-academy-report-button"
        v-if="userData.userType !== 'User' && selectedUserValue !== ''" @click="editAcademyReportModal()" size="lg"
        variant="outline-warning" style="margin-right: 1rem">
        <b-icon icon="pencil" aria-hidden="true"></b-icon> Edit academy
        report</b-button>

      <template>
        <template v-if="selectedUserValue !== ''">
          <ul class="tabs headding-space">
            <li class="tab-item">
              <a :class="selectedTab === 'daily-planner-tab'
                  ? 'tab-link active'
                  : 'tab-link'
                " @click="tabChange('selectedTab', 'daily-planner-tab')" href="javascript:;">Daily planner</a>
            </li>
            <li class="tab-item">
              <a :class="selectedTab === 'performace-tab'
                  ? 'tab-link active'
                  : 'tab-link'
                " @click="tabChange('selectedTab', 'performace-tab')" href="javascript:;">Performance</a>
            </li>
            <li class="tab-item">
              <a v-if="over14 === false" :class="selectedTab === 'review-tab' ? 'tab-link active' : 'tab-link'
                " @click="tabChange('selectedTab', 'review-tab')" href="javascript:;">Performance Reviews</a>
            </li>
            <li class="tab-item">
              <a :class="selectedTab === 'home-work-tab'
                  ? 'tab-link active'
                  : 'tab-link'
                " @click="tabChange('selectedTab', 'home-work-tab')" href="javascript:;">Homework</a>
            </li>
            <li class="tab-item">
              <a :class="selectedTab === 'wellness-tab'
                  ? 'tab-link active'
                  : 'tab-link'
                " @click="tabChange('selectedTab', 'wellness-tab')" href="javascript:;">Wellness information</a>
            </li>
            <li class="tab-item">
              <a :class="selectedTab === 'injury-tab' ? 'tab-link active' : 'tab-link'
                " @click="tabChange('selectedTab', 'injury-tab')" href="javascript:;">Injury information</a>
            </li>

            <li class="tab-item">
              <a :class="selectedTab === 'learning-development-plan-tab'
                  ? 'tab-link active'
                  : 'tab-link'
                " @click="
                  tabChange('selectedTab', 'learning-development-plan-tab')
                  " href="javascript:;">Individual Development plans (IDPs)</a>
            </li>

            <!--<li class="tab-item">
              <a
                :class="
                  selectedTab === 'learning-plan-tab'
                    ? 'tab-link active'
                    : 'tab-link'
                "
                @click="tabChange('selectedTab', 'learning-plan-tab')"
                href="javascript:;"
                >Individual learning plans (ILPs)</a
              >
            </li>-->

            <!--<li class="tab-item">
              <a
                :class="
                  selectedTab === 'payments-tab'
                    ? 'tab-link active'
                    : 'tab-link'
                "
                @click="tabChange('selectedTab', 'payments-tab')"
                href="javascript:;"
                >Payments / subscriptions</a
              >
            </li>

           <li
              class="tab-item"
              v-if="
                userData.age_group === '2007' ||
                userData.age_group === '2007' ||
                userData.age_group === '0000'
              "
            >
              <a
                :class="
                  selectedTab === 'parents-meeting-tab'
                    ? 'tab-link active'
                    : 'tab-link'
                "
                @click="tabChange('selectedTab', 'parents-meeting-tab')"
                href="javascript:;"
                >Parents Evenings</a
              >
            </li>-->

            <li class="tab-item">
              <a :class="selectedTab === 'coach-tab' ? 'tab-link active' : 'tab-link'
                " @click="tabChange('selectedTab', 'coach-tab')" href="javascript:;">Playing history</a>
            </li>
            <!--<li
              class="tab-item">
              <a
                :class="
                  selectedTab === 'code-of-conduct-tab'
                    ? 'tab-link active'
                    : 'tab-link'
                "
                @click="tabChange('selectedTab', 'code-of-conduct-tab')"
                href="javascript:;"
                >Code of Conduct</a
              >
            </li>-->
            <li class="tab-item">
              <a :class="selectedTab === 'player-documents-tab'
                  ? 'tab-link active'
                  : 'tab-link'
                " @click="tabChange('selectedTab', 'player-documents-tab')" href="javascript:;">Documents</a>
            </li>
            <!--<li
              class="tab-item">
              <a
                :class="
                  selectedTab === 'game-prep-tab'
                    ? 'tab-link active'
                    : 'tab-link'
                "
                @click="tabChange('selectedTab', 'game-prep-tab')"
                href="javascript:;"
                >Game preparation</a
              >
            </li>-->
          </ul>
          <div class="tabs-wrapper">
            <div class="current-week-tab tab-content" v-if="selectedTab === 'player-documents-tab'">
              <div class="games-info">
                <h3>Signed documents</h3>

                <b-button @click="charterModal()" size="lg" class="main-button" variant="outline-warning"><b-icon
                    icon="pencil" aria-hidden="true"></b-icon> Edit
                  charter</b-button>

                <h3>Documents</h3>

                <div class="document" v-for="document in uploadedDocuments" :key="document.id" v-b-tooltip.hover
                  :title="removeNumberFromStart(document.name)">
                  <a :href="`./${document.url}`" target="_blank">
                    <img src="../img/documentlib.svg" />
                    <h3>{{ removeNumberFromStart(document.name) }}</h3>
                  </a>
                </div>
                <div v-if="uploadedDocuments.length === 0">
                  <div class="empty-state">
                    <img src="../img/emptyStateIllustration.svg" />
                    <p>No documents</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="current-week-tab tab-content" v-if="selectedTab === 'daily-planner-tab'">
              <div class="daily-planner-wrapper">
                <DailyPlanner :selectedTeamId="selectedTeamId" :selectedUserData="selectedUserData" />
              </div>
            </div>
            <div class="current-week-tab tab-content" v-if="selectedTab === 'performace-tab'">
              <div class="games-info two-col-flex">
                <div class="games-stats">
                  <div class="button-wrapper-top">
                    <b-button v-if="
                      userData.userType !== 'Admin' &&
                      userData.userType !== 'Coach'
                    " class="main-button" @click="openGamePerformanceModal('performanceModal')" size="lg"
                      variant="outline-success" v-b-tooltip.hover title="Submit game performance report"><b-icon
                        icon="file-text" aria-hidden="true"></b-icon>
                    </b-button>

                    <b-button v-if="
                      userData.userType !== 'Admin' &&
                      userData.userType !== 'Coach'
                    " @click="playerAssesmentModal()" size="lg" class="main-button" variant="outline-success"
                      v-b-tooltip.hover title="Add a Player Self Assessment"><b-icon icon="person"
                        aria-hidden="true"></b-icon></b-button>

                    <b-button size="lg" class="main-button" variant="outline-success" v-b-tooltip.hover @click="
                      download_csv(
                        allplayerPerformanceReportData,
                        'player-Performance-Reviews'
                      )
                      " title="Export player performance data to csv"><b-icon icon="download"
                        aria-hidden="true"></b-icon></b-button>

                    <!-- <b-button
                      size="lg"
                      class="main-button"
                      variant="outline-success"
                      v-b-tooltip.hover
                      title="Import player performance data"
                      ><b-icon icon="upload" aria-hidden="true"></b-icon
                    ></b-button>-->

                    <b-button size="lg" class="main-button" variant="outline-success" v-b-tooltip.hover
                      title="Email player performance data"><b-icon icon="envelope"
                        aria-hidden="true"></b-icon></b-button>
                  </div>

                  <div class="stats-wrapper">
                    <div class="game-stats">
                      <h3 class="no-margin">Your game stats for this season</h3>
                      <p class="small-date-player-coach days-red-warning"
                        v-if="gamePerformanceData.created_date === undefined">
                        <b-icon icon="exclamation-circle" aria-hidden="true"></b-icon>
                        You have not submitted a game report. Please submit one
                        now.
                      </p>
                      <p v-else class="small-date-player-coach" :class="`days-${checkDateTimeFromNow(
                        gamePerformanceData.created_date
                      )}`">
                        You last submitted a game report on
                        {{ formatDate(gamePerformanceData.created_date) }}
                      </p>
                      <ul class="key-value-list">
                        <li>
                          <span>Appearances:</span>{{ gamePerformanceData.gamesPlayed }}
                        </li>
                        <li v-if="checkUserPosition() !== 'Goalkeeper'">
                          <span>Goals:</span>
                          <template v-if="gamePerformanceData.goals !== null">{{
                            gamePerformanceData.goals
                            }}</template>
                          <template v-else>0</template>
                        </li>
                        <li v-else>
                          <span>Clean sheets:</span>
                          <template v-if="gamePerformanceData.clean_sheet !== null">{{ gamePerformanceData.clean_sheet
                            }}</template>
                          <template v-else>0</template>
                        </li>
                        <li>
                          <span>Assists:</span>
                          {{ gamePerformanceData.assists }}
                        </li>
                        <!--<li
                          v-if="gamePerformanceData.player_self_rating !== null"
                        >
                          <span>Player performace rating:</span>
                          {{ gamePerformanceData.player_self_rating }}
                        </li>-->
                        <li v-if="gamePerformanceData.timeOnPitch !== null">
                          <span>Game time:</span>
                          {{ gamePerformanceData.timeOnPitch }} mins
                        </li>
                        <li>
                          <span>Yellow cards:</span>
                          {{ gamePerformanceData.yellowCardTotal }}
                        </li>
                        <li>
                          <span>Red cards:</span>
                          {{ gamePerformanceData.redCardTotal }}
                        </li>
                      </ul>
                    </div>

                    <div class="playing-positions">
                      <h3>Playing positions</h3>
                      <div class="pitch-wrapper">
                        <img class="goalkeeper small-shirt" src="../img/player-shirt-gray.svg" alt="player-shirt" />

                        <img class="small-shirt right-full-back" src="../img/player-shirt-gray.svg"
                          alt="player-shirt" />
                        <img class="small-shirt center-back-left" src="../img/player-shirt-gray.svg"
                          alt="player-shirt" />
                        <img class="small-shirt center-back-right" src="../img/player-shirt-gray.svg"
                          alt="player-shirt" />
                        <img class="small-shirt left-full-back" src="../img/player-shirt-gray.svg" alt="player-shirt" />

                        <img class="small-shirt left-midfielder" src="../img/player-shirt-gray.svg"
                          alt="player-shirt" />
                        <img class="small-shirt defensive-midfielder" src="../img/player-shirt-gray.svg"
                          alt="player-shirt" />
                        <img class="small-shirt attacking-midfielder" src="../img/player-shirt-gray.svg"
                          alt="player-shirt" />
                        <img class="small-shirt right-midfielder" src="../img/player-shirt-gray.svg"
                          alt="player-shirt" />

                        <img class="small-shirt striker-left" src="../img/player-shirt-gray.svg" alt="player-shirt" />
                        <img class="small-shirt striker-right" src="../img/player-shirt-gray.svg" alt="player-shirt" />

                        <div class="position-played-wrapper" v-for="(
position, index
                          ) in gamePerformanceData.postion_played" :key="index">
                          <img v-if="position === 'Goalkeeper'" class="goalkeeper small-shirt"
                            src="../img/player-shirt-blue.svg" alt="player-shirt" />

                          <img v-if="position === 'Right Full-back (Wingback)'" class="small-shirt right-full-back"
                            src="../img/player-shirt-blue.svg" alt="player-shirt" />
                          <img v-if="position === 'Center-back left'" class="small-shirt center-back-left"
                            src="../img/player-shirt-blue.svg" alt="player-shirt" />
                          <img v-if="position === 'Center-back right'" class="small-shirt center-back-right"
                            src="../img/player-shirt-blue.svg" alt="player-shirt" />
                          <img v-if="position === 'Left Full-back (Wingback)'" class="small-shirt left-full-back"
                            src="../img/player-shirt-blue.svg" alt="player-shirt" />

                          <img v-if="position === 'Left Midfielder (Winger)'" class="small-shirt left-midfielder"
                            src="../img/player-shirt-blue.svg" alt="player-shirt" />
                          <img v-if="
                            position === 'Defensive Midfielder' ||
                            position === 'Centre Defensive Midfield'
                          " class="small-shirt defensive-midfielder" src="../img/player-shirt-blue.svg"
                            alt="player-shirt" />
                          <img v-if="
                            position === 'Attacking Midfielder' ||
                            position === 'Centre midfield'
                          " class="small-shirt attacking-midfielder" src="../img/player-shirt-blue.svg"
                            alt="player-shirt" />
                          <img v-if="position === 'Right Midfielder (Winger)'" class="small-shirt right-midfielder"
                            src="../img/player-shirt-blue.svg" alt="player-shirt" />

                          <img v-if="position === 'Center Forward (Striker) left'" class="small-shirt striker-left"
                            src="../img/player-shirt-blue.svg" alt="player-shirt" />
                          <img v-if="position === 'Center Forward (Striker) right'" class="small-shirt striker-right"
                            src="../img/player-shirt-blue.svg" alt="player-shirt" />
                        </div>

                        <img src="../img/pitch.svg" alt="pitch" class="pitch-svg" id="pitch_svg" />
                      </div>
                    </div>
                  </div>

                  <section class="split-module">
                    <div class="all-training-reports">
                      <h3>Game assessments</h3>
                      <a href="javascript:;" @click="openAllGamegReportsModal('allGameReportsModal')">
                        <img class="large-icon-shape" src="../img/game-ass.svg" alt="" />
                        View all this season Game assessments</a>

                      <div class="buttons-row">
                        <b-button v-if="
                          userData.userType === 'Admin' ||
                          userData.userType === 'Coach'
                        " @click="
                            openGamePerformanceModal(
                              'coachGamePerformanceModal'
                            )
                            " class="main-button" size="lg" variant="outline-success" v-b-tooltip.hover
                          title="Coaches player game assesment"><b-icon icon="file-text" aria-hidden="true"></b-icon>
                          Rate player</b-button>
                        <b-button size="lg" class="main-button" variant="outline-success" v-b-tooltip.hover @click="
                          download_csv(
                            seasonsgamePerformanceReportsForUser,
                            'player-season-Game-stats-Reports'
                          )
                          " title="Export player season Game stats to csv"><b-icon icon="download"
                            aria-hidden="true"></b-icon></b-button>

                        <!--<b-button
                        size="lg"
                        class="main-button"
                        variant="outline-success"
                        v-b-tooltip.hover
                        title="Import player season Game stats"
                        ><b-icon icon="upload" aria-hidden="true"></b-icon
                      ></b-button>-->

                        <b-button size="lg" class="main-button" variant="outline-success" v-b-tooltip.hover
                          title="Email player season Game stats"><b-icon icon="envelope"
                            aria-hidden="true"></b-icon></b-button>
                      </div>
                    </div>

                    <div class="all-training-reports">
                      <h3>Player self assessments</h3>
                      <a href="javascript:;" @click="openAllPlayerSelfAssessmentModal()">
                        <img class="large-icon-shape" src="../img/player-ass.svg" alt="" />
                        View all this season Player self assessments</a>
                    </div>
                  </section>

                  <section class="session-metrics" v-if="Object.keys(playerPersonalBestAndAverages).length > 0">
                    <div v-if="
                      lastGameSessionData.hasOwnProperty(
                        'total_absolute_high_speed_distance'
                      ) && under16 === false
                    ">
                      <h3>Games avg</h3>

                      <b-form-select class="mb-3" v-model="metricsGameTab">
                        <b-form-select-option :value="null">Please select an option</b-form-select-option>
                        <b-form-select-option value="game-distance-tab">Distance</b-form-select-option>
                        <b-form-select-option value="game-hsr-tab">High Speed Running</b-form-select-option>
                        <b-form-select-option value="game-accelerations-tab">Accelerations</b-form-select-option>
                        <b-form-select-option value="game-decelerations-tab">Decelerations</b-form-select-option>
                        <b-form-select-option value="game-intensity-tab">Intensity</b-form-select-option>
                        <b-form-select-option value="game-perTopSpeed-tab">% Top Speed</b-form-select-option>
                        <b-form-select-option value="game-topSpeed-tab">Top Speed</b-form-select-option>
                      </b-form-select>

                      <div class="tabs-wrapper">
                        <div class="tab-content" v-if="metricsGameTab === 'game-distance-tab'">
                          <div class="metrics-wrapper">
                            <h4>Total distance</h4>

                            <div class="metrics-item">
                              <p class="title">Game Avg</p>
                              <p class="value">
                                {{
                                  Math.round(
                                    playerPersonalBestAndAverages.games
                                      .session_distance
                                  )
                                }}
                                <span>Meters</span>
                              </p>
                            </div>

                            <div class="metrics-item">
                              <p class="title">Game PB</p>
                              <p class="value">
                                {{
                                  playerPersonalBestAndAverages.games.pb
                                    .session_distance
                                }}
                                <span>Meters</span>
                              </p>
                            </div>

                            <div class="metrics-item">
                              <p class="session-date">
                                Session date:
                                {{
                                  formatDate(lastGameSessionData.session_date)
                                }}
                              </p>
                              <p class="title">Game Last session</p>
                              <p class="value">
                                {{ lastGameSessionData.session_distance }}
                                <span>Meters</span>
                              </p>
                            </div>
                            <Line-game-training-avg-chart class="pb-chart" v-if="playerDailyMins.length > 0" :chartData="lineGameTrainingAvgChartData(
                              playerDailyMins,
                              'Session',
                              'session_distance',
                              'Session Distance'
                            )
                              " />
                          </div>
                        </div>

                        <div class="tab-content" v-if="metricsGameTab === 'game-hsr-tab'">
                          <div class="metrics-wrapper">
                            <h4>High Speed Running</h4>

                            <div class="metrics-item">
                              <p class="title">Game Avg</p>
                              <p class="value">
                                {{
                                  Math.round(
                                    playerPersonalBestAndAverages.games
                                      .total_absolute_high_speed_distance
                                  )
                                }}
                                <span>Meters</span>
                              </p>
                            </div>

                            <div class="metrics-item">
                              <p class="title">Game PB</p>
                              <p class="value">
                                {{
                                  playerPersonalBestAndAverages.games.pb
                                    .total_absolute_high_speed_distance
                                }}
                                <span>Meters</span>
                              </p>
                            </div>

                            <div class="metrics-item">
                              <p class="session-date">
                                Session date:
                                {{
                                  formatDate(lastGameSessionData.session_date)
                                }}
                              </p>
                              <p class="title">Game Last session</p>
                              <p class="value">
                                {{
                                  lastGameSessionData.total_absolute_high_speed_distance
                                }}
                                <span>Meters</span>
                              </p>
                            </div>

                            <Line-game-training-avg-chart v-if="playerDailyMins.length > 0" :chartData="lineGameTrainingAvgChartData(
                              playerDailyMins,
                              'Session',
                              'total_absolute_high_speed_distance',
                              'High Speed Running'
                            )
                              " />
                          </div>
                        </div>

                        <div class="tab-content" v-if="metricsGameTab === 'game-accelerations-tab'">
                          <div class="metrics-wrapper">
                            <h4>Accelerations</h4>

                            <div class="metrics-item">
                              <p class="title">Game Avg</p>
                              <p class="value">
                                {{
                                  Math.round(
                                    playerPersonalBestAndAverages.games
                                      .accelerations_total_efforts
                                  )
                                }}
                              </p>
                            </div>

                            <div class="metrics-item">
                              <p class="title">Training PB</p>
                              <p class="value">
                                {{
                                  playerPersonalBestAndAverages.games.pb
                                    .accelerations_total_efforts
                                }}
                                <span>Meters</span>
                              </p>
                            </div>

                            <div class="metrics-item">
                              <p class="session-date">
                                Session date:
                                {{
                                  formatDate(lastGameSessionData.session_date)
                                }}
                              </p>
                              <p class="title">Game Last session</p>
                              <p class="value">
                                {{
                                  lastGameSessionData.accelerations_total_efforts
                                }}
                                <span>Meters</span>
                              </p>
                            </div>

                            <Line-game-training-avg-chart v-if="playerDailyMins.length > 0" :chartData="lineGameTrainingAvgChartData(
                              playerDailyMins,
                              'Session',
                              'accelerations_total_efforts',
                              'Accelerations'
                            )
                              " />
                          </div>
                        </div>

                        <div class="tab-content" v-if="metricsGameTab === 'game-decelerations-tab'">
                          <div class="metrics-wrapper">
                            <h4>Decelerations</h4>

                            <div class="metrics-item">
                              <p class="title">Game Avg</p>
                              <p class="value">
                                {{
                                  Math.round(
                                    playerPersonalBestAndAverages.games
                                      .decelerations_total_efforts
                                  )
                                }}
                              </p>
                            </div>

                            <div class="metrics-item">
                              <p class="title">Game PB</p>
                              <p class="value">
                                {{
                                  playerPersonalBestAndAverages.games.pb
                                    .decelerations_total_efforts
                                }}
                                <span>Meters</span>
                              </p>
                            </div>

                            <div class="metrics-item">
                              <p class="session-date">
                                Session date:
                                {{
                                  formatDate(lastGameSessionData.session_date)
                                }}
                              </p>
                              <p class="title">Game Last session</p>
                              <p class="value">
                                {{
                                  lastGameSessionData.decelerations_total_efforts
                                }}
                                <span>Meters</span>
                              </p>
                            </div>

                            <Line-game-training-avg-chart v-if="playerDailyMins.length > 0" :chartData="lineGameTrainingAvgChartData(
                              playerDailyMins,
                              'Session',
                              'decelerations_total_efforts',
                              'Decelerations'
                            )
                              " />
                          </div>
                        </div>

                        <div class="tab-content" v-if="metricsGameTab === 'game-intensity-tab'">
                          <div class="metrics-wrapper">
                            <h4>Intensity</h4>

                            <div class="metrics-item">
                              <p class="title">Game Avg</p>
                              <p class="value">
                                {{
                                  Math.round(
                                    playerPersonalBestAndAverages.games
                                      .meterage_per_minute
                                  )
                                }}
                                <span>M/Min</span>
                              </p>
                            </div>

                            <div class="metrics-item">
                              <p class="title">Training PB</p>
                              <p class="value">
                                {{
                                  playerPersonalBestAndAverages.games.pb
                                    .meterage_per_minute
                                }}
                                <span>M/Min</span>
                              </p>
                            </div>

                            <div class="metrics-item">
                              <p class="session-date">
                                Session date:
                                {{
                                  formatDate(lastGameSessionData.session_date)
                                }}
                              </p>
                              <p class="title">Game Last session</p>
                              <p class="value">
                                {{ lastGameSessionData.meterage_per_minute }}
                                <span>M/Min</span>
                              </p>
                            </div>

                            <Line-game-training-avg-chart v-if="playerDailyMins.length > 0" :chartData="lineGameTrainingAvgChartData(
                              playerDailyMins,
                              'Session',
                              'meterage_per_minute',
                              'Intensity'
                            )
                              " />
                          </div>
                        </div>

                        <div class="tab-content" v-if="metricsGameTab === 'game-perTopSpeed-tab'">
                          <div class="metrics-wrapper">
                            <h4>% Top Speed</h4>

                            <div class="metrics-item">
                              <p class="title">Game Avg</p>
                              <p class="value">
                                {{
                                  playerPersonalBestAndAverages.games
                                    .max_vel_max
                                }}
                                <span>Meters</span>
                              </p>
                            </div>

                            <div class="metrics-item">
                              <p class="title">Training PB</p>
                              <p class="value">
                                {{
                                  playerPersonalBestAndAverages.games.pb
                                    .max_vel_max
                                }}
                                <span>Meters</span>
                              </p>
                            </div>

                            <div class="metrics-item">
                              <p class="session-date">
                                Session date:
                                {{
                                  formatDate(lastGameSessionData.session_date)
                                }}
                              </p>
                              <p class="title">Game Last session</p>
                              <p class="value">
                                {{ lastGameSessionData.max_vel_max }}
                                <span>Meters</span>
                              </p>
                            </div>

                            <Line-game-training-avg-chart v-if="playerDailyMins.length > 0" :chartData="lineGameTrainingAvgChartData(
                              playerDailyMins,
                              'Session',
                              'max_vel_max',
                              '% Top Speed'
                            )
                              " />
                          </div>
                        </div>

                        <div class="tab-content" v-if="metricsGameTab === 'game-topSpeed-tab'">
                          <div class="metrics-wrapper">
                            <h4>Top Speed</h4>

                            <div class="metrics-item">
                              <p class="title">Game Avg</p>
                              <p class="value">
                                {{
                                  playerPersonalBestAndAverages.maximum_velocity
                                }}
                                <span>M/S</span>
                              </p>
                            </div>

                            <div class="metrics-item">
                              <p class="title">Training PB</p>
                              <p class="value">
                                {{
                                  (
                                    Math.round(
                                      playerPersonalBestAndAverages.games.pb
                                        .maximum_velocity * 100
                                    ) / 100
                                  ).toFixed(2)
                                }}
                                <span>M/S</span>
                              </p>
                            </div>

                            <div class="metrics-item">
                              <p class="session-date">
                                Session date:
                                {{
                                  formatDate(lastGameSessionData.session_date)
                                }}
                              </p>
                              <p class="title">Game Last session</p>
                              <p class="value">
                                {{
                                  (
                                    Math.round(
                                      lastGameSessionData.maximum_velocity * 100
                                    ) / 100
                                  ).toFixed(2)
                                }}
                                <span>M/S</span>
                              </p>
                            </div>
                          </div>

                          <Line-game-training-avg-chart v-if="playerDailyMins.length > 0" :chartData="lineGameTrainingAvgChartData(
                            playerDailyMins,
                            'Session',
                            'maximum_velocity',
                            'Top Speed'
                          )
                            " />
                        </div>
                      </div>
                    </div>

                    <div v-else>
                      <h3 v-if="
                        Object.keys(playerMakerPersonalBestAndAverages)
                          .length > 0
                      ">
                        Session effort score and stats
                      </h3>

                      <b-form-select class="mb-3" v-model="metricsGameTab" v-if="
                        Object.keys(playerMakerPersonalBestAndAverages)
                          .length > 0
                      ">
                        <b-form-select-option :value="null">Please select an option</b-form-select-option>
                        <b-form-select-option value="game-phase_duration_min-tab">Session
                          Duration</b-form-select-option>
                        <b-form-select-option value="game-distance_covered-tab">Total Distance</b-form-select-option>
                        <b-form-select-option value="game-work_rate-tab">Work Rate</b-form-select-option>
                        <b-form-select-option value="game-total_touches-tab">Total Touches</b-form-select-option>
                        <b-form-select-option value="game-left_leg_touches-tab">Left leg Touches</b-form-select-option>
                        <b-form-select-option value="game-right_leg_touches-tab">Right leg
                          Touches</b-form-select-option>
                        <b-form-select-option value="game-releases-tab">Total Passes</b-form-select-option>
                        <b-form-select-option value="game-releases_left-tab">Left Passes</b-form-select-option>
                        <b-form-select-option value="game-releases_right-tab">Right Passes</b-form-select-option>
                        <b-form-select-option value="game-top_speed-tab">Top speed</b-form-select-option>
                      </b-form-select>

                      <div class="tabs-wrapper" v-if="
                        Object.keys(playerMakerPersonalBestAndAverages)
                          .length > 0
                      ">
                        <div class="tab-content" v-if="
                          metricsGameTab === 'game-phase_duration_min-tab'
                        ">
                          <div class="metrics-wrapper">
                            <h4>Session Duration</h4>

                            <div class="metrics-item">
                              <p class="title">Game Avg</p>
                              <p class="value">
                                {{
                                  Math.round(
                                    playerMakerPersonalBestAndAverages.games
                                      .phase_duration_min
                                  )
                                }}
                                <span>Mins</span>
                              </p>
                            </div>

                            <div class="metrics-item">
                              <p class="title">Game PB</p>
                              <p class="value">
                                {{
                                  playerMakerPersonalBestAndAverages.games.pb
                                    .phase_duration_min
                                }}
                                <span>Mins</span>
                              </p>
                            </div>

                            <div class="metrics-item">
                              <p class="session-date">
                                Session date:
                                {{
                                  formatDate(lastGameSessionData.session_date)
                                }}
                              </p>
                              <p class="title">Game Last session</p>
                              <p class="value">
                                {{ lastGameSessionData.phase_duration_min }}
                                <span>Mins</span>
                              </p>
                            </div>

                            <Line-game-training-avg-chart class="table-responsive"
                              v-if="playerPlayMakerGpsData.length > 0" :chartData="lineGameTrainingAvgChartData(
                                playerPlayMakerGpsData,
                                'Session',
                                'phase_duration_min',
                                'Session Duration'
                              )
                                " />
                          </div>
                        </div>

                        <div class="tab-content" v-if="metricsGameTab === 'game-distance_covered-tab'">
                          <div class="metrics-wrapper">
                            <h4>Total Distance</h4>

                            <div class="metrics-item">
                              <p class="title">Game Avg</p>
                              <p class="value">
                                {{
                                  Math.round(
                                    playerMakerPersonalBestAndAverages.games
                                      .distance_covered
                                  )
                                }}
                                <span>Meters</span>
                              </p>
                            </div>

                            <div class="metrics-item">
                              <p class="title">Game PB</p>
                              <p class="value">
                                {{
                                  playerMakerPersonalBestAndAverages.games.pb
                                    .distance_covered
                                }}
                                <span>Meters</span>
                              </p>
                            </div>

                            <div class="metrics-item">
                              <p class="session-date">
                                Session date:
                                {{
                                  formatDate(lastGameSessionData.session_date)
                                }}
                              </p>
                              <p class="title">Training Last session</p>
                              <p class="value">
                                {{ lastGameSessionData.distance_covered }}
                                <span>Meters</span>
                              </p>
                            </div>

                            <Line-game-training-avg-chart class="pb-chart" v-if="playerPlayMakerGpsData.length > 0"
                              :chartData="lineGameTrainingAvgChartData(
                                playerPlayMakerGpsData,
                                'Session',
                                'distance_covered',
                                'Total Distance'
                              )
                                " />
                          </div>
                        </div>

                        <div class="tab-content" v-if="metricsGameTab === 'game-work_rate-tab'">
                          <div class="metrics-wrapper">
                            <h4>Work Rate</h4>

                            <div class="metrics-item">
                              <p class="title">Game Avg</p>
                              <p class="value">
                                {{
                                  Math.round(
                                    playerMakerPersonalBestAndAverages.games
                                      .work_rate
                                  )
                                }}
                                <span>M/Min</span>
                              </p>
                            </div>

                            <div class="metrics-item">
                              <p class="title">Game PB</p>
                              <p class="value">
                                {{
                                  playerMakerPersonalBestAndAverages.games.pb
                                    .work_rate
                                }}
                                <span>M/Min</span>
                              </p>
                            </div>

                            <div class="metrics-item">
                              <p class="session-date">
                                Session date:
                                {{
                                  formatDate(lastGameSessionData.session_date)
                                }}
                              </p>
                              <p class="title">Training Last session</p>
                              <p class="value">
                                {{ lastGameSessionData.work_rate }}
                                <span>M/Min</span>
                              </p>
                            </div>

                            <Line-game-training-avg-chart class="pb-chart" v-if="playerPlayMakerGpsData.length > 0"
                              :chartData="lineGameTrainingAvgChartData(
                                playerPlayMakerGpsData,
                                'Session',
                                'work_rate',
                                'Work Rate'
                              )
                                " />
                          </div>
                        </div>

                        <div class="tab-content" v-if="metricsGameTab === 'game-total_touches-tab'">
                          <div class="metrics-wrapper">
                            <h4>Total Touches</h4>

                            <div class="metrics-item">
                              <p class="title">Game Avg</p>
                              <p class="value">
                                {{
                                  Math.round(
                                    playerMakerPersonalBestAndAverages.games
                                      .total_touches
                                  )
                                }}
                              </p>
                            </div>

                            <div class="metrics-item">
                              <p class="title">Game PB</p>
                              <p class="value">
                                {{
                                  playerMakerPersonalBestAndAverages.games.pb
                                    .total_touches
                                }}
                              </p>
                            </div>

                            <div class="metrics-item">
                              <p class="session-date">
                                Session date:
                                {{
                                  formatDate(lastGameSessionData.session_date)
                                }}
                              </p>
                              <p class="title">Game Last session</p>
                              <p class="value">
                                {{ lastGameSessionData.total_touches }}
                              </p>
                            </div>

                            <Line-game-training-avg-chart class="pb-chart" v-if="playerPlayMakerGpsData.length > 0"
                              :chartData="lineGameTrainingAvgChartData(
                                playerPlayMakerGpsData,
                                'Session',
                                'total_touches',
                                'Total Touches'
                              )
                                " />
                          </div>
                        </div>

                        <div class="tab-content" v-if="metricsGameTab === 'game-left_leg_touches-tab'">
                          <div class="metrics-wrapper">
                            <h4>Left leg Touches</h4>

                            <div class="metrics-item">
                              <p class="title">Game Avg</p>
                              <p class="value">
                                {{
                                  Math.round(
                                    playerMakerPersonalBestAndAverages.games
                                      .left_leg_touches
                                  )
                                }}
                              </p>
                            </div>

                            <div class="metrics-item">
                              <p class="title">Game PB</p>
                              <p class="value">
                                {{
                                  playerMakerPersonalBestAndAverages.games.pb
                                    .left_leg_touches
                                }}
                              </p>
                            </div>

                            <div class="metrics-item">
                              <p class="session-date">
                                Session date:
                                {{
                                  formatDate(lastGameSessionData.session_date)
                                }}
                              </p>
                              <p class="title">Game Last session</p>
                              <p class="value">
                                {{ lastGameSessionData.left_leg_touches }}
                              </p>
                            </div>

                            <Line-game-training-avg-chart class="pb-chart" v-if="playerPlayMakerGpsData.length > 0"
                              :chartData="lineGameTrainingAvgChartData(
                                playerPlayMakerGpsData,
                                'Session',
                                'left_leg_touches',
                                'Left leg Touches'
                              )
                                " />
                          </div>
                        </div>

                        <div class="tab-content" v-if="metricsGameTab === 'game-right_leg_touches-tab'">
                          <div class="metrics-wrapper">
                            <h4>Right leg Touches</h4>

                            <div class="metrics-item">
                              <p class="title">Game Avg</p>
                              <p class="value">
                                {{
                                  Math.round(
                                    playerMakerPersonalBestAndAverages.games
                                      .right_leg_touches
                                  )
                                }}
                              </p>
                            </div>

                            <div class="metrics-item">
                              <p class="title">Game PB</p>
                              <p class="value">
                                {{
                                  playerMakerPersonalBestAndAverages.games.pb
                                    .right_leg_touches
                                }}
                              </p>
                            </div>

                            <div class="metrics-item">
                              <p class="session-date">
                                Session date:
                                {{
                                  formatDate(lastGameSessionData.session_date)
                                }}
                              </p>
                              <p class="title">Game Last session</p>
                              <p class="value">
                                {{ lastGameSessionData.right_leg_touches }}
                              </p>
                            </div>

                            <Line-game-training-avg-chart class="pb-chart" v-if="playerPlayMakerGpsData.length > 0"
                              :chartData="lineGameTrainingAvgChartData(
                                playerPlayMakerGpsData,
                                'Session',
                                'right_leg_touches',
                                'Right leg Touches'
                              )
                                " />
                          </div>
                        </div>

                        <div class="tab-content" v-if="metricsGameTab === 'game-releases-tab'">
                          <div class="metrics-wrapper">
                            <h4>Total Passes</h4>

                            <div class="metrics-item">
                              <p class="title">Game Avg</p>
                              <p class="value">
                                {{
                                  Math.round(
                                    playerMakerPersonalBestAndAverages.games
                                      .releases
                                  )
                                }}
                              </p>
                            </div>

                            <div class="metrics-item">
                              <p class="title">Training PB</p>
                              <p class="value">
                                {{
                                  playerMakerPersonalBestAndAverages.games.pb
                                    .releases
                                }}
                              </p>
                            </div>

                            <div class="metrics-item">
                              <p class="session-date">
                                Session date:
                                {{
                                  formatDate(lastGameSessionData.session_date)
                                }}
                              </p>
                              <p class="title">Game Last session</p>
                              <p class="value">
                                {{ lastGameSessionData.releases }}
                              </p>
                            </div>

                            <Line-game-training-avg-chart class="pb-chart" v-if="playerPlayMakerGpsData.length > 0"
                              :chartData="lineGameTrainingAvgChartData(
                                playerPlayMakerGpsData,
                                'Session',
                                'releases',
                                'Total Passes'
                              )
                                " />
                          </div>
                        </div>

                        <div class="tab-content" v-if="metricsGameTab === 'game-releases_left-tab'">
                          <div class="metrics-wrapper">
                            <h4>Left Passes</h4>

                            <div class="metrics-item">
                              <p class="title">Game Avg</p>
                              <p class="value">
                                {{
                                  Math.round(
                                    playerMakerPersonalBestAndAverages.games
                                      .releases_left
                                  )
                                }}
                              </p>
                            </div>

                            <div class="metrics-item">
                              <p class="title">Game PB</p>
                              <p class="value">
                                {{
                                  playerMakerPersonalBestAndAverages.training.pb
                                    .releases_left
                                }}
                              </p>
                            </div>

                            <div class="metrics-item">
                              <p class="session-date">
                                Session date:
                                {{
                                  formatDate(lastGameSessionData.session_date)
                                }}
                              </p>
                              <p class="title">Game Last session</p>
                              <p class="value">
                                {{ lastGameSessionData.releases_left }}
                              </p>
                            </div>

                            <Line-game-training-avg-chart class="pb-chart" v-if="playerPlayMakerGpsData.length > 0"
                              :chartData="lineGameTrainingAvgChartData(
                                playerPlayMakerGpsData,
                                'Session',
                                'releases_left',
                                'Left Passes'
                              )
                                " />
                          </div>
                        </div>

                        <div class="tab-content" v-if="metricsGameTab === 'game-releases_right-tab'">
                          <div class="metrics-wrapper">
                            <h4>Right Passes</h4>

                            <div class="metrics-item">
                              <p class="title">Game Avg</p>
                              <p class="value">
                                {{
                                  Math.round(
                                    playerMakerPersonalBestAndAverages.games
                                      .releases_right
                                  )
                                }}
                              </p>
                            </div>

                            <div class="metrics-item">
                              <p class="title">Game PB</p>
                              <p class="value">
                                {{
                                  playerMakerPersonalBestAndAverages.games.pb
                                    .releases_right
                                }}
                              </p>
                            </div>

                            <div class="metrics-item">
                              <p class="session-date">
                                Session date:
                                {{
                                  formatDate(lastGameSessionData.session_date)
                                }}
                              </p>
                              <p class="title">Game Last session</p>
                              <p class="value">
                                {{ lastGameSessionData.releases_right }}
                              </p>
                            </div>

                            <Line-game-training-avg-chart class="pb-chart" v-if="playerPlayMakerGpsData.length > 0"
                              :chartData="lineGameTrainingAvgChartData(
                                playerPlayMakerGpsData,
                                'Session',
                                'releases_right',
                                'Right Passes'
                              )
                                " />
                          </div>
                        </div>

                        <div class="tab-content" v-if="metricsGameTab === 'game-top_speed-tab'">
                          <div class="metrics-wrapper">
                            <h4>Top speed</h4>

                            <div class="metrics-item">
                              <p class="title">Game Avg</p>
                              <p class="value">
                                {{
                                  playerMakerPersonalBestAndAverages.games.pb
                                    .top_speed
                                }}
                                <span>M/S</span>
                              </p>
                            </div>

                            <div class="metrics-item">
                              <p class="title">Game PB</p>
                              <p class="value">
                                {{
                                  (
                                    Math.round(
                                      playerMakerPersonalBestAndAverages.games
                                        .pb.top_speed * 100
                                    ) / 100
                                  ).toFixed(2)
                                }}
                                <span>M/S</span>
                              </p>
                            </div>

                            <div class="metrics-item">
                              <p class="session-date">
                                Session date:
                                {{
                                  formatDate(lastGameSessionData.session_date)
                                }}
                              </p>
                              <p class="title">Game Last session</p>
                              <p class="value">
                                {{
                                  (
                                    Math.round(
                                      lastGameSessionData.top_speed * 100
                                    ) / 100
                                  ).toFixed(2)
                                }}
                                <span>M/S</span>
                              </p>
                            </div>

                            <Line-game-training-avg-chart class="pb-chart" v-if="playerPlayMakerGpsData.length > 0"
                              :chartData="lineGameTrainingAvgChartData(
                                playerPlayMakerGpsData,
                                'Session',
                                'top_speed',
                                'Top speed'
                              )
                                " />
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>

                <div class="training-stats">
                  <div class="game-stats">
                    <div class="button-wrapper-top">
                      <b-button class="main-button" v-if="
                        userData.userType !== 'Admin' &&
                        userData.userType !== 'Coach'
                      " @click="openTrainingPerformanceModal()" size="lg" variant="outline-success" v-b-tooltip.hover
                        title="Submit training report"><b-icon icon="file-text" aria-hidden="true"></b-icon>
                      </b-button>

                      <b-button size="lg" class="main-button" variant="outline-success" v-b-tooltip.hover @click="
                        download_csv(
                          seasonsTraningPerformanceReportsForUser,
                          'player-training-data'
                        )
                        " title="Export player training data to csv"><b-icon icon="download"
                          aria-hidden="true"></b-icon></b-button>

                      <!--<b-button
                            size="lg"
                            class="main-button"
                            variant="outline-success"
                            v-b-tooltip.hover
                            title="Import player training data"
                            ><b-icon icon="upload" aria-hidden="true"></b-icon
                          ></b-button>-->

                      <b-button size="lg" class="main-button" variant="outline-success" v-b-tooltip.hover
                        title="Email player training data"><b-icon icon="envelope"
                          aria-hidden="true"></b-icon></b-button>
                    </div>
                    <div class="training-scores stats-wrapper">
                      <div class="training-scores-data">
                        <h3 class="no-margin">
                          Your training stats for this season
                        </h3>

                        <p class="small-date-player-coach days-red-warning" v-if="
                          trainingPerformanceData.created_date === undefined
                        ">
                          <b-icon icon="exclamation-circle" aria-hidden="true"></b-icon>
                          You have not submitted a training report. Please
                          submit one now.
                        </p>
                        <p v-else class="small-date-player-coach" :class="`days-${checkDateTimeFromNow(
                          trainingPerformanceData.created_date
                        )}`">
                          You last submitted a training report on
                          {{ formatDate(playerMoodData.created_date) }}
                        </p>

                        <ul v-if="trainingPerformanceData.traningAttended > 0" class="key-value-list">
                          <li>
                            <span>Sessions attended:</span>{{ trainingPerformanceData.traningAttended }}
                          </li>
                          <li>
                            <span>Training hours this season:</span>{{ trainingPerformanceData.time }} mins
                          </li>
                          <li>
                            <span>Last Training session rating:</span>{{ trainingPerformanceData.enjoyment }}
                          </li>

                          <span v-if="
                            gamePerformanceData.created_date !== undefined
                          " class="small-date-player-coach full-width-error" :class="`days-${checkDateTimeFromNow(
                              gamePerformanceData.created_date
                            )}`">
                            Updated on
                            {{
                              formatDate(gamePerformanceData.created_date)
                            }}</span>
                        </ul>
                        <p v-else>
                          You have not attened any training sessions this
                          seasion. You must attened at least one training
                          session and submit a traiing repoort to view training
                          statistics.
                        </p>
                      </div>

                      <div class="rpe-scal-wrapper">
                        <h3>Last training score</h3>

                        <div v-if="trainingPerformanceData.effort == '10'" class="rpe-scale-result ten">
                          <span class="big-number"><b-icon icon="stopwatch" aria-hidden="true"></b-icon>
                            10</span>
                          <h3>MAX EFFORT ACTIVITY</h3>
                          <p>
                            Enjoyment rating:
                            {{ trainingPerformanceData.enjoyment }}
                          </p>
                        </div>

                        <div v-if="trainingPerformanceData.effort == '9'" class="rpe-scale-result nine">
                          <span class="big-number"><b-icon icon="stopwatch" aria-hidden="true"></b-icon>
                            9</span>
                          <h3>VERY HARD ACTIVITY</h3>
                          <p>
                            Enjoyment rating:
                            {{ trainingPerformanceData.enjoyment }}
                          </p>
                        </div>

                        <div v-if="trainingPerformanceData.effort == '8'" class="rpe-scale-result eight">
                          <span class="big-number"><b-icon icon="stopwatch" aria-hidden="true"></b-icon>
                            8</span>
                          <h3>VIGOROUS ACTIVITY</h3>
                          <p>
                            Enjoyment rating:
                            {{ trainingPerformanceData.enjoyment }}
                          </p>
                        </div>

                        <div v-if="trainingPerformanceData.effort == '7'" class="rpe-scale-result four-six">
                          <span class="big-number"><b-icon icon="stopwatch" aria-hidden="true"></b-icon>
                            7</span>
                          <h3>MODERATE ACTIVITY</h3>
                          <p>
                            Enjoyment rating:
                            {{ trainingPerformanceData.enjoyment }}
                          </p>
                        </div>

                        <div v-if="trainingPerformanceData.effort == '6'" class="rpe-scale-result two-three">
                          <span class="big-number"><b-icon icon="stopwatch" aria-hidden="true"></b-icon>
                            6</span>
                          <h3>LIGHT ACTIVITY</h3>
                          <p>
                            Enjoyment rating:
                            {{ trainingPerformanceData.enjoyment }}
                          </p>
                        </div>

                        <div v-if="trainingPerformanceData.effort == '5'" class="rpe-scale-result one">
                          <span class="big-number"><b-icon icon="stopwatch" aria-hidden="true"></b-icon>
                            5</span>
                          <h3>VERY LIGHT ACTIVITY</h3>
                          <p>
                            Enjoyment rating:
                            {{ trainingPerformanceData.enjoyment }}
                          </p>
                        </div>
                        <div v-if="trainingPerformanceData.effort === undefined">
                          <div class="empty-state">
                            <img src="../img/emptyStateIllustration.svg" />
                            <p>No session effort rating data</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="split-module">
                      <div class="all-training-reports">
                        <h3>Season training stats</h3>
                        <a href="javascript:;" @click="
                          openAllTrainingReportsModal(
                            'allTrainingReportsModal'
                          )
                          "><img class="large-icon-shape" src="../img/training-ass.svg" alt="" />
                          View all this season training stats</a>
                      </div>
                    </div>

                    <section class="session-metrics" v-if="
                      Object.keys(playerPersonalBestAndAverages).length > 0
                    ">
                      <div v-if="
                        lastTrainingSessionData.hasOwnProperty(
                          'total_absolute_high_speed_distance'
                        ) && under16 === false
                      ">
                        <h3>Training avg</h3>

                        <b-form-select class="mb-3" v-model="metricsTrainingTab">
                          <b-form-select-option :value="null">Please select an option</b-form-select-option>
                          <b-form-select-option value="training-distance-tab">Distance</b-form-select-option>
                          <b-form-select-option value="training-hsr-tab">High Speed Running</b-form-select-option>
                          <b-form-select-option value="training-accelerations-tab">Accelerations</b-form-select-option>
                          <b-form-select-option value="training-decelerations-tab">Decelerations</b-form-select-option>
                          <b-form-select-option value="training-intensity-tab">Intensity</b-form-select-option>
                          <b-form-select-option value="training-topSpeed-tab">% Top Speed</b-form-select-option>
                          <b-form-select-option value="training-topSpeed-tab">Top Speed</b-form-select-option>
                        </b-form-select>

                        <div class="tabs-wrapper">
                          <div class="tab-content" v-if="
                            metricsTrainingTab === 'training-distance-tab'
                          ">
                            <div class="metrics-wrapper">
                              <h4>Total distance</h4>

                              <div class="metrics-item">
                                <p class="title">Training Avg</p>
                                <p class="value">
                                  {{
                                    Math.round(
                                      playerPersonalBestAndAverages.training
                                        .session_distance
                                    )
                                  }}
                                  <span>Meters</span>
                                </p>
                              </div>

                              <div class="metrics-item">
                                <p class="title">Training PB</p>
                                <p class="value">
                                  {{
                                    playerPersonalBestAndAverages.training.pb
                                      .session_distance
                                  }}
                                  <span>Meters</span>
                                </p>
                              </div>

                              <div class="metrics-item">
                                <p class="session-date">
                                  Session date:
                                  {{
                                    formatDate(
                                      lastTrainingSessionData.session_date
                                    )
                                  }}
                                </p>
                                <p class="title">Training Last session</p>
                                <p class="value">
                                  {{ lastTrainingSessionData.session_distance }}
                                  <span>Meters</span>
                                </p>
                              </div>
                              <Line-game-training-avg-chart class="table-responsive" v-if="playerDailyMins.length > 0"
                                :chartData="lineGameTrainingAvgChartData(
                                  playerDailyMins,
                                  'training',
                                  'session_distance',
                                  'Session Distance'
                                )
                                  " />
                            </div>
                          </div>

                          <div class="tab-content" v-if="metricsTrainingTab === 'training-hsr-tab'">
                            <div class="metrics-wrapper">
                              <h4>High Speed Running</h4>

                              <div class="metrics-item">
                                <p class="title">Training Avg</p>
                                <p class="value">
                                  {{
                                    Math.round(
                                      playerPersonalBestAndAverages.training
                                        .total_absolute_high_speed_distance
                                    )
                                  }}
                                  <span>Meters</span>
                                </p>
                              </div>

                              <div class="metrics-item">
                                <p class="title">Training PB</p>
                                <p class="value">
                                  {{
                                    playerPersonalBestAndAverages.training.pb
                                      .total_absolute_high_speed_distance
                                  }}
                                  <span>Meters</span>
                                </p>
                              </div>

                              <div class="metrics-item">
                                <p class="session-date">
                                  Session date:
                                  {{
                                    formatDate(
                                      lastTrainingSessionData.session_date
                                    )
                                  }}
                                </p>
                                <p class="title">Training Last session</p>
                                <p class="value">
                                  {{
                                    lastTrainingSessionData.total_absolute_high_speed_distance
                                  }}
                                  <span>Meters</span>
                                </p>
                              </div>

                              <Line-game-training-avg-chart v-if="playerDailyMins.length > 0" :chartData="lineGameTrainingAvgChartData(
                                playerDailyMins,
                                'training',
                                'total_absolute_high_speed_distance',
                                'High Speed Running'
                              )
                                " />
                            </div>
                          </div>

                          <div class="tab-content" v-if="
                            metricsTrainingTab ===
                            'training-accelerations-tab'
                          ">
                            <div class="metrics-wrapper">
                              <h4>Accelerations</h4>

                              <div class="metrics-item">
                                <p class="title">Training Avg</p>
                                <p class="value">
                                  {{
                                    Math.round(
                                      playerPersonalBestAndAverages.training
                                        .accelerations_total_efforts
                                    )
                                  }}
                                </p>
                              </div>

                              <div class="metrics-item">
                                <p class="title">Training PB</p>
                                <p class="value">
                                  {{
                                    playerPersonalBestAndAverages.training.pb
                                      .accelerations_total_efforts
                                  }}
                                  <span>Meters</span>
                                </p>
                              </div>

                              <div class="metrics-item">
                                <p class="session-date">
                                  Session date:
                                  {{
                                    formatDate(
                                      lastTrainingSessionData.session_date
                                    )
                                  }}
                                </p>
                                <p class="title">Training Last session</p>
                                <p class="value">
                                  {{
                                    lastTrainingSessionData.accelerations_total_efforts
                                  }}
                                  <span>Meters</span>
                                </p>
                              </div>

                              <Line-game-training-avg-chart v-if="playerDailyMins.length > 0" :chartData="lineGameTrainingAvgChartData(
                                playerDailyMins,
                                'training',
                                'accelerations_total_efforts',
                                'Accelerations'
                              )
                                " />
                            </div>
                          </div>

                          <div class="tab-content" v-if="
                            metricsTrainingTab ===
                            'training-decelerations-tab'
                          ">
                            <div class="metrics-wrapper">
                              <h4>Decelerations</h4>

                              <div class="metrics-item">
                                <p class="title">Training Avg</p>
                                <p class="value">
                                  {{
                                    Math.round(
                                      playerPersonalBestAndAverages.training
                                        .decelerations_total_efforts
                                    )
                                  }}
                                </p>
                              </div>

                              <div class="metrics-item">
                                <p class="title">Training PB</p>
                                <p class="value">
                                  {{
                                    playerPersonalBestAndAverages.training.pb
                                      .decelerations_total_efforts
                                  }}
                                  <span>Meters</span>
                                </p>
                              </div>

                              <div class="metrics-item">
                                <p class="session-date">
                                  Session date:
                                  {{
                                    formatDate(
                                      lastTrainingSessionData.session_date
                                    )
                                  }}
                                </p>
                                <p class="title">Training Last session</p>
                                <p class="value">
                                  {{
                                    lastTrainingSessionData.decelerations_total_efforts
                                  }}
                                  <span>Meters</span>
                                </p>
                              </div>

                              <Line-game-training-avg-chart v-if="playerDailyMins.length > 0" :chartData="lineGameTrainingAvgChartData(
                                playerDailyMins,
                                'training',
                                'decelerations_total_efforts',
                                'Decelerations'
                              )
                                " />
                            </div>
                          </div>

                          <div class="tab-content" v-if="
                            metricsTrainingTab === 'training-intensity-tab'
                          ">
                            <div class="metrics-wrapper">
                              <h4>Intensity</h4>

                              <div class="metrics-item">
                                <p class="title">Training Avg</p>
                                <p class="value">
                                  {{
                                    Math.round(
                                      playerPersonalBestAndAverages.training
                                        .meterage_per_minute
                                    )
                                  }}
                                  <span>M/Min</span>
                                </p>
                              </div>

                              <div class="metrics-item">
                                <p class="title">Training PB</p>
                                <p class="value">
                                  {{
                                    playerPersonalBestAndAverages.training.pb
                                      .meterage_per_minute
                                  }}
                                  <span>M/Min</span>
                                </p>
                              </div>

                              <div class="metrics-item">
                                <p class="session-date">
                                  Session date:
                                  {{
                                    formatDate(
                                      lastTrainingSessionData.session_date
                                    )
                                  }}
                                </p>
                                <p class="title">Training Last session</p>
                                <p class="value">
                                  {{
                                    lastTrainingSessionData.meterage_per_minute
                                  }}
                                  <span>M/Min</span>
                                </p>
                              </div>

                              <Line-game-training-avg-chart v-if="playerDailyMins.length > 0" :chartData="lineGameTrainingAvgChartData(
                                playerDailyMins,
                                'training',
                                'meterage_per_minute',
                                'Intensity'
                              )
                                " />
                            </div>
                          </div>

                          <div class="tab-content" v-if="
                            metricsTrainingTab === 'training-perTopSpeed-tab'
                          ">
                            <div class="metrics-wrapper">
                              <h4>% Top Speed</h4>

                              <div class="metrics-item">
                                <p class="title">Training Avg</p>
                                <p class="value">
                                  {{
                                    playerPersonalBestAndAverages.training
                                      .max_vel_max
                                  }}
                                  <span>Meters</span>
                                </p>
                              </div>

                              <div class="metrics-item">
                                <p class="title">Training PB</p>
                                <p class="value">
                                  {{
                                    playerPersonalBestAndAverages.training.pb
                                      .max_vel_max
                                  }}
                                  <span>Meters</span>
                                </p>
                              </div>

                              <div class="metrics-item">
                                <p class="session-date">
                                  Session date:
                                  {{
                                    formatDate(
                                      lastTrainingSessionData.session_date
                                    )
                                  }}
                                </p>
                                <p class="title">Training Last session</p>
                                <p class="value">
                                  {{ lastTrainingSessionData.max_vel_max }}
                                  <span>Meters</span>
                                </p>
                              </div>

                              <Line-game-training-avg-chart v-if="playerDailyMins.length > 0" :chartData="lineGameTrainingAvgChartData(
                                playerDailyMins,
                                'training',
                                'max_vel_max',
                                '% Top Speed'
                              )
                                " />
                            </div>
                          </div>

                          <div class="tab-content" v-if="
                            metricsTrainingTab === 'training-topSpeed-tab'
                          ">
                            <div class="metrics-wrapper">
                              <h4>Top Speed</h4>

                              <div class="metrics-item">
                                <p class="title">Training Avg</p>
                                <p class="value">
                                  {{
                                    playerPersonalBestAndAverages.maximum_velocity
                                  }}
                                  <span>M/S</span>
                                </p>
                              </div>

                              <div class="metrics-item">
                                <p class="title">Training PB</p>
                                <p class="value">
                                  {{
                                    (
                                      Math.round(
                                        playerPersonalBestAndAverages.training
                                          .pb.maximum_velocity * 100
                                      ) / 100
                                    ).toFixed(2)
                                  }}
                                  <span>M/S</span>
                                </p>
                              </div>

                              <div class="metrics-item">
                                <p class="session-date">
                                  Session date:
                                  {{
                                    formatDate(
                                      lastTrainingSessionData.session_date
                                    )
                                  }}
                                </p>
                                <p class="title">Training Last session</p>
                                <p class="value">
                                  {{
                                    (
                                      Math.round(
                                        lastTrainingSessionData.maximum_velocity *
                                        100
                                      ) / 100
                                    ).toFixed(2)
                                  }}
                                  <span>M/S</span>
                                </p>
                              </div>
                            </div>

                            <Line-game-training-avg-chart v-if="playerDailyMins.length > 0" :chartData="lineGameTrainingAvgChartData(
                              playerDailyMins,
                              'training',
                              'maximum_velocity',
                              'Top Speed'
                            )
                              " />
                          </div>
                        </div>
                      </div>

                      <div v-else>
                        <h3 v-if="
                          Object.keys(playerMakerPersonalBestAndAverages)
                            .length > 0
                        ">
                          Session effort score and stats
                        </h3>

                        <b-form-select class="mb-3" v-model="metricsTrainingTab" v-if="
                          Object.keys(playerMakerPersonalBestAndAverages)
                            .length > 0
                        ">
                          <b-form-select-option :value="null">Please select an option</b-form-select-option>
                          <b-form-select-option value="training-phase_duration_min-tab">Session
                            Duration</b-form-select-option>
                          <b-form-select-option value="training-distance_covered-tab">Total
                            Distance</b-form-select-option>
                          <b-form-select-option value="training-work_rate-tab">Work Rate</b-form-select-option>
                          <b-form-select-option value="training-total_touches-tab">Total Touches</b-form-select-option>
                          <b-form-select-option value="training-left_leg_touches-tab">Left leg
                            Touches</b-form-select-option>
                          <b-form-select-option value="training-right_leg_touches-tab">Right leg
                            Touches</b-form-select-option>
                          <b-form-select-option value="training-releases-tab">Total Passes</b-form-select-option>
                          <b-form-select-option value="training-releases_left-tab">Left Passes</b-form-select-option>
                          <b-form-select-option value="training-releases_right-tab">Right Passes</b-form-select-option>
                          <b-form-select-option value="training-top_speed-tab">Top speed</b-form-select-option>
                        </b-form-select>

                        <div class="tabs-wrapper" v-if="
                          Object.keys(playerMakerPersonalBestAndAverages)
                            .length > 0
                        ">
                          <div class="tab-content" v-if="
                            metricsTrainingTab ===
                            'training-phase_duration_min-tab'
                          ">
                            <div class="metrics-wrapper">
                              <h4>Session Duration</h4>

                              <div class="metrics-item">
                                <p class="title">Training Avg</p>
                                <p class="value">
                                  {{
                                    Math.round(
                                      playerMakerPersonalBestAndAverages
                                        .training.phase_duration_min
                                    )
                                  }}
                                  <span>Mins</span>
                                </p>
                              </div>

                              <div class="metrics-item">
                                <p class="title">Training PB</p>
                                <p class="value">
                                  {{
                                    playerMakerPersonalBestAndAverages.training
                                      .pb.phase_duration_min
                                  }}
                                  <span>Mins</span>
                                </p>
                              </div>

                              <div class="metrics-item">
                                <p class="session-date">
                                  Session date:
                                  {{
                                    formatDate(
                                      lastTrainingSessionData.session_date
                                    )
                                  }}
                                </p>
                                <p class="title">Training Last session</p>
                                <p class="value">
                                  {{
                                    lastTrainingSessionData.phase_duration_min
                                  }}
                                  <span>Mins</span>
                                </p>
                              </div>

                              <Line-game-training-avg-chart class="pb-chart" v-if="playerPlayMakerGpsData.length > 0"
                                :chartData="lineGameTrainingAvgChartData(
                                  playerPlayMakerGpsData,
                                  'training',
                                  'phase_duration_min',
                                  'Session Duration'
                                )
                                  " />
                            </div>
                          </div>

                          <div class="tab-content" v-if="
                            metricsTrainingTab ===
                            'training-distance_covered-tab'
                          ">
                            <div class="metrics-wrapper">
                              <h4>Total Distance</h4>

                              <div class="metrics-item">
                                <p class="title">Training Avg</p>
                                <p class="value">
                                  {{
                                    Math.round(
                                      playerMakerPersonalBestAndAverages
                                        .training.distance_covered
                                    )
                                  }}
                                  <span>Meters</span>
                                </p>
                              </div>

                              <div class="metrics-item">
                                <p class="title">Training PB</p>
                                <p class="value">
                                  {{
                                    playerMakerPersonalBestAndAverages.training
                                      .pb.distance_covered
                                  }}
                                  <span>Meters</span>
                                </p>
                              </div>

                              <div class="metrics-item">
                                <p class="session-date">
                                  Session date:
                                  {{
                                    formatDate(
                                      lastTrainingSessionData.session_date
                                    )
                                  }}
                                </p>
                                <p class="title">Training Last session</p>
                                <p class="value">
                                  {{ lastTrainingSessionData.distance_covered }}
                                  <span>Meters</span>
                                </p>
                              </div>

                              <Line-game-training-avg-chart class="pb-chart" v-if="playerPlayMakerGpsData.length > 0"
                                :chartData="lineGameTrainingAvgChartData(
                                  playerPlayMakerGpsData,
                                  'training',
                                  'distance_covered',
                                  'Total Distance'
                                )
                                  " />
                            </div>
                          </div>

                          <div class="tab-content" v-if="
                            metricsTrainingTab === 'training-work_rate-tab'
                          ">
                            <div class="metrics-wrapper">
                              <h4>Work Rate</h4>

                              <div class="metrics-item">
                                <p class="title">Training Avg</p>
                                <p class="value">
                                  {{
                                    Math.round(
                                      playerMakerPersonalBestAndAverages
                                        .training.work_rate
                                    )
                                  }}
                                  <span>M/Min</span>
                                </p>
                              </div>

                              <div class="metrics-item">
                                <p class="title">Training PB</p>
                                <p class="value">
                                  {{
                                    playerMakerPersonalBestAndAverages.training
                                      .pb.work_rate
                                  }}
                                  <span>M/Min</span>
                                </p>
                              </div>

                              <div class="metrics-item">
                                <p class="session-date">
                                  Session date:
                                  {{
                                    formatDate(
                                      lastTrainingSessionData.session_date
                                    )
                                  }}
                                </p>
                                <p class="title">Training Last session</p>
                                <p class="value">
                                  {{ lastTrainingSessionData.work_rate }}
                                  <span>M/Min</span>
                                </p>
                              </div>

                              <Line-game-training-avg-chart class="pb-chart" v-if="playerPlayMakerGpsData.length > 0"
                                :chartData="lineGameTrainingAvgChartData(
                                  playerPlayMakerGpsData,
                                  'training',
                                  'work_rate',
                                  'Work Rate'
                                )
                                  " />
                            </div>
                          </div>

                          <div class="tab-content" v-if="
                            metricsTrainingTab ===
                            'training-total_touches-tab'
                          ">
                            <div class="metrics-wrapper">
                              <h4>Total Touches</h4>

                              <div class="metrics-item">
                                <p class="title">Training Avg</p>
                                <p class="value">
                                  {{
                                    Math.round(
                                      playerMakerPersonalBestAndAverages
                                        .training.total_touches
                                    )
                                  }}
                                </p>
                              </div>

                              <div class="metrics-item">
                                <p class="title">Training PB</p>
                                <p class="value">
                                  {{
                                    playerMakerPersonalBestAndAverages.training
                                      .pb.total_touches
                                  }}
                                </p>
                              </div>

                              <div class="metrics-item">
                                <p class="session-date">
                                  Session date:
                                  {{
                                    formatDate(
                                      lastTrainingSessionData.session_date
                                    )
                                  }}
                                </p>
                                <p class="title">Training Last session</p>
                                <p class="value">
                                  {{ lastTrainingSessionData.total_touches }}
                                </p>
                              </div>

                              <Line-game-training-avg-chart class="pb-chart" v-if="playerPlayMakerGpsData.length > 0"
                                :chartData="lineGameTrainingAvgChartData(
                                  playerPlayMakerGpsData,
                                  'training',
                                  'total_touches',
                                  'Total Touches'
                                )
                                  " />
                            </div>
                          </div>

                          <div class="tab-content" v-if="
                            metricsTrainingTab ===
                            'training-left_leg_touches-tab'
                          ">
                            <div class="metrics-wrapper">
                              <h4>Left leg Touches</h4>

                              <div class="metrics-item">
                                <p class="title">Training Avg</p>
                                <p class="value">
                                  {{
                                    Math.round(
                                      playerMakerPersonalBestAndAverages
                                        .training.left_leg_touches
                                    )
                                  }}
                                </p>
                              </div>

                              <div class="metrics-item">
                                <p class="title">Training PB</p>
                                <p class="value">
                                  {{
                                    playerMakerPersonalBestAndAverages.training
                                      .pb.left_leg_touches
                                  }}
                                </p>
                              </div>

                              <div class="metrics-item">
                                <p class="session-date">
                                  Session date:
                                  {{
                                    formatDate(
                                      lastTrainingSessionData.session_date
                                    )
                                  }}
                                </p>
                                <p class="title">Training Last session</p>
                                <p class="value">
                                  {{ lastTrainingSessionData.left_leg_touches }}
                                </p>
                              </div>

                              <Line-game-training-avg-chart class="pb-chart" v-if="playerPlayMakerGpsData.length > 0"
                                :chartData="lineGameTrainingAvgChartData(
                                  playerPlayMakerGpsData,
                                  'training',
                                  'left_leg_touches',
                                  'Left leg Touches'
                                )
                                  " />
                            </div>
                          </div>

                          <div class="tab-content" v-if="
                            metricsTrainingTab ===
                            'training-right_leg_touches-tab'
                          ">
                            <div class="metrics-wrapper">
                              <h4>Right leg Touches</h4>

                              <div class="metrics-item">
                                <p class="title">Training Avg</p>
                                <p class="value">
                                  {{
                                    Math.round(
                                      playerMakerPersonalBestAndAverages
                                        .training.right_leg_touches
                                    )
                                  }}
                                </p>
                              </div>

                              <div class="metrics-item">
                                <p class="title">Training PB</p>
                                <p class="value">
                                  {{
                                    playerMakerPersonalBestAndAverages.training
                                      .pb.right_leg_touches
                                  }}
                                </p>
                              </div>

                              <div class="metrics-item">
                                <p class="session-date">
                                  Session date:
                                  {{
                                    formatDate(
                                      lastTrainingSessionData.session_date
                                    )
                                  }}
                                </p>
                                <p class="title">Training Last session</p>
                                <p class="value">
                                  {{
                                    lastTrainingSessionData.right_leg_touches
                                  }}
                                </p>
                              </div>

                              <Line-game-training-avg-chart class="pb-chart" v-if="playerPlayMakerGpsData.length > 0"
                                :chartData="lineGameTrainingAvgChartData(
                                  playerPlayMakerGpsData,
                                  'training',
                                  'right_leg_touches',
                                  'Right leg Touches'
                                )
                                  " />
                            </div>
                          </div>

                          <div class="tab-content" v-if="
                            metricsTrainingTab === 'training-releases-tab'
                          ">
                            <div class="metrics-wrapper">
                              <h4>Total Passes</h4>

                              <div class="metrics-item">
                                <p class="title">Training Avg</p>
                                <p class="value">
                                  {{
                                    Math.round(
                                      playerMakerPersonalBestAndAverages
                                        .training.releases
                                    )
                                  }}
                                </p>
                              </div>

                              <div class="metrics-item">
                                <p class="title">Training PB</p>
                                <p class="value">
                                  {{
                                    playerMakerPersonalBestAndAverages.training
                                      .pb.releases
                                  }}
                                </p>
                              </div>

                              <div class="metrics-item">
                                <p class="session-date">
                                  Session date:
                                  {{
                                    formatDate(
                                      lastTrainingSessionData.session_date
                                    )
                                  }}
                                </p>
                                <p class="title">Training Last session</p>
                                <p class="value">
                                  {{ lastTrainingSessionData.releases }}
                                </p>
                              </div>

                              <Line-game-training-avg-chart class="pb-chart" v-if="playerPlayMakerGpsData.length > 0"
                                :chartData="lineGameTrainingAvgChartData(
                                  playerPlayMakerGpsData,
                                  'training',
                                  'releases',
                                  'Total Passes'
                                )
                                  " />
                            </div>
                          </div>

                          <div class="tab-content" v-if="
                            metricsTrainingTab ===
                            'training-releases_left-tab'
                          ">
                            <div class="metrics-wrapper">
                              <h4>Left Passes</h4>

                              <div class="metrics-item">
                                <p class="title">Training Avg</p>
                                <p class="value">
                                  {{
                                    Math.round(
                                      playerMakerPersonalBestAndAverages
                                        .training.releases_left
                                    )
                                  }}
                                </p>
                              </div>

                              <div class="metrics-item">
                                <p class="title">Training PB</p>
                                <p class="value">
                                  {{
                                    playerMakerPersonalBestAndAverages.training
                                      .pb.releases_left
                                  }}
                                </p>
                              </div>

                              <div class="metrics-item">
                                <p class="session-date">
                                  Session date:
                                  {{
                                    formatDate(
                                      lastTrainingSessionData.session_date
                                    )
                                  }}
                                </p>
                                <p class="title">Training Last session</p>
                                <p class="value">
                                  {{ lastTrainingSessionData.releases_left }}
                                </p>
                              </div>

                              <Line-game-training-avg-chart class="pb-chart" v-if="playerPlayMakerGpsData.length > 0"
                                :chartData="lineGameTrainingAvgChartData(
                                  playerPlayMakerGpsData,
                                  'training',
                                  'releases_left',
                                  'Left Passes'
                                )
                                  " />
                            </div>
                          </div>

                          <div class="tab-content" v-if="
                            metricsTrainingTab ===
                            'training-releases_right-tab'
                          ">
                            <div class="metrics-wrapper">
                              <h4>Right Passes</h4>

                              <div class="metrics-item">
                                <p class="title">Training Avg</p>
                                <p class="value">
                                  {{
                                    Math.round(
                                      playerMakerPersonalBestAndAverages
                                        .training.releases_right
                                    )
                                  }}
                                </p>
                              </div>

                              <div class="metrics-item">
                                <p class="title">Training PB</p>
                                <p class="value">
                                  {{
                                    playerMakerPersonalBestAndAverages.training
                                      .pb.releases_right
                                  }}
                                </p>
                              </div>

                              <div class="metrics-item">
                                <p class="session-date">
                                  Session date:
                                  {{
                                    formatDate(
                                      lastTrainingSessionData.session_date
                                    )
                                  }}
                                </p>
                                <p class="title">Training Last session</p>
                                <p class="value">
                                  {{ lastTrainingSessionData.releases_right }}
                                </p>
                              </div>

                              <Line-game-training-avg-chart class="pb-chart" v-if="playerPlayMakerGpsData.length > 0"
                                :chartData="lineGameTrainingAvgChartData(
                                  playerPlayMakerGpsData,
                                  'training',
                                  'releases_right',
                                  'Right Passes'
                                )
                                  " />
                            </div>
                          </div>

                          <div class="tab-content" v-if="
                            metricsTrainingTab === 'training-top_speed-tab'
                          ">
                            <div class="metrics-wrapper">
                              <h4>Top speed</h4>

                              <div class="metrics-item">
                                <p class="title">Training Avg</p>
                                <p class="value">
                                  {{
                                    playerMakerPersonalBestAndAverages.training
                                      .pb.top_speed
                                  }}
                                  <span>M/S</span>
                                </p>
                              </div>

                              <div class="metrics-item">
                                <p class="title">Training PB</p>
                                <p class="value">
                                  {{
                                    (
                                      Math.round(
                                        playerMakerPersonalBestAndAverages
                                          .training.pb.top_speed * 100
                                      ) / 100
                                    ).toFixed(2)
                                  }}
                                  <span>M/S</span>
                                </p>
                              </div>

                              <div class="metrics-item">
                                <p class="session-date">
                                  Session date:
                                  {{
                                    formatDate(
                                      lastTrainingSessionData.session_date
                                    )
                                  }}
                                </p>
                                <p class="title">Training Last session</p>
                                <p class="value">
                                  {{
                                    (
                                      Math.round(
                                        lastTrainingSessionData.top_speed * 100
                                      ) / 100
                                    ).toFixed(2)
                                  }}
                                  <span>M/S</span>
                                </p>
                              </div>

                              <Line-game-training-avg-chart class="pb-chart" v-if="playerPlayMakerGpsData.length > 0"
                                :chartData="lineGameTrainingAvgChartData(
                                  playerPlayMakerGpsData,
                                  'training',
                                  'top_speed',
                                  'Top speed'
                                )
                                  " />
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>

                <div class="mins-stats-wrapper visual-split-fade">
                  <h3>Players minutes and stats</h3>
                  <div class="cal-buttons-wrapper">
                    <!--<b-button size="lg" class="main-button no-margin-bottom" variant="outline-success" v-b-tooltip.hover
                      @click="openImportCSVModal()" title="Import player csv data"><b-icon icon="cloud-upload"
                        aria-hidden="true"></b-icon></b-button>-->

                    <a class="cal-buttons" href="javascript:;" @click="changeMonth(-1, 'minsData')">Previous month</a>
                    <a class="cal-buttons" href="javascript:;" @click="changeMonth(1, 'minsData')">Next month</a>

                    <a class="cal-buttons" href="javascript:;" @click="showTrendsGraph()">View trends</a>
                  </div>
                  <div class="date-year">
                    {{ getMonthName(minsData.month) }} | {{ minsData.year }}
                  </div>

                  <div class="mins-stats-view">
                    <div v-for="(weekDay, index) in minsData.weekDays" :key="index" class="mins-stats-col-day-wrapper">
                      <div class="mins-stats-col-day">
                        <div class="head">
                          {{ getDayFromDate(weekDay.date) }}
                        </div>
                        <div v-if="weekDay.minsTotal !== null" class="body" :id="weekDay.id">
                          <p>
                            <span>{{ weekDay.minsTotal }}</span> mins
                          </p>

                          <div class="buttons-wrapper">
                            <b-button size="lg" class="main-button" variant="outline-success" v-b-tooltip.hover
                              title="View players stats" @click="showDayStatsBreakDown(weekDay)"><b-icon icon="eye"
                                aria-hidden="true"></b-icon></b-button>

                            <b-button size="lg" class="main-button" variant="outline-warning" v-b-tooltip.hover
                              title="Edit players stats" @click="openaddDayStatsDataModal(weekDay, true)"><b-icon
                                icon="pencil" aria-hidden="true"></b-icon></b-button>
                          </div>
                        </div>
                        <div v-else class="body">
                          <p>No data</p>
                          <b-button size="lg" class="main-button" variant="outline-success" v-b-tooltip.hover
                            title="Add players stats" @click="openaddDayStatsDataModal(weekDay, false)"><b-icon
                              icon="plus" aria-hidden="true"></b-icon></b-button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="totals">
                    <div class="total">
                      <span>week 1 totals:</span>
                      <p class="green-totals" v-if="
                        playerDailyMinsWeek1Total >= 0 &&
                        playerDailyMinsWeek1Total <= 1300
                      ">
                        {{ playerDailyMinsWeek1Total }}
                      </p>
                      <p class="amber-totals" v-if="
                        playerDailyMinsWeek1Total > 1300 &&
                        playerDailyMinsWeek1Total <= 1400
                      ">
                        {{ playerDailyMinsWeek1Total }}
                      </p>
                      <p class="red-totals" v-if="playerDailyMinsWeek1Total > 1400">
                        {{ playerDailyMinsWeek1Total }}
                      </p>
                    </div>
                    <div class="total">
                      <span>week 2 totals:</span>
                      <p class="green-totals" v-if="
                        playerDailyMinsWeek2Total >= 0 &&
                        playerDailyMinsWeek2Total <= 1300
                      ">
                        {{ playerDailyMinsWeek2Total }}
                      </p>
                      <p class="amber-totals" v-if="
                        playerDailyMinsWeek2Total > 1300 &&
                        playerDailyMinsWeek2Total <= 1400
                      ">
                        {{ playerDailyMinsWeek2Total }}
                      </p>
                      <p class="red-totals" v-if="playerDailyMinsWeek2Total > 1400">
                        {{ playerDailyMinsWeek2Total }}
                      </p>
                    </div>
                    <div class="total">
                      <span>week 3 totals:</span>
                      <p class="green-totals" v-if="
                        playerDailyMinsWeek3Total >= 0 &&
                        playerDailyMinsWeek3Total <= 1300
                      ">
                        {{ playerDailyMinsWeek3Total }}
                      </p>
                      <p class="amber-totals" v-if="
                        playerDailyMinsWeek3Total > 1300 &&
                        playerDailyMinsWeek3Total <= 1400
                      ">
                        {{ playerDailyMinsWeek3Total }}
                      </p>
                      <p class="red-totals" v-if="playerDailyMinsWeek3Total > 1400">
                        {{ playerDailyMinsWeek3Total }}
                      </p>
                    </div>
                    <div class="total">
                      <span>week 4 totals:</span>
                      <p class="green-totals" v-if="
                        playerDailyMinsWeek4Total >= 0 &&
                        playerDailyMinsWeek4Total <= 1300
                      ">
                        {{ playerDailyMinsWeek4Total }}
                      </p>
                      <p class="amber-totals" v-if="
                        playerDailyMinsWeek4Total > 1300 &&
                        playerDailyMinsWeek4Total <= 1400
                      ">
                        {{ playerDailyMinsWeek4Total }}
                      </p>
                      <p class="red-totals" v-if="playerDailyMinsWeek4Total > 1400">
                        {{ playerDailyMinsWeek4Total }}
                      </p>
                    </div>

                    <div v-if="playerDailyMinsWeek5Total !== 0" class="total">
                      <span>week 5 totals:</span>
                      <p class="green-totals" v-if="
                        playerDailyMinsWeek5Total >= 0 &&
                        playerDailyMinsWeek5Total <= 1300
                      ">
                        {{ playerDailyMinsWeek5Total }}
                      </p>
                      <p class="amber-totals" v-if="
                        playerDailyMinsWeek5Total > 1300 &&
                        playerDailyMinsWeek5Total <= 1400
                      ">
                        {{ playerDailyMinsWeek5Total }}
                      </p>
                      <p class="red-totals" v-if="playerDailyMinsWeek5Total > 1400">
                        {{ playerDailyMinsWeek5Total }}
                      </p>
                    </div>
                    <div class="total">
                      <span>month totals:</span>

                      <p class="green-totals" v-if="
                        playerDailyMinsWeek1Total +
                        playerDailyMinsWeek2Total +
                        playerDailyMinsWeek3Total +
                        playerDailyMinsWeek4Total +
                        playerDailyMinsWeek5Total >=
                        0 &&
                        playerDailyMinsWeek1Total +
                        playerDailyMinsWeek2Total +
                        playerDailyMinsWeek3Total +
                        playerDailyMinsWeek4Total +
                        playerDailyMinsWeek5Total <=
                        1300
                      ">
                        {{
                          checkPlayerMinsMonthTotals(
                            playerDailyMinsWeek1Total +
                            playerDailyMinsWeek2Total +
                            playerDailyMinsWeek3Total +
                            playerDailyMinsWeek4Total +
                            playerDailyMinsWeek5Total
                          )
                        }}
                      </p>
                      <p class="red-totalst" v-if="
                        playerDailyMinsWeek1Total +
                        playerDailyMinsWeek2Total +
                        playerDailyMinsWeek3Total +
                        playerDailyMinsWeek4Total +
                        playerDailyMinsWeek5Total >
                        1300 &&
                        playerDailyMinsWeek1Total +
                        playerDailyMinsWeek2Total +
                        playerDailyMinsWeek3Total +
                        playerDailyMinsWeek4Total +
                        playerDailyMinsWeek5Total <=
                        1400
                      ">
                        {{
                          checkPlayerMinsMonthTotals(
                            playerDailyMinsWeek1Total +
                            playerDailyMinsWeek2Total +
                            playerDailyMinsWeek3Total +
                            playerDailyMinsWeek4Total +
                            playerDailyMinsWeek5Total
                          )
                        }}
                      </p>

                      <p class="amber-totals" v-if="
                        playerDailyMinsWeek1Total +
                        playerDailyMinsWeek2Total +
                        playerDailyMinsWeek3Total +
                        playerDailyMinsWeek4Total +
                        playerDailyMinsWeek5Total >
                        1400
                      ">
                        {{
                          checkPlayerMinsMonthTotals(
                            playerDailyMinsWeek1Total +
                            playerDailyMinsWeek2Total +
                            playerDailyMinsWeek3Total +
                            playerDailyMinsWeek4Total +
                            playerDailyMinsWeek5Total
                          )
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="home-work-tab tab-content" v-if="selectedTab === 'home-work-tab'">
              <div style="padding: 1rem">
                <div class="button-wrapper-top">
                  <b-button v-if="
                    userData.userType === 'Admin' ||
                    userData.userType === 'Coach'
                  " class="main-button" @click="addHomeWorkModalCall()" size="lg" v-b-tooltip.hover
                    title="Add homework" variant="outline-success"><b-icon icon="file-text"></b-icon></b-button>
                </div>

                <div class="homework-list">
                  <div class="player-homework" v-if="allHomeWorkData.length > 0">
                    <h4>Player homework</h4>

                    <table class="w-a-style homework-table" style="margin-bottom: 2rem">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Game</th>
                          <th>title</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="homework in allHomeWorkData" :key="homework.id">
                          <td>
                            {{ formatDateForHuman(homework.created_date) }}
                          </td>
                          <td>
                            {{ homework.opposition }} -
                            {{ formatDate(homework.matchDate) }}
                          </td>
                          <td>{{ homework.title }}</td>
                          <td>
                            <div class="player-game-responce">
                              <b-button v-if="
                                userData.userType === 'Admin' ||
                                userData.userType === 'Coach'
                              " @click="
                                  editHomeworkMdalCall(homework, 'playerCvData')
                                  " variant="outline-warning"><b-icon icon="pencil" aria-hidden="true"></b-icon>
                                Edit</b-button>
                              <b-button v-if="
                                userData.userType === 'Admin' ||
                                userData.userType === 'Coach'
                              " @click="deleteHomework(homework.id)" variant="outline-danger"><b-icon icon="trash"
                                  aria-hidden="true"></b-icon>
                                Delete</b-button>

                              <b-button @click="viewHomework(homework, 'playerCvData')"
                                variant="outline-success"><b-icon icon="eye" aria-hidden="true"></b-icon>
                                View</b-button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-else class="empty-state">
                    <img src="../img/emptyStateIllustration.svg" />
                    <p>No Player homework data</p>
                  </div>

                  <div v-if="allTeamWorkData.length > 0" class="team-homework">
                    <h4>Team homework</h4>

                    <table class="w-a-style homework-table">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Game</th>
                          <th>title</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="homework in allTeamWorkData" :key="homework.id">
                          <td>
                            {{ formatDateForHuman(homework.created_date) }}
                          </td>
                          <td>
                            {{ homework.opposition }} -
                            {{ formatDate(homework.matchDate) }}
                          </td>
                          <td>{{ homework.title }}</td>
                          <td>
                            <div class="player-game-responce">
                              <b-button @click="viewHomework(homework, 'teamVideos')" variant="outline-success"><b-icon
                                  icon="eye" aria-hidden="true"></b-icon>
                                View</b-button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-else class="empty-state">
                    <img src="../img/emptyStateIllustration.svg" />
                    <p>No Team homework data</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="current-week-tab tab-content" v-if="selectedTab === 'wellness-tab'">
              <div class="games-info">
                <div class="button-wrapper-top">
                  <b-button v-if="
                    userData.userType !== 'Admin' &&
                    userData.userType !== 'Coach'
                  " class="main-button" @click="showModal('viewDailyWellnessReport')" size="lg" v-b-tooltip.hover
                    title="Submit daily mood
                    report" variant="outline-success"><b-icon icon="emoji-smile"></b-icon></b-button>

                  <b-button v-if="
                    userData.userType === 'Admin' ||
                    userData.userType === 'Coach' ||
                    userData.id === '964'
                  " size="lg" class="main-button" variant="outline-success" v-b-tooltip.hover @click="
                      download_csv(
                        thisSeasionsPlayerMoodData,
                        'player-Mood-Reports'
                      )
                      " title="Export player wellness information to csv"><b-icon icon="download"
                      aria-hidden="true"></b-icon></b-button>

                  <!--<b-button
                    v-if="
                      userData.userType === 'Admin' ||
                      userData.userType !== 'Coach' ||
                      userData.id === '964'
                    "
                    size="lg"
                    class="main-button"
                    variant="outline-success"
                    v-b-tooltip.hover
                    title="Import player wellness information"
                    ><b-icon icon="upload" aria-hidden="true"></b-icon
                  ></b-button>-->

                  <b-button v-if="
                    userData.userType === 'Admin' ||
                    userData.userType !== 'Coach' ||
                    userData.id === '964'
                  " size="lg" class="main-button" variant="outline-success" v-b-tooltip.hover
                    title="Email player wellness information"><b-icon icon="envelope"
                      aria-hidden="true"></b-icon></b-button>

                  <p v-if="playerMoodData.date !== undefined">
                    Your last submitted a mood report on
                    {{ formatDate(playerMoodData.date) }}
                  </p>
                  <p v-else class="days-red-warning">
                    <b-icon icon="exclamation-circle" aria-hidden="true"></b-icon>
                    Please submit your first mood report
                  </p>
                </div>

                <div class="player-performance-review-info">
                  <div class="mood-data">
                    <h3>Mood and wellness score</h3>

                    <div v-if="Object.keys(playerMoodData).length !== 0" class="">
                      <div>
                        <div class="wellness-mood-summary">
                          <h3>{{ formatDateForHuman(playerMoodData.date) }}</h3>
                          <div class="wellness-row">
                            <h3>Sleep</h3>
                            <span v-if="playerMoodData.sleep == '7'">Amazing <img src="../img/amazing.svg"
                                alt="" /></span>
                            <span v-if="
                              playerMoodData.sleep > '4' &&
                              playerMoodData.sleep < '7'
                            ">Good <img src="../img/good.svg" alt="" /></span>
                            <span v-if="playerMoodData.sleep == '4'">Ok <img src="../img/ok.svg" alt="" /></span>
                            <span v-if="
                              playerMoodData.sleep >= '2' &&
                              playerMoodData.sleep < '4'
                            ">Not so good
                              <img src="../img/notSoGood.svg" alt="" /></span>
                            <span v-if="playerMoodData.sleep < '2'">Bad <img src="../img/bad.svg" alt="" /></span>
                          </div>

                          <div class="wellness-row">
                            <h3>Happiness</h3>
                            <span v-if="playerMoodData.happyness == '7'">Amazing <img src="../img/amazing.svg"
                                alt="" /></span>
                            <span v-if="
                              playerMoodData.happyness > '4' &&
                              playerMoodData.happyness < '7'
                            ">Good <img src="../img/good.svg" alt="" /></span>
                            <span v-if="playerMoodData.happyness == '4'">Ok <img src="../img/ok.svg" alt="" /></span>
                            <span v-if="
                              playerMoodData.happyness >= '2' &&
                              playerMoodData.happyness < '4'
                            ">Not so good
                              <img src="../img/notSoGood.svg" alt="" /></span>
                            <span v-if="playerMoodData.happyness < '2'">Bad <img src="../img/bad.svg" alt="" /></span>
                          </div>

                          <div class="wellness-row">
                            <h3>Muscle soreness</h3>
                            <span v-if="playerMoodData.muscle_soreness == '7'">None <img src="../img/amazing.svg"
                                alt="" /></span>
                            <span v-if="
                              playerMoodData.muscle_soreness > '4' &&
                              playerMoodData.muscle_soreness < '7'
                            ">not much <img src="../img/good.svg" alt="" /></span>
                            <span v-if="playerMoodData.muscle_soreness == '4'">Ok <img src="../img/ok.svg"
                                alt="" /></span>
                            <span v-if="
                              playerMoodData.muscle_soreness >= '2' &&
                              playerMoodData.muscle_soreness < '4'
                            ">Sore <img src="../img/notSoGood.svg" alt="" /></span>
                            <span v-if="playerMoodData.muscle_soreness < '2'">Very sore <img src="../img/bad.svg"
                                alt="" /></span>
                          </div>

                          <div class="wellness-row">
                            <h3>Fatigue</h3>
                            <span v-if="playerMoodData.fatigue == '7'">Ready to go <img src="../img/amazing.svg"
                                alt="" /></span>
                            <span v-if="
                              playerMoodData.fatigue > '4' &&
                              playerMoodData.fatigue < '7'
                            ">Slugish <img src="../img/good.svg" alt="" /></span>
                            <span v-if="playerMoodData.fatigue == '4'">Bit tired<img src="../img/ok.svg"
                                alt="" /></span>
                            <span v-if="
                              playerMoodData.fatigue >= '2' &&
                              playerMoodData.fatigue < '4'
                            ">Very tired
                              <img src="../img/notSoGood.svg" alt="" /></span>
                            <span v-if="playerMoodData.fatigue < '2'">Exhausted <img src="../img/bad.svg"
                                alt="" /></span>
                          </div>

                          <div class="wellness-row">
                            <h3>Stress</h3>
                            <span v-if="playerMoodData.stress == '7'">None <img src="../img/amazing.svg"
                                alt="" /></span>
                            <span v-if="
                              playerMoodData.stress > '4' &&
                              playerMoodData.stress < '7'
                            ">Not sure <img src="../img/good.svg" alt="" /></span>
                            <span v-if="playerMoodData.stress == '4'">Ok <img src="../img/ok.svg" alt="" /></span>
                            <span v-if="
                              playerMoodData.stress >= '2' &&
                              playerMoodData.stress < '4'
                            ">Bit stressed
                              <img src="../img/notSoGood.svg" alt="" /></span>
                            <span v-if="playerMoodData.stress < '2'">Really stressed <img src="../img/bad.svg"
                                alt="" /></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else class="empty-state">
                      <img src="../img/emptyStateIllustration.svg" />
                      <p>No mood and wellness data</p>
                    </div>

                    <div class="mins-stats-wrapper visual-split-fade">
                      <h3>Players mood and wellness data</h3>
                      <div class="cal-buttons-wrapper">
                        <a class="cal-buttons" href="javascript:;" @click="changeMonth(-1, 'wellnessData')">Previous
                          month</a>
                        <a class="cal-buttons" href="javascript:;" @click="changeMonth(1, 'wellnessData')">Next
                          month</a>

                        <a class="cal-buttons" href="javascript:;" @click="showTrendsGraph()">View trends</a>
                      </div>

                      <div class="date-year">
                        {{ getMonthName(wellnessData.month) }} |
                        {{ wellnessData.year }}
                      </div>

                      <div class="mins-stats-view">
                        <div v-for="(weekDay, index) in wellnessData.weekDays" :key="index"
                          class="mins-stats-col-day-wrapper">
                          <div class="mins-stats-col-day">
                            <div class="head">
                              {{ getDayFromDate(weekDay.date) }}
                            </div>
                            <div v-if="weekDay.id !== null" class="body" :id="weekDay.id">
                              <p class="tick-wellness" v-b-tooltip.hover title="Wellness stats added">
                                <b-icon scale="2" icon="check-circle" aria-hidden="true"></b-icon>
                              </p>
                            </div>
                            <div v-else class="body">
                              <p>No data</p>
                              <b-button v-if="
                                userData.userType !== 'Admin' &&
                                userData.userType !== 'Coach'
                              " size="lg" class="main-button" variant="outline-success" v-b-tooltip.hover
                                title="Add players stats" @click="showWellnessByDay(weekDay)"><b-icon icon="plus"
                                  aria-hidden="true"></b-icon></b-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="mood-chart">
                      <Bar-Chart :chartData="playerMoodBarChartDataSet" />
                    </div>
                  </div>

                  <!-- <div class="player-weight-height-stats">

                    <h3>Player health stats</h3>

                    <div
                    class="charts">
                    <h3>Height</h3>
                    <p>{{ selectedUserData.height }} cm</p>

                    <b-button
                      class="main-button"
                      @click="showModal('addHeight')"
                      size="lg"
                      variant="outline-success"
                      ><b-icon icon="person-lines-fill"></b-icon> Record
                      height</b-button
                    >
                  </div>

                  <div
                    class="charts">
                    <h3>Weight</h3>
                    <p>{{ selectedUserData.weight }} kg</p>

                    <b-button
                      class="main-button"
                      @click="showModal('addWeight')"
                      size="lg"
                      variant="outline-success"
                      ><b-icon icon="person-check-fill"></b-icon> Record
                      Weight</b-button
                    >
                  </div>



                  </div>-->
                </div>
              </div>
            </div>
            <div class="current-week-tab tab-content" v-if="selectedTab === 'injury-tab'">
              <div class="games-info">
                <div class="button-wrapper-top">
                  <b-button class="main-button" @click="openPlayerInjuryModal('playerInjuryModal')" size="lg"
                    v-b-tooltip.hover title="Submit player injury report" variant="outline-success"><b-icon
                      icon="file-text" aria-hidden="true"></b-icon></b-button>

                  <b-button v-if="
                    userData.userType === 'Admin' ||
                    userData.userType !== 'Coach' ||
                    userData.id === '964'
                  " size="lg" class="main-button" variant="outline-success" v-b-tooltip.hover @click="
                      download_csv(
                        allPlayerInjuryReports,
                        'player-Injury-Reports'
                      )
                      " title="Export player injuries to csv"><b-icon icon="download"
                      aria-hidden="true"></b-icon></b-button>

                  <!--<b-button
                    v-if="
                      userData.userType === 'Admin' ||
                      userData.userType !== 'Coach' ||
                      userData.id === '964'
                    "
                    size="lg"
                    class="main-button"
                    variant="outline-success"
                    v-b-tooltip.hover
                    title="Import player injuries"
                    ><b-icon icon="upload" aria-hidden="true"></b-icon
                  ></b-button>-->

                  <b-button v-if="
                    userData.userType === 'Admin' ||
                    userData.userType !== 'Coach' ||
                    userData.id === '964'
                  " size="lg" class="main-button" variant="outline-success" v-b-tooltip.hover
                    title="Email player injuries"><b-icon icon="envelope" aria-hidden="true"></b-icon></b-button>
                </div>
                <h3>Player injuries this season</h3>

                <div v-if="playerInjuryData.length > 0" class="games-info">
                  <div class="player-performance-review-info">
                    <table class="w-a-style injury-table">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Injury</th>
                          <th>More detail</th>
                          <th>Return</th>
                          <th>Status</th>
                          <th>Player document</th>
                          <th v-if="
                            userData.userType === 'Admin' ||
                            userData.userType === 'Coach'
                          ">
                            Physio document
                          </th>
                          <th>Contact</th>
                          <th v-if="
                            userData.userType === 'Admin' ||
                            userData.userType === 'Coach'
                          ">
                            Reports
                          </th>
                          <th v-if="
                            userData.userType === 'Admin' ||
                            userData.userType === 'Coach'
                          ">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="injury in playerInjuryData" :key="injury.id" :class="injury.injury_status === 'Fully recoverd'
                            ? 'full-recoverd'
                            : ''
                          ">
                          <td>{{ formatDateForHuman(injury.created_date) }}</td>
                          <td>{{ injury.injury_type }}</td>
                          <td>{{ injury.more_detail }}</td>
                          <td>{{ injury.return_time }}</td>
                          <td class="injury-status">
                            {{ injury.injury_status }}
                          </td>
                          <td>
                            <a v-if="injury.player_document_id !== null" href="javascript:;" @click="
                              showDownloadFile(injury.player_document_id)
                              ">Player document</a>
                            <span v-else>No document</span>
                          </td>
                          <td v-if="
                            userData.userType === 'Admin' ||
                            userData.userType === 'Coach'
                          ">
                            <a v-if="injury.physio_document_id !== null" href="javascript:;" @click="
                              showDownloadFile(injury.physio_document_id)
                              ">Physio document</a>
                            <span v-else>No document</span>
                          </td>
                          <td>{{ injury.contact }}</td>
                          <td v-if="
                            userData.userType === 'Admin' ||
                            userData.userType === 'Coach'
                          ">
                            <b-button @click="showAllInjuryReports(injury)" variant="outline-success" v-b-tooltip.hover
                              title="Detailed Reports">
                              <b-icon icon="journal-medical" aria-hidden="true"></b-icon></b-button>
                          </td>
                          <td v-if="
                            userData.userType === 'Admin' ||
                            userData.userType === 'Coach'
                          ">
                            <div class="player-game-responce">
                              <b-button @click="
                                editInjuryModal(
                                  'editPlayerInjuryModal',
                                  injury
                                )
                                " variant="outline-warning"><b-icon icon="pencil" aria-hidden="true"></b-icon>
                                Edit</b-button>
                              <b-button @click="deletePlayerInjuryReport(injury.id)" variant="outline-danger"><b-icon
                                  icon="trash" aria-hidden="true"></b-icon>
                                Delete</b-button>

                              <b-button v-if="
                                userData.userType === 'Admin' ||
                                userData.userType === 'Coach'
                              " @click="openDocumentUpLoadModal(injury)" variant="outline-success"><b-icon
                                  icon="upload" aria-hidden="true"></b-icon>
                                Upload</b-button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <injury-body-chart :playerInjuryData="playerInjuryData" />

                    <div class="seasion-injury-summary">
                      <h3>Injury history</h3>

                      <ul class="injury-list">
                        <li>
                          <p>
                            Head / Heck injury
                            <span>{{
                              playerInjuryHistoryAmounts.headNeckInjury
                              }}</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            Shoulder injury
                            <span>{{
                              playerInjuryHistoryAmounts.shoulderInjury
                              }}</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            Arm injury
                            <span>{{
                              playerInjuryHistoryAmounts.armInjury
                              }}</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            Wrist and hand injury
                            <span>{{
                              playerInjuryHistoryAmounts.wristAndHandInjury
                              }}</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            Hip injury
                            <span>{{
                              playerInjuryHistoryAmounts.hipInjury
                              }}</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            Groin injury
                            <span>{{
                              playerInjuryHistoryAmounts.groinInjury
                              }}</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            Quad injury
                            <span>{{
                              playerInjuryHistoryAmounts.quadInjury
                              }}</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            Hamstring injury
                            <span>{{
                              playerInjuryHistoryAmounts.hamstringInjury
                              }}</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            Knee injury
                            <span>{{
                              playerInjuryHistoryAmounts.kneeInjury
                              }}</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            Calf injury
                            <span>{{
                              playerInjuryHistoryAmounts.calfInjury
                              }}</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            Ankle injury
                            <span>{{
                              playerInjuryHistoryAmounts.ankleInjury
                              }}</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            foot injury
                            <span>{{
                              playerInjuryHistoryAmounts.footInjury
                              }}</span>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div v-else class="empty-state">
                  <img src="../img/emptyStateIllustration.svg" />
                  <p>No player injury data</p>
                </div>
              </div>
            </div>

            <div class="current-week-tab tab-content" v-if="selectedTab === 'review-tab'">
              <div class="games-info">
                <div class="button-wrapper-top">
                  <!--<b-button
                    v-if="userData.userType === 'User'"
                    class="main-button"
                    @click="
                      openPlayerCoachSignedPerformanceModal('playerCoachPerformanceAssessmentModal', 'Player')
                    "
                    size="lg"
                    variant="outline-success"
                    ><b-icon icon="file-text" aria-hidden="true"></b-icon>
                    Player Performance Review</b-button
                  >-->

                  <b-button v-if="
                    userData.userType === 'Admin' ||
                    userData.userType === 'Coach'
                  " class="main-button" @click="
                      openPlayerCoachSignedPerformanceModal(
                        'playerCoachPerformanceAssessmentModal',
                        'Coach'
                      )
                      " size="lg" variant="outline-success"><b-icon icon="file-text" aria-hidden="true"></b-icon> Coach
                    Performance Review</b-button>

                  <!--<b-button
                    class="main-button"
                    v-if="
                      userData.userType === 'Admin' ||
                      userData.userType === 'Coach'
                    "
                    @click="
                      openPlayerPerformanceModal('playerPerformanceReportModal')
                    "
                    size="lg"
                    variant="outline-success"
                    ><b-icon icon="file-text" aria-hidden="true"></b-icon>
                    Performance Review</b-button
                  >-->

                  <!--<b-button
                  v-if="
                      userData.userType === 'Admin' ||
                      userData.userType === 'Coach'
                    "
                    size="lg"
                    class="main-button"
                    variant="outline-success"
                    v-b-tooltip.hover
                    @click="
                      download_csv(
                        allplayerPerformanceReportData,
                        'player-Performance-Reviews'
                      )
                    "
                    title="Export player performance reviews to csv"
                    ><b-icon icon="download" aria-hidden="true"></b-icon
                  ></b-button>-->

                  <!--<b-button
                    size="lg"
                    class="main-button"
                    variant="outline-success"
                    v-b-tooltip.hover
                    title="Import player performance reviews"
                    ><b-icon icon="upload" aria-hidden="true"></b-icon
                  ></b-button>-->

                  <!--<b-button
                    size="lg"
                    class="main-button"
                    variant="outline-success"
                    v-b-tooltip.hover
                    title="Email player performance reviews"
                    ><b-icon icon="envelope" aria-hidden="true"></b-icon
                  ></b-button>-->
                </div>

                <div class="player-coach-signed-reviews">
                  <div class="table-scroll performance-reviews-data">
                    <table v-if="
                      allPlayerCoachSignedPerformanceReportData.length > 0
                    " class="w-a-style">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Report</th>
                          <th>Status</th>
                          <th>Coach</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="review in allPlayerCoachSignedPerformanceReportData" :key="review.id">
                          <td>{{ formatDateForHuman(review.created_date) }}</td>
                          <td>
                            <a v-if="userData.userType === 'User'" @click="
                              openPlayerCoachSignedPerformanceModal(
                                'playerCoachPerformanceAssessmentModal',
                                'Player',
                                review.id
                              )
                              " href="javascript:;"><b-icon icon="file-text"></b-icon> Performance
                              report details</a>
                            <a v-else @click="
                              openPlayerCoachSignedPerformanceModal(
                                'playerCoachPerformanceAssessmentModal',
                                'Coach',
                                review.id
                              )
                              " href="javascript:;"><b-icon icon="file-text"></b-icon> Performance
                              report details</a>
                          </td>
                          <td>
                            <span v-if="review.published === 1">Signed - Published</span>
                            <span v-else>{{ review.status }} -
                              <span v-b-tooltip.hover title="No player signature"
                                v-if="review.player_signature_id === null"><b-icon icon="pencil-square"></b-icon></span>
                              <span v-b-tooltip.hover title="No coach signature"
                                v-if="review.coach_signature_id === null"><b-icon icon="pencil-square"></b-icon></span>
                            </span>
                          </td>
                          <td>{{ review.name }}</td>
                          <td class="buttons-row">
                            <b-button @click="deletePlayerCoachAssessment(review.id)" variant="outline-danger"
                              v-b-tooltip.hover title="Delete"><b-icon icon="trash"
                                aria-hidden="true"></b-icon></b-button>

                            <b-button v-if="
                              review.published === 1 &&
                              userData.userType !== 'User'
                            " @click="produceThePlayerCoachReportPDF(review)" variant="outline-success"
                              v-b-tooltip.hover title="Print"><b-icon icon="printer"
                                aria-hidden="true"></b-icon></b-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div v-else class="empty-state">
                      <img src="../img/emptyStateIllustration.svg" />
                      <p>No player Performance Review data.</p>
                    </div>
                  </div>
                </div>

                <div class="player-performance-review-info">
                  <div class="player-performance-review-wrapper" v-if="
                    this.userData.userType === 'Admin' ||
                    this.userData.userType === 'Coach'
                  ">
                    <div class="table-scroll performance-reviews-data">
                      <!--<table
                    v-if="allplayerPerformanceReportData.length > 0"
                    class="w-a-style"
                  >
                      <thead>
                      <tr>
                        <th>Date</th>
                        <th>Document</th>
                        <th>Score</th>
                        <th>Coach</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="review in allplayerPerformanceReportData"
                        :key="review.id"
                      >
                        <td>{{ formatDateForHuman(review.review_date) }}</td>
                        <td>
                          <a
                            @click="openPlayerPerformanceReview(review.id)"
                            href="javascript:;"
                            ><b-icon icon="file-text"></b-icon> Performance
                            report details</a
                          >
                        </td>
                        <td class="td-no-padding">
                          <CircularPercentage
                            :review="review"
                            :allPlayerReviews="null"
                            circularType="single"
                          />
                        </td>
                        <td>{{review.coachId}}</td>
                        <td><b-button
                  @click="deletePerformanceReview(review.id)"
                  variant="outline-danger"
                  v-b-tooltip.hover
                  title="Delete"
                  ><b-icon icon="trash" aria-hidden="true"></b-icon
                ></b-button></td>
                      </tr>
                    </tbody>
                    </table>-->
                      <!---<div v-else class="empty-state">
                      <img src="../img/emptyStateIllustration.svg" />
                      <p>No player Performance Review data.</p>
                    </div>-->
                    </div>

                    <!--<div
                      v-if="allplayerPerformanceReportData.length > 0"
                      class="review-average-score"
                    >
                      <CircularPercentage
                        :review="null"
                        :allPlayerReviews="allplayerPerformanceReportData"
                        circularType="player-summary-stats"
                        v-if="selectedUserData.age_group === '2007'
                      || selectedUserData.age_group === '2008'
                      || selectedUserData.age_group === '2009'
                      || selectedUserData.age_group === '2010'"
                      />
                    </div>-->
                  </div>

                  <div class="physical-performance-wrapper">
                    <div class="physical-performance-stats">
                      <h3>Physical Performance</h3>

                      <p class="small-date-player-coach">
                        Last reviewed
                        {{
                          formatDateForHuman(playerPhysicalPerformanceData.date)
                        }}
                      </p>
                      <ul class="key-value-list">
                        <li>
                          <span>10m SPRINT:</span>
                          <span class="time-value">{{
                            playerPhysicalPerformanceData.tenM_sprint_time
                          }}s</span>
                          <img v-if="
                            playerPhysicalPerformanceData.tenM_sprint_time_icon ===
                            'better'
                          " class="chev-up" src="../img/chev-green-up.svg" />
                          <img v-if="
                            playerPhysicalPerformanceData.tenM_sprint_time_icon ===
                            'worse'
                          " class="chev-up" src="../img/chev-red-down.svg" />
                          <img v-if="
                            playerPhysicalPerformanceData.tenM_sprint_time_icon ===
                            'same'
                          " class="chev-up" src="../img/line.svg" />
                        </li>
                        <li>
                          <span>30m SPRINT:</span>
                          <span class="time-value">{{
                            playerPhysicalPerformanceData.fortyM_sprint_time
                          }}s</span>
                          <img v-if="
                            playerPhysicalPerformanceData.fortyM_sprint_time_icon ===
                            'better'
                          " class="chev-up" src="../img/chev-green-up.svg" />
                          <img v-if="
                            playerPhysicalPerformanceData.fortyM_sprint_time_icon ===
                            'worse'
                          " class="chev-up" src="../img/chev-red-down.svg" />
                          <img v-if="
                            playerPhysicalPerformanceData.fortyM_sprint_time_icon ===
                            'same'
                          " class="chev-up" src="../img/line.svg" />
                        </li>
                        <li>
                          <span>Reactive strength index:</span>
                          <span class="time-value">{{
                            playerPhysicalPerformanceData.reactive_strength_index
                            }}</span>
                          <img v-if="
                            playerPhysicalPerformanceData.reactive_strength_index_icon ===
                            'better'
                          " class="chev-up" src="../img/chev-green-up.svg" />
                          <img v-if="
                            playerPhysicalPerformanceData.reactive_strength_index_icon ===
                            'worse'
                          " class="chev-up" src="../img/chev-red-down.svg" />
                          <img v-if="
                            playerPhysicalPerformanceData.reactive_strength_index_icon ===
                            'same'
                          " class="chev-up" src="../img/line.svg" />
                        </li>
                        <li>
                          <span>Fitness Test Time:</span>
                          <span class="time-value">{{
                            playerPhysicalPerformanceData.thirty_15_intermittent_fitness_test
                            }}</span>
                          <img v-if="
                            playerPhysicalPerformanceData.thirty_15_intermittent_fitness_test_icon ===
                            'better'
                          " class="chev-up" src="../img/chev-green-up.svg" />
                          <img v-if="
                            playerPhysicalPerformanceData.thirty_15_intermittent_fitness_test_icon ===
                            'worse'
                          " class="chev-up" src="../img/chev-red-down.svg" />
                          <img v-if="
                            playerPhysicalPerformanceData.thirty_15_intermittent_fitness_test_icon ===
                            'same'
                          " class="chev-up" src="../img/line.svg" />
                        </li>
                        <li>
                          <span>Height:</span>
                          <span class="time-value">{{ playerPhysicalPerformanceData.height }} cm</span>
                          <img v-if="
                            playerPhysicalPerformanceData.height_icon ===
                            'better'
                          " class="chev-up" src="../img/chev-green-up.svg" />
                          <img v-if="
                            playerPhysicalPerformanceData.height_icon ===
                            'worse'
                          " class="chev-up" src="../img/chev-red-down.svg" />
                          <img v-if="
                            playerPhysicalPerformanceData.height_icon ===
                            'same'
                          " class="chev-up" src="../img/line.svg" />
                        </li>
                        <li>
                          <span>Weight:</span>
                          <span class="time-value">{{ playerPhysicalPerformanceData.weight }} kg</span>
                          <img v-if="
                            playerPhysicalPerformanceData.weight_icon ===
                            'better'
                          " class="chev-up" src="../img/chev-green-up.svg" />
                          <img v-if="
                            playerPhysicalPerformanceData.weight_icon ===
                            'worse'
                          " class="chev-up" src="../img/chev-red-down.svg" />
                          <img v-if="
                            playerPhysicalPerformanceData.weight_icon ===
                            'same'
                          " class="chev-up" src="../img/line.svg" />
                        </li>
                      </ul>

                      <div class="buttons-wrapper">
                        <div class="buttons-row">
                          <b-button v-if="
                            userData.userType === 'Admin' ||
                            userData.userType === 'Coach'
                          " size="lg" class="main-button" variant="outline-success" v-b-tooltip.hover
                            title="Add Physical Performance data" @click="addNewPhysicalPerformanceTimeModal()"><b-icon
                              icon="watch" aria-hidden="true"></b-icon>
                          </b-button>

                          <b-button size="lg" class="main-button" variant="outline-success" v-b-tooltip.hover
                            title="View Physical Performance history"
                            @click="openPhysicalPerformanceHistoryModal()"><b-icon icon="eye"
                              aria-hidden="true"></b-icon></b-button>

                          <b-button size="lg" class="main-button" variant="outline-success" v-b-tooltip.hover @click="
                            download_csv(
                              allPlayerPhysicalPerformanceData,
                              'player-Physical-Performance-data'
                            )
                            " title="Export Physical Performance to csv"><b-icon icon="download"
                              aria-hidden="true"></b-icon></b-button>

                          <!--<b-button
                          size="lg"
                          class="main-button"
                          variant="outline-success"
                          v-b-tooltip.hover
                          title="Import Physical Performance reviews"
                          ><b-icon icon="upload" aria-hidden="true"></b-icon
                        ></b-button>-->

                          <b-button size="lg" class="main-button" variant="outline-success" v-b-tooltip.hover
                            title="Email Physical Performance reviews"><b-icon icon="envelope"
                              aria-hidden="true"></b-icon></b-button>
                        </div>
                      </div>
                    </div>

                    <!--<div class="physical-performance-table">
                      <table class="w-a-style physicalPerformanceTable">
                        <thead>
                          <th>Date</th>
                          <th>10m SPRINT 1</th>
                          <th>10m SPRINT 2</th>
                          <th>30m SPRINT 1</th>
                          <th>30m SPRINT 2</th>
                          <th>Countermovement jump 1</th>
                          <th>Countermovement jump 2</th>
                          <th>Reactive strength index 1</th>
                          <th>Reactive strength index 2</th>
                          <th>Reactive strength index 3</th>
                          <th>Reactive strength index 4</th>
                          <th>Reactive strength index 5</th>
                          <th>Reactive strength average</th>
                          <th>30-15 Intermittent Fitness Test</th>
                          <th>Bronco Time Mins</th>
                          <th>Bronco Time Sec</th>
                        </thead>
                        <tbody>
                          <tr v-for="session in allPlayerPhysicalPerformanceData" :key="session.id">
                            <td>{{ formatDateForHuman(session.date) }}</td>
                            <td>{{ session.tenM_sprint_time }}</td>
                            <td>{{ session.tenM_sprint_time_1 }}</td>
                            <td>{{ session.fortyM_sprint_time }}</td>
                            <td>{{ session.fortyM_sprint_time_1 }}</td>
                            <td>{{ session.cuntermovement_jump }}</td>
                            <td>{{ session.cuntermovement_jump_2 }}</td>
                            <td>{{ session.reactive_strength_index }}</td>
                            <td>{{ session.reactive_strength_index_2 }}</td>
                            <td>{{ session.reactive_strength_index_3 }}</td>
                            <td>{{ session.reactive_strength_index_4 }}</td>
                            <td>{{ session.reactive_strength_index_5 }}</td>
                            <td>
                              {{ session.reactive_strength_index_average }}
                            </td>
                            <td>
                              {{ session.thirty_15_intermittent_fitness_test }}
                            </td>
                            <td>{{ session.bronco_time_mins }}</td>
                            <td>{{ session.bronco_time_sec }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>-->

                    <div class="physical-performance-trends" v-if="
                      Object.keys(playerPhysicalPerformanceData).length !== 0
                    ">
                      <b-form-select class="mb-3" v-model="physicalPerformanceChartSelected">
                        <b-form-select-option :value="null">Please select an option</b-form-select-option>
                        <b-form-select-option value="1">Sprints chart</b-form-select-option>
                        <b-form-select-option value="2">Countermovement jump chart</b-form-select-option>
                        <b-form-select-option value="3">Reactive strength index chart</b-form-select-option>
                        <b-form-select-option value="4">30-15 Intermittent Fitness Test
                          chart</b-form-select-option>
                        <b-form-select-option value="5">Player height chart</b-form-select-option>
                        <b-form-select-option value="6">Player weight chart</b-form-select-option>
                      </b-form-select>
                      <Line-physical-performance-chart v-if="physicalPerformanceChartSelected === '1'"
                        :chartData="physicalPerformanceLineChartData" />
                      <Line-physical-performance-chart v-if="physicalPerformanceChartSelected === '2'" :chartData="physicalPerformanceLineCountermovementJumpChartData
                        " />
                      <Line-physical-performance-chart v-if="physicalPerformanceChartSelected === '3'" :chartData="physicalPerformanceLineReactiveStrengthIndexChartData
                        " />
                      <Line-physical-performance-chart v-if="physicalPerformanceChartSelected === '4'" :chartData="physicalPerformanceLineThirtyFithteenIntermittentFitnessTestChartData
                        " />
                      <Line-physical-performance-chart v-if="physicalPerformanceChartSelected === '5'"
                        :chartData="physicalPerformanceLineHeightChartData" />
                      <Line-physical-performance-chart v-if="physicalPerformanceChartSelected === '6'"
                        :chartData="physicalPerformanceLineWeightChartData" />
                    </div>
                  </div>

                  <div class="performance-targets mins-stats-wrapper visual-split-fade"
                    v-if="this.playerPerformanceReviewTargetsData.length > 0">
                    <h3>Current Performance targets</h3>
                    <div class="buttons-row">
                      <b-button size="lg" class="main-button" variant="outline-success" v-b-tooltip.hover
                        title="View Performance targets history" @click="performanceTargetsHistoryModal()"><b-icon
                          icon="eye" aria-hidden="true"></b-icon></b-button>

                      <b-button v-if="
                        this.userData.userType === 'Admin' ||
                        this.userData.userType === 'Coach'
                      " size="lg" class="main-button" variant="outline-success" v-b-tooltip.hover
                        title="Add new Performance target" @click="
                          performanceTargetsModal(
                            {},
                            'newPerformanceTargetsModal',
                            'performance target'
                          )
                          "><b-icon icon="plus" aria-hidden="true"></b-icon></b-button>

                      <b-button size="lg" class="main-button" variant="outline-success" v-b-tooltip.hover
                        title="Email Performance targets"><b-icon icon="envelope"
                          aria-hidden="true"></b-icon></b-button>
                    </div>

                    <div class="target-categories-wrapper">
                      <div class="target-categories">
                        <h4>Attitude</h4>

                        <div class="target-info" v-for="performanceTarget in getPlayersCurrentPerformanceTargetsForCategory(
                          'Attitude'
                        )" :key="performanceTarget.id">
                          <h4><img src="./img/target.svg" alt="" /> Target</h4>
                          <span v-if="performanceTarget.complete == 0" class="small-date-player-coach">Not complete
                          </span>
                          <span v-else class="small-date-player-coach completed">Complete
                          </span>

                          <div class="target-content">
                            <div v-if="performanceTarget.target !== ''" v-html="performanceTarget.target"></div>
                            <div v-else>
                              <span>Looks you forgot to set a performance target
                                <br />for the current performance review. Use
                                the edit button to set one.</span>
                            </div>

                            <b-button v-if="
                              userData.userType === 'Admin' ||
                              userData.userType === 'Coach'
                            " size="lg" class="main-button" variant="outline-warning" v-b-tooltip.hover title="Edit"
                              @click="
                                performanceTargetsModal(
                                  performanceTarget,
                                  'performanceTargetsModal',
                                  'performance target'
                                )
                                "><b-icon icon="pencil" aria-hidden="true"></b-icon></b-button>
                          </div>
                        </div>
                      </div>

                      <div class="target-categories">
                        <h4>Skill</h4>

                        <div class="target-info" v-for="performanceTarget in getPlayersCurrentPerformanceTargetsForCategory(
                          'Skill'
                        )" :key="performanceTarget.id">
                          <h4><img src="./img/target.svg" alt="" /> Target</h4>
                          <span v-if="performanceTarget.complete == 0" class="small-date-player-coach">Not complete
                          </span>
                          <span v-else class="small-date-player-coach completed">Complete
                          </span>

                          <div class="target-content">
                            <div v-if="performanceTarget.target !== ''" v-html="performanceTarget.target"></div>
                            <div v-else>
                              <span>Looks you forgot to set a performance target
                                <br />for the current performance review. Use
                                the edit button to set one.</span>
                            </div>

                            <b-button size="lg" class="main-button" variant="outline-warning" v-b-tooltip.hover
                              title="Edit" @click="
                                performanceTargetsModal(
                                  performanceTarget,
                                  'performanceTargetsModal',
                                  'performance target'
                                )
                                "><b-icon icon="pencil" aria-hidden="true"></b-icon></b-button>
                          </div>
                        </div>
                      </div>
                      <div class="target-categories">
                        <h4>Physique</h4>

                        <div class="target-info" v-for="performanceTarget in getPlayersCurrentPerformanceTargetsForCategory(
                          'Physique'
                        )" :key="performanceTarget.id">
                          <h4><img src="./img/target.svg" alt="" /> Target</h4>
                          <span v-if="performanceTarget.complete == 0" class="small-date-player-coach">Not complete
                          </span>
                          <span v-else class="small-date-player-coach completed">Complete
                          </span>

                          <div class="target-content">
                            <div v-if="performanceTarget.target !== ''" v-html="performanceTarget.target"></div>
                            <div v-else>
                              <span>Looks you forgot to set a performance target
                                <br />for the current performance review. Use
                                the edit button to set one.</span>
                            </div>

                            <b-button size="lg" class="main-button" variant="outline-warning" v-b-tooltip.hover
                              title="Edit" @click="
                                performanceTargetsModal(
                                  performanceTarget,
                                  'performanceTargetsModal',
                                  'performance target'
                                )
                                "><b-icon icon="pencil" aria-hidden="true"></b-icon></b-button>
                          </div>
                        </div>
                      </div>

                      <div class="target-categories">
                        <h4>Mental</h4>

                        <div class="target-info" v-for="performanceTarget in getPlayersCurrentPerformanceTargetsForCategory(
                          'Mentality'
                        )" :key="performanceTarget.id">
                          <h4><img src="./img/target.svg" alt="" /> Target</h4>
                          <span v-if="performanceTarget.complete == 0" class="small-date-player-coach">Not complete
                          </span>
                          <span v-else class="small-date-player-coach completed">Complete
                          </span>

                          <div class="target-content">
                            <div v-if="performanceTarget.target !== ''" v-html="performanceTarget.target"></div>
                            <div v-else>
                              <span>Looks you forgot to set a performance target
                                <br />for the current performance review. Use
                                the edit button to set one.</span>
                            </div>

                            <b-button size="lg" class="main-button" variant="outline-warning" v-b-tooltip.hover
                              title="Edit" @click="
                                performanceTargetsModal(
                                  performanceTarget,
                                  'performanceTargetsModal',
                                  'performance target'
                                )
                                "><b-icon icon="pencil" aria-hidden="true"></b-icon></b-button>
                          </div>
                        </div>
                      </div>

                      <div class="target-categories">
                        <h4>Leadership</h4>

                        <div class="target-info" v-for="performanceTarget in getPlayersCurrentPerformanceTargetsForCategory(
                          'Leadership'
                        )" :key="performanceTarget.id">
                          <h4><img src="./img/target.svg" alt="" /> Target</h4>
                          <span v-if="performanceTarget.complete == 0" class="small-date-player-coach">Not complete
                          </span>
                          <span v-else class="small-date-player-coach completed">Complete
                          </span>

                          <div class="target-content">
                            <div v-if="performanceTarget.target !== ''" v-html="performanceTarget.target"></div>
                            <div v-else>
                              <span>Looks you forgot to set a performance target
                                <br />for the current performance review. Use
                                the edit button to set one.</span>
                            </div>

                            <b-button size="lg" class="main-button" variant="outline-warning" v-b-tooltip.hover
                              title="Edit" @click="
                                performanceTargetsModal(
                                  performanceTarget,
                                  'performanceTargetsModal',
                                  'performance target'
                                )
                                "><b-icon icon="pencil" aria-hidden="true"></b-icon></b-button>
                          </div>
                        </div>
                      </div>

                      <div class="target-categories">
                        <h4>Professionalism</h4>

                        <div class="target-info" v-for="performanceTarget in getPlayersCurrentPerformanceTargetsForCategory(
                          'Professionalism'
                        )" :key="performanceTarget.id">
                          <h4><img src="./img/target.svg" alt="" /> Target</h4>
                          <span v-if="performanceTarget.complete == 0" class="small-date-player-coach">Not complete
                          </span>
                          <span v-else class="small-date-player-coach completed">Complete
                          </span>

                          <div class="target-content">
                            <div v-if="performanceTarget.target !== ''" v-html="performanceTarget.target"></div>
                            <div v-else>
                              <span>Looks you forgot to set a performance target
                                <br />for the current performance review. Use
                                the edit button to set one.</span>
                            </div>

                            <b-button size="lg" class="main-button" variant="outline-warning" v-b-tooltip.hover
                              title="Edit" @click="
                                performanceTargetsModal(
                                  performanceTarget,
                                  'performanceTargetsModal',
                                  'performance target'
                                )
                                "><b-icon icon="pencil" aria-hidden="true"></b-icon></b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="current-week-tab tab-content" v-if="selectedTab === 'learning-development-plan-tab'">
              <div class="development-plan-info">
                <div class="button-wrapper-top">
                  <b-button class="main-button" @click="openNewIDPModal()" size="lg" v-b-tooltip.hover
                    title="Add new IDP report" variant="outline-success"><b-icon icon="file-text"
                      aria-hidden="true"></b-icon>
                  </b-button>
                </div>

                <h3>IDP reports</h3>
                <div class="idp-reports-all" v-if="allQuatersPlayerIDPdata.length > 0">
                  <b-form-select class="mb-3" v-model="playerIDPdata">
                    <b-form-select-option :value="null">Please select an option</b-form-select-option>

                    <b-form-select-option-group label="Q1 reports">
                      <b-form-select-option :value="IDPReport" v-for="IDPReport in setCurrentQuaterPlayerIDPdata(3)"
                        :key="IDPReport.id">
                        {{ formatDateForHuman(IDPReport.created_date) }} - view
                        report Q1
                      </b-form-select-option>
                    </b-form-select-option-group>

                    <b-form-select-option-group label="Q2 reports">
                      <b-form-select-option :value="IDPReport" v-for="IDPReport in setCurrentQuaterPlayerIDPdata(4)"
                        :key="IDPReport.id">
                        {{ formatDateForHuman(IDPReport.created_date) }} - view
                        report Q2
                      </b-form-select-option>
                    </b-form-select-option-group>

                    <b-form-select-option-group label="Q3 reports">
                      <b-form-select-option :value="IDPReport" v-for="IDPReport in setCurrentQuaterPlayerIDPdata(1)"
                        :key="IDPReport.id">
                        {{ formatDateForHuman(IDPReport.created_date) }} - view
                        report Q3
                      </b-form-select-option>
                    </b-form-select-option-group>

                    <b-form-select-option-group label="Q4 reports">
                      <b-form-select-option :value="IDPReport" v-for="IDPReport in setCurrentQuaterPlayerIDPdata(2)"
                        :key="IDPReport.id">
                        {{ formatDateForHuman(IDPReport.created_date) }} - view
                        report Q4
                      </b-form-select-option>
                    </b-form-select-option-group>
                  </b-form-select>
                </div>

                <div class="development-plan-wrapper">
                  <div class="player-coach-scores-idp" v-if="playerIDPdata.id !== undefined">
                    <h3>Player IDPs</h3>
                    <p>
                      From the Areas listed in your IDP, Please list a key skill
                      in each of the 4 boxes, with one skill for Green/ 1 for
                      Yellow / 1 for red.<br />
                      Two key areas of improvement (Yellow and Red) and One area
                      of your strongest/best attribute (Green). <br />
                      Add these into your IDP Grade using traffic light system.
                    </p>

                    <ul>
                      <li>Green – Excelling/Striving</li>
                      <li>Yellow- Coping </li>
                      <li>Red – Developing</li>
                    </ul>

                    <h4>
                      IDP report selected -
                      {{ formatDateForHuman(playerIDPdata.created_date) }}
                    </h4>

                    <div class="scores-wrapper">
                      <div class="score-item">
                        <h3>Technical</h3>
                        <div class="score-by-type green">
                          <h4>
                            <b-icon icon="check-circle" aria-hidden="true"></b-icon>
                            Green
                          </h4>
                          <p>{{ playerIDPdata.technical_green }}</p>
                        </div>

                        <div class="score-by-type yellow">
                          <h4>
                            <b-icon icon="bullseye" aria-hidden="true"></b-icon>
                            Yellow
                          </h4>
                          <p>{{ playerIDPdata.technical_yellow }}</p>
                        </div>

                        <div class="score-by-type red">
                          <h4>
                            <b-icon icon="bar-chart" aria-hidden="true"></b-icon>
                            Red
                          </h4>
                          <p>{{ playerIDPdata.technical_red }}</p>
                        </div>
                      </div>
                      <div class="score-item">
                        <h3>Tactical</h3>
                        <div class="score-by-type green">
                          <h4>
                            <b-icon icon="check-circle" aria-hidden="true"></b-icon>
                            Green
                          </h4>
                          <p>{{ playerIDPdata.tactical_green }}</p>
                        </div>

                        <div class="score-by-type yellow">
                          <h4>
                            <b-icon icon="exclamation-circle" aria-hidden="true"></b-icon>
                            Yellow
                          </h4>
                          <p>{{ playerIDPdata.tactical_yellow }}</p>
                        </div>

                        <div class="score-by-type red">
                          <h4>
                            <b-icon icon="bar-chart" aria-hidden="true"></b-icon>
                            Red
                          </h4>
                          <p>{{ playerIDPdata.tactical_red }}</p>
                        </div>
                      </div>
                      <div class="score-item">
                        <h3>Physical</h3>
                        <div class="score-by-type green">
                          <h4>
                            <b-icon icon="check-circle" aria-hidden="true"></b-icon>
                            Green
                          </h4>
                          <p>{{ playerIDPdata.physical_green }}</p>
                        </div>

                        <div class="score-by-type yellow">
                          <h4>
                            <b-icon icon="bullseye" aria-hidden="true"></b-icon>
                            Yellow
                          </h4>
                          <p>{{ playerIDPdata.physical_yellow }}</p>
                        </div>

                        <div class="score-by-type red">
                          <h4>
                            <b-icon icon="bar-chart" aria-hidden="true"></b-icon>
                            Red
                          </h4>
                          <p>{{ playerIDPdata.physical_red }}</p>
                        </div>
                      </div>
                      <div class="score-item">
                        <h3>Mental</h3>
                        <div class="score-by-type green">
                          <h4>
                            <b-icon icon="check-circle" aria-hidden="true"></b-icon>
                            Green
                          </h4>
                          <p>{{ playerIDPdata.physcological_green }}</p>
                        </div>

                        <div class="score-by-type yellow">
                          <h4>
                            <b-icon icon="bullseye" aria-hidden="true"></b-icon>
                            Yellow
                          </h4>
                          <p>{{ playerIDPdata.physcological_yellow }}</p>
                        </div>

                        <div class="score-by-type red">
                          <h4>
                            <b-icon icon="bar-chart" aria-hidden="true"></b-icon>
                            Red
                          </h4>
                          <p>{{ playerIDPdata.physcological_red }}</p>
                        </div>
                      </div>
                    </div>

                    <div class="idp-comments" v-if="playerIDPdata.id !== undefined">
                      <div class="comments-item">
                        <h4>
                          <b-icon icon="person-circle" aria-hidden="true"></b-icon>
                          Player comments
                        </h4>
                        <p>{{ playerIDPdata.player_comments }}</p>
                      </div>
                      <div class="comments-item">
                        <h4>
                          <b-icon icon="person-circle" aria-hidden="true"></b-icon>
                          Coach comments
                        </h4>
                        <p>{{ playerIDPdata.coach_comments }}</p>
                      </div>
                    </div>

                    <b-button class="main-button" @click="openIDPModal()" size="lg" variant="outline-warning"><b-icon
                        icon="pencil" aria-hidden="true"></b-icon>
                      Edit
                    </b-button>
                  </div>
                  <div v-else class="empty-state">
                    <img src="../img/emptyStateIllustration.svg" />
                    <p>No IDP reports, please add one.</p>
                  </div>

                  <div class="player-game-targets">
                    <h3>Game targets</h3>

                    <b-form-select class="mb-3" @change="selectQuaterDataForGameTargets()"
                      v-model="gameTargetQuarterSelection">
                      <b-form-select-option :value="null">Please select an option</b-form-select-option>
                      <b-form-select-option value="1">Q1</b-form-select-option>
                      <b-form-select-option value="2">Q2</b-form-select-option>
                      <b-form-select-option value="3">Q3</b-form-select-option>
                      <b-form-select-option value="4">Q4</b-form-select-option>
                      <b-form-select-option value="all">view all</b-form-select-option>
                    </b-form-select>

                    <div class="game-target">
                      <p>
                        <span v-if="gameTargetQuarterSelection !== 'all'">Q</span>{{ gameTargetQuarterSelection }}
                        selected -
                        <span v-if="gameTargetQuarterSelection === '1'">January – March</span>
                        <span v-if="gameTargetQuarterSelection === '2'">April – June</span>
                        <span v-if="gameTargetQuarterSelection === '3'">July – September</span>
                        <span v-if="gameTargetQuarterSelection === '4'">October – December</span>
                        <span v-if="gameTargetQuarterSelection === 'all'">Full year</span>
                      </p>

                      <div class="in-possession">
                        <div class="targets">
                          <h4>In possession</h4>
                        </div>
                        <div class="targets">
                          <h4>Targets</h4>
                          <div v-for="target in playerGameTargetsDataFiltered" :key="target.id">
                            <p class="target-outline" v-if="target.possession === 'In possession'">
                              <img src="./img/target.svg" alt="" />
                              <span v-html="target.target"></span>
                              <span v-if="target.complete === 0" class="not-complete">Not complete</span>
                              <span v-else class="complete">Complete</span>
                              <b-button v-if="
                                userData.userType === 'Admin' ||
                                userData.userType === 'Coach'
                              " size="lg" class="main-button" variant="outline-warning" v-b-tooltip.hover
                                title="Edit" @click="
                                  performanceTargetsModal(
                                    target,
                                    'performanceTargetsModal',
                                    'game target'
                                  )
                                  "><b-icon icon="pencil" aria-hidden="true"></b-icon></b-button>
                            </p>
                          </div>
                          <p v-if="playerGameTargetsDataFiltered.length === 0">
                            No Game targets.
                          </p>
                        </div>
                      </div>
                      <div class="out-of-possession">
                        <div class="targets">
                          <h4>Out of possession</h4>
                        </div>

                        <div class="targets">
                          <h4>Targets</h4>
                          <div v-for="target in playerGameTargetsDataFiltered" :key="target.id">
                            <p class="target-outline" v-if="target.possession === 'Out of possession'">
                              <img src="./img/target.svg" alt="" />
                              <span v-html="target.target"></span>
                              <span v-if="target.complete === 0" class="not-complete">Not complete</span>
                              <span v-else class="complete">Complete</span>
                              <b-button v-if="
                                userData.userType === 'Admin' ||
                                userData.userType === 'Coach'
                              " size="lg" class="main-button" variant="outline-warning" v-b-tooltip.hover
                                title="Edit" @click="
                                  performanceTargetsModal(
                                    target,
                                    'performanceTargetsModal',
                                    'game target'
                                  )
                                  "><b-icon icon="pencil" aria-hidden="true"></b-icon></b-button>
                            </p>
                          </div>
                          <p v-if="playerGameTargetsDataFiltered.length === 0">
                            No Game targets.
                          </p>
                        </div>
                      </div>

                      <!--<div class="target-comments">
                      <div class="targets">
                        <h4><b-icon icon="person-circle" aria-hidden="true"></b-icon> Coach comments</h4>
                      </div>

                      <div class="targets">
                        <h4><b-icon icon="person-circle" aria-hidden="true"></b-icon> Player comments</h4>
                      </div>
                    </div>-->
                    </div>

                    <b-button size="lg" class="main-button" variant="outline-success" v-b-tooltip.hover
                      title="Add new Game target" @click="
                        performanceTargetsModal(
                          {},
                          'newPerformanceTargetsModal',
                          'game target'
                        )
                        "><b-icon icon="plus" aria-hidden="true"></b-icon></b-button>
                  </div>

                  <div class="player-game-targets">
                    <h3>Your goals</h3>

                    <div class="out-of-possession">
                      <div class="targets">
                        <h4>3 months</h4>
                        <b-icon icon="calendar2" scale="2" aria-hidden="true"></b-icon>
                      </div>

                      <div class="targets threeMonth">
                        <h4>How can i achieve this</h4>
                        <div class="goal-item" v-for="goalItem in IDPGoalsData" :key="goalItem.id">
                          <p v-if="goalItem.month_category === '3'" class="target-outline">
                            <img src="./img/target.svg" alt="" />
                            <span v-html="goalItem.goal"></span>
                          </p>
                          <p v-if="goalItem.month_category === '3'">
                            {{ goalItem.how_can }}
                          </p>

                          <b-button v-if="goalItem.month_category === '3'" size="lg" class="main-button"
                            variant="outline-warning" v-b-tooltip.hover title="Edit Idp goal"
                            @click="editPlayerIDPGoal(goalItem)"><b-icon icon="pencil"
                              aria-hidden="true"></b-icon></b-button>
                        </div>
                      </div>

                      <div class="targets threeMonth">
                        <h4>What do i need to achieve this</h4>
                        <div class="goal-item" v-for="goalItem in IDPGoalsData" :key="goalItem.id">
                          <p v-if="goalItem.month_category === '3'" class="target-outline">
                            <img src="./img/target.svg" alt="" />
                            <span v-html="goalItem.goal"></span>
                          </p>
                          <p v-if="goalItem.month_category === '3'">
                            {{ goalItem.what_do_i_need }}
                          </p>
                          <b-button v-if="goalItem.month_category === '3'" size="lg" class="main-button"
                            variant="outline-warning" v-b-tooltip.hover title="Edit Idp goal"
                            @click="editPlayerIDPGoal(goalItem)"><b-icon icon="pencil"
                              aria-hidden="true"></b-icon></b-button>
                        </div>
                      </div>
                    </div>

                    <div class="out-of-possession">
                      <div class="targets">
                        <h4>6 months</h4>
                        <b-icon icon="calendar2" scale="2" aria-hidden="true"></b-icon>
                      </div>

                      <div class="targets sixMonth">
                        <h4>How can i achieve this</h4>
                        <div class="goal-item" v-for="goalItem in IDPGoalsData" :key="goalItem.id">
                          <p v-if="goalItem.month_category === '6'" class="target-outline">
                            <img src="./img/target.svg" alt="" />
                            <span v-html="goalItem.goal"></span>
                          </p>
                          <p v-if="goalItem.month_category === '6'">
                            {{ goalItem.how_can }}
                          </p>
                          <b-button v-if="goalItem.month_category === '6'" size="lg" class="main-button"
                            variant="outline-warning" v-b-tooltip.hover title="Edit Idp goal"
                            @click="editPlayerIDPGoal(goalItem)"><b-icon icon="pencil"
                              aria-hidden="true"></b-icon></b-button>
                        </div>
                      </div>

                      <div class="targets sixMonth">
                        <h4>What do i need to achieve this</h4>
                        <div class="goal-item" v-for="goalItem in IDPGoalsData" :key="goalItem.id">
                          <p v-if="goalItem.month_category === '6'" class="target-outline">
                            <img src="./img/target.svg" alt="" />
                            <span v-html="goalItem.goal"></span>
                          </p>
                          <p v-if="goalItem.month_category === '6'">
                            {{ goalItem.what_do_i_need }}
                          </p>
                          <b-button v-if="goalItem.month_category === '6'" size="lg" class="main-button"
                            variant="outline-warning" v-b-tooltip.hover title="Edit Idp goal"
                            @click="editPlayerIDPGoal(goalItem)"><b-icon icon="pencil"
                              aria-hidden="true"></b-icon></b-button>
                        </div>
                      </div>
                    </div>

                    <div class="out-of-possession">
                      <div class="targets">
                        <h4>12 months</h4>
                        <b-icon icon="calendar2" scale="2" aria-hidden="true"></b-icon>
                      </div>

                      <div class="targets twelveMonth">
                        <h4>How can i achieve this</h4>
                        <div class="goal-item" v-for="goalItem in IDPGoalsData" :key="goalItem.id">
                          <p v-if="goalItem.month_category === '12'" class="target-outline">
                            <img src="./img/target.svg" alt="" />
                            <span v-html="goalItem.goal"></span>
                          </p>
                          <p v-if="goalItem.month_category === '12'">
                            {{ goalItem.how_can }}
                          </p>
                          <b-button v-if="goalItem.month_category === '12'" size="lg" class="main-button"
                            variant="outline-warning" v-b-tooltip.hover title="Edit Idp goal"
                            @click="editPlayerIDPGoal(goalItem)"><b-icon icon="pencil"
                              aria-hidden="true"></b-icon></b-button>
                        </div>
                      </div>

                      <div class="targets twelveMonth">
                        <h4>What do i need to achieve this</h4>
                        <div class="goal-item" v-for="goalItem in IDPGoalsData" :key="goalItem.id">
                          <p v-if="goalItem.month_category === '12'" class="target-outline">
                            <img src="./img/target.svg" alt="" />
                            <span v-html="goalItem.goal"></span>
                          </p>
                          <p v-if="goalItem.month_category === '12'">
                            {{ goalItem.what_do_i_need }}
                          </p>
                          <b-button v-if="goalItem.month_category === '12'" size="lg" class="main-button"
                            variant="outline-warning" v-b-tooltip.hover title="Edit Idp goal"
                            @click="editPlayerIDPGoal(goalItem)"><b-icon icon="pencil"
                              aria-hidden="true"></b-icon></b-button>
                        </div>
                      </div>
                    </div>

                    <b-button size="lg" class="main-button" variant="outline-success" v-b-tooltip.hover
                      title="Add new month goal" @click="openMonthGoalModal()"><b-icon icon="plus"
                        aria-hidden="true"></b-icon></b-button>
                  </div>
                </div>
              </div>
            </div>

            <div class="current-week-tab tab-content" v-if="selectedTab === 'learning-plan-tab'">
              <div class="games-info">
                <TrainingPlansLinks :selectedUserData="selectedUserData" />
              </div>
            </div>

            <div class="payments-tab tab-content" v-if="selectedTab === 'payments-tab'">
              <h3>Manage your subscription and billing details</h3>

              <div class="games-info">
                <div v-if="playersPayment.length > 0">
                  <p>
                    <a href="https://billing.stripe.com/p/login/eVa8z1cuPfZ53pS5kk">Subscription and billing details</a>
                    You can use the portal to manage your subscription and
                    billing details.
                  </p>

                  <vue-good-table style-class="vgt-table bordered" :columns="columns" :rows="playersPayment"
                    :search-options="{
                      enabled: true,
                      placeholder: 'Search payments',
                    }">
                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field == 'date'">
                        <span>{{
                          formatDates(props.formattedRow[props.column.field])
                          }}</span>
                      </span>
                      <span v-else-if="props.column.field == 'total_price'">
                        {{ pounds(props.formattedRow[props.column.field]) }}
                      </span>
                      <span v-else-if="props.column.field == 'payment_state'">
                        <span :class="props.formattedRow[props.column.field]">{{
                          props.formattedRow[props.column.field]
                          }}</span>
                      </span>
                      <span v-else>
                        <span>{{
                          props.formattedRow[props.column.field]
                          }}</span>
                      </span>
                    </template>
                  </vue-good-table>
                </div>
                <div v-else>
                  <p>
                    We currently do not have any payment information on record.
                    To proceed, kindly subscribe to a payment plan through the
                    <router-link :to="{ name: 'payments' }">Payments and Subscriptions module</router-link>
                  </p>
                </div>
              </div>
            </div>

            <div class="current-week-tab tab-content" v-if="selectedTab === 'parents-meeting-tab'">
              <div class="games-info">
                <div class="button-wrapper-top">
                  <b-button size="lg" class="main-button" variant="outline-success" v-b-tooltip.hover
                    title="Export Parents evenings to csv"><b-icon icon="download"
                      aria-hidden="true"></b-icon></b-button>

                  <b-button size="lg" class="main-button" variant="outline-success" v-b-tooltip.hover
                    title="Import Parents evenings document"><b-icon icon="upload"
                      aria-hidden="true"></b-icon></b-button>

                  <b-button size="lg" class="main-button" variant="outline-success" v-b-tooltip.hover
                    title="Email Parents evenings data"><b-icon icon="envelope" aria-hidden="true"></b-icon></b-button>
                </div>
                <p>Parents evenings cover:</p>

                <ul>
                  <li>The club objectives for the forthcoming season</li>
                  <li>Club fees and registration processes</li>
                  <li>Club diary of events for the year</li>
                  <li>Team management and support</li>
                  <li>Club communication process</li>
                  <li>To recruit additional volunteers</li>
                  <li>Club welfare policy</li>
                  <li>Club Codes of Conduct and</li>
                  <li>disciplinary policy</li>
                </ul>

                <table class="w-a-style">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Discussion points and actions</th>
                      <th>Documents</th>
                      <th>Coach</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>April 2022</td>
                      <td>
                        Overview of progress and outline of player managment
                      </td>
                      <td>
                        <a target="_blank"
                          href="https://south-carolina-surf-performance-centre.com/files/W-academy-IDP-sheet-Ollie-Paxton-2010.pdf">W-academy-IDP-sheet-Ollie-Paxton-2010.pdf</a>
                      </td>
                      <td>Kyle and Olllie's parents present</td>
                      <td>
                        <div class="player-game-responce">
                          <b-button variant="outline-warning"><b-icon icon="pencil" aria-hidden="true"></b-icon>
                            Edit</b-button>
                          <b-button variant="outline-danger"><b-icon icon="trash" aria-hidden="true"></b-icon>
                            Delete</b-button>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>July 2022</td>
                      <td>
                        Overview of progress and outline of player managment
                      </td>
                      <td>
                        <a target="_blank"
                          href="https://south-carolina-surf-performance-centre.com/files/W-academy-IDP-sheet-Ollie-Paxton-2010.pdf">W-academy-IDP-sheet-Ollie-Paxton-2010.pdf</a>
                      </td>
                      <td>Kyle and Ollie's parents present</td>
                      <td>
                        <div class="player-game-responce">
                          <b-button variant="outline-warning"><b-icon icon="pencil" aria-hidden="true"></b-icon>
                            Edit</b-button>
                          <b-button variant="outline-danger"><b-icon icon="trash" aria-hidden="true"></b-icon>
                            Delete</b-button>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>October 2022</td>
                      <td>
                        Overview of progress and outline of player managment
                      </td>
                      <td>
                        <a target="_blank"
                          href="https://south-carolina-surf-performance-centre.com/files/W-academy-IDP-sheet-Ollie-Paxton-2010.pdf">W-academy-IDP-sheet-Ollie-Paxton-2010.pdf</a>
                      </td>
                      <td>Kyle and Olllie's parents present</td>
                      <td>
                        <div class="player-game-responce">
                          <b-button variant="outline-warning"><b-icon icon="pencil" aria-hidden="true"></b-icon>
                            Edit</b-button>
                          <b-button variant="outline-danger"><b-icon icon="trash" aria-hidden="true"></b-icon>
                            Delete</b-button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="current-week-tab tab-content" v-if="selectedTab === 'coach-tab'">
              <div class="games-info">
                <div class="button-wrapper-top">
                  <b-button class="main-button" @click="produceThePlayerCV()" size="lg" v-b-tooltip.hover
                    title="View players c.v" variant="outline-success"><b-icon icon="eye"></b-icon></b-button>
                </div>

                <div class="player-cv">
                  <section class="cv-section">
                    <h3>Personal profile</h3>

                    <p v-if="playerCvData.personal_profile !== ''">
                      {{ playerCvData.personal_profile }}
                    </p>
                    <p v-else>Add your personal Profile.</p>

                    <b-button variant="outline-warning" @click="openPlayerCVpersonalProfileModal()"><b-icon
                        icon="pencil" aria-hidden="true"></b-icon>
                      Edit</b-button>
                  </section>
                  <section class="cv-section">
                    <h3>PLAYING EXPERIENCE</h3>

                    <p v-if="playerCvData.playingExperence.length === 0">
                      Add your playing Experence
                    </p>

                    <table v-else style="margin-bottom: 0.5rem" class="w-a-style">
                      <thead>
                        <tr>
                          <th>Club</th>
                          <th>Time at the club</th>
                          <th>Playing stats</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in playerCvData.playingExperence" :key="index">
                          <td>{{ item.club }}</td>
                          <td>
                            {{ formatDateForHuman(item.start_date_at_club) }} -
                            {{ formatDateForHuman(item.end_date_at_club) }}
                          </td>
                          <td>{{ item.playing_info }}</td>
                          <td>
                            <div class="buttons-row">
                              <b-button @click="
                                openPlayerCVPlayingExperenceModal(index)
                                " size="lg" variant="outline-success">
                                Update</b-button>

                              <b-button @click="deletePlayingExperence(item.id)" size="lg"
                                variant="outline-danger">Delete</b-button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <b-button variant="outline-success" @click="openPlayerCVPlayingExperenceModal()"><b-icon
                        icon="pencil" aria-hidden="true"></b-icon>
                      Add</b-button>
                  </section>

                  <section class="cv-section">
                    <div class="education">
                      <h3>EDUCATION</h3>

                      <p v-if="playerCvData.education.length === 0">
                        Add your education
                      </p>

                      <ul style="margin-bottom: 0.5rem">
                        <li v-for="(item, index) in playerCvData.education" :key="index">
                          <span class="title">{{ item.title }}</span>
                          <span class="info">{{ item.start_date }} - {{ item.end_date }}</span>
                          <span class="info">{{ item.more_info }}</span>
                          <div class="buttons-row">
                            <b-button @click="openPlayerEducationModal(index)" size="lg" variant="outline-success">
                              Update</b-button>

                            <b-button @click="deletePlayerEducation(item.id)" size="lg"
                              variant="outline-danger">Delete</b-button>
                          </div>
                        </li>
                      </ul>

                      <b-button variant="outline-success" @click="openPlayerEducationModal()"><b-icon icon="pencil"
                          aria-hidden="true"></b-icon>
                        Add</b-button>
                    </div>
                  </section>

                  <section class="cv-section">
                    <div class="hobbies">
                      <h3>CAREER STATISTICS</h3>

                      <!--<ul>
                      <li>Goals: 31</li>
                      <li>Shots: 104</li>
                      <li>Assists: 43</li>
                      <li>Pass completion: 84.6%</li>
                    </ul>-->
                    </div>
                  </section>

                  <section class="cv-section">
                    <div class="hobbies">
                      <h3>Interest hobbies outside of football</h3>

                      <p v-if="playerCvData.interests_hobbies.length === 0">
                        Add your interest and hobbies
                      </p>
                      <table v-else style="margin-bottom: 0.5rem" class="w-a-style">
                        <thead>
                          <tr>
                            <th>Interest hobbies</th>
                            <th>More information</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(
item, index
                            ) in playerCvData.interests_hobbies" :key="index">
                            <td>{{ item.interests }}</td>
                            <td>
                              {{ item.more_info }}
                            </td>

                            <td>
                              <div class="buttons-row">
                                <b-button @click="
                                  openPlayerInterestsHobbiesModal(index)
                                  " size="lg" variant="outline-success">
                                  Update</b-button>

                                <b-button @click="deletePlayerInterestsHobbies(item.id)" size="lg"
                                  variant="outline-danger">Delete</b-button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <b-button variant="outline-success" @click="openPlayerInterestsHobbiesModal()"><b-icon
                          icon="pencil" aria-hidden="true"></b-icon>
                        Add</b-button>
                    </div>
                  </section>

                  <section class="cv-section">
                    <div class="players-videos">
                      <h3>Videos</h3>
                      <p v-if="playerCvData.videos.length === 0">
                        Add your football videos
                      </p>

                      <div class="sessions-info" style="margin-bottom: 0.5rem">
                        <div class="video-wrapper" v-for="(item, index) in playerCvData.videos" :key="index">
                          <a v-if="item.category === ''" target="_blank" :href="item.video_url" class="session-card">
                            <img src="./img/video.jpg" alt="" />
                            <p class="small-date">Video</p>
                            <h3>{{ item.video_name }}</h3>
                          </a>

                          <div class="buttons-row" v-if="item.category === ''">
                            <b-button @click="openPlayerVideosModal(index)" size="lg" variant="outline-success">
                              Update</b-button>

                            <b-button @click="deletePlayerVideoLink(item.id)" size="lg"
                              variant="outline-danger">Delete</b-button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <b-button variant="outline-success" @click="openPlayerVideosModal()"><b-icon icon="pencil"
                        aria-hidden="true"></b-icon>
                      Add</b-button>
                  </section>
                </div>
              </div>
            </div>

            <div class="current-week-tab tab-content" v-if="selectedTab === 'code-of-conduct-tab'">
              <div class="games-info">
                <div class="button-wrapper-top">
                  <b-button size="lg" class="main-button" variant="outline-success" v-b-tooltip.hover
                    title="Download players and parents code of conduct"><b-icon icon="download"
                      aria-hidden="true"></b-icon></b-button>

                  <b-button size="lg" class="main-button" variant="outline-success" v-b-tooltip.hover
                    title="Email players and parents code of conduct"><b-icon icon="envelope"
                      aria-hidden="true"></b-icon></b-button>
                </div>
                <div class="players-code">
                  <h3>Players code of conduct</h3>
                  <p>
                    The rules within the Academy are based upon the principle of
                    ‘consideration and respect for others.’ This ensures that
                    everyone at the Academy can be safe, secure and happy during
                    their time at the club.
                  </p>

                  <ul>
                    <li>
                      Players are reminded that they represent Partick Thistle.
                      and should behave in an appropriate manner;
                    </li>

                    <li>
                      Players are required to wear club colours with pride.
                    </li>

                    <li>
                      Swearing, fighting, bullying and bad behaviour of any kind
                      will not be tolerated at any time All match kit will be
                      provided.
                    </li>

                    <li>
                      Players should attend both training and matches with clean
                      and polished boots;
                    </li>

                    <li>
                      Jewellery must not be worn for matches or training
                      sessions, both for personal safety and that of other
                      players;
                    </li>

                    <li>
                      All Academy players are expected to keep themselves fit at
                      all times through a sensible nourishing diet, appropriate
                      rest and exercise. They may be given instructions on
                      exercises to complete away from the Academy and keep their
                      Player Planner up-to-date for inspection by their age
                      group Coach;
                    </li>

                    <li>
                      When travelling to away fixtures, players should ensure
                      they have all details of venue and time of arrival.
                    </li>

                    <li>
                      Players should arrive 60 minutes prior to kick off(where
                      possible) time for home games. For away matches, players
                      will be instructed by coach;
                    </li>

                    <li>
                      Players, under no circumstances, should argue or dispute
                      decisions given by the referee or his assistants;
                    </li>

                    <li>
                      Players attending the Academy must make a commitment to
                      adhere strictly to their individual programme at school;
                    </li>

                    <li>
                      All players are requested not to wear any jackets or kits
                      affiliated to any other football club on training evenings
                    </li>
                  </ul>
                </div>

                <div class="players-code">
                  <h3>Parents’/Guardians’ Code of Conduct</h3>

                  <p>
                    We ask parents/guardians to stand on the appropriate side of
                    the pitch, opposite to the Coaches and substitutes. On grass
                    pitches, please stay behind the cones to permit referees
                    sight of the lines;
                  </p>

                  <p>
                    We ask parents/guardians not to get involved in any form of
                    negative shouting from the touchline. The coaching staff
                    work hard to create the correct environment for all players
                    to express themselves fully, both in training and matches,
                    without fear of making mistakes. The support of
                    parents/guardians is requested to achieve the environment
                    and please leave the coaching to the age group Coaches;
                  </p>

                  <p>
                    If there is an issue that concerns the player or yourself,
                    then please raise it with the appropriate Academy staff;
                  </p>

                  <p>
                    Please ensure that players get plenty of rest. An early
                    night before a game or after training is recommended;
                  </p>

                  <p>
                    Try to get players to eat healthily and at regular
                    intervals.
                  </p>
                  <ul>
                    <li>
                      A good breakfast before a morning game is essential to set
                      up the body for the strenuous work required during a game.
                    </li>
                    <li>
                      Make sure players have an adequate supply of fluids to
                      drink before and after a game;
                    </li>
                  </ul>

                  <p>
                    Try to get players to take responsibility for their football
                    education, i.e. packing their kit bag, cleaning their boots,
                    preparing fluids for the day and making a snack for after
                    the game;
                  </p>

                  <p>
                    Please feel free to talk to the appropriate Coach for
                    feedback on players’ performance. At the start of each
                    season there will be an induction meeting with players and
                    parents/carers of the players to inform them of the games
                    programme and any additional information. Any concerns they
                    may have can be addressed at this meeting.
                  </p>
                </div>

                <b-button variant="outline-warning"><b-icon icon="pencil" aria-hidden="true"></b-icon>
                  Edit</b-button>
              </div>
            </div>

            <div class="current-week-tab tab-content" v-if="selectedTab === 'game-prep-tab'">
              <div class="games-info">
                <div class="button-wrapper-top">
                  <b-button size="lg" class="main-button" variant="outline-success" v-b-tooltip.hover
                    title="Import Game preparation data"><b-icon icon="upload" aria-hidden="true"></b-icon></b-button>

                  <b-button size="lg" class="main-button" variant="outline-success" v-b-tooltip.hover
                    title="Email Game preparation"><b-icon icon="envelope" aria-hidden="true"></b-icon></b-button>
                </div>
                <p>
                  Enjoy a good night’s rest by going to bed early after ensuring
                  that your kit bag is packed with your
                </p>
                <ul>
                  <li>Boots</li>
                  <li>Shin guards</li>
                  <li>Towel</li>
                  <li>Rain jacket</li>
                  <li>Toiletries</li>
                  <li>Water bottle</li>
                  <li>Isotonic drink</li>
                  <li>Other items at Coach’s request</li>
                </ul>

                <ul>
                  <li>
                    Prepare yourself if you have a long journey by bringing
                    reading material, quiz books, personal stereo and possibly
                    school reading material.
                  </li>
                  <li>
                    Have a substantial breakfast as outlined in the section on
                    ‘Food for Football.’
                  </li>
                  <li>
                    Travel to the game in your tracksuit, polo shirt and
                    trainers unless instructed otherwise by your Coach;
                  </li>
                  <li>
                    Allow yourself plenty of time to get to your pick up
                    location. You should arrive 15 minutes before your departure
                    time;
                  </li>
                  <li>
                    It is advisable to bring a snack to eat after the match;
                  </li>
                  <li>
                    If you follow these simple instructions we are confident
                    that you will enjoy the day and always make sure you enjoy
                    the game.
                  </li>
                </ul>

                <b-button variant="outline-warning"><b-icon icon="pencil" aria-hidden="true"></b-icon>
                  Edit</b-button>
              </div>
            </div>

            <b-modal id="newplayerIDPModal" size="xl" title="Player / Coach ratings" :hide-footer="Boolean(true)">
              <div class="development-plan-wrapper">
                <div class="player-coach-scores-idp">
                  <h3>Player IDPs</h3>
                  <p>
                    From the Areas listed in your IDP, Please list a key skill
                    in each of the 4 boxes, with one skill for Green/ 1 for
                    Yellow / 1 for red.<br />
                    Two key areas of improvement (Yellow and Red) and One area
                    of your strongest/best attribute (Green).
                    <br />
                    Add these into your IDP Grade using traffic light system.
                  </p>

                  <ul>
                    <li>Green – Excelling/Striving</li>
                    <li>Yellow- Coping </li>
                    <li>Red – Developing</li>
                  </ul>

                  <div class="scores-wrapper">
                    <div class="score-item">
                      <h3>Technical</h3>
                      <div class="score-by-type green">
                        <h4>Green</h4>
                        <b-form-select v-model="newPlayerIDPdata.technical_green" :options="technicalSelectOptions"
                          class="mb-3">
                          <template #first>
                            <b-form-select-option :value="null" disabled>-- Please select an option
                              --</b-form-select-option>
                          </template>
                        </b-form-select>
                      </div>

                      <div class="score-by-type yellow">
                        <h4>Yellow</h4>
                        <b-form-select v-model="newPlayerIDPdata.technical_yellow" :options="technicalSelectOptions"
                          class="mb-3">
                          <template #first>
                            <b-form-select-option :value="null" disabled>-- Please select an option
                              --</b-form-select-option>
                          </template>
                        </b-form-select>
                      </div>

                      <div class="score-by-type red">
                        <h4>Red</h4>
                        <b-form-select v-model="newPlayerIDPdata.technical_red" :options="technicalSelectOptions"
                          class="mb-3">
                          <template #first>
                            <b-form-select-option :value="null" disabled>-- Please select an option
                              --</b-form-select-option>
                          </template>
                        </b-form-select>
                      </div>
                    </div>

                    <div class="score-item">
                      <h3>Tactical</h3>
                      <div class="score-by-type green">
                        <h4>Green</h4>
                        <b-form-select v-model="newPlayerIDPdata.tactical_green" :options="tacticalSelectOptions"
                          class="mb-3">
                          <template #first>
                            <b-form-select-option :value="null" disabled>-- Please select an option
                              --</b-form-select-option>
                          </template>
                        </b-form-select>
                      </div>

                      <div class="score-by-type yellow">
                        <h4>Yellow</h4>
                        <b-form-select v-model="newPlayerIDPdata.tactical_yellow" :options="tacticalSelectOptions"
                          class="mb-3">
                          <template #first>
                            <b-form-select-option :value="null" disabled>-- Please select an option
                              --</b-form-select-option>
                          </template>
                        </b-form-select>
                      </div>

                      <div class="score-by-type red">
                        <h4>Red</h4>
                        <b-form-select v-model="newPlayerIDPdata.tactical_red" :options="tacticalSelectOptions"
                          class="mb-3">
                          <template #first>
                            <b-form-select-option :value="null" disabled>-- Please select an option
                              --</b-form-select-option>
                          </template>
                        </b-form-select>
                      </div>
                    </div>

                    <div class="score-item">
                      <h3>Physical</h3>

                      <div class="score-by-type green">
                        <h4>Green</h4>
                        <b-form-select v-model="newPlayerIDPdata.physical_green" :options="physicalSelectOptions"
                          class="mb-3">
                          <template #first>
                            <b-form-select-option :value="null" disabled>-- Please select an option
                              --</b-form-select-option>
                          </template>
                        </b-form-select>
                      </div>

                      <div class="score-by-type yellow">
                        <h4>Yellow</h4>
                        <b-form-select v-model="newPlayerIDPdata.physical_yellow" :options="physicalSelectOptions"
                          class="mb-3">
                          <template #first>
                            <b-form-select-option :value="null" disabled>-- Please select an option
                              --</b-form-select-option>
                          </template>
                        </b-form-select>
                      </div>

                      <div class="score-by-type red">
                        <h4>Red</h4>
                        <b-form-select v-model="newPlayerIDPdata.physical_red" :options="physicalSelectOptions"
                          class="mb-3">
                          <template #first>
                            <b-form-select-option :value="null" disabled>-- Please select an option
                              --</b-form-select-option>
                          </template>
                        </b-form-select>
                      </div>
                    </div>

                    <div class="score-item">
                      <h3>Mental</h3>
                      <div class="score-by-type green">
                        <h4>Green</h4>
                        <b-form-select v-model="newPlayerIDPdata.physcological_green"
                          :options="physcologicalSelectOptions" class="mb-3">
                          <template #first>
                            <b-form-select-option :value="null" disabled>-- Please select an option
                              --</b-form-select-option>
                          </template>
                        </b-form-select>
                      </div>

                      <div class="score-by-type yellow">
                        <h4>Yellow</h4>
                        <b-form-select v-model="newPlayerIDPdata.physcological_yellow"
                          :options="physcologicalSelectOptions" class="mb-3">
                          <template #first>
                            <b-form-select-option :value="null" disabled>-- Please select an option
                              --</b-form-select-option>
                          </template>
                        </b-form-select>
                      </div>

                      <div class="score-by-type red">
                        <h4>Red</h4>
                        <b-form-select v-model="newPlayerIDPdata.physcological_red"
                          :options="physcologicalSelectOptions" class="mb-3">
                          <template #first>
                            <b-form-select-option :value="null" disabled>-- Please select an option
                              --</b-form-select-option>
                          </template>
                        </b-form-select>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="idp-comments">
                  <div class="comments-item">
                    <h4>Player comments</h4>
                    <b-form-textarea id="textarea-rows" placeholder="add a comment..."
                      v-model="newPlayerIDPdata.player_comments" rows="8"></b-form-textarea>
                  </div>
                  <div class="comments-item" v-if="
                    userData.userType === 'Admin' ||
                    userData.userType === 'Coach'
                  ">
                    <h4>Coach comments</h4>
                    <b-form-textarea id="textarea-rows" placeholder="add a comment..."
                      v-model="newPlayerIDPdata.coach_comments" rows="8"></b-form-textarea>
                  </div>
                </div>

                <b-button @click="savePlayerIDPScores()" size="lg" variant="outline-success">Save</b-button>
              </div>
            </b-modal>

            <b-modal id="playerIDPModal" size="xl" :hide-footer="Boolean(true)">
              <div class="development-plan-wrapper">
                <div class="player-coach-scores-idp">
                  <h3>Player / Coach ratings</h3>

                  <div class="scores-wrapper">
                    <div class="score-item">
                      <h3>Technical</h3>
                      <div class="score-by-type green">
                        <h4>Green</h4>
                        <b-form-select v-model="playerIDPdata.technical_green" :options="technicalSelectOptions"
                          class="mb-3">
                          <template #first>
                            <b-form-select-option :value="null" disabled>-- Please select an option
                              --</b-form-select-option>
                          </template>
                        </b-form-select>
                      </div>

                      <div class="score-by-type yellow">
                        <h4>Yellow</h4>
                        <b-form-select v-model="playerIDPdata.technical_yellow" :options="technicalSelectOptions"
                          class="mb-3">
                          <template #first>
                            <b-form-select-option :value="null" disabled>-- Please select an option
                              --</b-form-select-option>
                          </template>
                        </b-form-select>
                      </div>

                      <div class="score-by-type red">
                        <h4>Red</h4>
                        <b-form-select v-model="playerIDPdata.technical_red" :options="technicalSelectOptions"
                          class="mb-3">
                          <template #first>
                            <b-form-select-option :value="null" disabled>-- Please select an option
                              --</b-form-select-option>
                          </template>
                        </b-form-select>
                      </div>
                    </div>

                    <div class="score-item">
                      <h3>Tactical</h3>
                      <div class="score-by-type green">
                        <h4>Green</h4>
                        <b-form-select v-model="playerIDPdata.tactical_green" :options="tacticalSelectOptions"
                          class="mb-3">
                          <template #first>
                            <b-form-select-option :value="null" disabled>-- Please select an option
                              --</b-form-select-option>
                          </template>
                        </b-form-select>
                      </div>

                      <div class="score-by-type yellow">
                        <h4>Yellow</h4>
                        <b-form-select v-model="playerIDPdata.tactical_yellow" :options="tacticalSelectOptions"
                          class="mb-3">
                          <template #first>
                            <b-form-select-option :value="null" disabled>-- Please select an option
                              --</b-form-select-option>
                          </template>
                        </b-form-select>
                      </div>

                      <div class="score-by-type red">
                        <h4>Red</h4>
                        <b-form-select v-model="playerIDPdata.tactical_red" :options="tacticalSelectOptions"
                          class="mb-3">
                          <template #first>
                            <b-form-select-option :value="null" disabled>-- Please select an option
                              --</b-form-select-option>
                          </template>
                        </b-form-select>
                      </div>
                    </div>

                    <div class="score-item">
                      <h3>Physical</h3>

                      <div class="score-by-type green">
                        <h4>Green</h4>
                        <b-form-select v-model="playerIDPdata.physical_green" :options="physicalSelectOptions"
                          class="mb-3">
                          <template #first>
                            <b-form-select-option :value="null" disabled>-- Please select an option
                              --</b-form-select-option>
                          </template>
                        </b-form-select>
                      </div>

                      <div class="score-by-type yellow">
                        <h4>Yellow</h4>
                        <b-form-select v-model="playerIDPdata.physical_yellow" :options="physicalSelectOptions"
                          class="mb-3">
                          <template #first>
                            <b-form-select-option :value="null" disabled>-- Please select an option
                              --</b-form-select-option>
                          </template>
                        </b-form-select>
                      </div>

                      <div class="score-by-type red">
                        <h4>Red</h4>
                        <b-form-select v-model="playerIDPdata.physical_red" :options="physicalSelectOptions"
                          class="mb-3">
                          <template #first>
                            <b-form-select-option :value="null" disabled>-- Please select an option
                              --</b-form-select-option>
                          </template>
                        </b-form-select>
                      </div>
                    </div>

                    <div class="score-item">
                      <h3>Mental</h3>
                      <div class="score-by-type green">
                        <h4>Green</h4>
                        <b-form-select v-model="playerIDPdata.physcological_green" :options="physcologicalSelectOptions"
                          class="mb-3">
                          <template #first>
                            <b-form-select-option :value="null" disabled>-- Please select an option
                              --</b-form-select-option>
                          </template>
                        </b-form-select>
                      </div>

                      <div class="score-by-type yellow">
                        <h4>Yellow</h4>
                        <b-form-select v-model="playerIDPdata.physcological_yellow"
                          :options="physcologicalSelectOptions" class="mb-3">
                          <template #first>
                            <b-form-select-option :value="null" disabled>-- Please select an option
                              --</b-form-select-option>
                          </template>
                        </b-form-select>
                      </div>

                      <div class="score-by-type red">
                        <h4>Red</h4>
                        <b-form-select v-model="playerIDPdata.physcological_red" :options="physcologicalSelectOptions"
                          class="mb-3">
                          <template #first>
                            <b-form-select-option :value="null" disabled>-- Please select an option
                              --</b-form-select-option>
                          </template>
                        </b-form-select>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="idp-comments">
                  <div class="comments-item">
                    <h4>Player comments</h4>
                    <b-form-textarea id="textarea-rows" placeholder="add a comment..."
                      v-model="playerIDPdata.player_comments" rows="8"></b-form-textarea>
                  </div>
                  <div class="comments-item" v-if="
                    userData.userType === 'Admin' ||
                    userData.userType === 'Coach'
                  ">
                    <h4>Coach comments</h4>
                    <b-form-textarea id="textarea-rows" placeholder="add a comment..."
                      v-model="playerIDPdata.coach_comments" rows="8"></b-form-textarea>
                  </div>
                </div>

                <b-button @click="updatePlayerIDPScores()" size="lg" variant="outline-success">Update</b-button>
              </div>
            </b-modal>

            <b-modal id="viewHomeworkModal" size="lg" :hide-footer="Boolean(true)">
              <h2>homework</h2>

              <div class="form__item">
                <label class="form__label" for="goals">Title</label>
                <p>{{ newHomeWorkData.title }}</p>
              </div>

              <div class="form__item">
                <label class="form__label" for="goals">Game</label>
                <p>
                  {{ newHomeWorkData.opposition }} -
                  {{ formatDate(newHomeWorkData.matchDate) }}
                </p>
              </div>

              <div class="form__item">
                <label class="form__label" for="goals">Information</label>
                <p v-html="newHomeWorkData.information"></p>
              </div>

              <h4>Videos</h4>

              <div v-if="newHomeWorkData.videos.length > 0" class="homework-links player-cv">
                <div class="sessions-info" style="margin-bottom: 0.5rem">
                  <div class="video-wrapper" v-for="(item, index) in newHomeWorkData.videos" :key="index">
                    <a target="_blank" :href="item.video_url" class="session-card">
                      <img src="./img/video.jpg" alt="" />
                      <p class="small-date">Video</p>
                      <h3>{{ item.video_name }}</h3>
                    </a>
                  </div>
                </div>
              </div>
            </b-modal>

            <b-modal id="addHomeworkModal" size="lg" :hide-footer="Boolean(true)">
              <h2>
                <span v-if="newHomeWorkData.id !== undefined">Edit</span><span v-else>Add</span> homework
              </h2>

              <div class="form__item">
                <label class="form__label" for="goals">Title</label>
                <b-form-input id="textarea" v-model="newHomeWorkData.title"></b-form-input>
              </div>

              <div class="form__item">
                <label class="form__label" for="goals">Select game</label><br />

                <b-form-select v-model="newHomeWorkData.gameId" class="mb-3">
                  <b-form-select-option v-for="(game, index) in allGameData" :key="index" :value="game.id">{{
                    formatDates(game.matchDate) }} -
                    {{ game.opposition }}</b-form-select-option>
                </b-form-select>
              </div>

              <div class="form__item">
                <label class="form__label" for="goals">Information</label>
                <wysiwyg v-model="newHomeWorkData.information" />
              </div>

              <h4>Videos</h4>

              <b-button v-b-tooltip.hover title="Add video" @click="addVideo('homework')" variant="success">
                <b-icon icon="plus" aria-hidden="true"></b-icon>
              </b-button>

              <div class="homework-links" v-for="(video, index) in newHomeWorkData.videos" :key="index" :value="video">
                <div class="form__item">
                  <label class="form__label" for="video-name">Video name</label>
                  <b-form-input id="video-name" v-model="video.video_name"></b-form-input>
                </div>

                <div class="form__item">
                  <label class="form__label" for="video-url">Video URL</label>
                  <b-form-input id="video-url" v-model="video.video_url"></b-form-input>
                </div>

                <b-button v-b-tooltip.hover title="remove video" variant="danger">
                  <b-icon icon="x" aria-hidden="true" @click="removeVideo(index)"></b-icon></b-button>
              </div>

              <b-button v-if="newHomeWorkData.id !== undefined" @click="editHomework()" size="lg"
                variant="outline-success"><b-icon icon="file-text" aria-hidden="true"></b-icon> Edit game
                homework</b-button>

              <b-button v-else @click="saveHomework()" size="lg" variant="outline-success"><b-icon icon="file-text"
                  aria-hidden="true"></b-icon> Submit
                game homework</b-button>
            </b-modal>
            <b-modal id="addHeight" size="lg" :hide-footer="Boolean(true)">
              <h2>Add your height</h2>

              <div class="form__item">
                <b-input-group append="cm">
                  <br />
                  <b-form-input type="number" placeholder="Height" v-model="height"></b-form-input>
                </b-input-group>
              </div>

              <b-button @click="saveHeight()" variant="outline-success">Save</b-button>
            </b-modal>

            <b-modal id="addWeight" :hide-footer="Boolean(true)">
              <h2>Add your weight</h2>

              <div class="form__item">
                <b-input-group append="kg">
                  <br />
                  <b-form-input type="number" v-model="weight" placeholder="Weight"></b-form-input>
                </b-input-group>
              </div>

              <b-button @click="saveWeight()" variant="outline-success">Save</b-button>
            </b-modal>

            <b-modal id="viewDailyWellnessReport" size="lg" @close="onCloseDailyWellnessModal()"
              :hide-footer="Boolean(true)">
              <h2>How do you feel today?</h2>
              <b-form-group class="colour-radios" label="Sleep" v-slot="{ ariaDescribedby }">
                <b-form-radio-group v-model="wellnessQuestions.sleep" :aria-describedby="ariaDescribedby"
                  :options="zeroToTen" name="sleep-radios" buttons>
                </b-form-radio-group>
                <div class="tyes-of-stuff">
                  <span>Bad</span>
                  <span>Amazing</span>
                </div>
              </b-form-group>

              <b-form-group class="colour-radios" label="Happiness" v-slot="{ ariaDescribedby }">
                <b-form-radio-group v-model="wellnessQuestions.happyness" :aria-describedby="ariaDescribedby"
                  :options="zeroToTen" name="Happyness-radios" buttons>
                </b-form-radio-group>
                <div class="tyes-of-stuff">
                  <span>Very sad</span>
                  <span>Very happy</span>
                </div>
              </b-form-group>

              <b-form-group class="colour-radios" label="Stress" v-slot="{ ariaDescribedby }">
                <b-form-radio-group v-model="wellnessQuestions.stress" :aria-describedby="ariaDescribedby"
                  :options="zeroToTen" name="Stress-radios" buttons>
                </b-form-radio-group>
                <div class="tyes-of-stuff">
                  <span>Really stressed</span>
                  <span>None </span>
                </div>
              </b-form-group>

              <b-form-group class="colour-radios" label="Fatigue" v-slot="{ ariaDescribedby }">
                <b-form-radio-group v-model="wellnessQuestions.fatigue" :aria-describedby="ariaDescribedby"
                  :options="zeroToTen" name="Fatigue-radios" buttons>
                </b-form-radio-group>
                <div class="tyes-of-stuff">
                  <span>Exhausted</span>
                  <span>Ready to go</span>
                </div>
              </b-form-group>

              <b-form-group class="colour-radios" label="Muscle Soreness" v-slot="{ ariaDescribedby }">
                <b-form-radio-group v-model="wellnessQuestions.muscleSoreness" :aria-describedby="ariaDescribedby"
                  :options="zeroToTen" name="Muscle-radios" buttons>
                </b-form-radio-group>
                <div class="tyes-of-stuff">
                  <span>Very sore</span>
                  <span>None</span>
                </div>
              </b-form-group>

              <div class="player-game-responce">
                <b-button @click="saveHowYouFeel()" variant="outline-success">Save</b-button>
              </div>
            </b-modal>

            <b-modal id="performanceModal" size="lg" :hide-footer="Boolean(true)">
              <h2>Record your game performance</h2>

              <form class="form performance-form" @submit.prevent="saveUserGamePerformanceReport()">
                <div class="form__item">
                  <label class="form__label" for="gameId">Select game</label>
                  <select id="gameId" v-model="newGamePerformanceData.gameId">
                    <option disabled value="">Please select a game</option>
                    <option v-for="game in pastGameDataDataFiltered" :value="game.id" :key="game.id"
                      :disabled="game.disabled">
                      {{ formatISOStringDateAndTime(game.kickOffTime) }} |
                      {{ game.opposition }}
                    </option>
                  </select>
                </div>

                <div class="form__item">
                  <label class="form__label" for="postion_played">What position did you play?</label>
                  <select id="postion_played" v-model="newGamePerformanceData.postion_played">
                    <option disabled value="">Please select a position</option>
                    <option value="Goalkeeper">Goalkeeper</option>
                    <option value="Right Full-back (Wingback)">
                      Right Full-back (Wingback)
                    </option>
                    <option value="Left Full-back (Wingback)">
                      Left Full-back (Wingback)
                    </option>
                    <option value="Center-back left">Center-back left</option>
                    <option value="Center-back right">Center-back right</option>
                    <option value="Centre midfield">Centre midfield</option>
                    <option value="Centre Defensive Midfield">
                      Centre Defensive Midfield
                    </option>
                    <option value="Defensive Midfielder">
                      Defensive Midfielder
                    </option>
                    <option value="Right Midfielder (Winger)">
                      Right Midfielder (Winger)
                    </option>
                    <option value="Left Midfielder (Winger)">
                      Left Midfielder (Winger)
                    </option>
                    <option value="Attacking Midfielder">
                      Attacking Midfielder
                    </option>
                    <option value="Center Forward (Striker) left">
                      Center Forward (Striker) left
                    </option>
                    <option value="Center Forward (Striker) right">
                      Center Forward (Striker) right
                    </option>
                  </select>
                </div>

                <div class="form__item">
                  <label class="form__label" for="postion_played_two">Second position played?</label>
                  <select id="postion_played_two" v-model="newGamePerformanceData.postion_played_two">
                    <option disabled value="">Please select a position</option>
                    <option value="Goalkeeper">Goalkeeper</option>
                    <option value="Right Full-back (Wingback)">
                      Right Full-back (Wingback)
                    </option>
                    <option value="Left Full-back (Wingback)">
                      Left Full-back (Wingback)
                    </option>
                    <option value="Center-back left">Center-back left</option>
                    <option value="Center-back right">Center-back right</option>
                    <option value="Centre midfield">Centre midfield</option>
                    <option value="Centre Defensive Midfield">
                      Centre Defensive Midfield
                    </option>

                    <option value="Defensive Midfielder">
                      Defensive Midfielder
                    </option>
                    <option value="Right Midfielder (Winger)">
                      Right Midfielder (Winger)
                    </option>
                    <option value="Left Midfielder (Winger)">
                      Left Midfielder (Winger)
                    </option>
                    <option value="Attacking Midfielder">
                      Attacking Midfielder
                    </option>
                    <option value="Center Forward (Striker) left">
                      Center Forward (Striker) left
                    </option>
                    <option value="Center Forward (Striker) right">
                      Center Forward (Striker) right
                    </option>
                  </select>
                </div>

                <h3>Technically</h3>

                <div class="form__item">
                  <label class="form__label" for="goals">Improvements</label>
                  <b-form-textarea id="textarea" v-model="newGamePerformanceData.technically_improvements"
                    placeholder="Technical improvements text..." rows="3" max-rows="6"></b-form-textarea>
                </div>

                <div class="form__item">
                  <label class="form__label" for="goals">Positives</label>
                  <b-form-textarea id="textarea" v-model="newGamePerformanceData.technically_positives"
                    placeholder="Positives text..." rows="3" max-rows="6"></b-form-textarea>
                </div>

                <h3>Tactility</h3>

                <div class="form__item">
                  <label class="form__label" for="goals">Improvements</label>
                  <b-form-textarea id="textarea" v-model="newGamePerformanceData.tactility_improvements"
                    placeholder="Tactility improvements text..." rows="3" max-rows="6"></b-form-textarea>
                </div>

                <div class="form__item">
                  <label class="form__label" for="goals">Positives</label>
                  <b-form-textarea id="textarea" v-model="newGamePerformanceData.tactility_positives"
                    placeholder="Tactility Positives text..." rows="3" max-rows="6"></b-form-textarea>
                </div>

                <h3>Physicality</h3>

                <div class="form__item">
                  <label class="form__label" for="goals">Improvements</label>
                  <b-form-textarea id="textarea" v-model="newGamePerformanceData.physicality_improvements"
                    placeholder="Physicality improvements text..." rows="3" max-rows="6"></b-form-textarea>
                </div>

                <div class="form__item">
                  <label class="form__label" for="goals">Positives</label>
                  <b-form-textarea id="textarea" v-model="newGamePerformanceData.physicality_positives"
                    placeholder="Physicality Positives text..." rows="3" max-rows="6"></b-form-textarea>
                </div>

                <h3>Mental</h3>

                <div class="form__item">
                  <label class="form__label" for="goals">Improvements</label>
                  <b-form-textarea id="textarea" v-model="newGamePerformanceData.mentally_improvements"
                    placeholder="Physiological improvements text..." rows="3" max-rows="6"></b-form-textarea>
                </div>

                <div class="form__item">
                  <label class="form__label" for="goals">Positives</label>
                  <b-form-textarea id="textarea" v-model="newGamePerformanceData.mentally_positives"
                    placeholder="Physiological Positives text..." rows="3" max-rows="6"></b-form-textarea>
                </div>
                <div class="form__item head-style">
                  <label class="form__label" for="timeOnPitch">How long did you play for? (mins)</label>

                  <input class="form__input" type="number" id="timeOnPitch" max="120" min="0"
                    onKeyDown="if(this.value.length==4 && event.keyCode>47 && event.keyCode < 58)return false;"
                    v-model="newGamePerformanceData.timeOnPitch" />
                </div>

                <div class="form__item">
                  <label class="form__label" for="card">Did you get a card?</label>
                  <select id="card" v-model="newGamePerformanceData.card">
                    <option value="no">No</option>
                    <option value="Yellow">Yellow</option>
                    <option value="two Yellows (red)">Two yellows (red)</option>
                    <option value="red">red</option>
                  </select>
                </div>

                <!--<div class="form__item">
                  <label class="form__label" for="player_self_rating"
                    >How did you think you played?</label
                  >
                  <select
                    id="player_self_rating"
                    v-model="newGamePerformanceData.player_self_rating"
                  >
                    <option value="Amazing">Amazing</option>
                    <option value="Good">Good</option>
                    <option value="Ok">Ok</option>
                    <option value="Not so good">Not so good</option>
                    <option value="Could play better">Could play better</option>
                  </select>
                </div>-->

                <div v-if="newGamePerformanceData.postion_played !== 'Goalkeeper'" class="form__item">
                  <label class="form__label" for="assists">How many Assists?</label>
                  <input class="form__input" type="number" id="assists" v-model="newGamePerformanceData.assists" />
                </div>

                <div v-if="newGamePerformanceData.postion_played !== 'Goalkeeper'" class="form__item">
                  <label class="form__label" for="goals">How many goals did you score?</label>
                  <input class="form__input" type="number" id="goals" v-model="newGamePerformanceData.goals" />
                </div>
                <div v-else class="form__item">
                  <label class="form__label" for="goals">Did you have a clean sheet?</label>
                  <select id="goals" v-model="newGamePerformanceData.clean_sheet">
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>

                <div class="form__item">
                  <label class="form__label" for="goals">Overall comments</label>
                  <b-form-textarea id="textarea" v-model="newGamePerformanceData.overall_comments"
                    placeholder="Comments..." rows="3" max-rows="6"></b-form-textarea>
                </div>

                <b-button type="submit" size="lg" variant="outline-success"><b-icon icon="file-text"
                    aria-hidden="true"></b-icon>
                  Submit
                  game performance report</b-button>
              </form>
            </b-modal>

            <b-modal id="trainingPerformanceModal" size="lg" :hide-footer="Boolean(true)">
              <h2>Record your training report</h2>
              <form class="form" @submit.prevent="saveTraningPerformanceReport()">
                <div class="form__item">
                  <label class="form__label" for="traningId">Select training session</label>
                  <select id="traningId" v-model="newTrainingPerformanceData.traningId">
                    <option disabled value="">Please select a game</option>
                    <option v-for="training in pastTrainingSessionsDataFiltered" :value="training.id" :key="training.id"
                      :disabled="training.disabled">
                      {{ formatISOStringDateAndTime(training.kickOffTime) }} |
                      Traning
                    </option>
                  </select>
                </div>

                <div class="form__item">
                  <label class="form__label" for="effort">How hard was the session?</label>
                  <select id="effort" v-model="newTrainingPerformanceData.effort">
                    <option disabled value="">Please select an option</option>
                    <option value="10">10 Max effort</option>
                    <option value="9">9 VERY HARD</option>
                    <option value="8">8 VIGOROUS ACTIVITY</option>
                    <option value="7">7 MODERATE ACTIVITY</option>
                    <option value="6">6 LIGHT ACTIVITY</option>
                    <option value="5">5 VERY LIGHT ACTIVITY</option>
                  </select>
                </div>

                <div class="form__item">
                  <label class="form__label" for="enjoyment">Did you enjoy the session?</label>
                  <select id="enjoyment" v-model="newTrainingPerformanceData.enjoyment">
                    <option value="Amazing">Amazing</option>
                    <option value="Good">Good</option>
                    <option value="Ok">Ok</option>
                    <option value="Not so good">Not so good</option>
                    <option value="Did not enjoy it">Did not enjoy it</option>
                  </select>
                </div>

                <b-button v-if="pastTrainingSessionsDataFiltered.length > 0" type="submit" size="lg"
                  variant="outline-success"><b-icon icon="file-text" aria-hidden="true"></b-icon> Submit
                  training performance report</b-button>
                <p class="red-warning" v-else>
                  You need to attended at least one training session to complete
                  a training performance report
                </p>
              </form>
            </b-modal>

            <b-modal id="coachGamePerformanceModal" size="lg" :hide-footer="Boolean(true)"
              @hide="hideCoachPerformanceModel()">
              <h2>Record players game report</h2>

              <form class="form" @submit.prevent="coachesGamePerformanceReport()">
                <div class="form__item">
                  <label class="form__label" for="gameIdSelect">Select game</label>
                  <select id="gameIdSelect" v-model="coachGamePerformanceData.gameId">
                    <option disabled value="">Please select a game</option>
                    <option v-for="game in pastGameDataFilteredCoach" :value="game.gameId" :key="game.id"
                      :disabled="game.disabled">
                      {{ formatDates(game.matchDate) }} |
                      {{ game.opposition }}
                    </option>
                  </select>
                </div>

                <div class="form__item">
                  <label class="form__label" for="player_rating">How did you think<br />
                    {{ selectedUserData.name }} played?</label>
                  <select id="player_rating" v-model="coachGamePerformanceData.player_rating">
                    <option value="Amazing">Amazing</option>
                    <option value="Good">Good</option>
                    <option value="0k">Ok</option>
                    <option value="Could play better">Could play better</option>
                  </select>
                </div>

                <div class="form__item">
                  <label class="form__label" for="goals">Positive comments</label>
                  <textarea class="form__input" id="game_comments"
                    v-model="coachGamePerformanceData.game_comments"></textarea>
                </div>

                <div class="form__item">
                  <label class="form__label" for="goals">Improvments comments</label>
                  <textarea class="form__input" id="improvments_comments"
                    v-model="coachGamePerformanceData.game_improvements"></textarea>
                </div>

                <b-button v-if="pastGameData.length > 0" type="submit" size="lg" variant="outline-success"><b-icon
                    icon="file-text" aria-hidden="true"></b-icon> Submit
                  player game performance report</b-button>
                <p class="red-warning" v-else>
                  You need to play at least one game to complete a players game
                  report
                </p>
              </form>
            </b-modal>
          </div>
        </template>
        <template v-else>
          <section class="player-profile-wrapper">
            <ul class="tabs headding-space">
              <li class="tab-item" v-if="userData.userType === 'Admin'">
                <a :class="selectedDashboardTab === 'player-over-veiw-tab'
                    ? 'tab-link active'
                    : 'tab-link'
                  " @click="
                    tabChange('selectedDashboardTab', 'player-over-veiw-tab')
                    " href="javascript:;">Players</a>
              </li>
              <!--<li class="tab-item" v-if="userData.userType === 'Admin'">
                <a
                  :class="
                    selectedDashboardTab === 'players-reports-tab'
                      ? 'tab-link active'
                      : 'tab-link'
                  "
                  @click="
                    tabChange('selectedDashboardTab', 'players-reports-tab')
                  "
                  href="javascript:;"
                  >Stats / reports</a
                >
              </li>-->
            </ul>

            <div class="tabs-wrapper">
              <div class="player-over-veiw-tab tab-content" v-if="selectedDashboardTab === 'player-over-veiw-tab'">
                <div class="number-of-results">
                  <p v-if="filterdCoachesUsersData.length > 0">
                    Number of players: {{ filterdCoachesUsersData.length }}
                  </p>
                  <p v-else>Number of players: {{ coachesUsersData.length }}</p>
                </div>
                <div class="button-wrapper-top filters-sort">
                  <b-input-group size="sm" class="mb-2 autocomplete search-small">
                    <b-input-group-prepend is-text>
                      <b-icon icon="search"></b-icon>
                    </b-input-group-prepend>
                    <b-form-input type="search" placeholder="Search players"
                      @input="lookUpSearch($event)"></b-form-input>
                    <ul class="autocomplete-results" v-if="filterdCoachesUsersData.length > 0">
                      <li @click="setSelectedUserOfTrainingPlan(userData)" v-for="userData in filterdCoachesUsersData"
                        :key="userData.id" class="autocomplete-result">
                        {{ userData.name }}
                      </li>
                    </ul>
                  </b-input-group>

                  <b-button @click="toggleFilters = !toggleFilters" variant="outline-success filter-small">
                    <b-icon icon="filter" aria-hidden="true"></b-icon>
                    Filters</b-button>

                  <div class="filters" v-if="toggleFilters === true">
                    <b-form-select class="mb-3" @change="
                      filterBy('allUserDataBeforFilterApplied', 'teamName')
                      " v-model="selectedFilterData">
                      <b-form-select-option :value="null" disabled>Select a team</b-form-select-option>
                      <b-form-select-option v-for="team in clubTeams" :key="team.id" :value="team.teamName">{{
                        team.teamName }}</b-form-select-option>
                    </b-form-select>
                    <a @click="removeFilter()" v-if="hideRemoveTeamFilterButton === false" href="javascript:;"
                      class="remove-icon-button"><b-icon icon="x" font-scale="2" aria-hidden="true"></b-icon></a>
                    <div>
                      <input :checked="showOnlyInjuredPlayers" type="checkbox" id="fullPitch" name="fullPitch"
                        @change="showOnlyInjuredPlayersToggle()" />
                      <label for="fullPitch">Show only injured players</label><br />
                    </div>
                  </div>
                </div>

                <template v-if="coachesUsersData.length > 0">
                  <div v-for="player in coachesUsersData" :value="player.id" :key="player.id" :class="player.injury === true
                      ? 'player-profile-details injury'
                      : 'player-profile-details'
                    " @click="selectedUseChanged(player)">
                    <div class="user-photo-info">
                      <img v-if="
                        player.userImage !== '' && player.userImage !== null
                      " :src="getImgUrl(player.userImage)" alt="" />
                      <img v-else :src="getImgUrl('default-image.jpg')" alt="" />
                    </div>
                    <div class="user-info">
                      <h2>{{ player.name }}</h2>
                      <p>{{ player.teamName }}</p>
                      <p class="red-warning" v-if="player.injury === true">
                        <b-icon icon="exclamation-circle"></b-icon> Player is
                        injured
                      </p>
                      <p>Age: {{ player.age_group }}</p>
                      <p>Position: {{ player.position }}</p>
                      <p v-if="checkSignedDate(player.joined_date) === true">
                        Signed: {{ formatDate(player.joined_date) }}
                      </p>
                      <p class="red-warning" v-else>Signed: Missing date</p>
                      <p>
                        Registered:
                        {{ converBoolean(player.sfa_registered) }}
                      </p>
                    </div>
                  </div>
                </template>
                <div class="empty-state" v-else>
                  <img src="../img/emptyStateIllustration.svg" />
                  <p>No player information</p>
                </div>
              </div>

              <div class="players-reports-tab tab-content" v-if="selectedDashboardTab === 'players-reports-tab'">
                <div class="tab-content">
                  <div class="players-without-subscriptions">
                    <h2>Players without subscriptions</h2>
                    <vue-good-table style-class="vgt-table bordered" :columns="columnsPlayers"
                      :rows="playersWithoutLiveSubscriptions" :search-options="{
                        enabled: true,
                        placeholder: 'Search players',
                      }">
                      <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field == 'payment_state'">
                          <span class="unpaid">No subscription information</span>
                        </span>
                        <span v-else>
                          <span>{{
                            props.formattedRow[props.column.field]
                            }}</span>
                        </span>
                      </template>
                    </vue-good-table>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </template>

        <b-modal id="editPlayerInjuryModal" size="lg" :hide-footer="Boolean(true)"
          @hide="hidePlayerInjuryReportModel()">
          <h2>Edit Player injury report</h2>

          <form class="form" @submit.prevent="updatePlayerInjuryInformation()">
            <div class="form__item">
              <label class="form__label" for="injury_type">Injury type</label>

              <select id="injury_type" v-model="newInjuryInformation.injury_type">
                <option value="Left ankle injury">Left ankle injury</option>
                <option value="Right ankle injury">Right ankle injury</option>
                <option value="Left Knee injury">Left Knee injury</option>
                <option value="Right Knee injury">Right Knee injury</option>
                <option value="Left Quad injury">Left Quad injury</option>
                <option value="Right Quad injury">Right Quad injury</option>
                <option value="Left hamstring injury">
                  Left hamstring injury
                </option>
                <option value="Right hamstring injury">
                  Right hamstring injury
                </option>
                <option value="Left groin injury">Left groin injury</option>
                <option value="Right groin injury">Right groin injury</option>
                <option value="Right Hip injury">Right Hip injury</option>
                <option value="Left Hip injury">Left Hip injury</option>
                <option value="Right Shoulder injury">
                  Right Shoulder injury
                </option>
                <option value="Left Shoulder injury">
                  Left Shoulder injury
                </option>
                <option value="Right Wrist and hand injury">
                  Right Wrist and hand injury
                </option>
                <option value="Left Wrist and hand injury">
                  Left Wrist and hand injury
                </option>
                <option value="Head injury">Head injury</option>
                <option value="Left foot injury">Left foot injury</option>
                <option value="Right foot injury">Right foot injury</option>
                <option value="Right arm injury">Right arm injury</option>
                <option value="Left arm injury">Left arm injury</option>
                <option value="Left calf injury">Left calf injury</option>
                <option value="Right calf injury">Right calf injury</option>
              </select>
            </div>

            <div class="form__item">
              <label class="form__label" for="more_detail">More detail</label>
              <textarea class="form__input" id="more_detail" v-model="newInjuryInformation.more_detail"></textarea>
            </div>

            <div class="form__item">
              <label class="form__label" for="return_time">Return time</label>
              <select id="return_time" v-model="newInjuryInformation.return_time">
                <option value="1 - 2 days">1 - 2 days</option>
                <option value="3 - 5 days">3 - 5 days</option>
                <option value="1 week">1 week</option>
                <option value="2 - 3 weeks">2 - 3 weeks</option>
                <option value="A month">A month</option>
                <option value="A few months">A few months</option>
              </select>
            </div>

            <div class="form__item">
              <label class="form__label" for="injury_status">Injury Status</label>
              <select id="injury_status" v-model="newInjuryInformation.injury_status">
                <option value="Reciving treatment">Reciving treatment</option>
                <option value="Resting">Resting</option>
                <option value="Light training">
                  Light training ( return to fitness plan )
                </option>
                <option value="Fully recoverd">Fully recoverd</option>
              </select>
            </div>

            <div class="form__item">
              <label class="form__label" for="contact">Contact information</label>
              <input class="form__input" type="text" id="contact" v-model="newInjuryInformation.contact" />
            </div>

            <div class="documents" v-if="
              newInjuryInformation.player_document_id !== null ||
              newInjuryInformation.physio_document_id !== null
            ">
              <h4>Documents</h4>

              <div class="document" v-if="newInjuryInformation.player_document_id !== null" v-b-tooltip.hover
                title="Delete Player document" @click="
                  removeUploadedDocument(
                    newInjuryInformation.player_document_id
                  )
                  ">
                <img src="../img/documentlib.svg" />
                <h3>Player document</h3>
                <div class="doc-buttons">
                  <b-icon icon="trash" aria-hidden="true"></b-icon>
                </div>
              </div>

              <div class="document" v-if="newInjuryInformation.physio_document_id !== null" v-b-tooltip.hover
                title="Delete Physio document" @click="
                  removeUploadedDocument(
                    newInjuryInformation.physio_document_id
                  )
                  ">
                <img src="../img/documentlib.svg" />
                <h3>Physio document</h3>
                <div class="doc-buttons">
                  <b-icon icon="trash" aria-hidden="true"></b-icon>
                </div>
              </div>
            </div>

            <b-button type="submit" size="lg" variant="outline-success"><b-icon icon="file-text"
                aria-hidden="true"></b-icon>
              Submit
              player injury report</b-button>
          </form>
        </b-modal>

        <b-modal id="allplayerInjuryReportsModal" modal-class="modal-fullscreen" size="xl" title="Player injury reports"
          :hide-footer="Boolean(true)">
          <h2>{{ selectedInjury.injury_type }}</h2>

          <ul class="key-value-list">
            <li>
              <span>Injury status:</span> {{ selectedInjury.injury_status }}
            </li>
            <li><span>Return time:</span> {{ selectedInjury.return_time }}</li>
            <li><span>More details:</span> {{ selectedInjury.more_detail }}</li>
          </ul>

          <b-button style="margin-top: 1rem" @click="openPlayerInjuryModal('playerInjuryModal', selectedInjury)"
            size="lg" variant="outline-success">
            <b-icon icon="plus" aria-hidden="true"></b-icon> Add player injury
            report</b-button>

          <section class="attached-reports-wrapper">
            <div class="attached-reports">
              <h3>List of attached reports</h3>
              <table class="w-a-style injury-table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Injury</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="injury in selectedInjuryReports" :key="injury.id" :class="injury.injury_status === 'Fully recoverd'
                      ? 'full-recoverd'
                      : ''
                    ">
                    <td>{{ formatDateForHuman(injury.created_date) }}</td>
                    <td>{{ injury.injury_type }}</td>

                    <td class="injury-status">
                      {{ injury.injury_status }}
                    </td>
                    <td>
                      <b-button variant="outline-success" v-b-tooltip.hover title="View Injury report"
                        @click="selectInjuryReportView(injury)"><b-icon icon="eye"
                          aria-hidden="true"></b-icon></b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="reports-view" v-if="Object.keys(editInjuryReport).length !== 0">
              <form class="form">
                <h3>
                  Report / update
                  {{ formatDateForHuman(editInjuryReport.created_date) }}
                </h3>

                <div class="form__item">
                  <label class="form__label" for="activity_at_time">Type of activity at time of injury</label>
                  <select id="activity_at_time" v-model="editInjuryReport.activity_at_time">
                    <option value="Training/practice for club">
                      Training/practice for club
                    </option>
                    <option value="Game for club">Game for club</option>
                    <option value="Training/practice not for club">
                      Training/practice not for club
                    </option>
                    <option value="Game not for club">Game not for club</option>
                    <option value="Other">Other</option>
                  </select>
                </div>

                <div class="form__item">
                  <label class="form__label" for="reasion_for_presentation">Reason for Presentation</label>
                  <select id="reasion_for_presentation" v-model="editInjuryReport.reasion_for_presentation">
                    <option value="New injury">New injury</option>
                    <option value="exacerbated/aggravated injury">
                      Exacerbated/aggravated injury
                    </option>
                    <option value="recurrent injury">Recurrent injury</option>
                    <option value="Continuation of treatment">
                      Continuation of treatment
                    </option>
                    <option value="Left hamstring injury">Other</option>
                  </select>
                </div>

                <div class="form__item">
                  <label class="form__label" for="diagnosis">Diagnosis</label>
                  <textarea class="form__input" id="diagnosis" v-model="editInjuryReport.diagnosis"></textarea>
                </div>

                <div class="form__item">
                  <label class="form__label" for="treatment">Treatment plan</label>
                  <textarea class="form__input" id="treatment" v-model="editInjuryReport.treatment"></textarea>
                </div>

                <div class="form__item">
                  <label class="form__label" for="return_time">Return time</label>
                  <select id="return_time" v-model="editInjuryReport.return_time">
                    <option value="1 - 2 days">1 - 2 days</option>
                    <option value="3 - 5 days">3 - 5 days</option>
                    <option value="1 week">1 week</option>
                    <option value="2 - 3 weeks">2 - 3 weeks</option>
                    <option value="A month">A month</option>
                    <option value="A few months">A few months</option>
                    <option value="Phased return">Phased return</option>
                  </select>
                </div>

                <div class="form__item">
                  <label class="form__label" for="injury_status">Injury Status</label>
                  <select id="injury_status" v-model="editInjuryReport.injury_status">
                    <option value="Reciving treatment">
                      Reciving treatment
                    </option>
                    <option value="Resting">Resting</option>
                    <option value="Phased return">Phased return</option>
                    <option value="Light training">Light training</option>
                    <option value="Fully recoverd">Fully recoverd</option>
                  </select>
                </div>

                <div class="form__item">
                  <label class="form__label" for="contact">Physio filling out the report</label>

                  <select id="injury_status" v-model="editInjuryReport.physio_id">
                    <option value="20">Kajal Lalwani</option>
                    <option value="131">Sania Nisa</option>
                    <option value="132">Rigved Mulay</option>
                  </select>
                </div>

                <div class="document-upload-physio-report">
                  <DocumentUploader @getDocumentUploadsCall="getDocumentUploadsCall" documentCategory="physio-report"
                    :club_id="userData.clubId" :user_id="selectedUserData.id" :elementData="editInjuryReport"
                    :selectedDocumentData="{ category: 'physio-report' }" />
                </div>

                <div class="uploaded-documents-wrapper">
                  <h4>Documents</h4>

                  <div v-for="physioDocument in reportDocuments" :key="physioDocument.id" class="document"
                    v-b-tooltip.hover :title="physioDocument.name">
                    <img src="../img/documentlib.svg" />
                    <h3>{{ physioDocument.name }}</h3>

                    <div class="doc-buttons">
                      <b-icon @click="
                        removeUploadedDocument(
                          physioDocument.id,
                          physioDocument.name
                        )
                        " icon="trash" aria-hidden="true"></b-icon>
                      <a :href="`./${physioDocument.url}`" target="_blank"><b-icon @click="`./${physioDocument.url}`"
                          icon="eye" aria-hidden="true"></b-icon></a>
                    </div>
                  </div>

                  <div v-if="reportDocuments.length === 0">
                    <div class="empty-state">
                      <img src="../img/emptyStateIllustration.svg" />
                      <p>No uploaded documents</p>
                    </div>
                  </div>
                </div>

                <b-button @click="updatePlayerInjuryReport()" size="lg" variant="outline-success"><b-icon
                    icon="file-text" aria-hidden="true"></b-icon> Edit
                  player injury report</b-button>
              </form>
            </div>
            <div v-else class="empty-state">
              <img src="../img/emptyStateIllustration.svg" />
              <p>No reports</p>
            </div>
          </section>
        </b-modal>

        <b-modal id="playerInjuryModal" modal-class="modal-fullscreen" size="xl" title="Record Player injury report"
          :hide-footer="Boolean(true)" @hide="hidePlayerInjuryReportModel()">
          <form class="form" @submit.prevent="savePlayerInjuryReport()">
            <div class="form__item">
              <label class="form__label" for="injury_type">Injury type</label>

              <select id="injury_type" v-model="newInjuryInformation.injury_type">
                <option value="Left ankle injury">Left ankle injury</option>
                <option value="Right ankle injury">Right ankle injury</option>
                <option value="Left Knee injury">Left Knee injury</option>
                <option value="Right Knee injury">Right Knee injury</option>
                <option value="Left Quad injury">Left Quad injury</option>
                <option value="Right Quad injury">Right Quad injury</option>
                <option value="Left hamstring injury">
                  Left hamstring injury
                </option>
                <option value="Right hamstring injury">
                  Right hamstring injury
                </option>
                <option value="Left groin injury">Left groin injury</option>
                <option value="Right groin injury">Right groin injury</option>
                <option value="Right Hip injury">Right Hip injury</option>
                <option value="Left Hip injury">Left Hip injury</option>
                <option value="Right Shoulder injury">
                  Right Shoulder injury
                </option>
                <option value="Left Shoulder injury">
                  Left Shoulder injury
                </option>
                <option value="Right Wrist and hand injury">
                  Right Wrist and hand injury
                </option>
                <option value="Left Wrist and hand injury">
                  Left Wrist and hand injury
                </option>
                <option value="Head injury">Head injury</option>
                <option value="Left foot injury">Left foot injury</option>
                <option value="Right foot injury">Right foot injury</option>
                <option value="Right arm injury">Right arm injury</option>
                <option value="Left arm injury">Left arm injury</option>
                <option value="Left calf injury">Left calf injury</option>
                <option value="Right calf injury">Right calf injury</option>
              </select>
            </div>

            <div class="form__item">
              <label class="form__label" for="activity_at_time">Type of activity at time of injury</label>
              <select id="activity_at_time" v-model="newInjuryInformation.activity_at_time">
                <option value="Training/practice for club">
                  Training/practice for club
                </option>
                <option value="Game for club">Game for club</option>
                <option value="Training/practice not for club">
                  Training/practice not for club
                </option>
                <option value="Game not for club">Game not for club</option>
                <option value="Other">Other</option>
              </select>
            </div>

            <div class="form__item">
              <label class="form__label" for="reasion_for_presentation">Reason for Presentation</label>
              <select id="reasion_for_presentation" v-model="newInjuryInformation.reasion_for_presentation">
                <option value="New injury">New injury</option>
                <option value="exacerbated/aggravated injury">
                  Exacerbated/aggravated injury
                </option>
                <option value="recurrent injury">Recurrent injury</option>
                <option value="Continuation of treatment">
                  Continuation of treatment
                </option>
                <option value="Left hamstring injury">Other</option>
              </select>
            </div>

            <div class="form__item">
              <label class="form__label" for="more_detail">Details of injury</label>
              <textarea class="form__input" id="more_detail" v-model="newInjuryInformation.more_detail"></textarea>
            </div>

            <div class="form__item">
              <label class="form__label" for="diagnosis">Diagnosis</label>
              <textarea class="form__input" id="diagnosis" v-model="newInjuryInformation.diagnosis"></textarea>
            </div>

            <div class="form__item">
              <label class="form__label" for="treatment">Treatment plan</label>
              <textarea class="form__input" id="treatment" v-model="newInjuryInformation.treatment"></textarea>
            </div>

            <div class="form__item">
              <label class="form__label" for="return_time">Return time</label>
              <select id="return_time" v-model="newInjuryInformation.return_time">
                <option value="1 - 2 days">1 - 2 days</option>
                <option value="3 - 5 days">3 - 5 days</option>
                <option value="1 week">1 week</option>
                <option value="2 - 3 weeks">2 - 3 weeks</option>
                <option value="A month">A month</option>
                <option value="A few months">A few months</option>
                <option value="Phased return">Phased return</option>
              </select>
            </div>

            <div class="form__item">
              <label class="form__label" for="injury_status">Injury Status</label>
              <select id="injury_status" v-model="newInjuryInformation.injury_status">
                <option value="Reciving treatment">Reciving treatment</option>
                <option value="Resting">Resting</option>
                <option value="Phased return">Phased return</option>
                <option value="Light training">Light training</option>
                <option value="Fully recoverd">Fully recoverd</option>
              </select>
            </div>

            <div class="form__item">
              <label class="form__label" for="contact">Physio</label>

              <select id="injury_status" v-model="newInjuryInformation.physio_id">
                <option value="20">Kajal Lalwani</option>
                <option value="131">Sania Nisa</option>
                <option value="132">Rigved Mulay</option>
              </select>
            </div>

            <div class="form__item">
              <label class="form__label" for="contact">Contact information</label>
              <input class="form__input" type="text" id="contact" v-model="newInjuryInformation.contact" />
            </div>

            <b-button type="submit" size="lg" variant="outline-success"><b-icon icon="file-text"
                aria-hidden="true"></b-icon>
              Submit
              player injury report</b-button>
          </form>
        </b-modal>

        <b-modal id="addNewPhysicalPerformanceTimeModal" size="lg" :hide-footer="Boolean(true)">
          <h2>Physical Performance</h2>

          <form class="form" @submit.prevent="addPhysicalPerformanceValue()">
            <div class="form__item">
              <label class="form__label" for="contact">10m SPRINT:</label>
              <input class="form__input" type="number" step=".01" id="contact"
                v-model="playerPhysicalPerformanceData.tenM_sprint_time" />
            </div>

            <div class="form__item">
              <label class="form__label" for="contact">30m SPRINT:</label>
              <input class="form__input" type="number" step=".01" id="contact"
                v-model="playerPhysicalPerformanceData.fortyM_sprint_time" />
            </div>

            <div class="form__item">
              <label class="form__label" for="contact">Countermovement jump:</label>
              <input class="form__input" type="number" step=".01" id="contact"
                v-model="playerPhysicalPerformanceData.cuntermovement_jump" />
            </div>

            <div class="form__item">
              <label class="form__label" for="contact">Reactive strength index:</label>
              <input class="form__input" type="number" step=".01" id="contact"
                v-model="playerPhysicalPerformanceData.reactive_strength_index" />
            </div>

            <div class="form__item">
              <label class="form__label" for="contact">Fitness Test Time:</label>
              <input class="form__input" type="number" step=".01" id="contact" v-model="playerPhysicalPerformanceData.thirty_15_intermittent_fitness_test
                " />
            </div>

            <div class="form__item">
              <label class="form__label" for="height">Height (cm):</label>
              <input class="form__input" type="number" step=".01" id="height"
                v-model="playerPhysicalPerformanceData.height" />
            </div>

            <div class="form__item">
              <label class="form__label" for="weight">Weight (kg):</label>
              <input class="form__input" type="number" step=".01" id="weight"
                v-model="playerPhysicalPerformanceData.weight" />
            </div>

            <b-button type="submit" size="lg" variant="outline-success">
              Add entry</b-button>
          </form>
        </b-modal>

        <b-modal id="missingDataModal" size="lg" :hide-footer="Boolean(true)">
          <h2>Missing data</h2>
          <p>Looks like you need to give us some more data.</p>

          <p v-if="userData.joined_date === null">The date you joined.</p>
          <p v-if="userData.userImage === null">Your photo.</p>

          <p>
            Please update your profile -
            <router-link :to="{ name: 'Settings' }">Settings</router-link>
          </p>
        </b-modal>
        <b-modal id="playerPerformanceReportModal" size="lg" :hide-footer="Boolean(true)" title="Perfomance Report">
          <form class="form" @submit.prevent="savePlayerPerformanceReview()">
            <div class="two-col-pop">
              <div class="user-photo-info">
                <img v-if="
                  selectedUserData.userImage !== '' &&
                  selectedUserData.userImage !== null
                " :src="getImgUrl(selectedUserData.userImage)" alt="" />
                <img v-else :src="getImgUrl('default-image.jpg')" alt="" />
              </div>
              <div class="user-details-pop">
                <p><span>Name:</span> {{ selectedUserData.name }}</p>
                <p><span>Phone:</span> {{ selectedUserData.phone }}</p>
                <p><span>Email:</span> {{ selectedUserData.email }}</p>
                <p>
                  <span>DOB:</span>
                  {{ formatDateForHuman(selectedUserData.dob) }}
                </p>
                <p><span>Position:</span> {{ selectedUserData.position }}</p>
                <p>
                  <span>Preferred foot:</span>
                  {{ selectedUserData.preferred_foot }}
                </p>
              </div>
            </div>

            <h4>
              Performance review -
              {{ formatDateForHuman(playerPerformanceReportData.review_date) }}
            </h4>

            <div class="form__item">
              <label class="form__label" for="attitude">Attitude</label>
              <div class="ratingStars">
                <star-rating v-model="playerPerformanceReportData.attitude"></star-rating>

                <wysiwyg v-model="playerPerformanceReportData.attitude_comments" />
              </div>
            </div>

            <div class="form__item">
              <label class="form__label" for="skill">Skill</label>
              <div class="ratingStars">
                <star-rating v-model="playerPerformanceReportData.skill"></star-rating>
                <wysiwyg v-model="playerPerformanceReportData.skill_comments" />
              </div>
            </div>

            <div class="form__item">
              <label class="form__label" for="physique">Physique</label>
              <div class="ratingStars">
                <star-rating v-model="playerPerformanceReportData.physique"></star-rating>
                <wysiwyg v-model="playerPerformanceReportData.physique_comments" />
              </div>
            </div>

            <div class="form__item">
              <label class="form__label" for="physique">Mental</label>
              <div class="ratingStars">
                <star-rating v-model="playerPerformanceReportData.mentality"></star-rating>
                <wysiwyg v-model="playerPerformanceReportData.mentality_comments" />
              </div>
            </div>

            <div class="form__item">
              <label class="form__label" for="physique">Leadership</label>
              <div class="ratingStars">
                <star-rating v-model="playerPerformanceReportData.leadership"></star-rating>
                <wysiwyg v-model="playerPerformanceReportData.leadership_comments" />
              </div>
            </div>

            <div class="form__item">
              <label class="form__label" for="physique">Professionalism</label>
              <div class="ratingStars">
                <star-rating v-model="playerPerformanceReportData.professionalism"></star-rating>
                <wysiwyg v-model="playerPerformanceReportData.professionalism_comments" />
              </div>
            </div>

            <div class="form__item">
              <label class="form__label" for="comments">Coachs notes </label>
              <wysiwyg v-model="playerPerformanceReportData.coachsNotes" />
            </div>

            <div class="perfomance-targets">
              <label class="form__label" for="perfomance-targets">Add Performance target

                <b-button v-b-tooltip.hover title="Add Performance Target" @click="addItem()" variant="success">
                  <b-icon icon="plus" aria-hidden="true"></b-icon>
                </b-button>
              </label>

              <div class="each-target" v-for="(item, index) in playerPerformanceTargets" :key="index">
                <label class="form__label" for="perfomance-targets">Performance target
                </label>
                <br />

                <b-form-select class="mb-3" v-model="item.category">
                  <b-form-select-option :value="null" disabled>Select category</b-form-select-option>
                  <b-form-select-option v-for="(category, index) in performanceCategories" :key="index"
                    :value="category">{{
                    category }}</b-form-select-option>
                </b-form-select>

                <wysiwyg v-model="item.target" />

                <b-button v-b-tooltip.hover title="remove Performance Target" variant="danger">
                  <b-icon icon="x" aria-hidden="true" @click="removeItem(index)"></b-icon></b-button>
              </div>
            </div>

            <div class="player-game-responce">
              <b-button type="submit" variant="success">Save Report</b-button>
            </div>
          </form>
        </b-modal>

        <b-modal id="playerCoachPerformanceAssessmentModal" modal-class="modal-fullscreen" size="lg"
          :hide-footer="Boolean(true)" title="Performance review">
          <h4>
            {{
              formatDateForHuman(
                playerCoachSignedPerformanceReportData.created_date
              )
            }}
            -
            {{ playerCoachSignedPerformanceReportData.status }}
          </h4>

          <div class="signed-report" v-if="playerCoachsignedReportReady">
            <div class="player-signed-report">
              <h4>Player review</h4>
              <div class="two-col-pop">
                <div class="user-photo-info">
                  <img v-if="
                    selectedUserData.userImage !== '' &&
                    selectedUserData.userImage !== null
                  " :src="getImgUrl(selectedUserData.userImage)" alt="" />
                  <img v-else :src="getImgUrl('default-image.jpg')" alt="" />
                </div>
                <div class="user-details-pop">
                  <p><span>Name:</span> {{ selectedUserData.name }}</p>
                  <p><span>Phone:</span> {{ selectedUserData.phone }}</p>
                  <p><span>Email:</span> {{ selectedUserData.email }}</p>
                  <p>
                    <span>DOB:</span>
                    {{ formatDateForHuman(selectedUserData.dob) }}
                  </p>
                  <p><span>Position:</span> {{ selectedUserData.position }}</p>
                </div>
              </div>

              <div class="review-wrapper" v-if="playerCoachSignedPerformanceReportData.id !== undefined">
                <div class="form__item block-item">
                  <label class="form__label" for="attitude">Technical</label>
                  <div class="ratingStars" v-if="
                    playerCoachSignedPerformanceReportData.status !==
                    'published'
                  ">
                    <wysiwyg v-model="playerCoachSignedPerformanceReportData.technical_player_comments
                      " />
                  </div>
                  <p v-else v-html="playerCoachSignedPerformanceReportData.technical_player_comments
                    "></p>
                </div>

                <div class="form__item block-item">
                  <label class="form__label" for="skill">Tactical</label>
                  <div class="ratingStars" v-if="
                    playerCoachSignedPerformanceReportData.status !==
                    'published'
                  ">
                    <wysiwyg v-model="playerCoachSignedPerformanceReportData.tactical_player_comments
                      " />
                  </div>
                  <p v-else v-html="playerCoachSignedPerformanceReportData.tactical_player_comments
                    "></p>
                </div>

                <div class="form__item block-item">
                  <label class="form__label" for="physique">Physical</label>
                  <div class="ratingStars" v-if="
                    playerCoachSignedPerformanceReportData.status !==
                    'published'
                  ">
                    <wysiwyg v-model="playerCoachSignedPerformanceReportData.physical_player_comments
                      " />
                  </div>
                  <p v-else v-html="playerCoachSignedPerformanceReportData.physical_player_comments
                    "></p>
                </div>

                <div class="form__item block-item">
                  <label class="form__label" for="physique">Mental</label>
                  <div class="ratingStars" v-if="
                    playerCoachSignedPerformanceReportData.status !==
                    'published'
                  ">
                    <wysiwyg v-model="playerCoachSignedPerformanceReportData.physcological_player_comments
                      " />
                  </div>
                  <p v-else v-html="playerCoachSignedPerformanceReportData.physcological_player_comments
                    "></p>
                </div>

                <div class="form__item block-item ratingStars" style="margin-bottom: 2rem">
                  <label class="form__label" for="comments">Overall comments
                  </label>
                  <wysiwyg v-if="
                    playerCoachSignedPerformanceReportData.status !==
                    'published'
                  " v-model="playerCoachSignedPerformanceReportData.player_comments
                      " />
                  <p v-else v-html="playerCoachSignedPerformanceReportData.player_comments
                    "></p>
                </div>
              </div>
              <p v-else>No Player report saved.</p>

              <div class="players-charter charter" v-if="
                playerCoachSignedPerformanceReportData.status === 'live' ||
                playerCoachSignedPerformanceReportData.status === 'published'
              ">
                <h4>Player Signature</h4>
                <p v-if="
                  coachSignedPerformanceReportSignature.player_signature_change ===
                  true
                ">
                  Please sign below
                </p>
                <div class="signatureWrapper">
                  <vueSignature v-if="
                    coachSignedPerformanceReportSignature.player_signature_change ===
                    true
                  " ref="playerReportSignature" :sigOption="signatureOptions" :w="'800px'" :h="'400px'"
                    :disabled="false">
                  </vueSignature>
                  <img id="playerReportSignature" v-else :src="getImgUrl(
                    playerCoachSignedPerformanceReportData.playerReportSignature
                  )
                    " />

                  <div v-if="
                    coachSignedPerformanceReportSignature.player_signature_change ===
                    true
                  " class="player-game-responce">
                    <button @click="
                      saveSignatureWithDocumentId(
                        'playerReportSignature',
                        playerCoachSignedPerformanceReportData.id
                      )
                      ">
                      Save
                    </button>
                    <button @click="clearSignature('playerReportSignature')">
                      Clear
                    </button>
                  </div>
                  <div v-else class="player-game-responce">
                    <button @click="
                      changeSignature(
                        'player_signature_change',
                        playerCoachSignedPerformanceReportData.id
                      )
                      ">
                      Edit
                    </button>
                  </div>
                </div>
              </div>

              <div class="players-charter charter" v-if="
                playerCoachSignedPerformanceReportData.status === 'live' ||
                playerCoachSignedPerformanceReportData.status === 'published'
              ">
                <h4>Parent Signature</h4>
                <div class="signatureWrapper">
                  <vueSignature v-if="
                    coachSignedPerformanceReportSignature.parent_signature_change ===
                    true
                  " ref="parentReportSignature" :sigOption="signatureOptions" :w="'800px'" :h="'400px'"
                    :disabled="false">
                  </vueSignature>
                  <img id="parentReportSignature" v-else :src="getImgUrl(
                    playerCoachSignedPerformanceReportData.parentReportSignature
                  )
                    " />

                  <div v-if="
                    coachSignedPerformanceReportSignature.parent_signature_change ===
                    true
                  " class="player-game-responce">
                    <button @click="
                      saveSignatureWithDocumentId(
                        'parentReportSignature',
                        playerCoachSignedPerformanceReportData.id
                      )
                      ">
                      Save
                    </button>
                    <button @click="clearSignature('parentReportSignature')">
                      Clear
                    </button>
                  </div>
                  <div v-else class="player-game-responce">
                    <button @click="
                      changeSignature(
                        'parent_signature_change',
                        playerCoachSignedPerformanceReportData.id
                      )
                      ">
                      Edit
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="coach-signed-report" v-if="
              playerSignedPerformanceReportSelected === 'Coach' ||
              playerCoachSignedPerformanceReportData.status === 'published'
            ">
              <h4>Coach review</h4>

              <div class="two-col-pop">
                <div class="user-photo-info">
                  <img v-if="
                    userData.userImage !== '' && userData.userImage !== null
                  " :src="getImgUrl(
                      playerCoachSignedPerformanceReportData.userImage
                    )
                      " alt="" />
                  <img v-else :src="getImgUrl('default-image.jpg')" alt="" />
                </div>
                <div class="user-details-pop">
                  <p>
                    <span>Coach name:</span>
                    {{ playerCoachSignedPerformanceReportData.name }}
                  </p>
                </div>
              </div>

              <div class="form__item block-item">
                <label class="form__label" for="attitude">Technical</label>
                <div class="ratingStars" v-if="
                  playerCoachSignedPerformanceReportData.status !==
                  'published'
                ">
                  <wysiwyg v-model="playerCoachSignedPerformanceReportData.technical_coach_comments
                    " />
                </div>
                <p v-else v-html="playerCoachSignedPerformanceReportData.technical_coach_comments
                  "></p>
              </div>

              <div class="form__item block-item">
                <label class="form__label" for="skill">Tactical</label>
                <div class="ratingStars" v-if="
                  playerCoachSignedPerformanceReportData.status !==
                  'published'
                ">
                  <wysiwyg v-model="playerCoachSignedPerformanceReportData.tactical_coach_comments
                    " />
                </div>
                <p v-else v-html="playerCoachSignedPerformanceReportData.tactical_coach_comments
                  "></p>
              </div>

              <div class="form__item block-item">
                <label class="form__label" for="physique">Physical</label>
                <div class="ratingStars" v-if="
                  playerCoachSignedPerformanceReportData.status !==
                  'published'
                ">
                  <wysiwyg v-model="playerCoachSignedPerformanceReportData.physical_coach_comments
                    " />
                </div>
                <p v-else v-html="playerCoachSignedPerformanceReportData.physical_coach_comments
                  "></p>
              </div>

              <div class="form__item block-item">
                <label class="form__label" for="physique">Mental</label>
                <div class="ratingStars" v-if="
                  playerCoachSignedPerformanceReportData.status !==
                  'published'
                ">
                  <wysiwyg v-model="playerCoachSignedPerformanceReportData.physcological_coach_comments
                    " />
                </div>
                <p v-else v-html="playerCoachSignedPerformanceReportData.physcological_coach_comments
                  "></p>
              </div>

              <div class="form__item block-item ratingStars">
                <label class="form__label" for="comments">Overall comments
                </label>
                <wysiwyg v-model="playerCoachSignedPerformanceReportData.coach_comments
                  " v-if="
                    playerCoachSignedPerformanceReportData.status !==
                    'published'
                  " />
                <p v-else v-html="playerCoachSignedPerformanceReportData.coach_comments"></p>
              </div>

              <div class="players-charter charter" v-if="
                playerCoachSignedPerformanceReportData.status === 'live' ||
                playerCoachSignedPerformanceReportData.status === 'published'
              ">
                <h4>Coach Signature</h4>
                <p v-if="
                  coachSignedPerformanceReportSignature.coach_signature_change ===
                  true
                ">
                  Please sign below
                </p>
                <div class="signatureWrapper">
                  <vueSignature v-if="
                    coachSignedPerformanceReportSignature.coach_signature_change ===
                    true
                  " ref="coachSignature" :sigOption="signatureOptions" :w="'800px'" :h="'400px'" :disabled="false">
                  </vueSignature>
                  <img id="coachSignature" v-else :src="getImgUrl(
                    playerCoachSignedPerformanceReportData.coachSignature
                  )
                    " />

                  <div v-if="
                    coachSignedPerformanceReportSignature.coach_signature_change ===
                    true
                  " class="player-game-responce">
                    <button @click="
                      saveSignatureWithDocumentId(
                        'coachSignature',
                        playerCoachSignedPerformanceReportData.id
                      )
                      ">
                      Save
                    </button>
                    <button @click="clearSignature('coachSignature')">
                      Clear
                    </button>
                  </div>
                  <div v-else class="player-game-responce">
                    <button @click="
                      changeSignature(
                        'coach_signature_change',
                        playerCoachSignedPerformanceReportData.id
                      )
                      ">
                      Edit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="buttons-wrapper">
            <b-form-select v-if="
              userData.userType === 'Admin' ||
              (userData.userType === 'Coach' &&
                playerCoachSignedPerformanceReportData.status !== 'published')
            " @change="changePlayerCoachPerformanceAssessmentStatus($event)">
              <b-form-select-option :value="null">Please select an option</b-form-select-option>
              <b-form-select-option value="Draft">Draft</b-form-select-option>
              <b-form-select-option value="live">Live</b-form-select-option>
            </b-form-select>

            <b-button v-if="playerCoachSignedPerformanceReportData.id === undefined" size="lg" variant="outline-success"
              @click="savePlayerCoachSignedPerformanceReport()">
              <b-icon icon="file-text" aria-hidden="true"></b-icon> Save Report
            </b-button>

            <b-button v-if="
              playerCoachSignedPerformanceReportData.id !== undefined &&
              playerCoachSignedPerformanceReportData.status !== 'published'
            " size="lg" variant="outline-success" @click="updatePlayerCoachSignedPerformanceReport()">
              <b-icon icon="file-text" aria-hidden="true"></b-icon> Update
              Report
            </b-button>

            <b-button v-if="
              playerCoachSignedPerformanceReportData.status !== 'published' &&
              playerCoachSignedPerformanceReportData.player_signature_id !==
              null &&
              playerCoachSignedPerformanceReportData.coach_signature_id !==
              null
            " @click="publishPlayerCoachReport()" variant="outline-success" size="lg">
              <b-icon icon="file-earmark-check" aria-hidden="true"></b-icon>
              Publish Report
            </b-button>

            <b-button v-if="
              playerCoachSignedPerformanceReportData.status === 'published' &&
              userData.userType !== 'User'
            " @click="unpublishPlayerCoachReport()" variant="outline-danger" size="lg">
              <b-icon icon="file-earmark-check" aria-hidden="true"></b-icon>
              Unpublish Report
            </b-button>

            <b-button v-if="
              playerCoachSignedPerformanceReportData.status === 'published' &&
              userData.userType !== 'User'
            " @click="printPlayerCoachReport()" variant="outline-success" size="lg">
              <b-icon icon="printer" aria-hidden="true"></b-icon> Print Report
            </b-button>
          </div>
        </b-modal>

        <b-modal id="playerPerformanceReportVewDetailsModal" size="lg" :hide-footer="Boolean(true)"
          title="Perfomance Report">
          <form class="form">
            <template v-if="Object.keys(selectedUserData).length !== 0">
              <UserImageAndInfo :selectedUserDataForUserImageAndInfo="selectedUserData"
                :playerPerformanceReportData="playerPerformanceReportData" />
            </template>
            <template v-else>
              <UserImageAndInfo :selectedUserDataForUserImageAndInfo="userData"
                :playerPerformanceReportData="playerPerformanceReportData" />
            </template>

            <h4>
              Performance review -
              {{ formatDateForHuman(selectedUserData.review_date) }}
            </h4>

            <div class="form__item">
              <label class="form__label" for="attitude">Attitude</label>
              <div class="ratingStars">
                <star-rating read-only v-model="playerPerformanceReportData.attitude"></star-rating>

                <div class="comments-box" v-html="playerPerformanceReportData.attitude_comments" />
              </div>
            </div>

            <div class="form__item">
              <label class="form__label" for="skill">Skill</label>
              <div class="ratingStars">
                <star-rating read-only v-model="playerPerformanceReportData.skill"></star-rating>
                <div class="comments-box" v-html="playerPerformanceReportData.skill_comments" />
              </div>
            </div>

            <div class="form__item">
              <label class="form__label" for="physique">Physique</label>
              <div class="ratingStars">
                <star-rating read-only v-model="playerPerformanceReportData.physique"></star-rating>
                <div class="comments-box" v-html="playerPerformanceReportData.physique_comments" />
              </div>
            </div>

            <div class="form__item">
              <label class="form__label" for="physique">Mentality</label>
              <div class="ratingStars">
                <star-rating read-only v-model="playerPerformanceReportData.mentality"></star-rating>
                <div class="comments-box" v-html="playerPerformanceReportData.mentality_comments" />
              </div>
            </div>

            <div class="form__item">
              <label class="form__label" for="physique">Leadership</label>
              <div class="ratingStars">
                <star-rating read-only v-model="playerPerformanceReportData.leadership"></star-rating>
                <div class="comments-box" v-html="playerPerformanceReportData.leadership_comments" />
              </div>
            </div>

            <div class="form__item">
              <label class="form__label" for="physique">Professionalism</label>
              <div class="ratingStars">
                <star-rating read-only v-model="playerPerformanceReportData.professionalism"></star-rating>
                <div class="comments-box" v-html="playerPerformanceReportData.professionalism_comments" />
              </div>
            </div>

            <div class="form__item">
              <label class="form__label" for="comments">Coachs notes </label>
              <div class="comments-box" v-html="playerPerformanceReportData.coachsNotes" />
            </div>

            <div class="form__item performance-targets-display">
              <h4>Performance targets</h4>

              <div class="performance-target-item" v-for="target in getSelectedPerformanceReportTargets(
                playerPerformanceReportData.id
              )" :key="target.id">
                <p>Category: {{ target.category }}</p>
                <div class="target-wrapper">
                  <img src="./img/target.svg" alt="" />
                  <div v-html="target.target"></div>
                </div>
              </div>
            </div>
          </form>
        </b-modal>

        <b-modal id="allTrainingReportsModal" size="xl" modal-class="modal-fullscreen" :hide-footer="Boolean(true)">
          <div class="all-training-reports">
            <h3>Seasons training and game stats</h3>
            <h3>{{ selectedUserData.name }}</h3>

            <div class="form__item uk-form">
              <label class="form__label" for="trainingGameDateSelected">Select date:</label>

              <datepicker name="trainingGameDateSelected" id="trainingGameDateSelected"
                @selected="setSelectedDatetrainingAndGameData($event)" />
            </div>

            <div v-if="
              Object.keys(selectedGameAndTrainingChartStats).length !== 0 &&
              under16 === false
            ">
              <h3>
                Session name:
                {{ selectedGameAndTrainingChartStats.session_type }}
              </h3>

              <div class="col-wrapper-first">
                <div class="cols" v-if="
                  Object.keys(
                    fullSessionStrainingAndGamesTotalDistanceChartData
                  ).length !== 0
                ">
                  <h3>Total Distance</h3>
                  <Bar-chart-horizontal :chartData="fullSessionStrainingAndGamesTotalDistanceChartData
                    " height="200px" />
                </div>

                <div class="cols" v-if="
                  Object.keys(
                    fullSessionStrainingAndGamesHighSpeedRunningChartData
                  ).length !== 0
                ">
                  <h3>High Speed Running</h3>
                  <Bar-chart-horizontal :chartData="fullSessionStrainingAndGamesHighSpeedRunningChartData
                    " height="200px" />
                </div>

                <div class="cols" v-if="
                  sessionStrainingAndGamesSessionHighSpeedRunningChartData.length >
                  0
                ">
                  <h3>Accelerations / Decelerations</h3>
                  <Bar-chart-horizontal :chartData="sessionStrainingAndGamesSessionHighSpeedRunningChartData
                    " height="200px" />
                </div>
              </div>

              <div class="col-wrapper-charts">
                <div class="cols">
                  <div class="pie-charts" v-if="
                    Object.keys(
                      sessionStrainingAndGamesSessionDistancePieData
                    ).length !== 0
                  ">
                    <h3>Total Distance</h3>
                    <Pie-chart :chartData="sessionStrainingAndGamesSessionDistancePieData
                      " />
                  </div>

                  <div class="pie-charts" v-if="
                    Object.keys(
                      sessionStrainingAndGamesSessionDistancePieData
                    ).length !== 0
                  ">
                    <h3>Total Distance</h3>
                    <Pie-chart :chartData="sessionStrainingAndGamesSessionDistancePieData
                      " />
                  </div>
                </div>

                <div class="cols">
                  <div class="pie-charts" v-if="
                    Object.keys(
                      sessionStrainingAndGamesSessionHighSpeedRunningPieData
                    ).length !== 0
                  ">
                    <h3>High Speed Running</h3>
                    <Pie-chart :chartData="sessionStrainingAndGamesSessionHighSpeedRunningPieData
                      " />
                  </div>

                  <div class="pie-charts" v-if="
                    Object.keys(
                      sessionStrainingAndGamesSessionMaximumVelocityPieData
                    ).length !== 0
                  ">
                    <h3>Maximum Velocity</h3>
                    <Pie-chart :chartData="sessionStrainingAndGamesSessionMaximumVelocityPieData
                      " />
                  </div>
                </div>
              </div>

              <div>
                <div>
                  <h3>Accelerations and Decelerations</h3>
                  <Bar-chart-stacked :chartData="sessionStrainingAndGamesSessionHighSpeedRunningChartData
                    " height="500px" />
                </div>

                <div class="games-info">
                  <table class="w-a-style" style="width: 100%">
                    <thead>
                      <th>Date</th>
                      <th>Session type</th>
                      <th>Session mins</th>
                      <th>Total Distance</th>
                      <th>High Speed Running</th>
                      <th>Accelerations</th>
                      <th>Decelerations</th>
                    </thead>

                    <tbody>
                      <tr v-for="session in playerDailyMins" :key="session.id">
                        <td>{{ formatDateForHuman(session.session_date) }}</td>
                        <td>{{ session.session_type }}</td>
                        <td>{{ session.session_mins }}</td>
                        <td>{{ session.session_distance }}</td>
                        <td>
                          {{ session.total_absolute_high_speed_distance }}
                        </td>
                        <td>{{ session.accelerations_total_efforts }}</td>
                        <td>{{ session.decelerations_total_efforts }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div v-if="
              Object.keys(selectedGameAndTrainingChartStats).length !== 0 &&
              under16 === true
            ">
              <h3>
                Session name:
                {{ selectedGameAndTrainingChartStats.session_type }}
              </h3>
              <div class="col-wrapper-first">
                <div class="cols" v-if="
                  Object.keys(
                    fullSessionStrainingAndGamesTotalTouchesChartData
                  ).length !== 0
                ">
                  <h3>Total Touches</h3>
                  <Bar-chart-horizontal :chartData="fullSessionStrainingAndGamesTotalTouchesChartData
                    " />
                </div>

                <div class="cols" v-if="
                  Object.keys(
                    fullSessionStrainingAndGamesRightFootTouchesChartData
                  ).length !== 0
                ">
                  <h3>Right foot Touches</h3>
                  <Bar-chart-horizontal :chartData="fullSessionStrainingAndGamesRightFootTouchesChartData
                    " />
                </div>

                <div class="cols" v-if="
                  Object.keys(
                    fullSessionStrainingAndGamesLeftFootTouchesChartData
                  ).length !== 0
                ">
                  <h3>Left foot Touches</h3>
                  <Bar-chart-horizontal :chartData="fullSessionStrainingAndGamesLeftFootTouchesChartData
                    " />
                </div>
              </div>

              <div class="col-wrapper-charts">
                <div class="cols">
                  <div class="pie-charts">
                    <h3>Right foot touches</h3>
                    <Pie-chart :chartData="sessionStrainingAndGamesSessionLeftRighPieData
                      " />
                  </div>

                  <div class="pie-charts">
                    <h3>Left foot touches</h3>
                    <Pie-chart :chartData="sessionStrainingAndGamesSessionLeftLeftPieData
                      " />
                  </div>
                </div>

                <div class="cols">
                  <div class="cols">
                    <h3>Total Releases</h3>
                    <Bar-chart-horizontal :chartData="fullSessionStrainingAndGamesTotalTouchesChartData
                      " />
                  </div>

                  <div class="cols">
                    <h3>Right foot Releases</h3>
                    <Bar-chart-horizontal :chartData="fullSessionStrainingAndGamesTotalTouchesChartData
                      " />
                  </div>

                  <div class="cols">
                    <h3>Left foot Releases</h3>
                    <Bar-chart-horizontal :chartData="fullSessionStrainingAndGamesTotalTouchesChartData
                      " />
                  </div>
                </div>

                <div class="cols">
                  <div class="cols">
                    <h3>Total Distance</h3>
                    <Bar-chart-horizontal :chartData="fullSessionStrainingAndGamesTotalTouchesChartData
                      " />
                  </div>

                  <div class="cols">
                    <h3>Top speed</h3>
                    <Bar-chart-horizontal :chartData="sessionStrainingAndGamesSessionTopSpeedsChartData
                      " />
                  </div>

                  <div class="cols">
                    <h3>Work rate</h3>
                    <Bar-chart-horizontal :chartData="sessionStrainingAndGamesSessionWorkRateChartData
                      " />
                  </div>
                </div>

                <div class="games-info">
                  <table class="w-a-style" style="width: 100%">
                    <thead>
                      <th>Date</th>
                      <th>Session Type</th>
                      <th>Tag</th>
                      <th>Session Mins</th>
                      <th>Total Distance</th>
                      <th>work rate</th>
                      <th>Total touches</th>
                      <th>Left Leg Touches</th>
                      <th>Right Leg Touches</th>
                      <th>Releases Left</th>
                      <th>Releases Right</th>
                      <th>Releases total</th>
                      <th>Top speed</th>
                    </thead>

                    <tbody>
                      <tr v-for="session in playerPlayMakerGpsData" :key="session.id">
                        <td>{{ formatDateForHuman(session.session_date) }}</td>
                        <td>{{ session.session_type }}</td>
                        <td>{{ session.tag }}</td>
                        <td>{{ session.phase_duration_min }}</td>
                        <td>{{ session.distance_covered }}</td>
                        <td>{{ session.work_rate }}</td>
                        <td>{{ session.total_touches }}</td>
                        <td>{{ session.left_leg_touches }}</td>
                        <td>{{ session.right_leg_touches }}</td>
                        <td>{{ session.releases_left }}</td>
                        <td>{{ session.releases_right }}</td>
                        <td>{{ session.releases }}</td>
                        <td>{{ session.top_speed }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div v-if="Object.keys(selectedGameAndTrainingChartStats).length === 0" class="empty-state">
              <img src="../img/emptyStateIllustration.svg" />
              <p>No chart data</p>
            </div>
          </div>
        </b-modal>

        <b-modal id="playerLoadReportsModal" modal-class="modal-fullscreen" size="xl" :hide-footer="Boolean(true)">
          <div class="all-training-reports">
            <h3>Seasons load report</h3>
          </div>
          <Bar-Chart :chartData="playerLoadChartDataSet" />
        </b-modal>

        <b-modal id="performanceTargetsHistoryModal" size="lg" :hide-footer="Boolean(true)">
          <table class="w-a-style">
            <thead>
              <th>Date</th>
              <th>Target</th>
              <th>Category</th>
              <th>Status</th>
            </thead>
            <tbody>
              <tr v-for="target in playerPerformanceReviewTargetsData" :key="target.id">
                <td>{{ formatDateForHuman(target.created_date) }}</td>
                <td>
                  <div v-html="target.target"></div>
                </td>
                <td>{{ target.category }}</td>
                <td v-if="target.complete == 1" class="completed">Completed</td>
                <td v-else>Not complete</td>
              </tr>
            </tbody>
          </table>
        </b-modal>

        <b-modal modal-class="modal-fullscreen" id="physicalPerformanceHistoryModal" size="lg"
          :hide-footer="Boolean(true)">
          <div class="physical-performance-table">
            <table class="w-a-style physicalPerformanceTable">
              <thead>
                <th>Date</th>
                <th>10m SPRINT</th>
                <th>30m SPRINT</th>
                <th>Countermovement jump</th>
                <th>Fitness Test Time</th>
              </thead>
              <tbody>
                <tr v-for="session in allPlayerPhysicalPerformanceData" :key="session.id">
                  <td>{{ formatDateForHuman(session.date) }}</td>
                  <td>{{ session.tenM_sprint_time }}</td>
                  <td>{{ session.fortyM_sprint_time }}</td>
                  <td>{{ session.cuntermovement_jump }}</td>
                  <td>{{ session.thirty_15_intermittent_fitness_test }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-modal>

        <b-modal id="allGameReportsModal" size="lg" modal-class="modal-fullscreen" :hide-footer="Boolean(true)">
          <h3>Seasons Game assessment reports</h3>

          <div class="all-game-reports">
            <table class="w-a-style game-assesment-reports-table">
              <thead>
                <th>Game Date</th>
                <th>Game name</th>
                <th>Actions</th>
              </thead>
              <tbody>
                <tr v-for="session in seasonsgamePerformanceReportsForUser" :key="session.id">
                  <td>{{ formatDateForHuman(session.matchDate) }}</td>
                  <td>{{ session.opposition }}</td>
                  <td>
                    <b-button size="lg" class="main-button" variant="outline-success" v-b-tooltip.hover
                      title="View full report" @click="
                        populateSelectedGameFullPerformanceReportsForUser(
                          session.gameId
                        )
                        "><b-icon icon="eye" aria-hidden="true"></b-icon></b-button>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="player-game-report" v-if="
              Object.keys(selectedGameFullPerformanceReportsForUser).length >
              0
            ">
              <h3>Full Game assessment report</h3>

              <div class="full-player-report">
                <p class="label">
                  <span class="value">Match date:</span>
                  {{
                    formatDateForHuman(
                      selectedGameFullPerformanceReportsForUser.matchDate
                    )
                  }}
                </p>
                <p class="label">
                  <span class="value">Opposition:</span>
                  {{ selectedGameFullPerformanceReportsForUser.opposition }}
                </p>
                <p class="label">
                  <span class="value">Position played:</span>
                  {{ selectedGameFullPerformanceReportsForUser.postion_played }}
                </p>
                <p v-if="
                  selectedGameFullPerformanceReportsForUser.postion_played_two !==
                  ''
                " class="label">
                  <span class="value">Position played:</span>
                  {{
                    selectedGameFullPerformanceReportsForUser.postion_played_two
                  }}
                </p>

                <div class="player-assesment-report">
                  <h4>Player self assessment</h4>

                  <div class="small-image-name-wrapper">
                    <img v-if="
                      selectedUserData.userImage !== '' &&
                      selectedUserData.userImage !== null
                    " class="small-image" :src="getImgUrl(selectedUserData.userImage)" alt="" />
                    <img v-else class="small-image" :src="getImgUrl('default-image.png')" alt="" />
                    <span>{{ selectedUserData.name }}</span>
                  </div>

                  <!--<p class="label"><span class="value">Self rating:</span> {{ selectedGameFullPerformanceReportsForUser.player_self_rating }}</p>-->
                  <p class="label">
                    <span class="value">Goals:</span>
                    {{ selectedGameFullPerformanceReportsForUser.goals }}
                  </p>
                  <p class="label">
                    <span class="value">Assists:</span>
                    {{ selectedGameFullPerformanceReportsForUser.assists }}
                  </p>
                  <p class="label">
                    <span class="value">Card:</span>
                    {{ selectedGameFullPerformanceReportsForUser.card }}
                  </p>
                  <p class="label">
                    <span class="value">Time on pitch:</span>
                    {{ selectedGameFullPerformanceReportsForUser.timeOnPitch }}
                    mins
                  </p>
                  <p v-if="
                    selectedGameFullPerformanceReportsForUser.postion_played ===
                    'Goalkeeper'
                  " class="label">
                    <span class="value">Clean sheet:</span>
                    {{ selectedGameFullPerformanceReportsForUser.clean_sheet }}
                  </p>

                  <div class="areas-wrapper">
                    <div class="areas">
                      <h4>Technically</h4>
                      <p class="label">
                        <span class="value">Improvements:</span>
                      </p>
                      <p class="text">
                        {{
                          selectedGameFullPerformanceReportsForUser.technically_improvements
                        }}
                      </p>
                      <p class="label"><span class="value">Positives:</span></p>
                      <p class="text">
                        {{
                          selectedGameFullPerformanceReportsForUser.technically_positives
                        }}
                      </p>
                    </div>

                    <div class="areas">
                      <h4>Tactility</h4>
                      <p class="label">
                        <span class="value">Improvements:</span>
                      </p>
                      <p class="text">
                        {{
                          selectedGameFullPerformanceReportsForUser.tactility_improvements
                        }}
                      </p>
                      <p class="label"><span class="value">Positives:</span></p>
                      <p class="text">
                        {{
                          selectedGameFullPerformanceReportsForUser.tactility_positives
                        }}
                      </p>
                    </div>

                    <div class="areas">
                      <h4>Physicality</h4>
                      <p class="label">
                        <span class="value">Improvements:</span>
                      </p>
                      <p class="text">
                        {{
                          selectedGameFullPerformanceReportsForUser.physicality_improvements
                        }}
                      </p>

                      <p class="label"><span class="value">Positives:</span></p>
                      <p class="text">
                        {{
                          selectedGameFullPerformanceReportsForUser.physicality_positives
                        }}
                      </p>
                    </div>

                    <div class="areas">
                      <h4>Mentally</h4>
                      <p class="label">
                        <span class="value">Improvements:</span>
                      </p>
                      <p class="text">
                        {{
                          selectedGameFullPerformanceReportsForUser.mentally_improvements
                        }}
                      </p>
                      <p class="label"><span class="value">Positives:</span></p>
                      <p class="text">
                        {{
                          selectedGameFullPerformanceReportsForUser.mentally_positives
                        }}
                      </p>
                    </div>

                    <div class="areas">
                      <h4>Overall comments</h4>
                      <p class="text">
                        {{
                          selectedGameFullPerformanceReportsForUser.overall_comments
                        }}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="coach-assesment-report" v-if="
                  selectedGameFullPerformanceReportsForUser.game_comments !==
                  '' &&
                  selectedGameFullPerformanceReportsForUser.coach_name !== ''
                ">
                  <h4>Coach assessment</h4>

                  <div class="small-image-name-wrapper">
                    <img v-if="
                      selectedGameFullPerformanceReportsForUser.userImage !==
                      '' &&
                      selectedGameFullPerformanceReportsForUser.userImage !==
                      null
                    " class="small-image" :src="getImgUrl(
                        selectedGameFullPerformanceReportsForUser.userImage
                      )
                        " alt="" />
                    <img v-else class="small-image" :src="getImgUrl('default-image.png')" alt="" />
                    <span>{{
                      selectedGameFullPerformanceReportsForUser.coach_name
                      }}</span>
                  </div>

                  <p class="label" v-if="
                    userData.userType === 'Admin' ||
                    userData.userType === 'Coach'
                  ">
                    <span class="value">Coach rating:</span>
                    {{
                      selectedGameFullPerformanceReportsForUser.player_rating
                    }}
                  </p>

                  <p>
                    <span class="value">Positive:</span>
                    <span class="coach-comments">{{
                      selectedGameFullPerformanceReportsForUser.game_comments
                      }}</span>
                  </p>
                  <p>
                    <span class="value">Improvement:</span>
                    <span class="coach-comments">{{
                      selectedGameFullPerformanceReportsForUser.game_improvements
                      }}</span>
                  </p>
                </div>
              </div>
              <div class="areas idp-game-targets">
                <h4>IDP's - Game targets</h4>

                <p class="label"><span class="value">In possession:</span></p>

                <div class="text target-outline" v-for="gameTarget in playerGameTargetsData"
                  :key="`${gameTarget.id}-Inpossession`">
                  <div class="target-wrapper" v-if="gameTarget.possession === 'In possession'">
                    <img src="./img/target.svg" alt="" />
                    <span v-html="gameTarget.target"></span>
                    <span v-if="gameTarget.complete === 0" class="not-complete">Not complete</span>
                    <span v-else class="complete">Complete</span>
                  </div>
                </div>

                <p class="label" style="margin-top: 1rem">
                  <span class="value">Out of possession:</span>
                </p>
                <div class="text target-outline" v-for="gameTarget in playerGameTargetsData"
                  :key="`${gameTarget.id}-Outofpossession`">
                  <div class="target-wrapper" v-if="gameTarget.possession === 'Out of possession'">
                    <img src="./img/target.svg" alt="" />
                    <span v-html="gameTarget.target"></span>
                    <span v-if="gameTarget.complete === 0" class="not-complete">Not complete</span>
                    <span v-else class="complete">Complete</span>
                  </div>
                </div>
              </div>

              <div class="gps-data-for-same-date" v-if="
                Object.keys(selectedGameFullPerformanceReportGPSdataForUser)
                  .length > 0
              ">
                <h4>GPS data</h4>

                <div class="player-stats-gps-wrapper">
                  <div class="wrapper-mins-stuff"></div>

                  <div class="player-session-details">
                    <div class="session-name">
                      <p>
                        <span>Session name:</span>
                        {{
                          selectedGameFullPerformanceReportGPSdataForUser.session_type
                        }}
                      </p>
                    </div>
                    <gps-pannel :selectedDayPlayerData="selectedGameFullPerformanceReportGPSdataForUser
                      " :selectedUserData="selectedUserData" :playerPlayMakerGpsData="playerPlayMakerGpsData" />
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="full-player-report">
              <div class="empty-state">
                <img src="../img/emptyStateIllustration.svg" />
                <p>No Game assessment report selected</p>
              </div>
            </div>
          </div>
        </b-modal>

        <b-modal id="newPerformanceTargetsModal" size="lg" :hide-footer="Boolean(true)">
          <div class="performance-target-modal-content">
            <h3 v-if="
              selectedPerformanceTarget.typeOfTarget === 'Performance target'
            ">
              Performance target
            </h3>
            <h3 v-else>Game target</h3>
            <div v-if="
              selectedPerformanceTarget.typeOfTarget === 'Performance target'
            " class="form__item">
              <label class="form__label" for="select_performace-reports">Select performance review</label>
              <select id="select_performace-reports" v-model="selectedPerformanceTarget.performance_report_id">
                <option v-for="review in allplayerPerformanceReportData" :key="review.id" :value="review.id">
                  {{ formatDateForHuman(review.review_date) }}
                </option>
              </select>
              <p>This will add a performance target to the review selected.</p>
            </div>

            <div v-else class="form__item">
              <label class="form__label" for="select_performace-reports">Select possession</label>

              <b-form-select class="mb-3" v-model="selectedPerformanceTarget.possession">
                <b-form-select-option :value="null" disabled>Select possession</b-form-select-option>
                <b-form-select-option value="In possession">In possession</b-form-select-option>
                <b-form-select-option value="Out of possession">Out of possession</b-form-select-option>
              </b-form-select>
            </div>

            <div class="form__item">
              <label class="form__label" for="select_performace-reports">Select category</label>

              <b-form-select class="mb-3" v-model="selectedPerformanceTarget.category">
                <b-form-select-option :value="null" disabled>Select category</b-form-select-option>
                <b-form-select-option v-for="(category, index) in performanceCategories" :key="index"
                  :value="category">{{
                  category }}</b-form-select-option>
              </b-form-select>
            </div>

            <wysiwyg v-model="selectedPerformanceTarget.target" />
            <b-form-checkbox id="checkbox-1" size="lg" v-model="selectedPerformanceTarget.complete" name="checkbox-1"
              value="1" unchecked-value="0">Mark target as complete
            </b-form-checkbox>

            <div class="buttons-row">
              <b-button @click="addNewPerformanceReportTarget()" size="lg" variant="outline-success">
                Save</b-button>
            </div>
          </div>
        </b-modal>

        <b-modal id="performanceTargetsModal" size="lg" :hide-footer="Boolean(true)">
          <div class="performance-target-modal-content">
            <h3>Performance target</h3>
            <p class="small-date-player-coach">
              {{ formatDateForHuman(selectedPerformanceTarget.created_date) }}
            </p>

            <b-form-select class="mb-3" v-model="selectedPerformanceTarget.category">
              <b-form-select-option :value="null" disabled>Select category</b-form-select-option>
              <b-form-select-option v-for="(category, index) in performanceCategories" :key="index" :value="category">{{
                category }}</b-form-select-option>
            </b-form-select>

            <wysiwyg v-model="selectedPerformanceTarget.target" />
            <b-form-checkbox id="checkbox-2" size="lg" v-model="selectedPerformanceTarget.complete" name="checkbox-2"
              value="1" unchecked-value="0">Mark target as complete
            </b-form-checkbox>

            <div class="buttons-row">
              <b-button @click="updatePerformanceReportTarget()" size="lg" variant="outline-success">
                Update</b-button>

              <b-button @click="deletePerformanceReportTarget()" size="lg" variant="outline-danger">Delete</b-button>
            </div>
          </div>
        </b-modal>

        <b-modal id="editAcademyReportModal" size="lg" :hide-footer="Boolean(true)">
          <div class="performance-target-modal-content">
            <h3>Edit Academy report</h3>

            <div class="form__item">
              <label class="form__label" for="coaches_comments">Coaches comments:</label>

              <b-form-textarea id="coaches_comments" v-model="academyReport.coaches_comments"
                placeholder="Enter your comments..." rows="3" max-rows="6"></b-form-textarea>
            </div>

            <div class="form__item">
              <label class="form__label" for="contact">Coaches average score:</label>
              <input class="form__input" type="number" id="average_score" v-model="academyReport.average_score" />
              / 30
            </div>

            <div class="buttons-row">
              <b-button size="lg" variant="outline-warning" @click="updateTheAcademyReport(academyReport.id)">
                Update Academy report</b-button>
            </div>
          </div>
        </b-modal>

        <b-modal id="academyReportModal" size="lg" :hide-footer="Boolean(true)">
          <div class="performance-target-modal-content">
            <h3>Academy report</h3>

            <form class="form">
              <div class="form__item">
                <label class="form__label" for="comments">Selected Player
                </label>
                <p></p>
              </div>

              <div class="form__item">
                <label class="form__label" for="select_season">Select season</label>
                <select id="select_season">
                  <option value="2022-23">2022-23</option>
                  <option value="2022-23">2019-20</option>
                  <option value="2022-23">2021-22</option>
                </select>
              </div>
            </form>

            <div class="buttons-row">
              <b-button size="lg" variant="outline-success" @click="produceTheAcademyReport()">
                Produce report</b-button>
            </div>

            <div class="modal-container performance-report">
              <iframe v-if="showReport === true" id="theAcademyReportPdf_preview" src="" />
              <img v-else src="./img/football-illustration.jpg" alt="" />
            </div>
          </div>
        </b-modal>

        <b-modal id="playerCVpersonalProfileModal" size="lg" :hide-footer="Boolean(true)" title="Personal profile">
          <div class="player-cv-content">
            <b-form-textarea id="personal_profile_text_box" v-model="playerCvData.personal_profile"
              placeholder="Enter your personal proflie..." rows="8"></b-form-textarea>

            <b-button v-b-tooltip.hover @click="savePersonalProfile()" title="Save" variant="success">Save Personal
              profile</b-button>
          </div>
        </b-modal>

        <b-modal id="openPlayerInterestsHobbiesModal" size="lg" :hide-footer="Boolean(true)"
          @close="playerInterestsHobbiesClose()" title="Player Interests and Hobbies">
          <div class="player-cv-content">
            <div class="form__item">
              <label class="form__label" for="contact">Interest or Hobbie:</label>
              <input class="form__input" type="text" id="interests"
                v-model="selectedPlayerInterestsHobbiesData.interests" />
            </div>

            <div class="form__item">
              <label class="form__label" for="contact">More information:</label>

              <b-form-textarea id="textarea" v-model="selectedPlayerInterestsHobbiesData.more_info"
                placeholder="Enter more information..." rows="3" max-rows="6"></b-form-textarea>
            </div>

            <b-button v-b-tooltip.hover title="Save" variant="success"
              v-if="selectedPlayerInterestsHobbiesDataEdit === false" @click="saveInterestsHobbies()">Save
              interest</b-button>

            <b-button v-b-tooltip.hover title="Update" variant="success" v-else
              @click="editPlayerInterestsHobbies()">Update
              interest</b-button>
          </div>
        </b-modal>

        <b-modal id="openPlayerEducationModal" size="lg" :hide-footer="Boolean(true)"
          @close="playerEducationModalClose()" title="Player Education">
          <div class="player-cv-content">
            <div class="playing-experence-wrapper">
              <div class="each-target playing-experence-item">
                <div class="form__item">
                  <label class="form__label" for="contact">Qualification name:</label>
                  <input class="form__input" type="text" id="Qualification_name"
                    v-model="selectedPlayerEducationData.title" />
                </div>

                <div class="form__item">
                  <label class="form__label" for="contact">Start date:</label>
                  <datepicker v-model="selectedPlayerEducationData.start_date" name="start_date" id="start_date" />
                </div>

                <div class="form__item">
                  <label class="form__label" for="contact">End date:</label>
                  <datepicker v-model="selectedPlayerEducationData.end_date" name="end_date" id="end_date" />
                </div>

                <div class="form__item">
                  <label class="form__label" for="perfomance-targets">Information
                  </label>

                  <b-form-textarea id="textarea" v-model="selectedPlayerEducationData.more_info"
                    placeholder="Enter more information..." rows="3" max-rows="6"></b-form-textarea>
                </div>
              </div>

              <b-button v-b-tooltip.hover title="Save" variant="success"
                v-if="selectedPlayerEducationDataEdit === false" @click="savePlayerEducation()">Save
                education</b-button>

              <b-button v-b-tooltip.hover title="Update" variant="success" v-else @click="editPlayerEducation()">Update
                education</b-button>
            </div>
          </div>
        </b-modal>

        <b-modal id="playerVideosModal" size="lg" :hide-footer="Boolean(true)" title="Personal profile">
          <div class="player-cv-content">
            <div class="playing-experence-wrapper">
              <div class="each-target playing-experence-item">
                <div class="form__item">
                  <label class="form__label" for="contact">Video title:</label>
                  <input class="form__input" type="text" id="club" v-model="selectedPlayerVideoData.video_name" />
                </div>

                <div class="form__item">
                  <label class="form__label" for="contact">Video URL:</label>
                  <input class="form__input" type="text" id="club" v-model="selectedPlayerVideoData.video_url" />
                </div>

                <b-button v-b-tooltip.hover title="Save" variant="success" v-if="selectedPlayerVideoDataEdit === false"
                  @click="savePlayerVideoLink()">Save video</b-button>

                <b-button v-b-tooltip.hover title="Update" variant="success" v-else
                  @click="editPlayerVideoLink()">Update
                  video</b-button>
              </div>
            </div>
          </div>
        </b-modal>

        <b-modal id="playerCVPlayingExperenceModal" size="lg" :hide-footer="Boolean(true)"
          @close="playerCVPlayingExperenceModalClose()" title="Playing experence">
          <div class="player-cv-content">
            <div class="playing-experence-wrapper">
              <div class="each-target playing-experence-item">
                <div class="form__item">
                  <label class="form__label" for="contact">Club name:</label>
                  <input class="form__input" type="text" id="club" v-model="selectedPlayingExperenceData.club" />
                </div>

                <div class="form__item">
                  <label class="form__label" for="contact">Start date at the club:</label>
                  <datepicker v-model="selectedPlayingExperenceData.start_date_at_club" name="startDateAtClub"
                    id="startDateAtClub" />
                </div>

                <div class="form__item">
                  <label class="form__label" for="contact">End date at the club:</label>
                  <datepicker v-model="selectedPlayingExperenceData.end_date_at_club" name="endDateAtClub"
                    id="endDateAtClub" />
                </div>

                <div class="form__item">
                  <label class="form__label" for="perfomance-targets"> </label>

                  <b-form-textarea id="textarea" v-model="selectedPlayingExperenceData.playing_info"
                    placeholder="Enter Playing information..." rows="3" max-rows="6"></b-form-textarea>
                </div>
              </div>

              <b-button v-b-tooltip.hover title="Save" variant="success" v-if="selectedPlayingExperenceEdit === false"
                @click="savePlayerPlayingExperence()">Save playing experence</b-button>

              <b-button v-b-tooltip.hover title="Update" variant="success" v-else @click="editPlayingExperence()">Update
                playing
                experence</b-button>
            </div>
          </div>
        </b-modal>

        <b-modal id="viewDayStatsBreakDown" size="xl" :hide-footer="Boolean(true)">
          <div class="player-stats-gps-wrapper">
            <div class="player-session-details">
              <p class="player-name">{{ selectedUserData.name }}</p>
              <p class="session-date">
                {{
                  this.formatDateForHuman(selectedDayPlayerData.session_date)
                }}
              </p>

              <div class="session-name">
                <p>
                  <span>Session name:</span>{{ selectedDayPlayerData.session_type }}
                </p>
              </div>
              <gps-pannel :selectedDayPlayerData="selectedDayPlayerData" :selectedUserData="selectedUserData"
                :playerPlayMakerGpsData="playerPlayMakerGpsData" />
            </div>
          </div>
        </b-modal>

        <b-modal id="addDayStatsBreakDown" size="xl" :hide-footer="Boolean(true)">
          <div class="player-stats-gps-wrapper">
            <div class="player-session-details">
              <p class="player-name">{{ selectedUserData.name }}</p>
              <p class="session-date">
                {{ selectedDayPlayerData.session_date }}
              </p>

              <div class="main-session">
                <div class="session-name">
                  <p>
                    <span>Session type:</span>
                    <input class="form__input" type="text" id="club" v-model="selectedDayPlayerData.session_type" />
                  </p>
                </div>

                <div class="wrapper-mins-stuff">
                  <div class="mins-from-session">
                    <span>Mins from session</span>
                    <br />
                    <input class="form__input" type="text" id="club" v-model="selectedDayPlayerData.session_mins" />
                    <br />
                    <p><span>mins</span></p>
                  </div>
                </div>
              </div>

              <div class="main-session">
                <div v-if="under16 === false" class="wrapper-mins-stuff">
                  <div class="mins-from-session">
                    <span>Mins from session</span>
                    <br />
                    <input class="form__input" type="text" id="club" v-model="selectedDayPlayerData.session_mins" />
                    <br />
                    <p><span>mins</span></p>
                  </div>

                  <div class="mins-from-session">
                    <span>Distance covered</span>
                    <br />
                    <input class="form__input" type="text" id="club" v-model="selectedDayPlayerData.session_distance" />
                    <br />
                    <p><span>Meters</span></p>
                  </div>

                  <div class="mins-from-session">
                    <span>High Speed Running Distance</span>
                    <br />
                    <input class="form__input" type="text" id="club" v-model="selectedDayPlayerData.total_absolute_high_speed_distance
                      " />
                    <br />
                    <p><span>m</span></p>
                  </div>

                  <div class="mins-from-session">
                    <span>Meterage per minute</span>
                    <br />
                    <input class="form__input" type="text" id="club"
                      v-model="selectedDayPlayerData.meterage_per_minute" />
                    <br />
                    <p><span></span></p>
                  </div>

                  <div class="mins-from-session">
                    <span>Max vel max</span>
                    <br />
                    <input class="form__input" type="text" id="club" v-model="selectedDayPlayerData.max_vel_max" />
                    <br />
                    <p></p>
                  </div>

                  <div class="mins-from-session">
                    <span>Accelerations total efforts</span>
                    <br />
                    <input class="form__input" type="text" id="club" v-model="selectedDayPlayerData.accelerations_total_efforts
                      " />
                    <br />
                    <p><span>min</span></p>
                  </div>

                  <div class="mins-from-session">
                    <span>decelerations_total_efforts</span>
                    <br />
                    <input class="form__input" type="text" id="club" v-model="selectedDayPlayerData.decelerations_total_efforts
                      " />
                    <br />
                    <p><span>mph</span></p>
                  </div>

                  <div class="mins-from-session">
                    <span>Maximum velocity</span>
                    <br />
                    <input class="form__input" type="text" id="club" v-model="selectedDayPlayerData.maximum_velocity" />
                    <br />
                    <p><span>%</span></p>
                  </div>
                </div>

                <button class="mins-button" v-if="editDayPlayerData === false" v-on:click="addDayMinsStatsData()">
                  Save
                </button>
                <button class="mins-button" v-else v-on:click="editDayMinsStatsData()">
                  Edit
                </button>
              </div>
            </div>
          </div>
        </b-modal>

        <b-modal id="uploadPlayerMinsCSV" size="lg" :hide-footer="Boolean(true)">
          <div>
            <h2>Import CSV file</h2>
            <hr />

            <div class="type-of-csv-import">
              <h3>Select data upload type</h3>

              <b-form-group class="radios-group-gps">
                <b-form-radio v-model="gpsUploadDataTypeSelected" name="catapult-radios" value="catapult"><img
                    src="../img/cat-gps.jpg" /></b-form-radio>
                <b-form-radio v-model="gpsUploadDataTypeSelected" name="playermaker-radios" value="playermaker"><img
                    src="../img/playMaker.jpg" /></b-form-radio>
              </b-form-group>
            </div>

            <div class="upload-wrapper" v-if="gpsUploadDataTypeSelected !== undefined">
              <label>File
                <input type="file" @change="importCSV($event)" />
              </label>

              <div v-if="csvDataImported !== null" class="show-imported-CSV-data">
                <pre>{{ csvDataImported }}</pre>
              </div>

              <button v-if="csvDataImported !== null" v-on:click="importUploadedCSVDataFile()">
                Save
              </button>
            </div>
            <p class="days-red-warning" v-if="dataCSVErrorMessage === null">
              <b-icon icon="exclamation-circle" aria-hidden="true"></b-icon>
              {{ dataCSVErrorMessage }}
            </p>
          </div>
        </b-modal>

        <b-modal id="viewDownloadPDF" size="lg" :hide-footer="Boolean(true)">
          <div class="player-cv-content">
            <iframe :src="downloadURL" />
          </div>
        </b-modal>

        <b-modal id="viewPlayersCV" size="lg" :hide-footer="Boolean(true)">
          <div class="player-cv-content">
            <iframe id="thePlayerCVPDF_preview" src="" />
          </div>
        </b-modal>

        <b-modal id="viewPlayerCoachReviewPDF" size="lg" :hide-footer="Boolean(true)">
          <div class="player-cv-content">
            <iframe id="thePlayerCoachReviewPDF_preview" />
          </div>
        </b-modal>

        <b-modal id="upLoadModal" size="lg" :hide-footer="Boolean(true)">
          <div class="player-cv-content">
            <DocumentUploader @getDocumentUploadsCall="getDocumentUploadsCall" documentCategory="physio"
              :club_id="userData.clubId" :user_id="selectedUserData.id" :elementData="newInjuryInformation" />
          </div>
        </b-modal>

        <b-modal id="charterModal" title="Charter" modal-class="modal-fullscreen" size="xl"
          :hide-footer="Boolean(true)">
          <div class="games-info">
            <div class="players-charter charter">
              <h3>Players Charter</h3>

              <div v-if="charterData.player_text !== ''" v-html="charterData.player_text"></div>
              <div v-else>
                <div class="empty-state">
                  <img src="../img/emptyStateIllustration.svg" />
                  <p>No Players Charter information.</p>
                </div>
              </div>

              <h4>Player Signature</h4>
              <p v-if="charterSignature.player_signature_change === true">
                Please sign below
              </p>
              <div class="signatureWrapper">
                <vueSignature v-if="charterSignature.player_signature_change === true" ref="playerSignature"
                  :sigOption="signatureOptions" :w="'800px'" :h="'400px'" :disabled="false"></vueSignature>
                <img v-else :src="getImgUrl(charterSignature.player_signature)" />

                <div v-if="charterSignature.player_signature_change === true" class="player-game-responce">
                  <button @click="saveSignature('playerSignature')">
                    Save
                  </button>
                  <button @click="clearSignature('playerSignature')">
                    Clear
                  </button>
                </div>
                <div v-else class="player-game-responce">
                  <button @click="changeSignature('player_signature_change')">
                    Edit
                  </button>
                </div>
              </div>
            </div>

            <div class="players-charter charter">
              <h3>Parents Charter</h3>

              <div v-if="charterData.parent_text !== ''" v-html="charterData.parent_text"></div>
              <div v-else>
                <div class="empty-state">
                  <img src="../img/emptyStateIllustration.svg" />
                  <p>No Parents Charter information.</p>
                </div>
              </div>

              <h4>Parent Signature</h4>
              <p v-if="charterSignature.parent_signature_change === true">
                Please sign below
              </p>
              <div class="signatureWrapper">
                <vueSignature v-if="charterSignature.parent_signature_change === true" ref="parentSignature"
                  :sigOption="signatureOptions" :w="'800px'" :h="'400px'" :disabled="false"></vueSignature>
                <img v-else :src="getImgUrl(charterSignature.parent_signature)" />

                <div v-if="charterSignature.parent_signature_change === true" class="player-game-responce">
                  <button @click="saveSignature('parentSignature')">
                    Save
                  </button>
                  <button @click="clearSignature('parentSignature')">
                    Clear
                  </button>
                </div>
                <div v-else class="player-game-responce">
                  <button @click="changeSignature('parent_signature_change')">
                    Edit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </b-modal>

        <b-modal title="Your goals" id="iDPGoalsModal" size="lg" :hide-footer="Boolean(true)">
          <div class="player-cv-content">
            <div class="form__item">
              <label class="form__label" for="contact">Goal month:</label>

              <b-form-select v-model="IDPselectedGoalData.month_category" class="mb-3">
                <b-form-select-option value="12">12 months</b-form-select-option>
                <b-form-select-option value="6">6 months</b-form-select-option>
                <b-form-select-option value="3">3 months</b-form-select-option>
              </b-form-select>
            </div>

            <div class="form__item">
              <label class="form__label" for="contact">Goal:</label>
              <input class="form__input" type="text" id="club" v-model="IDPselectedGoalData.goal" />
            </div>

            <div class="form__item">
              <label class="form__label" for="how_can">How can I achieve this goal:</label>

              <b-form-textarea id="how_can" placeholder="Add text..." rows="8"
                v-model="IDPselectedGoalData.how_can"></b-form-textarea>
            </div>

            <div class="form__item">
              <label class="form__label" for="what_do_i_need">What do i need to achieve this goal:</label>

              <b-form-textarea id="what_do_i_need" placeholder="Add text..." rows="8"
                v-model="IDPselectedGoalData.what_do_i_need"></b-form-textarea>
            </div>

            <div class="buttons-row">
              <b-button v-if="IDPselectedGoalData.id !== undefined" size="lg" variant="outline-success"
                @click="editIDPGoal()">Edit
              </b-button>

              <b-button v-else size="lg" variant="outline-success" @click="saveIDPGoal()">Save
              </b-button>

              <b-button @click="deleteIDPGoal()" size="lg" variant="outline-danger">Delete</b-button>
            </div>
          </div>
        </b-modal>

        <b-modal id="playerAssesmentModalView" size="xl" modal-class="modal-fullscreen" title="Player Self Assessment"
          :hide-footer="Boolean(true)">
          <div class="all-game-reports">
            <table class="w-a-style game-assesment-reports-table" v-if="allPlayerSelfAssessmentData.length > 0">
              <thead>
                <th>Date</th>
                <th>Actions</th>
              </thead>
              <tbody>
                <tr v-for="playerSelfAssessment in allPlayerSelfAssessmentData" :key="playerSelfAssessment.id">
                  <td>
                    {{ formatDateForHuman(playerSelfAssessment.created_date) }}
                  </td>
                  <td>
                    <b-button size="lg" class="main-button" variant="outline-success" v-b-tooltip.hover
                      title="View full report" @click="
                        populateSelectedPlayerSelfAssessment(
                          playerSelfAssessment.id
                        )
                        "><b-icon icon="eye" aria-hidden="true"></b-icon></b-button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="empty-state" v-else>
              <img src="../img/emptyStateIllustration.svg" />
              <p>No Player Self Assessments</p>
            </div>

            <section class="player-self-assessment-wrapper"
              v-if="Object.keys(playerSelfAssessmentU11to18Data).length > 0">
              <h3>
                Self Assessment
                {{
                  formatDateForHuman(
                    playerSelfAssessmentU11to18Data.created_date
                  )
                }}
              </h3>

              <div class="multi-radio-wrapper">
                <h4>Technical</h4>

                <div class="form__item">
                  <label class="form__label" for="contact">Can Control the ball with both feet in multiple
                    directions:</label>
                  <p v-if="playerSelfAssessmentU11to18Data.q1 === 5" class="green-totals">
                    Excellent
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q1 === 4" class="green-totals">
                    Good
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q1 === 3" class="green-totals">
                    Progressing Well
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q1 === 2" class="amber-totals">
                    Requires Improvement
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q1 === 1" class="red-totals">
                    Unsatisfactory
                  </p>
                </div>

                <div class="form__item">
                  <label class="form__label" for="contact">Can pass with both feet (short):</label>
                  <p v-if="playerSelfAssessmentU11to18Data.q2 === 5" class="green-totals">
                    Excellent
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q2 === 4" class="green-totals">
                    Good
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q2 === 3" class="green-totals">
                    Progressing Well
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q2 === 2" class="amber-totals">
                    Requires Improvement
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q2 === 1" class="red-totals">
                    Unsatisfactory
                  </p>
                </div>

                <div class="form__item">
                  <label class="form__label" for="contact">Has mastered at least one skill move to beat an
                    opponent:</label>
                  <p v-if="playerSelfAssessmentU11to18Data.q3 === 5" class="green-totals">
                    Excellent
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q3 === 4" class="green-totals">
                    Good
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q3 === 3" class="green-totals">
                    Progressing Well
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q3 === 2" class="amber-totals">
                    Requires Improvement
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q3 === 1" class="red-totals">
                    Unsatisfactory
                  </p>
                </div>

                <div class="form__item">
                  <label class="form__label" for="contact">Can shoot/cross/pass accurately with both feet:</label>
                  <p v-if="playerSelfAssessmentU11to18Data.q4 === 5" class="green-totals">
                    Excellent
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q4 === 4" class="green-totals">
                    Good
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q4 === 3" class="green-totals">
                    Progressing Well
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q4 === 2" class="amber-totals">
                    Requires Improvement
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q4 === 1" class="red-totals">
                    Unsatisfactory
                  </p>
                </div>

                <div class="form__item">
                  <label class="form__label" for="contact">Can dribble in multiple directions using both feet:</label>
                  <p v-if="playerSelfAssessmentU11to18Data.q5 === 5" class="green-totals">
                    Excellent
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q5 === 4" class="green-totals">
                    Good
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q5 === 3" class="green-totals">
                    Progressing Well
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q5 === 2" class="amber-totals">
                    Requires Improvement
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q5 === 1" class="red-totals">
                    Unsatisfactory
                  </p>
                </div>

                <div class="form__item">
                  <label class="form__label" for="contact">Can protect the ball from an opponent:</label>
                  <p v-if="playerSelfAssessmentU11to18Data.q6 === 5" class="green-totals">
                    Excellent
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q6 === 4" class="green-totals">
                    Good
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q6 === 3" class="green-totals">
                    Progressing Well
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q6 === 2" class="amber-totals">
                    Requires Improvement
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q6 === 1" class="red-totals">
                    Unsatisfactory
                  </p>
                </div>
              </div>

              <div class="multi-radio-wrapper">
                <h4>Tactical</h4>

                <div class="form__item">
                  <label class="form__label" for="contact">Makes good decisions on the ball:</label>
                  <p v-if="playerSelfAssessmentU11to18Data.q7 === 5" class="green-totals">
                    Excellent
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q7 === 4" class="green-totals">
                    Good
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q7 === 3" class="green-totals">
                    Progressing Well
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q7 === 2" class="amber-totals">
                    Requires Improvement
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q7 === 1" class="red-totals">
                    Unsatisfactory
                  </p>
                </div>

                <div class="form__item">
                  <label class="form__label" for="contact">Takes up good positions to receive the ball:</label>
                  <p v-if="playerSelfAssessmentU11to18Data.q8 === 5" class="green-totals">
                    Excellent
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q8 === 4" class="green-totals">
                    Good
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q8 === 3" class="green-totals">
                    Progressing Well
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q8 === 2" class="amber-totals">
                    Requires Improvement
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q8 === 1" class="red-totals">
                    Unsatisfactory
                  </p>
                </div>

                <div class="form__item">
                  <label class="form__label" for="contact">Makes good runs off the ball at the right times:</label>
                  <p v-if="playerSelfAssessmentU11to18Data.q9 === 5" class="green-totals">
                    Excellent
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q9 === 4" class="green-totals">
                    Good
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q9 === 3" class="green-totals">
                    Progressing Well
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q9 === 2" class="amber-totals">
                    Requires Improvement
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q9 === 1" class="red-totals">
                    Unsatisfactory
                  </p>
                </div>

                <div class="form__item">
                  <label class="form__label" for="contact">Can play in more than one area of the field:</label>
                  <p v-if="playerSelfAssessmentU11to18Data.q10 === 5" class="green-totals">
                    Excellent
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q10 === 4" class="green-totals">
                    Good
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q10 === 3" class="green-totals">
                    Progressing Well
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q10 === 2" class="amber-totals">
                    Requires Improvement
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q10 === 1" class="red-totals">
                    Unsatisfactory
                  </p>
                </div>

                <div class="form__item">
                  <label class="form__label" for="contact">Can attack in 1v1 situations:</label>
                  <p v-if="playerSelfAssessmentU11to18Data.q11 === 5" class="green-totals">
                    Excellent
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q11 === 4" class="green-totals">
                    Good
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q11 === 3" class="green-totals">
                    Progressing Well
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q11 === 2" class="amber-totals">
                    Requires Improvement
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q11 === 1" class="red-totals">
                    Unsatisfactory
                  </p>
                </div>

                <div class="form__item">
                  <label class="form__label" for="contact">Can defend in 1v1 situations:</label>
                  <p v-if="playerSelfAssessmentU11to18Data.q12 === 5" class="green-totals">
                    Excellent
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q12 === 4" class="green-totals">
                    Good
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q12 === 3" class="green-totals">
                    Progressing Well
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q12 === 2" class="amber-totals">
                    Requires Improvement
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q12 === 1" class="red-totals">
                    Unsatisfactory
                  </p>
                </div>

                <div class="form__item">
                  <label class="form__label" for="contact">Reads situations in the game:</label>
                  <p v-if="playerSelfAssessmentU11to18Data.q13 === 5" class="green-totals">
                    Excellent
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q13 === 4" class="green-totals">
                    Good
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q13 === 3" class="green-totals">
                    Progressing Well
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q13 === 2" class="amber-totals">
                    Requires Improvement
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q13 === 1" class="red-totals">
                    Unsatisfactory
                  </p>
                </div>
              </div>

              <div class="multi-radio-wrapper">
                <h4>Physical</h4>

                <div class="form__item">
                  <label class="form__label" for="contact">Has good mobility:</label>
                  <p v-if="playerSelfAssessmentU11to18Data.q14 === 5" class="green-totals">
                    Excellent
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q14 === 4" class="green-totals">
                    Good
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q14 === 3" class="green-totals">
                    Progressing Well
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q14 === 2" class="amber-totals">
                    Requires Improvement
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q14 === 1" class="red-totals">
                    Unsatisfactory
                  </p>
                </div>

                <div class="form__item">
                  <label class="form__label" for="contact">Can Jump and land single leg and double footed:</label>
                  <p v-if="playerSelfAssessmentU11to18Data.q15 === 5" class="green-totals">
                    Excellent
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q15 === 4" class="green-totals">
                    Good
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q15 === 3" class="green-totals">
                    Progressing Well
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q15 === 2" class="amber-totals">
                    Requires Improvement
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q15 === 1" class="red-totals">
                    Unsatisfactory
                  </p>
                </div>

                <div class="form__item">
                  <label class="form__label" for="contact">Can kick with good technique and generate power (short
                    distances):</label>

                  <p v-if="playerSelfAssessmentU11to18Data.q16 === 5" class="green-totals">
                    Excellent
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q16 === 4" class="green-totals">
                    Good
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q16 === 3" class="green-totals">
                    Progressing Well
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q16 === 2" class="amber-totals">
                    Requires Improvement
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q16 === 1" class="red-totals">
                    Unsatisfactory
                  </p>
                </div>

                <div class="form__item">
                  <label class="form__label" for="contact">Has good balance (single leg, both sides):</label>
                  <p v-if="playerSelfAssessmentU11to18Data.q17 === 5" class="green-totals">
                    Excellent
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q17 === 4" class="green-totals">
                    Good
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q17 === 3" class="green-totals">
                    Progressing Well
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q17 === 2" class="amber-totals">
                    Requires Improvement
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q17 === 1" class="red-totals">
                    Unsatisfactory
                  </p>
                </div>

                <div class="form__item">
                  <label class="form__label" for="contact">Developing basic speed and strength:</label>
                  <p v-if="playerSelfAssessmentU11to18Data.q18 === 5" class="green-totals">
                    Excellent
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q18 === 4" class="green-totals">
                    Good
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q18 === 3" class="green-totals">
                    Progressing Well
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q18 === 2" class="amber-totals">
                    Requires Improvement
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q18 === 1" class="red-totals">
                    Unsatisfactory
                  </p>
                </div>

                <div class="form__item">
                  <label class="form__label" for="contact">Is flexible and agile:</label>
                  <p v-if="playerSelfAssessmentU11to18Data.q19 === 5" class="green-totals">
                    Excellent
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q19 === 4" class="green-totals">
                    Good
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q19 === 3" class="green-totals">
                    Progressing Well
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q19 === 2" class="amber-totals">
                    Requires Improvement
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q19 === 1" class="red-totals">
                    Unsatisfactory
                  </p>
                </div>
              </div>

              <div class="multi-radio-wrapper">
                <h4>Mental</h4>

                <div class="form__item">
                  <label class="form__label" for="contact">Has a growth mindset, willing to learn:</label>
                  <p v-if="playerSelfAssessmentU11to18Data.q20 === 5" class="green-totals">
                    Excellent
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q20 === 4" class="green-totals">
                    Good
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q20 === 3" class="green-totals">
                    Progressing Well
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q20 === 2" class="amber-totals">
                    Requires Improvement
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q20 === 1" class="red-totals">
                    Unsatisfactory
                  </p>
                </div>

                <div class="form__item">
                  <label class="form__label" for="contact">Has a competitive mentality:</label>
                  <p v-if="playerSelfAssessmentU11to18Data.q21 === 5" class="green-totals">
                    Excellent
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q21 === 4" class="green-totals">
                    Good
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q21 === 3" class="green-totals">
                    Progressing Well
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q21 === 2" class="amber-totals">
                    Requires Improvement
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q21 === 1" class="red-totals">
                    Unsatisfactory
                  </p>
                </div>

                <div class="form__item">
                  <label class="form__label" for="contact">Can build positive relationships and friendships:</label>
                  <p v-if="playerSelfAssessmentU11to18Data.q22 === 5" class="green-totals">
                    Excellent
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q22 === 4" class="green-totals">
                    Good
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q22 === 3" class="green-totals">
                    Progressing Well
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q22 === 2" class="amber-totals">
                    Requires Improvement
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q22 === 1" class="red-totals">
                    Unsatisfactory
                  </p>
                </div>

                <div class="form__item">
                  <label class="form__label" for="contact">Calmly and directly expresses their and feelings about
                    situations:</label>
                  <p v-if="playerSelfAssessmentU11to18Data.q23 === 5" class="green-totals">
                    Excellent
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q23 === 4" class="green-totals">
                    Good
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q23 === 3" class="green-totals">
                    Progressing Well
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q23 === 2" class="amber-totals">
                    Requires Improvement
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q23 === 1" class="red-totals">
                    Unsatisfactory
                  </p>
                </div>

                <div class="form__item">
                  <label class="form__label" for="contact">Is a problem solver, can find positive solutions to
                    challenges:</label>

                  <p v-if="playerSelfAssessmentU11to18Data.q24 === 5" class="green-totals">
                    Excellent
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q24 === 4" class="green-totals">
                    Good
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q24 === 3" class="green-totals">
                    Progressing Well
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q24 === 2" class="amber-totals">
                    Requires Improvement
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q24 === 1" class="red-totals">
                    Unsatisfactory
                  </p>
                </div>

                <div class="form__item">
                  <label class="form__label" for="contact">Emotional control of winning and losing:</label>
                  <p v-if="playerSelfAssessmentU11to18Data.q25 === 5" class="green-totals">
                    Excellent
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q25 === 4" class="green-totals">
                    Good
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q25 === 3" class="green-totals">
                    Progressing Well
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q25 === 2" class="amber-totals">
                    Requires Improvement
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q25 === 1" class="red-totals">
                    Unsatisfactory
                  </p>
                </div>
              </div>

              <div class="multi-radio-wrapper">
                <h4>Social</h4>

                <div class="form__item">
                  <label class="form__label" for="contact">Follows Instructions from coaches:</label>
                  <p v-if="playerSelfAssessmentU11to18Data.q26 === 5" class="green-totals">
                    Excellent
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q26 === 4" class="green-totals">
                    Good
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q26 === 3" class="green-totals">
                    Progressing Well
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q26 === 2" class="amber-totals">
                    Requires Improvement
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q26 === 1" class="red-totals">
                    Unsatisfactory
                  </p>
                </div>

                <div class="form__item">
                  <label class="form__label" for="contact">Respects club and match rules:</label>
                  <p v-if="playerSelfAssessmentU11to18Data.q27 === 5" class="green-totals">
                    Excellent
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q27 === 4" class="green-totals">
                    Good
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q27 === 3" class="green-totals">
                    Progressing Well
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q27 === 2" class="amber-totals">
                    Requires Improvement
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q27 === 1" class="red-totals">
                    Unsatisfactory
                  </p>
                </div>

                <div class="form__item">
                  <label class="form__label" for="contact">Is a good listener and pays attention to coaches and
                    teammates:</label>

                  <p v-if="playerSelfAssessmentU11to18Data.q28 === 5" class="green-totals">
                    Excellent
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q28 === 4" class="green-totals">
                    Good
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q28 === 3" class="green-totals">
                    Progressing Well
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q28 === 2" class="amber-totals">
                    Requires Improvement
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q28 === 1" class="red-totals">
                    Unsatisfactory
                  </p>
                </div>

                <div class="form__item">
                  <label class="form__label" for="contact">Ignores distractions to focus on tasks:</label>
                  <p v-if="playerSelfAssessmentU11to18Data.q29 === 5" class="green-totals">
                    Excellent
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q29 === 4" class="green-totals">
                    Good
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q29 === 3" class="green-totals">
                    Progressing Well
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q29 === 2" class="amber-totals">
                    Requires Improvement
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q29 === 1" class="red-totals">
                    Unsatisfactory
                  </p>
                </div>

                <div class="form__item">
                  <label class="form__label" for="contact">Is willing to ask questions to learn:</label>
                  <p v-if="playerSelfAssessmentU11to18Data.q30 === 5" class="green-totals">
                    Excellent
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q30 === 4" class="green-totals">
                    Good
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q30 === 3" class="green-totals">
                    Progressing Well
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q30 === 2" class="amber-totals">
                    Requires Improvement
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q30 === 1" class="red-totals">
                    Unsatisfactory
                  </p>
                </div>

                <div class="form__item">
                  <label class="form__label" for="contact">Is positive and enjoys being in a group environment with
                    others:</label>
                  <p v-if="playerSelfAssessmentU11to18Data.q31 === 5" class="green-totals">
                    Excellent
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q31 === 4" class="green-totals">
                    Good
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q31 === 3" class="green-totals">
                    Progressing Well
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q31 === 2" class="amber-totals">
                    Requires Improvement
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q31 === 1" class="red-totals">
                    Unsatisfactory
                  </p>
                </div>

                <div class="form__item">
                  <label class="form__label" for="contact">Is responsible for their own behaviour:</label>

                  <p v-if="playerSelfAssessmentU11to18Data.q32 === 5" class="green-totals">
                    Excellent
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q32 === 4" class="green-totals">
                    Good
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q32 === 3" class="green-totals">
                    Progressing Well
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q32 === 2" class="amber-totals">
                    Requires Improvement
                  </p>
                  <p v-if="playerSelfAssessmentU11to18Data.q32 === 1" class="red-totals">
                    Unsatisfactory
                  </p>
                </div>
              </div>
            </section>
          </div>
        </b-modal>

        <b-modal id="playerAssesmentModal" size="xl" modal-class="modal-fullscreen" title="Player Self Assessment"
          :hide-footer="Boolean(true)">
          <div class="player-cv-content">
            <div class="multi-radio-wrapper">
              <h4>Technical</h4>
              <p>* Please select all that apply</p>

              <div class="form__item">
                <label class="form__label" for="contact">Can Control the ball with both feet in multiple
                  directions:</label>

                <b-form-radio-group id="radio-group-technical" class="custom-control-inline-player-assesment"
                  v-model="playerSelfAssessmentU11to18Data.q1" name="radio-options-1">
                  <b-form-radio value="5">Excellent</b-form-radio>
                  <b-form-radio value="4">Good</b-form-radio>
                  <b-form-radio value="3">Progressing Well</b-form-radio>
                  <b-form-radio value="2">Requires Improvement</b-form-radio>
                  <b-form-radio value="1">Unsatisfactory</b-form-radio>
                </b-form-radio-group>
              </div>

              <div class="form__item">
                <label class="form__label" for="contact">Can pass with both feet (short):</label>

                <b-form-radio-group id="radio-group-technical" class="custom-control-inline-player-assesment"
                  v-model="playerSelfAssessmentU11to18Data.q2" name="radio-options-2">
                  <b-form-radio value="5">Excellent</b-form-radio>
                  <b-form-radio value="4">Good</b-form-radio>
                  <b-form-radio value="3">Progressing Well</b-form-radio>
                  <b-form-radio value="2">Requires Improvement</b-form-radio>
                  <b-form-radio value="1">Unsatisfactory</b-form-radio>
                </b-form-radio-group>
              </div>

              <div class="form__item">
                <label class="form__label" for="contact">Has mastered at least one skill move to beat an
                  opponent:</label>

                <b-form-radio-group id="radio-group-technical" class="custom-control-inline-player-assesment"
                  v-model="playerSelfAssessmentU11to18Data.q3" name="radio-options-3">
                  <b-form-radio value="5">Excellent</b-form-radio>
                  <b-form-radio value="4">Good</b-form-radio>
                  <b-form-radio value="3">Progressing Well</b-form-radio>
                  <b-form-radio value="2">Requires Improvement</b-form-radio>
                  <b-form-radio value="1">Unsatisfactory</b-form-radio>
                </b-form-radio-group>
              </div>

              <div class="form__item">
                <label class="form__label" for="contact">Can shoot/cross/pass accurately with both feet:</label>

                <b-form-radio-group id="radio-group-technical" class="custom-control-inline-player-assesment"
                  v-model="playerSelfAssessmentU11to18Data.q4" name="radio-options-4">
                  <b-form-radio value="5">Excellent</b-form-radio>
                  <b-form-radio value="4">Good</b-form-radio>
                  <b-form-radio value="3">Progressing Well</b-form-radio>
                  <b-form-radio value="2">Requires Improvement</b-form-radio>
                  <b-form-radio value="1">Unsatisfactory</b-form-radio>
                </b-form-radio-group>
              </div>

              <div class="form__item">
                <label class="form__label" for="contact">Can dribble in multiple directions using both feet:</label>

                <b-form-radio-group id="radio-group-technical" class="custom-control-inline-player-assesment"
                  v-model="playerSelfAssessmentU11to18Data.q5" name="radio-options-5">
                  <b-form-radio value="5">Excellent</b-form-radio>
                  <b-form-radio value="4">Good</b-form-radio>
                  <b-form-radio value="3">Progressing Well</b-form-radio>
                  <b-form-radio value="2">Requires Improvement</b-form-radio>
                  <b-form-radio value="1">Unsatisfactory</b-form-radio>
                </b-form-radio-group>
              </div>

              <div class="form__item">
                <label class="form__label" for="contact">Can protect the ball from an opponent:</label>

                <b-form-radio-group id="radio-group-technical" class="custom-control-inline-player-assesment"
                  v-model="playerSelfAssessmentU11to18Data.q6" name="radio-options-6">
                  <b-form-radio value="5">Excellent</b-form-radio>
                  <b-form-radio value="4">Good</b-form-radio>
                  <b-form-radio value="3">Progressing Well</b-form-radio>
                  <b-form-radio value="2">Requires Improvement</b-form-radio>
                  <b-form-radio value="1">Unsatisfactory</b-form-radio>
                </b-form-radio-group>
              </div>
            </div>

            <div class="multi-radio-wrapper">
              <h4>Tactical</h4>
              <p>* Please select all that apply</p>

              <div class="form__item">
                <label class="form__label" for="contact">Makes good decisions on the ball:</label>

                <b-form-radio-group id="radio-group-technical" class="custom-control-inline-player-assesment"
                  v-model="playerSelfAssessmentU11to18Data.q7" name="radio-options-7">
                  <b-form-radio value="5">Excellent</b-form-radio>
                  <b-form-radio value="4">Good</b-form-radio>
                  <b-form-radio value="3">Progressing Well</b-form-radio>
                  <b-form-radio value="2">Requires Improvement</b-form-radio>
                  <b-form-radio value="1">Unsatisfactory</b-form-radio>
                </b-form-radio-group>
              </div>

              <div class="form__item">
                <label class="form__label" for="contact">Takes up good positions to receive the ball:</label>

                <b-form-radio-group id="radio-group-technical" class="custom-control-inline-player-assesment"
                  v-model="playerSelfAssessmentU11to18Data.q8" name="radio-options-8">
                  <b-form-radio value="5">Excellent</b-form-radio>
                  <b-form-radio value="4">Good</b-form-radio>
                  <b-form-radio value="3">Progressing Well</b-form-radio>
                  <b-form-radio value="2">Requires Improvement</b-form-radio>
                  <b-form-radio value="1">Unsatisfactory</b-form-radio>
                </b-form-radio-group>
              </div>

              <div class="form__item">
                <label class="form__label" for="contact">Makes good runs off the ball at the right times:</label>

                <b-form-radio-group id="radio-group-technical" class="custom-control-inline-player-assesment"
                  v-model="playerSelfAssessmentU11to18Data.q9" name="radio-options-9">
                  <b-form-radio value="5">Excellent</b-form-radio>
                  <b-form-radio value="4">Good</b-form-radio>
                  <b-form-radio value="3">Progressing Well</b-form-radio>
                  <b-form-radio value="2">Requires Improvement</b-form-radio>
                  <b-form-radio value="1">Unsatisfactory</b-form-radio>
                </b-form-radio-group>
              </div>

              <div class="form__item">
                <label class="form__label" for="contact">Can play in more than one area of the field:</label>

                <b-form-radio-group id="radio-group-technical" class="custom-control-inline-player-assesment"
                  v-model="playerSelfAssessmentU11to18Data.q10" name="radio-options-10">
                  <b-form-radio value="5">Excellent</b-form-radio>
                  <b-form-radio value="4">Good</b-form-radio>
                  <b-form-radio value="3">Progressing Well</b-form-radio>
                  <b-form-radio value="2">Requires Improvement</b-form-radio>
                  <b-form-radio value="1">Unsatisfactory</b-form-radio>
                </b-form-radio-group>
              </div>

              <div class="form__item">
                <label class="form__label" for="contact">Can attack in 1v1 situations:</label>

                <b-form-radio-group id="radio-group-technical" class="custom-control-inline-player-assesment"
                  v-model="playerSelfAssessmentU11to18Data.q11" name="radio-options-11">
                  <b-form-radio value="5">Excellent</b-form-radio>
                  <b-form-radio value="4">Good</b-form-radio>
                  <b-form-radio value="3">Progressing Well</b-form-radio>
                  <b-form-radio value="2">Requires Improvement</b-form-radio>
                  <b-form-radio value="1">Unsatisfactory</b-form-radio>
                </b-form-radio-group>
              </div>

              <div class="form__item">
                <label class="form__label" for="contact">Can defend in 1v1 situations:</label>

                <b-form-radio-group id="radio-group-technical" class="custom-control-inline-player-assesment"
                  v-model="playerSelfAssessmentU11to18Data.q12" name="radio-options-12">
                  <b-form-radio value="5">Excellent</b-form-radio>
                  <b-form-radio value="4">Good</b-form-radio>
                  <b-form-radio value="3">Progressing Well</b-form-radio>
                  <b-form-radio value="2">Requires Improvement</b-form-radio>
                  <b-form-radio value="1">Unsatisfactory</b-form-radio>
                </b-form-radio-group>
              </div>

              <div class="form__item">
                <label class="form__label" for="contact">Reads situations in the game:</label>

                <b-form-radio-group id="radio-group-technical" class="custom-control-inline-player-assesment"
                  v-model="playerSelfAssessmentU11to18Data.q13" name="radio-options-13">
                  <b-form-radio value="5">Excellent</b-form-radio>
                  <b-form-radio value="4">Good</b-form-radio>
                  <b-form-radio value="3">Progressing Well</b-form-radio>
                  <b-form-radio value="2">Requires Improvement</b-form-radio>
                  <b-form-radio value="1">Unsatisfactory</b-form-radio>
                </b-form-radio-group>
              </div>
            </div>

            <div class="multi-radio-wrapper">
              <h4>Physical</h4>
              <p>* Please select all that apply</p>

              <div class="form__item">
                <label class="form__label" for="contact">Has good mobility:</label>

                <b-form-radio-group id="radio-group-technical" class="custom-control-inline-player-assesment"
                  v-model="playerSelfAssessmentU11to18Data.q14" name="radio-options-14">
                  <b-form-radio value="5">Excellent</b-form-radio>
                  <b-form-radio value="4">Good</b-form-radio>
                  <b-form-radio value="3">Progressing Well</b-form-radio>
                  <b-form-radio value="2">Requires Improvement</b-form-radio>
                  <b-form-radio value="1">Unsatisfactory</b-form-radio>
                </b-form-radio-group>
              </div>

              <div class="form__item">
                <label class="form__label" for="contact">Can Jump and land single leg and double footed:</label>

                <b-form-radio-group id="radio-group-technical" class="custom-control-inline-player-assesment"
                  v-model="playerSelfAssessmentU11to18Data.q15" name="radio-options-15">
                  <b-form-radio value="5">Excellent</b-form-radio>
                  <b-form-radio value="4">Good</b-form-radio>
                  <b-form-radio value="3">Progressing Well</b-form-radio>
                  <b-form-radio value="2">Requires Improvement</b-form-radio>
                  <b-form-radio value="1">Unsatisfactory</b-form-radio>
                </b-form-radio-group>
              </div>

              <div class="form__item">
                <label class="form__label" for="contact">Can kick with good technique and generate power (short
                  distances):</label>

                <b-form-radio-group id="radio-group-technical" class="custom-control-inline-player-assesment"
                  v-model="playerSelfAssessmentU11to18Data.q16" name="radio-options-16">
                  <b-form-radio value="5">Excellent</b-form-radio>
                  <b-form-radio value="4">Good</b-form-radio>
                  <b-form-radio value="3">Progressing Well</b-form-radio>
                  <b-form-radio value="2">Requires Improvement</b-form-radio>
                  <b-form-radio value="1">Unsatisfactory</b-form-radio>
                </b-form-radio-group>
              </div>

              <div class="form__item">
                <label class="form__label" for="contact">Has good balance (single leg, both sides):</label>

                <b-form-radio-group id="radio-group-technical" class="custom-control-inline-player-assesment"
                  v-model="playerSelfAssessmentU11to18Data.q17" name="radio-options-17">
                  <b-form-radio value="5">Excellent</b-form-radio>
                  <b-form-radio value="4">Good</b-form-radio>
                  <b-form-radio value="3">Progressing Well</b-form-radio>
                  <b-form-radio value="2">Requires Improvement</b-form-radio>
                  <b-form-radio value="1">Unsatisfactory</b-form-radio>
                </b-form-radio-group>
              </div>

              <div class="form__item">
                <label class="form__label" for="contact">Developing basic speed and strength:</label>

                <b-form-radio-group id="radio-group-technical" class="custom-control-inline-player-assesment"
                  v-model="playerSelfAssessmentU11to18Data.q18" name="radio-options-18">
                  <b-form-radio value="5">Excellent</b-form-radio>
                  <b-form-radio value="4">Good</b-form-radio>
                  <b-form-radio value="3">Progressing Well</b-form-radio>
                  <b-form-radio value="2">Requires Improvement</b-form-radio>
                  <b-form-radio value="1">Unsatisfactory</b-form-radio>
                </b-form-radio-group>
              </div>

              <div class="form__item">
                <label class="form__label" for="contact">Is flexible and agile:</label>

                <b-form-radio-group id="radio-group-technical" class="custom-control-inline-player-assesment"
                  v-model="playerSelfAssessmentU11to18Data.q19" name="radio-options-19">
                  <b-form-radio value="5">Excellent</b-form-radio>
                  <b-form-radio value="4">Good</b-form-radio>
                  <b-form-radio value="3">Progressing Well</b-form-radio>
                  <b-form-radio value="2">Requires Improvement</b-form-radio>
                  <b-form-radio value="1">Unsatisfactory</b-form-radio>
                </b-form-radio-group>
              </div>
            </div>

            <div class="multi-radio-wrapper">
              <h4>Mental</h4>
              <p>* Please select all that apply</p>

              <div class="form__item">
                <label class="form__label" for="contact">Has a growth mindset, willing to learn:</label>

                <b-form-radio-group id="radio-group-technical" class="custom-control-inline-player-assesment"
                  v-model="playerSelfAssessmentU11to18Data.q20" name="radio-options-20">
                  <b-form-radio value="5">Excellent</b-form-radio>
                  <b-form-radio value="4">Good</b-form-radio>
                  <b-form-radio value="3">Progressing Well</b-form-radio>
                  <b-form-radio value="2">Requires Improvement</b-form-radio>
                  <b-form-radio value="1">Unsatisfactory</b-form-radio>
                </b-form-radio-group>
              </div>

              <div class="form__item">
                <label class="form__label" for="contact">Has a competitive mentality:</label>

                <b-form-radio-group id="radio-group-technical" class="custom-control-inline-player-assesment"
                  v-model="playerSelfAssessmentU11to18Data.q21" name="radio-options-21">
                  <b-form-radio value="5">Excellent</b-form-radio>
                  <b-form-radio value="4">Good</b-form-radio>
                  <b-form-radio value="3">Progressing Well</b-form-radio>
                  <b-form-radio value="2">Requires Improvement</b-form-radio>
                  <b-form-radio value="1">Unsatisfactory</b-form-radio>
                </b-form-radio-group>
              </div>

              <div class="form__item">
                <label class="form__label" for="contact">Can build positive relationships and friendships:</label>

                <b-form-radio-group id="radio-group-technical" class="custom-control-inline-player-assesment"
                  v-model="playerSelfAssessmentU11to18Data.q22" name="radio-options-22">
                  <b-form-radio value="5">Excellent</b-form-radio>
                  <b-form-radio value="4">Good</b-form-radio>
                  <b-form-radio value="3">Progressing Well</b-form-radio>
                  <b-form-radio value="2">Requires Improvement</b-form-radio>
                  <b-form-radio value="1">Unsatisfactory</b-form-radio>
                </b-form-radio-group>
              </div>

              <div class="form__item">
                <label class="form__label" for="contact">Calmly and directly expresses their and feelings about
                  situations:</label>

                <b-form-radio-group id="radio-group-technical" class="custom-control-inline-player-assesment"
                  v-model="playerSelfAssessmentU11to18Data.q23" name="radio-options-23">
                  <b-form-radio value="5">Excellent</b-form-radio>
                  <b-form-radio value="4">Good</b-form-radio>
                  <b-form-radio value="3">Progressing Well</b-form-radio>
                  <b-form-radio value="2">Requires Improvement</b-form-radio>
                  <b-form-radio value="1">Unsatisfactory</b-form-radio>
                </b-form-radio-group>
              </div>

              <div class="form__item">
                <label class="form__label" for="contact">Is a problem solver, can find positive solutions to
                  challenges:</label>

                <b-form-radio-group id="radio-group-technical" class="custom-control-inline-player-assesment"
                  v-model="playerSelfAssessmentU11to18Data.q24" name="radio-options-24">
                  <b-form-radio value="5">Excellent</b-form-radio>
                  <b-form-radio value="4">Good</b-form-radio>
                  <b-form-radio value="3">Progressing Well</b-form-radio>
                  <b-form-radio value="2">Requires Improvement</b-form-radio>
                  <b-form-radio value="1">Unsatisfactory</b-form-radio>
                </b-form-radio-group>
              </div>

              <div class="form__item">
                <label class="form__label" for="contact">Emotional control of winning and losing:</label>

                <b-form-radio-group id="radio-group-technical" class="custom-control-inline-player-assesment"
                  v-model="playerSelfAssessmentU11to18Data.q25" name="radio-options-25">
                  <b-form-radio value="5">Excellent</b-form-radio>
                  <b-form-radio value="4">Good</b-form-radio>
                  <b-form-radio value="3">Progressing Well</b-form-radio>
                  <b-form-radio value="2">Requires Improvement</b-form-radio>
                  <b-form-radio value="1">Unsatisfactory</b-form-radio>
                </b-form-radio-group>
              </div>
            </div>

            <div class="multi-radio-wrapper">
              <h4>Social</h4>
              <p>* Please select all that apply</p>

              <div class="form__item">
                <label class="form__label" for="contact">Follows Instructions from coaches:</label>

                <b-form-radio-group id="radio-group-technical" class="custom-control-inline-player-assesment"
                  v-model="playerSelfAssessmentU11to18Data.q26" name="radio-options-26">
                  <b-form-radio value="5">Excellent</b-form-radio>
                  <b-form-radio value="4">Good</b-form-radio>
                  <b-form-radio value="3">Progressing Well</b-form-radio>
                  <b-form-radio value="2">Requires Improvement</b-form-radio>
                  <b-form-radio value="1">Unsatisfactory</b-form-radio>
                </b-form-radio-group>
              </div>

              <div class="form__item">
                <label class="form__label" for="contact">Respects club and match rules:</label>

                <b-form-radio-group id="radio-group-technical" class="custom-control-inline-player-assesment"
                  v-model="playerSelfAssessmentU11to18Data.q27" name="radio-options-27">
                  <b-form-radio value="5">Excellent</b-form-radio>
                  <b-form-radio value="4">Good</b-form-radio>
                  <b-form-radio value="3">Progressing Well</b-form-radio>
                  <b-form-radio value="2">Requires Improvement</b-form-radio>
                  <b-form-radio value="1">Unsatisfactory</b-form-radio>
                </b-form-radio-group>
              </div>

              <div class="form__item">
                <label class="form__label" for="contact">Is a good listener and pays attention to coaches and
                  teammates:</label>

                <b-form-radio-group id="radio-group-technical" class="custom-control-inline-player-assesment"
                  v-model="playerSelfAssessmentU11to18Data.q28" name="radio-options-28">
                  <b-form-radio value="5">Excellent</b-form-radio>
                  <b-form-radio value="4">Good</b-form-radio>
                  <b-form-radio value="3">Progressing Well</b-form-radio>
                  <b-form-radio value="2">Requires Improvement</b-form-radio>
                  <b-form-radio value="1">Unsatisfactory</b-form-radio>
                </b-form-radio-group>
              </div>

              <div class="form__item">
                <label class="form__label" for="contact">Ignores distractions to focus on tasks:</label>

                <b-form-radio-group id="radio-group-technical" class="custom-control-inline-player-assesment"
                  v-model="playerSelfAssessmentU11to18Data.q29" name="radio-options-29">
                  <b-form-radio value="5">Excellent</b-form-radio>
                  <b-form-radio value="4">Good</b-form-radio>
                  <b-form-radio value="3">Progressing Well</b-form-radio>
                  <b-form-radio value="2">Requires Improvement</b-form-radio>
                  <b-form-radio value="1">Unsatisfactory</b-form-radio>
                </b-form-radio-group>
              </div>

              <div class="form__item">
                <label class="form__label" for="contact">Is willing to ask questions to learn:</label>

                <b-form-radio-group id="radio-group-technical" class="custom-control-inline-player-assesment"
                  v-model="playerSelfAssessmentU11to18Data.q30" name="radio-options-30">
                  <b-form-radio value="5">Excellent</b-form-radio>
                  <b-form-radio value="4">Good</b-form-radio>
                  <b-form-radio value="3">Progressing Well</b-form-radio>
                  <b-form-radio value="2">Requires Improvement</b-form-radio>
                  <b-form-radio value="1">Unsatisfactory</b-form-radio>
                </b-form-radio-group>
              </div>

              <div class="form__item">
                <label class="form__label" for="contact">Is positive and enjoys being in a group environment with
                  others:</label>

                <b-form-radio-group id="radio-group-technical" class="custom-control-inline-player-assesment"
                  v-model="playerSelfAssessmentU11to18Data.q31" name="radio-options-31">
                  <b-form-radio value="5">Excellent</b-form-radio>
                  <b-form-radio value="4">Good</b-form-radio>
                  <b-form-radio value="3">Progressing Well</b-form-radio>
                  <b-form-radio value="2">Requires Improvement</b-form-radio>
                  <b-form-radio value="1">Unsatisfactory</b-form-radio>
                </b-form-radio-group>
              </div>

              <div class="form__item">
                <label class="form__label" for="contact">Is responsible for their own behaviour:</label>

                <b-form-radio-group id="radio-group-technical" class="custom-control-inline-player-assesment"
                  v-model="playerSelfAssessmentU11to18Data.q32" name="radio-options-32">
                  <b-form-radio value="5">Excellent</b-form-radio>
                  <b-form-radio value="4">Good</b-form-radio>
                  <b-form-radio value="3">Progressing Well</b-form-radio>
                  <b-form-radio value="2">Requires Improvement</b-form-radio>
                  <b-form-radio value="1">Unsatisfactory</b-form-radio>
                </b-form-radio-group>
              </div>
            </div>

            <b-button @click="saveplayerAssesment('U11-18')" v-b-tooltip.hover title="Update"
              variant="success">Save</b-button>
          </div>
        </b-modal>

        <img v-if="
          playerCoachSignedPerformanceReportData.parentReportSignature !==
          undefined
        " :src="getImgUrl(
            playerCoachSignedPerformanceReportData.parentReportSignature
          )
            " alt="" class="visually-hidden" id="player_signature_full" />
        <img src="./img/video-icon.jpg" alt="" class="visually-hidden" id="video-icon" />

        <img src="./img/pitch-potions.jpg" alt="" class="visually-hidden" id="pitch_potions" />
        <img src="./img/avrage-scores.jpg" alt="" class="visually-hidden" id="average_scores" />
        <img src="./img/video-link-image.jpg" alt="" class="visually-hidden" id="video_link_image" />
        <img src="./img/mood-example.jpg" alt="" class="visually-hidden" id="mood-example_image" />
      </template>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import {
  CHECK_PROFILE_HAS_DATA,
  USER_POPULATE,
  POPULATE_COACHES_USER_DATA,
} from "@/store/actions/user.js";
import { Storage } from "@/services/storage";
import { Users } from "@/services/user-service.js";
import { Wellness } from "@/services/wellness-service.js";
import { PlayerInjury } from "@/services/player-Injury-service.js";
import { Games } from "@/services/games-service.js";
import { PerformanceReview } from "@/services/performance-review-service.js";
import { Teams } from "@/services/teams-service.js";
import { Payments } from "@/services/stripe-service.js";
import { PlayerPhysicalPerformance } from "@/services/player-physical-performance-service.js";
import { PlayerCv } from "@/services/player-cv-service.js";
import { AcademyReport } from "@/services/academy-report-service.js";
import { Documents } from "@/services/documents-service.js";
import { Homework } from "@/services/homework-service.js";
import { Chat } from "@/services/chat-service.js";
import AppTemplate from "../layouts/App";
import JsPDF from "jspdf";
import SpinnerLoader from "../../components/LoadingSpinning";
//import CircularPercentage from "../../components/CircularPercentage";
import UserImageAndInfo from "../../components/UserImageAndInfo";
import UserHeader from "../../components/UserHeader";
import GpsPannel from "../../components/GpsPanel";
import TrainingPlansLinks from "../training-plans/training-plans-links";
import StarRating from "vue-star-rating";
import moment, { months } from "moment";
import VModal from "vue-js-modal";
import vueSignature from "vue-signature";
import BarChart from "../../components/BarChart";
import BarChartStacked from "../../components/BarChart-horizontal-stacked";
import BarChartHorizontal from "../../components/BarChart-horizontal-single";
import PieChart from "../../components/PieChart";
import LineGameTrainingAvgChart from "../../components/charts/LinePhysicalPerformanceChart";
import LinePhysicalPerformanceChart from "../../components/charts/LineGameTrainingAvgChart";
import injuryBodyChart from "../../components/injuryBodyChart";
import DailyPlanner from "../../components/DailyPlanner";
import extendSeasonEndDateToFullYearMixin from "@/mixin/extendSeasonEndDateToFullYear.js";
import removeDuplicatesMixin from "@/mixin/removeDuplicates.js";
import checkIfUserHasMoreThanOneAccountMixin from "@/mixin/checkIfUserHasMoreThanOneAccount.js";
import fixwizywigLinkButtonMixin from "@/mixin/fixwizywigLinkButton.js";
import Datepicker from "vuejs-datepicker";
import DocumentUploader from "../../components/doucmentUploader";
import wysiwyg from "vue-wysiwyg";
import dayjs from "dayjs";

Vue.use(VModal, wysiwyg, {
  hideModules: { image: true },
});

export default {
  name: "profile",
  mixins: [
    extendSeasonEndDateToFullYearMixin,
    removeDuplicatesMixin,
    checkIfUserHasMoreThanOneAccountMixin,
    fixwizywigLinkButtonMixin,
  ],
  components: {
    AppTemplate,
    SpinnerLoader,
    UserHeader,
    GpsPannel,
    TrainingPlansLinks,
    StarRating,
    //CircularPercentage,
    UserImageAndInfo,
    BarChart,
    BarChartStacked,
    BarChartHorizontal,
    PieChart,
    LinePhysicalPerformanceChart,
    LineGameTrainingAvgChart,
    vueSignature,
    DailyPlanner,
    Datepicker,
    DocumentUploader,
    injuryBodyChart,
  },
  data() {
    return {
      physicalPerformanceChartSelected: "1",
      physicalPerformanceLineChartData: {},
      physicalPerformanceLineCountermovementJumpChartData: {},
      physicalPerformanceLineReactiveStrengthIndexChartData: {},
      physicalPerformanceLineThirtyFithteenIntermittentFitnessTestChartData: {},
      physicalPerformanceLineHeightChartData: {},
      physicalPerformanceLineWeightChartData: {},
      sessionStrainingAndGamesSessionDistanceChartData: {},
      sessionStrainingAndGamesSessionHighSpeedRunningChartData: {},
      sessionStrainingAndGamesSessionAccelerationsChartData: {},
      sessionStrainingAndGamesSessionDistancePieData: {},
      sessionStrainingAndGamesSessionLeftRighPieData: {},
      sessionStrainingAndGamesSessionLeftLeftPieData: {},
      sessionStrainingAndGamesSessionHighSpeedRunningPieData: {},
      sessionStrainingAndGamesSessionMaximumVelocityPieData: {},
      fullSessionStrainingAndGamesTotalDistanceChartData: {},
      fullSessionStrainingAndGamesHighSpeedRunningChartData: {},
      fullSessionStrainingAndGamesTotalTouchesChartData: {},
      sessionStrainingAndGamesSessionTopSpeedsChartData: {},
      sessionStrainingAndGamesSessionWorkRateChartData: {},
      fullSessionStrainingAndGamesRightFootTouchesChartData: {},
      fullSessionStrainingAndGamesLeftFootTouchesChartData: {},
      sessionStrainingAndGamesChartSelected: "1",
      playerSignature: {
        player_signature: null,
        player_signature_change: true,
        parent_signature: null,
        parent_signature_change: true,
        player_id: null,
        id: null,
      },
      charterSignature: {
        player_signature: null,
        player_signature_change: true,
        parent_signature: null,
        parent_signature_change: true,
        player_id: null,
        id: null,
      },
      coachSignedPerformanceReportSignature: {
        player_signature_change: true,
        coach_signature_change: true,
        parent_signature_change: true,
      },
      charterData: {
        player_id: "",
        team_id: "",
        player_text: "",
        parent_text: "",
        created_date: "",
      },
      playerDocuments: [],
      technicalSelectOptions: [
        "Passing/Possession",
        "Shooting/Finishing",
        "Heading",
        "1v1 Attacking",
        "1v1 Defending",
        "Crossing",
        "Receiving and ball retaining skills",
        "Dribbling/Running with ball",
        "Close control/First touch",
        "Weak foot",
      ],
      tacticalSelectOptions: [
        "In Possession find/create space",
        "Out of Possession /close space",
        "Winning possession / create space/reaction",
        "Losing Possession /close space/ reaction",
        "Switching Play",
        "Combination/link up play",
        "Positional understanding",
      ],
      physicalSelectOptions: [
        "Agility",
        "Co-ordination",
        "Balance",
        "Speed",
        "Endurance",
        "Strength (U13-U18)",
      ],
      physcologicalSelectOptions: [
        "Intelligence – Game craft/knowledge",
        "Vision",
        "Leadership",
        "Decision making",
        "Composure",
        "Communication",
        "Coachability",
        "Drive/Desire",
        "Discipline",
        "Confidence",
      ],
      playerIDPMonthGoals: {
        tweleveMonth: [],
        sixMonth: [],
        threeMonth: [],
      },
      playerSelfAssessmentU11to18Data: {},
      allPlayerSelfAssessmentData: [],
      wellnessDaySelected: null,
      IDPselectedGoalData: {},
      IDPGoalsData: [],
      allQuatersPlayerIDPdata: [],
      newPlayerIDPdata: {},
      playerIDPdata: {
        technical_green: "",
        technical_yellow: "",
        technical_red: "",
        tactical_green: "",
        tactical_yellow: "",
        tactical_red: "",
        physical_green: "",
        physical_yellow: "",
        physical_red: "",
        physcological_green: "",
        physcological_yellow: "",
        physcological_red: "",
        player_comments: "",
        coach_comments: "",
      },
      allGameData: [],
      allHomeWorkDataVideoLinks: [],
      allHomeWorkData: [],
      allTeamWorkData: [],
      newHomeWorkData: {
        created_date: "",
        gameId: null,
        id: null,
        matchDate: null,
        opposition: null,
        title: "",
        information: "",
        player_id: null,
        videos: [],
      },
      physioDocument: {},
      downloadURL: "",
      uploadedDocuments: [],
      playerLoadChartDataSet: {},
      allPlayerLoadReporsData: [],
      file: "",
      selectedDayPlayerData: {},
      csvDataImported: null,
      minsData: {
        year: 2024,
        month: 5,
        weekDays: [
          {
            day: "",
            date: "",
          },
        ],
      },
      wellnessData: {
        year: 2024,
        month: 5,
        weekDays: [
          {
            day: "",
            date: "",
          },
        ],
      },
      editDayPlayerData: false,
      playerDailyMins: [],
      playerDailyMinsWeek1Total: 0,
      playerDailyMinsWeek2Total: 0,
      playerDailyMinsWeek3Total: 0,
      playerDailyMinsWeek4Total: 0,
      playerDailyMinsWeek5Total: 0,
      userHasMoreThanOneAccount: null,
      weight: 0,
      height: 0,
      pastGameDataDataFiltered: [],
      pastGameDataFilteredCoach: [],
      playersWithoutLiveSubscriptions: [],
      allBookingData: [],
      playersPayment: [],
      clubTeams: [],
      selectedFilterData: null,
      toggleFilters: false,
      hideRemoveTeamFilterButton: true,
      filterdCoachesUsersData: [],
      allUserDataBeforFilterApplied: [],
      selectedDashboardTab: "player-over-veiw-tab",
      selectedTeamId: 0,
      metricsTrainingTab: "training-distance-tab",
      metricsGameTab: "game-distance-tab",
      signatureOptions: {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255,255,255)",
      },
      allplayerPerformanceReportData: [],
      playerPerformanceReportData: {
        review_date: moment().format("YYYY-MM-DD"),
      },
      selectedPlayerVideoData: {},
      selectedPlayerVideoDataEdit: false,
      selectedPlayerInterestsHobbiesData: {},
      selectedPlayerInterestsHobbiesDataEdit: false,
      selectedPlayerEducationData: {},
      selectedPlayerEducationDataEdit: false,
      selectedPlayingExperenceData: {},
      selectedPlayingExperenceEdit: false,
      selectedGameFullPerformanceReportGPSdataForUser: {},
      teamVideos: [],
      playerCvData: {
        personal_profile: "",
        playingExperence: [],
        education: [],
        interests_hobbies: [],
        videos: [],
      },
      playerInjuryReportsData: [],
      playerInjuryData: [],
      editInjuryReport: {},
      newInjuryInformation: {
        injury_type: null,
        more_detail: null,
        return_time: null,
        injury_status: null,
        contact: null,
      },
      thisseasongamePerformanceData: [],
      trainingPerformanceData: {},
      newTrainingPerformanceData: {},
      newGamePerformanceData: {
        gameId: null,
        postion_played: null,
        postion_played_two: null,
        goals: 0,
        assists: 0,
        clean_sheet: null,
        player_self_rating: null,
        card: "No",
        timeOnPitch: null,
        gamesPlayed: null,
        technically_improvements: null,
        technically_positives: null,
        tactility_improvements: null,
        tactility_positives: null,
        physicality_improvements: null,
        physicality_positives: null,
        mentally_improvements: null,
        mentally_positives: null,
        pdps_improvements: null,
        pdps_positives: null,
        overall_comments: null,
      },
      gamePerformanceData: {
        gameId: null,
        postion_played: [],
        goals: 0,
        assists: 0,
        clean_sheet: null,
        player_self_rating: null,
        yellowCardTotal: 0,
        redCardTotal: 0,
        redCardTotal: 0,
        timeOnPitch: null,
        gamesPlayed: null,
      },
      seasionsCoachGamePerformanceData: [],
      coachGamePerformanceData: {
        gameId: null,
        player_rating: null,
        game_comments: null,
      },
      pastTrainingSessionsData: [],
      pastTrainingSessionsDataFiltered: [],
      pastGameData: [],
      playerMoodData: {},
      thisSeasionsPlayerMoodData: [],
      playerMoodBarChartDataSet: {},
      wellnessQuestions: {
        sleep: 0,
        stress: 0,
        happyness: 0,
        fatigue: 0,
        muscleSoreness: 0,
      },
      zeroToTen: [
        { text: "1", value: "1" },
        { text: "2", value: "2" },
        { text: "3", value: "3" },
        { text: "4", value: "4" },
        { text: "5", value: "5" },
        { text: "6", value: "6" },
        { text: "7", value: "7" },
      ],
      publicPath: process.env.BASE_URL,
      loadingStuff: false,
      selectedUserValue: "",
      weekSelectedNumber: "",
      weeklyDate: "",
      selectedWeekMonSunday: "",
      gamesPlayed: 0,
      selectedUserData: {},
      selectedUserDataForHeader: {},
      userData: {
        name: "",
        userType: "",
      },
      coachesUsersData: [],
      selectedCoachImageURL: null,
      seasonsgamePerformanceReportsForUser: [],
      seasonsTraningPerformanceReportsForUser: [],
      selectedGameFullPerformanceReportsForUser: {},
      selectedTab: "performace-tab",
      currentPlayingSeason: null,
      season_start_date: null,
      season_end_date: null,
      allPlayerPhysicalPerformanceData: [],
      playerPhysicalPerformanceData: {},
      trainingPerformanceBarChartDataSet: {},
      allPlayerInjuryReports: [],
      selectedInjuryReports: [],
      selectedInjury: {},
      playerInjuryHistoryAmounts: {
        headNeckInjury: 0,
        shoulderInjury: 0,
        armInjury: 0,
        wristAndHandInjury: 0,
        hipInjury: 0,
        groinInjury: 0,
        quadInjury: 0,
        hamstringInjury: 0,
        kneeInjury: 0,
        calfInjury: 0,
        ankleInjury: 0,
        footInjury: 0,
      },
      playerCoachSignedPerformanceReportData: {
        created_date: null,
        user_id: "",
        coach_id: null,
        technical_player_comments: "",
        technical_coach_comments: "",
        tactical_player_comments: "",
        tactical_coach_comments: "",
        physical_player_comments: "",
        physical_coach_comments: "",
        physcological_player_comments: "",
        physcological_coach_comments: "",
        player_comments: "",
        coach_comments: "",
        player_signature_id: null,
        parent_signature_id: null,
        coach_signature_id: null,
        published: 0,
      },
      playerSignedPerformanceReportSelected: null,
      performanceCategories: [
        "Technical",
        "Tactical",
        "Physical",
        "Psychological",
        /*"Attitude",
        "Skill",
        "Physique",
        "Mentality",
        "Leadership",
        "Professionalism",*/
      ],
      selectedPerformanceTarget: {},
      playerPerformanceTargets: [
        {
          id: null,
          player_id: this.selectedUserValue,
          performance_report_id: 0,
          idp_id: 0,
          target: "",
          complete: 0,
          created_date: moment().format("YYYY-MM-DD"),
          possession: "",
        },
      ],
      gameTargetQuarterSelection: "all",
      playerGameTargetsData: [],
      playerGameTargetsDataFiltered: [],
      playerPerformanceReviewTargetsData: [],
      showReport: false,
      showCVReport: false,
      showPlayerCoachReportPDFReport: false,
      academyReport: {
        id: null,
        player_id: null,
        seasion: null,
        average_score: 0,
        coaches_comments: "",
        seasion: "",
      },
      playerMakerPersonalBestAndAverages: {},
      playerPersonalBestAndAverages: {},
      reportDocuments: [],
      slide: 0,
      sliding: true,
      columns: [
        {
          label: "Session name",
          field: "session_name",
        },
        {
          label: "Player name",
          field: "user_name",
        },
        {
          label: "Billing email",
          field: "email",
        },
        {
          label: "Purchase date",
          field: "date",
        },
        {
          label: "Total paid",
          field: "total_price",
        },
        {
          label: "Payment status",
          field: "payment_state",
        },
      ],
      columnsPlayers: [
        {
          label: "Player name",
          field: "name",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "Payment status",
          field: "payment_state",
        },
      ],
      showOnlyInjuredPlayers: false,
      lastTrainingSessionData: {},
      lastGameSessionData: {},
      gpsUploadDataTypeSelected: undefined,
      dataCSVErrorMessage: null,
      playerPlayMakerGpsData: [],
      under16: false,
      over14: false,
      selectedGameAndTrainingChartStats: {},
      allPlayerCoachSignedPerformanceReportData: [],
      playerCoachsignedReportReady: false,
    };
  },
  computed: {
    ...mapGetters(["getProfile", "getCoachesUsersData"]),
  },
  async created() {
    this.setPlayingSeasion();
    this.minsData.weekDays = this.populateMinsCal(
      Number(dayjs().format("YYYY")),
      Number(dayjs().month()),
      "minsData"
    );

    this.wellnessData.weekDays = this.populateMinsCal(
      Number(dayjs().format("YYYY")),
      Number(dayjs().month()),
      "wellnessData"
    );
    this.resetPersonalBestAndAveragesData();
    this.loadingStuff = true;
    this.userHasMoreThanOneAccount = this.checkIfUserHasMoreThanOneAccount();
    const now = moment().format("MM-DD-YYYY");
    this.weekSelectedNumber = moment(now).isoWeek();
    this.setMonToSundayWeekDates();

    const clientId = JSON.parse(localStorage.getItem("the_w_selectedClientId"));

    const response = await this.getUserById(clientId);
    if (response === true) {
      if (
        this.userData.userType !== "Admin" &&
        this.userData.userType !== "Coach" &&
        this.userHasMoreThanOneAccount === null
      ) {
        Promise.all([this.getTeamsDataById(this.userData.teamId)]).then(() => {
          this.under16 = this.showIfUnderU16(this.userData);
          this.over14 = this.showIfOverU11(this.userData);
          this.loadPlayersProfilePerformanceData();
          this.getPlayerPerformanceReviews();
          this.getAllgoalsScoredByPlayerId();
          this.getAllgoalAssistsByPlayerId();
          this.selectedTeamId = this.userData.teamId;
          this.populatePlayerCV(this.userData);
          this.populateAcademyReport();
          this.loadPlayerMinsPerformanceData();
          this.getAllHomeworkForPlayer();
        });
      }

      if (this.userData.userType === "Admin") {
        this.setPlayingSeasion().then((value) => {
          this.getAllUsersForClubId();
        });
      }

      if (this.userData.userType === "Coach") {
        if (this.userData.position === "Goalkeeper coach") {
          this.setPlayingSeasion().then((value) => {
            this.getAllGoalKeepersForCoach();
          });
        } else {
          this.setPlayingSeasion().then((value) => {
            this.getAllUsersForCoach();
          });
        }
      }

      if (this.userHasMoreThanOneAccount !== null) {
        this.getAllUsersForHasMoreThanOneAccount();
      }
      this.getGameTargets();
      this.loadingStuff = false;
    }
  },
  methods: {
    mapSessionDatesToLables(data, typeOfData) {
      let dataArray = [];

      if (typeOfData === "Session") {
        data.map((chartData) => {
          if (chartData.gameType !== "Training") {
            dataArray.push(chartData.session_date);
          }
        });
      }

      if (typeOfData === "training") {
        data.map((chartData) => {
          if (chartData.gameType === "Training") {
            dataArray.push(chartData.session_date);
          }
        });
      }

      return dataArray;
    },
    mapSessionData(data, typeOfData, typeOfChartData) {
      let dataArray = [];

      if (typeOfData === "Session") {
        data.map((chartData) => {
          if (chartData.gameType !== "Training") {
            dataArray.push(chartData[typeOfChartData]);
          }
        });
      }

      if (typeOfData === "training") {
        data.map((chartData) => {
          if (chartData.gameType === "Training") {
            dataArray.push(chartData[typeOfChartData]);
          }
        });
      }

      return dataArray;
    },
    lineGameTrainingAvgChartData(
      data,
      typeOfData,
      typeOfChartData,
      nameOfData
    ) {
      return {
        labels: this.mapSessionDatesToLables(data, typeOfData, typeOfChartData),
        datasets: [
          {
            label: nameOfData,
            backgroundColor: "#79f89a",
            data: this.mapSessionData(data, typeOfData, typeOfChartData),
          },
        ],
      };
    },
    filterUploadedDocuments(categorySelected) {
      return this.uploadedDocuments.filter(
        (document) => document.category === categorySelected
      );
    },
    async selectInjuryReportView(injury) {
      this.editInjuryReport = injury;
      await this.getPhysioDocumentUploadsForInjury(injury.id);
    },
    async getPhysioDocumentUploadsForInjury(playerInjuryReportId) {
      const userData = {
        action: "getPhysioDocumentUploadsForInjury",
        user_id: this.selectedUserValue,
        playerInjuryReportId: playerInjuryReportId,
      };
      const response = await Documents.find(userData).catch((error) => {
        console.log(error);
      });

      if (response.status === 200) {
        this.reportDocuments = response.data;
      }
    },
    showAllInjuryReports(mainInjury, id) {
      if (mainInjury === null) {
        mainInjury = this.allPlayerInjuryReports.find(
          (report) => report.id === id
        );
      }
      this.editInjuryReport = {};
      this.selectedInjury = mainInjury;
      this.selectedInjuryReports = [];
      this.selectedInjuryReports = this.playerInjuryReportsData.filter(
        (report) => report.injury_information_id === mainInjury.id
      );
      this.showModal("allplayerInjuryReportsModal");
    },
    changePlayerCoachPerformanceAssessmentStatus(status) {
      this.playerCoachSignedPerformanceReportData.status = status;
      this.updatePlayerCoachSignedPerformanceReport();
    },
    async getSeasionDates() {
      const userData = {
        action: "getSeasionDates",
      };

      const response = await Teams.find(userData).catch((error) => {
        console.log(error);
      });

      if (response.status === 200) {
        return response.data;
      }
    },
    setPlayingSeasion() {
      let result = this.getSeasionDates().then((responce) => {
        let year = moment().year();
        localStorage.setItem(
          "currentPlayingseasonStartDate",
          responce.season_start_date
        );
        localStorage.setItem(
          "currentPlayingseasonEndDate",
          responce.season_end_date
        );

        localStorage.setItem("currentPlayingSeason", `${year}`);

        return true;
      });

      return result;
    },
    checkChartHasData(dataName, playerId, date) {
      const data = this.playerDailyMins.find(
        (data) =>
          data.player_id === playerId &&
          data.session_date === date &&
          data.session_type === "Session" &&
          data[dataName] !== undefined
      );
      return data !== undefined;
    },
    setSelectedDatetrainingAndGameData(data) {
      const date = this.formatDateForDB(data);

      if (this.under16 === false) {
        // ---- 2009 - up
        this.selectedGameAndTrainingChartStats = this.playerDailyMins.find(
          (dailyMins) => dailyMins.session_date === date
        );

        if (this.selectedGameAndTrainingChartStats !== undefined) {
          this.sessionStrainingAndGamesSessionDistancePieData =
            this.populateSeasonStrainingAndGamesstatsChartPieDataSetsData(
              this.selectedUserData.id,
              date,
              "session_distance"
            );
          this.sessionStrainingAndGamesSessionHighSpeedRunningPieData =
            this.populateSeasonStrainingAndGamesstatsChartPieDataSetsData(
              this.selectedUserData.id,
              date,
              "total_absolute_high_speed_distance"
            );
          this.sessionStrainingAndGamesSessionMaximumVelocityPieData =
            this.populateSeasonStrainingAndGamesstatsChartPieDataSetsData(
              this.selectedUserData.id,
              date,
              "maximum_velocity"
            );

          if (
            this.checkChartHasData(
              "total_absolute_high_speed_distance",
              this.selectedUserData.id,
              date
            ) === true
          ) {
            this.fullSessionStrainingAndGamesTotalDistanceChartData = {
              labels: ["High Speed Running"],
              datasets:
                this.populateFullSeasonStrainingAndGamesstatsChartDataSetsData(
                  "total_absolute_high_speed_distance",
                  this.selectedUserData.id,
                  date,
                  "High Speed Running"
                ),
            };
          }

          if (
            this.checkChartHasData(
              "session_distance",
              this.selectedUserData.id,
              date
            ) === true
          ) {
            this.fullSessionStrainingAndGamesHighSpeedRunningChartData = {
              labels: ["Total Distance"],
              datasets:
                this.populateFullSeasonStrainingAndGamesstatsChartDataSetsData(
                  "session_distance",
                  this.selectedUserData.id,
                  date,
                  "Total Distance"
                ),
            };
          }

          this.sessionStrainingAndGamesSessionHighSpeedRunningChartData = {
            labels: this.populateSeasonStrainingAndGamesstatsChartDataLabels(
              "session_type",
              this.selectedUserData.id,
              date
            ),
            datasets:
              this.populateSeasonTrainingAndGamesstatsAccelerationsAndDecelerationsChart(
                this.selectedUserData.id,
                date
              ),
          };
        } else {
          this.selectedGameAndTrainingChartStats = {};
        }
      } else {
        // under - 2009

        this.selectedGameAndTrainingChartStats = this.playerDailyMins.find(
          (dailyMins) => dailyMins.session_date === date
        );

        if (this.selectedGameAndTrainingChartStats === undefined) {
          this.selectedGameAndTrainingChartStats = {};
        }

        if (
          this.playerPlayMakerGpsData.find(
            (gps) => gps.session_date === date
          ) !== undefined
        ) {
          this.sessionStrainingAndGamesSessionLeftRighPieData =
            this.populateSeasonStrainingAndGamesstatsChartPieDataPlayMakerSetsData(
              this.selectedUserData.id,
              date,
              "right_leg_touches"
            );
          this.sessionStrainingAndGamesSessionLeftLeftPieData =
            this.populateSeasonStrainingAndGamesstatsChartPieDataPlayMakerSetsData(
              this.selectedUserData.id,
              date,
              "left_leg_touches"
            );

          this.fullSessionStrainingAndGamesTotalTouchesChartData = {
            labels: ["Total Touches"],
            datasets:
              this.populateFullSeasonStrainingAndGamesstatsAllPlaymakerChartDataSetsData(
                "total_touches",
                this.selectedUserData.id,
                date,
                "Total Touches"
              ),
          };

          this.fullSessionStrainingAndGamesRightFootTouchesChartData = {
            labels: ["Right foot Touches"],
            datasets:
              this.populateFullSeasonStrainingAndGamesstatsAllPlaymakerChartDataSetsData(
                "right_leg_touches",
                this.selectedUserData.id,
                date,
                "Right foot Touches"
              ),
          };

          this.fullSessionStrainingAndGamesLeftFootTouchesChartData = {
            labels: ["Left foot Touches"],
            datasets:
              this.populateFullSeasonStrainingAndGamesstatsAllPlaymakerChartDataSetsData(
                "left_leg_touches",
                this.selectedUserData.id,
                date,
                "Left foot Touches"
              ),
          };

          this.fullSessionStrainingAndGamesTotalDistanceChartData = {
            labels: ["Total distance (m)"],
            datasets:
              this.populateFullSeasonStrainingAndGamesstatsAllPlaymakerChartDataSetsData(
                "distance_covered",
                this.selectedUserData.id,
                date,
                "Total distance (m)"
              ),
          };

          this.sessionStrainingAndGamesSessionTopSpeedsChartData = {
            labels: ["Top Speed"],
            datasets:
              this.populateFullSeasonStrainingAndGamesstatsAllPlaymakerChartDataSetsData(
                "top_speed",
                this.selectedUserData.id,
                date,
                "Top Speed"
              ),
          };

          this.sessionStrainingAndGamesSessionWorkRateChartData = {
            labels: ["Work rate"],
            datasets:
              this.populateFullSeasonStrainingAndGamesstatsAllPlaymakerChartDataSetsData(
                "work_rate",
                this.selectedUserData.id,
                date,
                "Work rate"
              ),
          };
        } else {
          this.sessionStrainingAndGamesSessionLeftRighPieData = {};
          this.sessionStrainingAndGamesSessionLeftLeftPieData = {};
          this.fullSessionStrainingAndGamesTotalTouchesChartData = {};
          this.fullSessionStrainingAndGamesRightFootTouchesChartData = {};
          this.fullSessionStrainingAndGamesLeftFootTouchesChartData = {};
          this.fullSessionStrainingAndGamesTotalDistanceChartData = {};
          this.sessionStrainingAndGamesSessionTopSpeedsChartData = {};
          this.sessionStrainingAndGamesSessionWorkRateChartData = {};
        }
      }
    },
    showOnlyInjuredPlayersToggle() {
      this.showOnlyInjuredPlayers = !this.showOnlyInjuredPlayers;
      if (this.showOnlyInjuredPlayers === true) {
        this.removeFilter();
        this.filterBy("allUserDataBeforFilterApplied", "injury");
        const filterResult = this.allUserDataBeforFilterApplied.filter(
          (player) => player.injury === true
        );
        this.coachesUsersData = filterResult;
      } else {
        this.removeFilter();
        this.coachesUsersData = this.allUserDataBeforFilterApplied;
      }
    },
    removeNumberFromStart(documentName) {
      if (documentName !== undefined) {
        return documentName.substring(documentName.indexOf("_") + 1);
      }
    },
    populateSelectedPlayerSelfAssessment(id) {
      this.playerSelfAssessmentU11to18Data =
        this.allPlayerSelfAssessmentData.find(
          (assessment) => assessment.id === id
        );
      console.log(this.playerSelfAssessmentU11to18Data);
    },
    async getPlayerCoachSignedPerformanceReport() {
      const userData = {
        action: "getPlayerCoachSignedPerformanceReport",
        id: this.selectedUserData.id,
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };

      const response = await PerformanceReview.find(userData).catch((error) => {
        console.log(error);
      });

      if (response.status === 200) {
        this.allPlayerCoachSignedPerformanceReportData = response.data;
      }
    },
    unpublishPlayerCoachReport() {
      this.playerCoachSignedPerformanceReportData.published = 0;
      this.playerCoachSignedPerformanceReportData.status = "live";
      this.updatePlayerCoachSignedPerformanceReport();
    },
    publishPlayerCoachReport() {
      this.playerCoachSignedPerformanceReportData.published = 1;
      this.playerCoachSignedPerformanceReportData.status = "published";
      this.updatePlayerCoachSignedPerformanceReport();
    },
    signPlayerCoachReport() {
      this.playerCoachSignedPerformanceReportData.status = "live";
      this.updatePlayerCoachSignedPerformanceReport();
    },
    async updatePlayerCoachSignedPerformanceReport() {
      const userData = {
        action: "updatePlayerCoachSignedPerformanceReport",
        ...this.playerCoachSignedPerformanceReportData,
      };

      const response = await PerformanceReview.find(userData).catch((error) => {
        console.log(error);
      });

      if (response.status === 200) {
        this.playerCoachsignedReportReady = false;
        this.getPlayerCoachSignedPerformanceReport();
        this.$bvModal.hide("playerCoachPerformanceAssessmentModal");
        this.$bvToast.toast("Performance Assesment updated", {
          title: "Saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async savePlayerCoachSignedPerformanceReport() {
      this.playerCoachSignedPerformanceReportData.created_date =
        moment().format("YYYY-MM-DD");
      this.playerCoachSignedPerformanceReportData.user_id =
        this.selectedUserData.id;
      if (this.userData.userType !== "User") {
        this.playerCoachSignedPerformanceReportData.coach_id = this.userData.id;
      }
      const userData = {
        action: "savePlayerCoachSignedPerformanceReport",
        user_id: this.selectedUserData.id,
        ...this.playerCoachSignedPerformanceReportData,
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };

      const response = await PerformanceReview.find(userData).catch((error) => {
        console.log(error);
      });

      if (response.status === 200) {
        this.playerCoachsignedReportReady = false;
        this.getPlayerCoachSignedPerformanceReport();
        this.$bvModal.hide("playerCoachPerformanceAssessmentModal");
        this.$bvToast.toast("Performance Assesment saved", {
          title: "Saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async getAllPlayerAssesments() {
      const userData = {
        action: "getAllPlayerAssesments",
        user_id: this.selectedUserData.id,
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };

      const response = await PerformanceReview.find(userData).catch((error) => {
        console.log(error);
      });

      if (response.status === 200) {
        this.allPlayerSelfAssessmentData = response.data;
      }
    },
    async saveplayerAssesment(formName) {
      const userData = {
        action: "saveplayerAssesment",
        ...this.playerSelfAssessmentU11to18Data,
        user_id: this.selectedUserData.id,
        age_group: this.selectedUserData.age_group,
        created_date: moment().format("YYYY-MM-DD"),
      };
      const response = await PerformanceReview.find(userData).catch((error) => {
        console.log(error);
      });

      if (response.status === 200) {
        this.getAllPlayerAssesments();
        this.$bvModal.hide("playerAssesmentModal");
        this.$bvToast.toast("Player Assesment saved", {
          title: "Saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    playerAssesmentModal() {
      this.playerSelfAssessmentU11to18Data = {};
      this.$bvModal.show("playerAssesmentModal");
    },
    checkPlayerMinsMonthTotals(total) {
      if (total > 1300) {
        //this.sendFBNotificationMessage(`${this.selectedUserData.name} mins load high this month!`, `Please review ${this.selectedUserData.name} mins load! `, [], this.selectedUserData.coach_id);
      }
      return total;
    },
    charterModal() {
      this.showModal("charterModal");
    },
    async getCharterData() {
      const userData = {
        action: "getCharterData",
        player_id: 0,
      };
      const response = await Documents.find(userData).catch((error) => {
        console.log(error);
      });

      if (response.status === 200) {
        this.charterData = response.data;
        if (this.charterData.parent_signature !== null) {
          this.charterData.parent_signature = false;
        }

        if (this.charterData.player_signature !== null) {
          this.charterData.player_signature = false;
        }
      }
    },
    async getDocumentUploadsByUserId() {
      const userData = {
        action: "getDocumentUploadsByUserId",
        user_id: this.selectedUserValue,
      };
      const response = await Documents.find(userData).catch((error) => {
        console.log(error);
      });

      if (response.status === 200) {
        this.uploadedDocuments = response.data;
      }
    },
    onCloseDailyWellnessModal() {
      this.wellnessDaySelected = null;
    },
    showWellnessByDay(weekDay) {
      this.wellnessDaySelected = weekDay;
      this.showModal("viewDailyWellnessReport");
    },
    async showIfOver2010() {
      const under2011Groups = ["2016", "2015", "2014", "2013", "2012", "2011"];

      try {
        const clientId = JSON.parse(
          localStorage.getItem("the_w_selectedClientId")
        );
        const response = await this.getUserByIdReturnData(clientId);
        return response && under2011Groups.includes(response.age_group);
      } catch (error) {
        console.error("Error fetching user data:", error);
        return false;
      }
    },
    showIfOverU11(player) {
      if (player !== undefined) {
        const under2009Groups = ["2016", "2015"];
        return under2009Groups.includes(player.age_group);
      }
    },
    showIfUnderU16(player) {
      if (player !== undefined) {
        const under2009Groups = [
          "2016",
          "2015",
          "2014",
          "2013",
          "2012",
          "2011",
        ];
        return under2009Groups.includes(player.age_group);
      }
    },
    selectQuaterDataForGameTargets() {
      const quarters = {
        1: {
          start: moment().quarter(1).startOf("quarter").format("YYYY-MM-DD"),
          end: moment().quarter(1).endOf("quarter").format("YYYY-MM-DD"),
        },
        2: {
          start: moment().quarter(2).startOf("quarter").format("YYYY-MM-DD"),
          end: moment().quarter(2).endOf("quarter").format("YYYY-MM-DD"),
        },
        3: {
          start: moment().quarter(3).startOf("quarter").format("YYYY-MM-DD"),
          end: moment().quarter(3).endOf("quarter").format("YYYY-MM-DD"),
        },
        4: {
          start: moment().quarter(4).startOf("quarter").format("YYYY-MM-DD"),
          end: moment().quarter(4).endOf("quarter").format("YYYY-MM-DD"),
        },
      };

      let selectedQuarterStart = null;
      let selectedQuarterEnd = null;

      if (this.gameTargetQuarterSelection in quarters) {
        selectedQuarterStart = quarters[this.gameTargetQuarterSelection].start;
        selectedQuarterEnd = quarters[this.gameTargetQuarterSelection].end;
      }

      if (this.gameTargetQuarterSelection === "all") {
        this.playerGameTargetsDataFiltered = this.playerGameTargetsData;
      } else {
        this.playerGameTargetsDataFiltered = this.playerGameTargetsData.filter(
          (gameTarget) => {
            return (
              gameTarget.created_date >= selectedQuarterStart &&
              gameTarget.created_date <= selectedQuarterEnd
            );
          }
        );
      }
    },
    async updatePlayerIDPScores() {
      const data = {
        action: "updatePlayerIDPScores",
        ...this.playerIDPdata,
      };

      const responce = await PerformanceReview.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.getallQuatersPlayerIDPscores();
        this.getGameTargets();
        this.getIDPGoals();
        this.$bvModal.hide("playerIDPModal");
        this.$bvToast.toast("Player IDP score updated", {
          title: "Updated",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async savePlayerIDPScores() {
      if (!this.newPlayerIDPdata.hasOwnProperty("player_comments")) {
        this.newPlayerIDPdata.player_comments = "";
      }

      const data = {
        action: "savePlayerIDPScores",
        user_id: this.selectedUserValue,
        ...this.newPlayerIDPdata,
        coach_comments: "",
        created_date: moment().format("YYYY-MM-DD"),
      };

      const responce = await PerformanceReview.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.getallQuatersPlayerIDPscores();
        this.getGameTargets();
        this.getIDPGoals();
        this.$bvModal.hide("newplayerIDPModal");
        this.$bvToast.toast("Player IDP score saved", {
          title: "Saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async deleteIDPGoal() {
      const data = {
        action: "deleteIDPGoal",
        player_id: this.IDPselectedGoalData.id,
      };

      const responce = await PerformanceReview.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.getIDPGoals();
        this.$bvModal.hide("iDPGoalsModal");
        this.$bvToast.toast("Goal deleted", {
          title: "Deleted",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async editIDPGoal() {
      const data = {
        action: "editIDPGoal",
        ...this.IDPselectedGoalData,
      };

      const responce = await PerformanceReview.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.getIDPGoals();
        this.$bvModal.hide("iDPGoalsModal");
        this.$bvToast.toast("Player IDP score edited", {
          title: "edited",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async saveIDPGoal() {
      const data = {
        action: "saveIDPGoal",
        player_id: this.selectedUserValue,
        created_date: moment().format("YYYY-MM-DD"),
        ...this.IDPselectedGoalData,
      };

      const responce = await PerformanceReview.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.getIDPGoals();
        this.$bvModal.hide("iDPGoalsModal");
        this.$bvToast.toast("Player IDP score saved", {
          title: "Saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async getIDPGoals() {
      const data = {
        action: "getIDPGoals",
        user_id: this.selectedUserValue,
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };

      const responce = await PerformanceReview.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.IDPGoalsData = responce.data;
      }
    },
    async getallQuatersPlayerIDPscores() {
      const data = {
        action: "allQuatersPlayerIDPdata",
        user_id: this.selectedUserValue,
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };

      const responce = await PerformanceReview.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200 && responce.data !== false) {
        this.allQuatersPlayerIDPdata = responce.data;
      }
    },
    setCurrentQuaterPlayerIDPdata(quaterNumber) {
      const quarters = {
        1: {
          start: moment().quarter(1).startOf("quarter").format("YYYY-MM-DD"),
          end: moment().quarter(1).endOf("quarter").format("YYYY-MM-DD"),
        },
        2: {
          start: moment().quarter(2).startOf("quarter").format("YYYY-MM-DD"),
          end: moment().quarter(2).endOf("quarter").format("YYYY-MM-DD"),
        },
        3: {
          start: moment().quarter(3).startOf("quarter").format("YYYY-MM-DD"),
          end: moment().quarter(3).endOf("quarter").format("YYYY-MM-DD"),
        },
        4: {
          start: moment().quarter(4).startOf("quarter").format("YYYY-MM-DD"),
          end: moment().quarter(4).endOf("quarter").format("YYYY-MM-DD"),
        },
      };

      let selectedQuarterStart = null;
      let selectedQuarterEnd = null;
      selectedQuarterStart = quarters[quaterNumber].start;
      selectedQuarterEnd = quarters[quaterNumber].end;

      return this.allQuatersPlayerIDPdata.filter((iDP) => {
        return (
          iDP.created_date >= selectedQuarterStart &&
          iDP.created_date <= selectedQuarterEnd
        );
      });
    },
    async getPlayersIPDscores() {
      const data = {
        action: "getPlayersIPDscores",
        user_id: this.selectedUserValue,
      };

      const responce = await PerformanceReview.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200 && responce.data !== false) {
        //this.playerIDPdata = responce.data;
        this.getallQuatersPlayerIDPscores();
      }
    },
    populateIdp(idpData) {
      this.playerIDPdata = idpData;
    },
    editPlayerIDPGoal(goal) {
      this.IDPselectedGoalData = goal;
      this.showModal("iDPGoalsModal");
    },
    openMonthGoalModal(goal) {
      this.IDPselectedGoalData = {};
      this.showModal("iDPGoalsModal");
    },
    populateGoalKeeperIDPs() {
      this.technicalSelectOptions = [
        "Shot stopping – various distances / angles",
        "Good Footwork to adjust position",
        "Correct start position and body stance",
        "1v1’s – spreads body, delays striker, diverts shots",
        "Cross balls – catching / punching crosses",
        "Movement to provide angles of support",
        "Good decision making in build phase",
        "Distribution by hand accurately over various distances",
        "Distribution over long / short distances by foot",
        "Pass back clearances",
      ];

      this.tacticalSelectOptions = [
        "Adjusts pitch position in relation to the ball / team-mat",
        "Provides depth to recycle / enable switch of play",
        "Recognises when to play around, through or over",
        "Anticipates balls over the top – sweeper keeper",
        "Identifies overloads in build phase",
        "Reacts to opposition counterattack",
        "Good distribution to trigger counterattack opportunities",
      ];

      this.physicalSelectOptions = [
        "Agility/Change of Direction",
        "Co-ordination",
        "Balance",
        "Speed",
        "Endurance",
        "Strength (U13-U18) Upper body / Lower body",
        "Other   - Please specify",
      ];

      this.physcologicalSelectOptions = [
        "Intelligence – Game craft/knowledge",
        "Vision",
        "Leadership",
        "Decision making",
        "Composure",
        "Communication",
        "Coachability",
        "Drive/Desire",
        "Discipline",
        "Confidence",
      ];
    },
    openNewIDPModal() {
      if (this.selectedUserData.position === "Goalkeeper") {
        this.populateGoalKeeperIDPs();
      }
      this.showModal("newplayerIDPModal");
    },
    openIDPModal() {
      this.showModal("playerIDPModal");
    },
    async sendFBNotificationMessage(title, body, ageGroup, playerId) {
      const data = {
        action: "sendFBNotificationMessage",
        title: title,
        body: body,
        age_group: ageGroup,
        link: `${window.location.origin}/login`,
        player_id: playerId,
      };
      const responce = await Chat.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.$bvToast.toast("Notification sent", {
          title: "Notification sent",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    editHomeworkMdalCall(homework, type) {
      Object.assign(this.newHomeWorkData, {
        created_date: homework.created_date,
        gameId: homework.gameId,
        id: homework.id,
        information: homework.information,
        matchDate: homework.matchDate,
        opposition: homework.opposition,
        player_id: homework.player_id,
        title: homework.title,
        videos: homework.videos,
      });

      if (!homework.hasOwnProperty("homework")) {
        this.newHomeWorkData.videos = [];
      }
      this.filterHomeworkVideos(homework.id, type);
      this.showModal("addHomeworkModal");
      this.fixwizywigLinkButton();
    },
    addHomeWorkModalCall() {
      this.resetNewHomeWorkData();
      this.showModal("addHomeworkModal");
    },
    async getAllHomeworkForPlayer() {
      const data = {
        action: "getAllHomeworkForPlayer",
        player_id: this.selectedUserValue,
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };
      const response = await Homework.find(data).catch((error) => {
        console.log(error);
      });

      if (response.status === 200) {
        this.allHomeWorkData = response.data;
        this.getAllHomeworkForTeam();
      }
    },
    async getAllHomeworkForTeam() {
      const data = {
        action: "getAllHomeworkForTeam",
        teamId: this.selectedUserData.teamId,
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };
      const response = await Homework.find(data).catch((error) => {
        console.log(error);
      });

      if (response.status === 200) {
        this.allTeamWorkData = response.data;
      }
    },
    async editHomework() {
      const data = {
        ...this.newHomeWorkData,
        created_date: moment().format("YYYY-MM-DD"),
        player_id: this.selectedUserValue,
        action: "editHomework",
        document_id: this.newHomeWorkData.id,
      };

      const response = await Homework.find(data).catch((error) => {
        console.log(error);
      });

      if (response.status === 200) {
        this.getAllHomeworkForPlayer();
        this.populatePlayerVideoLinkData();
        this.populateTeamVideoLinkData();

        this.$bvModal.hide("addHomeworkModal");
        this.resetNewHomeWorkData();

        this.$bvToast.toast("Homework successfully updated.", {
          title: "Updated",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async saveHomework() {
      const data = {
        ...this.newHomeWorkData,
        created_date: moment().format("YYYY-MM-DD"),
        player_id: this.selectedUserValue,
        action: "saveHomework",
      };

      const response = await Homework.find(data).catch((error) => {
        console.log(error);
      });

      if (response.status === 200) {
        this.getAllHomeworkForPlayer();
        this.populatePlayerVideoLinkData();
        this.populateTeamVideoLinkData();

        this.$bvModal.hide("addHomeworkModal");
        this.resetNewHomeWorkData();

        this.$bvToast.toast("Homework successfully saved.", {
          title: "Saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });

        this.sendFBNotificationMessage(
          "Homework added",
          "You have a new homework item to review.",
          [],
          this.selectedUserValue
        );
      }
    },
    resetNewHomeWorkData() {
      this.newHomeWorkData = {
        title: "",
        information: "",
        videos: [
          {
            video_name: "",
            video_url: "",
            player_id: "",
            category: "",
          },
        ],
      };
    },
    filterHomeworkVideos(id, type) {
      let result = [];

      if (type === "teamVideos") {
        result = this[type].filter((video) => video.document_id === id);
      } else {
        result = this[type].videos.filter((video) => video.document_id === id);
      }

      this.newHomeWorkData.videos = result;
    },
    viewHomework(homeworkData, type) {
      this.newHomeWorkData = homeworkData;
      this.filterHomeworkVideos(homeworkData.id, type);
      this.$bvModal.show("viewHomeworkModal");
    },
    async deleteHomework(id) {
      const data = {
        id: id,
        action: "deleteHomework",
      };

      const response = await Homework.find(data).catch((error) => {
        console.log(error);
      });

      if (response.status === 200) {
        this.getAllHomeworkForPlayer();

        this.$bvToast.toast("Homework successfully deleted.", {
          title: "Deleted",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async removeUploadedDocument(id, name) {
      const document = this.uploadedDocuments.find(
        (document) => document.id === id
      );
      const userData = {
        action: "removeDocumentUpload",
        id: document.id,
        name: document.name,
        user_id: this.selectedUserData.id,
        injury_Id: newInjuryInformation.id,
      };
      const response = await Documents.find(userData).catch((error) => {
        console.log(error);
      });

      if (response.status === 200) {
        this.$bvModal.hide("editPlayerInjuryModal");
        this.getDocumentUploadsCall();

        this.$bvToast.toast("Document successfully deleted.", {
          title: "Deleted",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    getFileURL(fileId) {
      const document = this.uploadedDocuments.find(
        (document) => document.id === fileId
      );
      return document ? document.url : null;
    },
    showDownloadFile(fileId) {
      this.downloadURL = this.getFileURL(fileId);
      this.$bvModal.show("viewDownloadPDF");
    },
    openDocumentUpLoadModal(injuryData) {
      this.newInjuryInformation = injuryData;
      this.$bvModal.show("upLoadModal");
    },
    async getDocumentUploadsCall() {
      const userData = {
        action: "getDocumentUploadsByCategoryandUser",
        user_id: this.selectedUserData.id,
      };
      const response = await Documents.find(userData).catch((error) => {
        console.log(error);
      });

      if (response.status === 200) {
        this.uploadedDocuments = response.data;
        this.getPlayerInjuryInformation();
        this.getPlayerInjuryReport();
        this.$bvModal.hide("upLoadModal");
      }
    },
    populateSelectedGameFullPerformanceReportsForUser(gameId) {
      const usersReport = this.seasonsgamePerformanceReportsForUser.find(
        (report) => report.gameId === gameId
      );
      const coachReport =
        this.coachGamePerformanceData.find(
          (report) => report.gameId === gameId
        ) || [];

      this.selectedGameFullPerformanceReportsForUser = {
        id: usersReport.id,
        assists: usersReport.assists,
        card: usersReport.card,
        clean_sheet: usersReport.clean_sheet,
        goals: usersReport.goals,
        matchDate: usersReport.matchDate,
        timeOnPitch: usersReport.timeOnPitch,
        opposition: usersReport.opposition,
        player_self_rating: usersReport.player_self_rating,
        postion_played: usersReport.postion_played,
        postion_played_two: usersReport.postion_played_two,
        technically_improvements: usersReport.technically_improvements,
        technically_positives: usersReport.technically_positives,
        tactility_improvements: usersReport.tactility_improvements,
        tactility_positives: usersReport.tactility_positives,
        physicality_improvements: usersReport.physicality_improvements,
        physicality_positives: usersReport.physicality_positives,
        mentally_improvements: usersReport.mentally_improvements,
        mentally_positives: usersReport.mentally_positives,
        pdps_improvements: usersReport.pdps_improvements,
        pdps_positives: usersReport.pdps_positives,
        overall_comments: usersReport.overall_comments,
        user_id: usersReport.user_id,
        coachReportId: coachReport.id,
        coach_name: coachReport.name || "",
        coachId: coachReport.coachId,
        game_comments: coachReport.game_comments || "",
        game_improvements: coachReport.game_improvements || "",
        player_rating: coachReport.player_rating || "",
        userImage: coachReport.userImage || "",
        gameId: usersReport.gameId,
        created_date: usersReport.created_date,
        gameType: usersReport.gameType,
      };

      this.loadPlayerMinsPerformanceDataForDate(gameId, usersReport.matchDate);
    },
    showTrendsGraph() {
      this.loadAllPlayerReporsData();
    },
    async loadAllPlayerReporsData() {
      const data = {
        action: "loadAllPlayerReporsData",
        player_id: this.selectedUserData.id,
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };
      const responce = await PlayerPhysicalPerformance.find(data).catch(
        (error) => {
          console.log(error);
        }
      );
      if (responce.status === 200) {
        this.allPlayerLoadReporsData = responce.data;
        this.barChartDataForLoad();
        this.$bvModal.show("playerLoadReportsModal");
      }
    },
    async checkIfMinsStatsDataExists(matchDate, gameType, responceType) {
      const data = {
        action: "getPlayerMinsPerformanceDataForDate",
        session_date: matchDate,
        player_id: this.selectedUserValue,
      };
      const responce = await PlayerPhysicalPerformance.find(data).catch(
        (error) => {
          console.log(error);
        }
      );

      responce.data !== false
        ? this.editDayMinsStatsData(
          matchDate,
          gameType,
          responceType,
          responce.data.id
        )
        : this.addDayMinsStatsData(matchDate, gameType, responceType);
    },
    async addDayMinsStatsData() {
      const data = {
        action: "addDayMinsStatsData",
        data: this.selectedDayPlayerData,
      };
      const responce = await PlayerPhysicalPerformance.find(data).catch(
        (error) => {
          console.log(error);
        }
      );
      if (responce.status === 200) {
        this.loadPlayerMinsPerformanceData();
        this.$bvModal.hide("addDayStatsBreakDown");
        this.$bvToast.toast("Player day mins stats added.", {
          title: "saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    getDayMinsData() {
      return this.pastGameData.find(
        (game) => game.id === this.newGamePerformanceData.gameId
      );
    },
    async editDayMinsOnlyData() {
      const selectedGameData = this.getDayMinsData();
      const date = selectedGameData.kickOffTime.substring(0, 10);
      const minsData = this.playerDailyMins.find(
        (mins) => mins.session_date === date
      );

      minsData.session_mins = Number(this.newGamePerformanceData.timeOnPitch);
      this.selectedDayPlayerData = minsData;
      this.editDayMinsStatsData();
    },
    async editDayMinsStatsData() {
      const data = {
        action: "editDayMinsStatsData",
        ...this.selectedDayPlayerData,
      };
      const responce = await PlayerPhysicalPerformance.find(data).catch(
        (error) => {
          console.log(error);
        }
      );
      if (responce.status === 200) {
        this.loadPlayerMinsPerformanceData();
        this.$bvModal.hide("addDayStatsBreakDown");
        this.$bvToast.toast("Player day mins stats updated.", {
          title: "saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    openaddDayStatsDataModal(weekDay, edit) {
      if (edit !== true) {
        this.selectedDayPlayerData = {
          total_absolute_high_speed_distance: 0,
          id: 0,
          maximum_velocity: 0,
          player_id: this.selectedUserValue,
          playing_down_level: 0,
          playing_up_level: 0,
          session_date: dayjs(weekDay.date).format("YYYY-MM-DD"),
          session_distance: 0,
          session_mins: 0,
          session_type: "",
          max_vel_max: 0,
          accelerations_total_efforts: 0,
          decelerations_total_efforts: 0,
          meterage_per_minute: 0,
        };
        this.editDayPlayerData = false;
      } else {
        this.editDayPlayerData = true;
        this.selectedDayPlayerData = weekDay;
      }
      this.$bvModal.show("addDayStatsBreakDown");
    },
    async importUploadedCSVDataFile() {
      this.gpsUploadDataTypeSelected === "catapult"
        ? this.saveCSVCatapultData()
        : this.saveCSVPlayermakerData();
    },
    csvErrorMessage() {
      this.dataCSVErrorMessage =
        "Please verify that your CSV upload template is correct and that you have selected the appropriate upload option.";
      setTimeout(() => (this.dataCSVErrorMessage = null), 2000);
    },
    checkCSVdata() {
      return this.gpsUploadDataTypeSelected === "catapult"
        ? this.csvDataImported[0].hasOwnProperty("Accelerations Total Efforts")
        : this.csvDataImported[0].hasOwnProperty("Tag");
    },
    fixDateForImport(date) {
      return date.substring(0, 10);
    },
    async saveCSVPlayermakerData() {
      const dataIsGood = this.checkCSVdata();
      if (dataIsGood === true) {
        this.csvDataImported[0].Date = this.fixDateForImport(
          this.csvDataImported[0].Date
        );
        const data = {
          action: "importUploadedCSVDataForPlayMakerFile",
          data: this.csvDataImported,
        };
        const responce = await PlayerPhysicalPerformance.find(data).catch(
          (error) => {
            console.log(error);
          }
        );
        if (responce.status === 200) {
          this.loadPlayerMinsPerformanceData();
          this.$bvModal.hide("uploadPlayerMinsCSV");

          this.$bvToast.toast("CSV PlayMaker data added.", {
            title: "saved",
            toaster: "b-toaster-bottom-right",
            solid: true,
            appendToast: true,
            variant: "success",
          });
        }
      } else {
        this.csvErrorMessage();
      }
    },
    async saveCSVCatapultData() {
      const dataIsGood = this.checkCSVdata();

      if (dataIsGood === true) {
        const data = {
          action: "importUploadedCSVDataFile",
          data: this.csvDataImported,
        };
        const responce = await PlayerPhysicalPerformance.find(data).catch(
          (error) => {
            console.log(error);
          }
        );
        if (responce.status === 200) {
          this.loadPlayerMinsPerformanceData();
          this.$bvModal.hide("uploadPlayerMinsCSV");

          this.$bvToast.toast("CSV Catapult data added.", {
            title: "saved",
            toaster: "b-toaster-bottom-right",
            solid: true,
            appendToast: true,
            variant: "success",
          });
        }
      } else {
        this.csvErrorMessage();
      }
    },
    openImportCSVModal() {
      this.$bvModal.show("uploadPlayerMinsCSV");
    },
    async importCSV(e) {
      const file = e.target.files.item(0);
      const csv = await file.text();
      var lines = csv.split("\n");

      var result = [];

      var headers = lines[0].split(",");

      for (var i = 1; i < lines.length; i++) {
        var obj = {};
        var currentline = lines[i].split(",");

        for (var j = 0; j < headers.length; j++) {
          let str1 = currentline[j].replace(/\n|\r/g, "");
          let str2 = headers[j].replace(/\n|\r/g, "");
          obj[str2] = str1;
        }

        result.push(obj);
      }
      this.csvDataImported = result;
    },
    populatePlayerWellnesData() {
      this.thisSeasionsPlayerMoodData.map((dayData) => {
        this.wellnessData.weekDays.map((data, index) => {
          if (dayjs(data.date).format("YYYY-MM-DD") === dayData.date) {
            this.wellnessData.weekDays[index].id = dayData.id;
            this.wellnessData.weekDays[index].player_id = dayData.player_id;
            this.wellnessData.weekDays[index].fatigue = dayData.fatigue;
            this.wellnessData.weekDays[index].happyness = dayData.happyness;
            this.wellnessData.weekDays[index].muscle_soreness =
              dayData.muscle_soreness;
            this.wellnessData.weekDays[index].sleep = dayData.sleep;
            this.wellnessData.weekDays[index].stress = dayData.stress;
          }
        });
      });
    },
    populatePlayerMinsPerformanceData() {
      this.playerDailyMins.forEach((dayData) => {
        let addSession = true;

        // Find matching index by date in weekDays array
        const index = this.minsData.weekDays.findIndex(
          (data) =>
            dayjs(data.date).format("YYYY-MM-DD") === dayData.session_date
        );

        if (index !== -1) {
          const isMultipleSessions =
            this.playerDailyMins.filter(
              (day) => day.session_date === dayData.session_date
            ).length > 1;

          // Check if session_type allows adding data
          if (isMultipleSessions && dayData.session_type !== "Session") {
            addSession = false;
          }

          if (addSession) {
            Object.assign(this.minsData.weekDays[index], {
              id: dayData.id,
              player_id: dayData.player_id,
              minsTotal: dayData.session_mins,
              ...dayData, // Merge all properties directly from dayData
            });
          }
        }
      });

      this.setPlayerMinsPerformanceDataWeeklyTotals();
    },
    resetPlayerMinsTotals() {
      this.playerDailyMinsWeek1Total = 0;
      this.playerDailyMinsWeek2Total = 0;
      this.playerDailyMinsWeek3Total = 0;
      this.playerDailyMinsWeek4Total = 0;
      this.playerDailyMinsWeek5Total = 0;
    },
    setPlayerMinsPerformanceDataWeeklyTotals() {
      this.resetPlayerMinsTotals();
      let year = this.minsData.year;
      let month = this.minsData.month + 1;
      const firstDay = dayjs(`${year}-${month}`).startOf("month").format("d");

      let startDay;
      if (firstDay == 1) {
        startDay = dayjs(`${year}-${month}`).format("YYYY-MM-DD");
      } else {
        const diffDays = (8 - firstDay) % 7;
        startDay = dayjs(`${year}-${month}`)
          .startOf("month")
          .add(diffDays, "days")
          .format("YYYY-MM-DD");
      }

      const lastDay = dayjs(`${year}-${month}`).endOf("month").format("d");

      let endDay;
      if (lastDay == 0) {
        endDay = dayjs(`${year}-${month}`).endOf("month").format("YYYY-MM-DD");
      } else {
        const lastDiffDays = 6 - lastDay;
        endDay = dayjs(`${year}-${month}`)
          .startOf("month")
          .add(1, "month")
          .add(lastDiffDays, "days")
          .format("YYYY-MM-DD");
      }

      const weeks = (dayjs(endDay).diff(dayjs(startDay), "days") + 1) / 7;

      const weekList = [];
      for (let i = 1; i <= weeks; i++) {
        weekList.push({
          weekName: `${i}`,
          num: i,
          startDay,
          endDay: dayjs(startDay).add(6, "days").format("YYYY-MM-DD"),
          range: `${dayjs(startDay).format("MM-DD")}~${dayjs(startDay)
            .add(6, "days")
            .format("MM-DD")}`,
        });
        startDay = dayjs(startDay).add(7, "days").format("YYYY-MM-DD");
      }
      weekList.extraDays = [];

      weekList.map((weekData) => {
        if (weekData.weekName === "1") {
          if (dayjs(weekData.startDay).format("D") !== "1") {
            const times = Number(dayjs(weekData.startDay).format("D")) - 1;
            let dayCount = 1;

            for (let i = 0; i < times; i++) {
              weekList.extraDays.push({
                startDay: dayjs(weekData.startDay)
                  .subtract(dayCount, "day")
                  .format("YYYY-MM-DD"),
              });
              dayCount = dayCount + 1;
            }
          }
        }
      });

      this.playerDailyMins.map((data) => {
        // week 1
        if (
          dayjs(data.session_date, "YYYY-MM-DD") >=
          dayjs(weekList[0].startDay, "YYYY-MM-DD") &&
          dayjs(data.session_date, "YYYY-MM-DD") <=
          dayjs(weekList[0].endDay, "YYYY-MM-DD")
        ) {
          this.playerDailyMinsWeek1Total =
            this.playerDailyMinsWeek1Total + data.session_mins;
        }

        // week 2
        if (
          dayjs(data.session_date, "YYYY-MM-DD") >=
          dayjs(weekList[1].startDay, "YYYY-MM-DD") &&
          dayjs(data.session_date, "YYYY-MM-DD") <=
          dayjs(weekList[1].endDay, "YYYY-MM-DD")
        ) {
          this.playerDailyMinsWeek2Total =
            this.playerDailyMinsWeek2Total + data.session_mins;
        }

        // week 3
        if (
          dayjs(data.session_date, "YYYY-MM-DD") >=
          dayjs(weekList[2].startDay, "YYYY-MM-DD") &&
          dayjs(data.session_date, "YYYY-MM-DD") <=
          dayjs(weekList[2].endDay, "YYYY-MM-DD")
        ) {
          this.playerDailyMinsWeek3Total =
            this.playerDailyMinsWeek3Total + data.session_mins;
        }

        // week 4
        if (
          dayjs(data.session_date, "YYYY-MM-DD") >=
          dayjs(weekList[3].startDay, "YYYY-MM-DD") &&
          dayjs(data.session_date, "YYYY-MM-DD") <=
          dayjs(weekList[3].endDay, "YYYY-MM-DD")
        ) {
          this.playerDailyMinsWeek4Total =
            this.playerDailyMinsWeek4Total + data.session_mins;
        }

        if (weekList.length === 5) {
          // week 5
          if (
            dayjs(data.session_date, "YYYY-MM-DD") >=
            dayjs(weekList[4].startDay, "YYYY-MM-DD") &&
            dayjs(data.session_date, "YYYY-MM-DD") <=
            dayjs(weekList[4].endDay, "YYYY-MM-DD")
          ) {
            if (
              dayjs(weekList[4].endDay, "MM") === dayjs(data.session_date, "MM")
            ) {
              this.playerDailyMinsWeek5Total =
                this.playerDailyMinsWeek5Total + data.session_mins;
            }
          }
        }

        weekList.extraDays.map((listData) => {
          if (data.session_date === listData.startDay) {
            this.playerDailyMinsWeek1Total =
              this.playerDailyMinsWeek1Total + data.session_mins;
          }
        });
      });
    },
    async getPlayerMinsPerformanceDataForLastGameSessionPlayMaker() {
      const data = {
        action: "getPlayerMinsPerformanceDataForLastGameSessionPlayMaker",
        player_id: this.selectedUserValue,
      };

      const responce = await PlayerPhysicalPerformance.find(data).catch(
        (error) => {
          console.log(error);
        }
      );

      if (responce.status === 200) {
        this.lastGameSessionData = responce.data;
      }
    },
    async getPlayerMinsPerformanceDataForLastTrainingSessionPlayMaker() {
      const data = {
        action: "getPlayerMinsPerformanceDataForLastTrainingSessionPlayMaker",
        player_id: this.selectedUserValue,
      };

      const responce = await PlayerPhysicalPerformance.find(data).catch(
        (error) => {
          console.log(error);
        }
      );

      if (responce.status === 200) {
        this.lastTrainingSessionData = responce.data;
      }
    },
    async getPlayerMinsPerformanceDataForLastTrainingSession() {
      const data = {
        action: "getPlayerMinsPerformanceDataForLastTrainingSession",
        player_id: this.selectedUserValue,
      };

      const responce = await PlayerPhysicalPerformance.find(data).catch(
        (error) => {
          console.log(error);
        }
      );

      if (responce.status === 200) {
        this.lastTrainingSessionData = responce.data;
      }
    },
    async getPlayerMinsPerformanceDataForLastGameSession() {
      const data = {
        action: "getPlayerMinsPerformanceDataForLastGameSession",
        player_id: this.selectedUserValue,
      };

      const responce = await PlayerPhysicalPerformance.find(data).catch(
        (error) => {
          console.log(error);
        }
      );

      if (responce.status === 200) {
        this.lastGameSessionData = responce.data;
      }
    },
    async loadPlayerMinsPerformanceDataForDate(gameId, matchDate) {
      const data = {
        action: "getPlayerMinsPerformanceDataForDate",
        player_id: this.selectedUserValue,
        session_date: matchDate,
      };

      const responce = await PlayerPhysicalPerformance.find(data).catch(
        (error) => {
          console.log(error);
        }
      );

      if (responce.status === 200) {
        this.selectedGameFullPerformanceReportGPSdataForUser = responce.data;
      }
    },
    getAverage(array) {
      return array.reduce((sum, item) => sum + item, 0) / array.length;
    },
    getAverageFromNumber(data, metric, type) {
      const allNumbers = data
        .filter((item) => item.session_type === type)
        .map((item) => Number(item[metric]));

      return allNumbers.length ? this.getAverage(allNumbers) : 0;
    },
    getPBNumber(data, metric, type) {
      const allNumbers = data
        .filter((item) => item.session_type === type)
        .map((item) => item[metric]);
      return allNumbers.length ? Math.max(...allNumbers) : 0;
    },
    personalBestAndAverages(data, typeOfData) {
      let metrics = [];

      if (typeOfData === "Playmaker") {
        metrics = [
          "phase_duration_min",
          "distance_covered",
          "work_rate",
          "total_touches",
          "left_leg_touches",
          "right_leg_touches",
          "releases",
          "releases_left",
          "releases_right",
          "top_speed",
        ];
      } else {
        metrics = [
          "accelerations_total_efforts",
          "decelerations_total_efforts",
          "max_vel_max",
          "maximum_velocity",
          "meterage_per_minute",
          "session_distance",
          "total_absolute_high_speed_distance",
        ];
      }

      const generateMetrics = (type) => {
        return metrics.reduce((acc, metric) => {
          acc[metric] = this.getAverageFromNumber(data, metric, type);
          return acc;
        }, {});
      };

      const generatePBMetrics = (type) => {
        return metrics.reduce((acc, metric) => {
          acc[metric] = this.getPBNumber(data, metric, type);
          return acc;
        }, {});
      };

      if (typeOfData === "Playmaker") {
        this.metricsTrainingTab = "training-phase_duration_min-tab";
        this.metricsGameTab = "game-phase_duration_min-tab";
        this.playerMakerPersonalBestAndAverages = {
          games: {
            ...generateMetrics("Session"),
            pb: generatePBMetrics("Session"),
          },
          training: {
            ...generateMetrics("Training"),
            pb: generatePBMetrics("Training"),
          },
        };
      } else {
        this.playerPersonalBestAndAverages = {
          games: {
            ...generateMetrics("Session"),
            pb: generatePBMetrics("Session"),
          },
          training: {
            ...generateMetrics("Training"),
            pb: generatePBMetrics("Training"),
          },
        };
      }
    },
    async loadPlayerMinsPerformanceData() {
      if (this.under16 === true) {
        const data = {
          action: "getPlayerMinsPerformancePlayeMakerData",
          player_id: this.selectedUserValue,
          season_start_date: localStorage.getItem(
            "currentPlayingseasonStartDate"
          ),
          season_end_date: this.extendSeasonEndDateToFullYear(),
        };
        const responce = await PlayerPhysicalPerformance.find(data).catch(
          (error) => {
            console.log(error);
          }
        );

        if (responce.status === 200) {
          this.playerPlayMakerGpsData = responce.data;
          if (this.playerPlayMakerGpsData.length > 0) {
            this.personalBestAndAverages(
              this.playerPlayMakerGpsData,
              "Playmaker"
            );
          }
        }
      }

      const data = {
        action: "getPlayerMinsPerformanceData",
        player_id: this.selectedUserValue,
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };
      const responce = await PlayerPhysicalPerformance.find(data).catch(
        (error) => {
          console.log(error);
        }
      );

      if (responce.status === 200) {
        this.playerDailyMins = responce.data;
        if (this.playerDailyMins.length > 0) {
          this.personalBestAndAverages(this.playerDailyMins, "Catapult");
        }
        this.populatePlayerMinsPerformanceData();
      }
    },
    resetPersonalBestAndAveragesData() {
      this.playerMakerPersonalBestAndAverages = {};
      this.playerPersonalBestAndAverages = {};
    },
    showDayStatsBreakDown(day) {
      this.selectedDayPlayerData = day;
      this.$bvModal.show("viewDayStatsBreakDown");
    },
    changeMonth(value, dataType) {
      let changeNumber = 0;
      let yearNumber = this[dataType].year;

      if (value === 1) {
        changeNumber = Number(dayjs().month(this[dataType].month).format("M"));
        if (this[dataType].month === 11) {
          changeNumber = 0;
          yearNumber = dayjs().year(yearNumber).add(1, "year").format("YYYY");
        }
      } else {
        changeNumber = Number(
          dayjs().month(this[dataType].month).subtract(2, "month").format("M")
        );
        if (this[dataType].month === 1) {
          changeNumber = 0;
        }

        if (this[dataType].month === 0) {
          yearNumber = dayjs()
            .year(yearNumber)
            .subtract(1, "year")
            .format("YYYY");
        }
      }

      this[dataType].weekDays = this.populateMinsCal(
        Number(yearNumber),
        Number(changeNumber),
        dataType
      );
      dataType === "minsData"
        ? this.loadPlayerMinsPerformanceData()
        : this.getThisSeasionsPlayerMoodData();
    },
    getMonthName(dateNumber) {
      return dayjs().month(dateNumber).format("MMMM");
    },
    getDayFromDate(date) {
      return dayjs(date).format("ddd D");
    },
    populateMinsCal(year, month, dataType) {
      this[dataType].year = year;
      this[dataType].month = month;
      // month is zero-based (0 for January, 1 for February, etc.)
      let date = new Date(year, month, 1);
      let dates = [];

      while (date.getMonth() === month) {
        const entry = {
          date: new Date(date),
          id: null,
        };

        if (dataType === "minsData") {
          entry.minsTotal = null;
        }

        dates.push(entry);
        date.setDate(date.getDate() + 1);
      }

      return dates;
    },
    editAcademyReportModal() {
      this.$bvModal.show("editAcademyReportModal");
    },
    async updateTheAcademyReport(id) {
      id !== null ? this.editAcademyReport() : this.saveAcademyReport();
    },
    async editAcademyReport() {
      const data = {
        action: "editAcademyReport",
        academyReport: this.academyReport,
      };
      const responce = await AcademyReport.find(data).catch((error) => {
        console.log(error);
      });
      if (responce.status === 200) {
        this.populateAcademyReport();
      }
      this.$bvModal.hide("editAcademyReportModal");
    },
    async saveAcademyReport() {
      const data = {
        action: "saveAcademyReport",
        academyReport: this.academyReport,
      };
      const responce = await AcademyReport.find(data).catch((error) => {
        console.log(error);
      });
      if (responce.status === 200) {
        this.populateAcademyReport();
      }
    },
    async populateAcademyReport() {
      const data = {
        action: "populateAcademyReport",
        userId: this.selectedUserValue,
      };
      const responce = await AcademyReport.find(data).catch((error) => {
        console.log(error);
      });
      if (responce.status === 200 && responce.data.id !== undefined) {
        this.academyReport = responce.data;
      }
    },
    async populateTeamVideoLinkData() {
      const data = {
        action: "populatePlayerVideoLinkData",
        teamId: this.selectedTeamId,
      };
      const responce = await PlayerCv.find(data).catch((error) => {
        console.log(error);
      });
      if (responce.status === 200) {
        this.teamVideos = responce.data;
      }
    },
    async populatePlayerVideoLinkData() {
      const data = {
        action: "populatePlayerVideoLinkData",
        userId: this.selectedUserValue,
      };
      const responce = await PlayerCv.find(data).catch((error) => {
        console.log(error);
      });
      if (responce.status === 200) {
        this.playerCvData.videos = responce.data;
      }
    },
    async populatePlayerInterestsHobbiesData() {
      const data = {
        action: "populatePlayerInterestsHobbiesData",
        userId: this.selectedUserValue,
      };
      const responce = await PlayerCv.find(data).catch((error) => {
        console.log(error);
      });
      if (responce.status === 200) {
        this.playerCvData.interests_hobbies = responce.data;
      }
    },

    async populatePlayerEducationData() {
      const data = {
        action: "populatePlayerEducationData",
        userId: this.selectedUserValue,
      };
      const responce = await PlayerCv.find(data).catch((error) => {
        console.log(error);
      });
      if (responce.status === 200) {
        this.playerCvData.education = responce.data;
      }
    },

    async editPlayerVideoLink() {
      const data = {
        action: "editPlayerVideoLink",
        playerVideo: this.selectedPlayerVideoData,
      };
      const responce = await PlayerCv.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.populatePlayerVideoLinkData();
        this.populateTeamVideoLinkData();
        this.$bvToast.toast("Player video updated.", {
          title: "saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });

        this.$bvModal.hide("playerVideosModal");
      }
    },
    async editPlayingExperence() {
      this.updateDatesForDB(
        "selectedPlayingExperenceData",
        "start_date_at_club"
      );
      this.updateDatesForDB("selectedPlayingExperenceData", "end_date_at_club");
      const data = {
        action: "editPlayingExperence",
        playingExperence: this.selectedPlayingExperenceData,
      };
      const responce = await PlayerCv.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.populatePlayerPlayingExperenceData();
        this.$bvToast.toast("Player Playing Experence updated.", {
          title: "saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });

        this.$bvModal.hide("playerCVPlayingExperenceModal");
      }
    },
    async editPlayerInterestsHobbies() {
      const data = {
        action: "editPlayerInterestsHobbies",
        playerInterestsHobbies: this.selectedPlayerInterestsHobbiesData,
      };
      const responce = await PlayerCv.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.populatePlayerPlayingExperenceData();
        this.$bvToast.toast("Player Interests and Hobbies updated.", {
          title: "saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });

        this.$bvModal.hide("openPlayerInterestsHobbiesModal");
      }
    },
    async editPlayerEducation() {
      this.updateDatesForDB("selectedPlayerEducationData", "start_date");
      this.updateDatesForDB("selectedPlayerEducationData", "end_date");
      const data = {
        action: "editPlayerEducation",
        playerEducation: this.selectedPlayerEducationData,
      };
      const responce = await PlayerCv.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.populatePlayerPlayingExperenceData();
        this.$bvToast.toast("Player Education updated.", {
          title: "saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });

        this.$bvModal.hide("openPlayerEducationModal");
      }
    },
    async deletePlayerEducation(id) {
      const data = {
        action: "deletePlayerEducation",
        id: id,
      };
      const responce = await PlayerCv.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.populatePlayerEducationData();
        this.$bvToast.toast("Player Education deleted.", {
          title: "saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async deletePlayerVideoLink(id) {
      const data = {
        action: "deletePlayerVideoLink",
        id: id,
      };
      const responce = await PlayerCv.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.populatePlayerVideoLinkData();
        this.populateTeamVideoLinkData();
        this.$bvToast.toast("Player video  deleted.", {
          title: "saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async deletePlayerInterestsHobbies(id) {
      const data = {
        action: "deletePlayerInterestsHobbies",
        id: id,
      };
      const responce = await PlayerCv.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.populatePlayerInterestsHobbiesData();
        this.$bvToast.toast("Player Interests and Hobbies deleted.", {
          title: "saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async deletePlayingExperence(id) {
      const data = {
        action: "deletePlayingExperence",
        id: id,
      };
      const responce = await PlayerCv.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.populatePlayerPlayingExperenceData();
        this.$bvToast.toast("Player Playing Experence deleted.", {
          title: "saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async populatePlayerPlayingExperenceData() {
      const data = {
        action: "populatePlayerPlayingExperenceData",
        userId: this.selectedUserValue,
      };
      const responce = await PlayerCv.find(data).catch((error) => {
        console.log(error);
      });
      if (responce.status === 200) {
        this.playerCvData.playingExperence = responce.data;
      }
    },
    updateDatesForDB(objectName, itemName) {
      if (this[objectName][itemName] !== null) {
        this[objectName][itemName] = this.formatDateForDB(
          this[objectName][itemName]
        );
      }
    },
    populatePlayerCV(data) {
      this.playerCvData.personal_profile = data.personal_profile;
      this.populatePlayerPlayingExperenceData();
      this.populatePlayerEducationData();
      this.populatePlayerInterestsHobbiesData();
      this.populatePlayerVideoLinkData();
      this.populateTeamVideoLinkData();
    },
    async savePlayerVideoLink() {
      this.selectedPlayerVideoData.player_id = this.selectedUserData.id;

      const data = {
        playerVideo: this.selectedPlayerVideoData,
        action: "savePlayerVideoLink",
      };
      const responce = await PlayerCv.find(data).catch((error) => {
        console.log(error);
      });
      if (responce.status === 200) {
        this.populatePlayerVideoLinkData();
        this.populateTeamVideoLinkData();
        this.$bvToast.toast("Player video saved.", {
          title: "saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });

        this.$bvModal.hide("playerVideosModal");
      }
    },
    async saveInterestsHobbies() {
      this.selectedPlayerInterestsHobbiesData.player_id =
        this.selectedUserData.id;

      const data = {
        playerInterestsHobbies: this.selectedPlayerInterestsHobbiesData,
        action: "savePlayerInterestsHobbies",
      };
      const responce = await PlayerCv.find(data).catch((error) => {
        console.log(error);
      });
      if (responce.status === 200) {
        this.populatePlayerInterestsHobbiesData();
        this.$bvToast.toast("Player Interests Hobbies saved.", {
          title: "saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });

        this.$bvModal.hide("openPlayerInterestsHobbiesModal");
      }
    },
    async savePlayerEducation() {
      this.selectedPlayerEducationData.player_id = this.selectedUserData.id;
      this.updateDatesForDB("selectedPlayerEducationData", "start_date");
      this.updateDatesForDB("selectedPlayerEducationData", "end_date");

      const data = {
        playerEducation: this.selectedPlayerEducationData,
        action: "savePlayerEducation",
      };
      const responce = await PlayerCv.find(data).catch((error) => {
        console.log(error);
      });
      if (responce.status === 200) {
        this.populatePlayerEducationData();
        this.$bvToast.toast("Player Education saved.", {
          title: "saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });

        this.$bvModal.hide("openPlayerEducationModal");
      }
    },
    async savePlayerPlayingExperence() {
      this.updateDatesForDB(
        "selectedPlayingExperenceData",
        "start_date_at_club"
      );
      this.updateDatesForDB("selectedPlayingExperenceData", "end_date_at_club");
      this.selectedPlayingExperenceData.player_id = this.selectedUserData.id;

      const data = {
        playingExperence: this.selectedPlayingExperenceData,
        action: "savePlayerPlayingExperence",
      };
      const responce = await PlayerCv.find(data).catch((error) => {
        console.log(error);
      });
      if (responce.status === 200) {
        this.populatePlayerPlayingExperenceData();
        this.$bvToast.toast("Player Playing Experence saved.", {
          title: "saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });

        this.$bvModal.hide("playerCVPlayingExperenceModal");
      }
    },
    async savePersonalProfile() {
      const data = {
        personalProfileData: this.playerCvData.personal_profile,
        userId: this.selectedUserData.id,
        action: "savePersonalProfile",
      };
      const responce = await Users.find(data).catch((error) => {
        console.log(error);
      });
      if (responce.status === 200) {
        this.$bvToast.toast("Player Personal Profile saved.", {
          title: "saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });

        this.$bvModal.hide("playerCVpersonalProfileModal");
      }
    },
    playerPlayerVideosClose() {
      this.selectedPlayerVideoData = {};
      this.selectedPlayerVideoDataEdit = false;
    },
    playerInterestsHobbiesClose() {
      this.selectedPlayerInterestsHobbiesData = {};
      this.selectedPlayerInterestsHobbiesDataEdit = false;
    },
    playerEducationModalClose() {
      this.selectedPlayerEducationData = {};
      this.selectedPlayerEducationDataEdit = false;
    },
    playerCVPlayingExperenceModalClose() {
      this.selectedPlayingExperenceData = {};
      this.selectedPlayingExperenceEdit = false;
    },

    openPlayerVideosModal(index) {
      this.playerPlayerVideosClose();
      if (index !== undefined) {
        this.selectedPlayerVideoData = this.playerCvData.videos[index];
        this.selectedPlayerVideoDataEdit = true;
      }
      this.$bvModal.show("playerVideosModal");
    },
    openPlayerInterestsHobbiesModal(index) {
      this.playerInterestsHobbiesClose();
      if (index !== undefined) {
        this.selectedPlayerInterestsHobbiesData =
          this.playerCvData.interests_hobbies[index];
        this.selectedPlayerInterestsHobbiesDataEdit = true;
      }
      this.$bvModal.show("openPlayerInterestsHobbiesModal");
    },
    openPlayerEducationModal(index) {
      this.playerEducationModalClose();
      if (index !== undefined) {
        this.selectedPlayerEducationData = this.playerCvData.education[index];
        this.selectedPlayerEducationDataEdit = true;
      }
      this.$bvModal.show("openPlayerEducationModal");
    },
    openPlayerCVPlayingExperenceModal(index) {
      this.playerCVPlayingExperenceModalClose();
      if (index !== undefined) {
        this.selectedPlayingExperenceData =
          this.playerCvData.playingExperence[index];
        this.selectedPlayingExperenceEdit = true;
      }
      this.$bvModal.show("playerCVPlayingExperenceModal");
    },
    openPlayerCVpersonalProfileModal() {
      this.$bvModal.show("playerCVpersonalProfileModal");
    },
    addPlayingExperence() {
      const playingExperenceData = {
        club: "",
        start_date_at_club: null,
        end_date_at_club: null,
        playing_info: "",
        player_id: this.selectedUserData.id,
      };

      this.playerCvData.playingExperence.push(playingExperenceData);
    },
    poupulateUserData(userData) {
      this.$store.dispatch(USER_POPULATE, { userData });
      Storage.setJSON("selectedClientId", userData.id);
    },
    getTeamIdsFromUsers() {
      return this.userHasMoreThanOneAccount.map((user) => user.id);
    },
    async getAllUsersForHasMoreThanOneAccount() {
      const userData = {
        clubId: this.userData.clubId,
        userIds: this.getTeamIdsFromUsers(),
        action: "getAllUsersForHasMoreThanOneAccount",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.populatePlayerDataFromAllUsersCall(responce.data);
      }
    },
    async saveHeight() {
      const userData = {
        id: this.selectedUserData.id,
        value: this.height,
        action: "saveHeight",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.status === 200) {
        this.getPlayerUserDataId(this.selectedUserValue);
        this.$bvModal.hide("addHeight");

        this.$bvToast.toast("Player height updated.", {
          title: "saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async saveWeight() {
      const userData = {
        id: this.selectedUserData.id,
        value: this.weight,
        action: "saveWeight",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.status === 200) {
        this.getPlayerUserDataId(this.selectedUserValue);
        this.$bvModal.hide("addWeight");

        this.$bvToast.toast("Player weight updated.", {
          title: "saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    removeFilter() {
      this.coachesUsersData = this.allUserDataBeforFilterApplied;
      this.hideRemoveTeamFilterButton = true;
    },
    filterBy(arrayToFilter, filterBy) {
      const filterResult = this[arrayToFilter].filter(
        (player) => player[filterBy] === this.selectedFilterData
      );

      this.coachesUsersData = filterResult;
      this.hideRemoveTeamFilterButton = false;
    },
    setCoachesUsersData(id) {
      this.$store.dispatch(
        POPULATE_COACHES_USER_DATA,
        JSON.parse(localStorage.getItem("coachesUsersData"))
      );
      this.getAllUsersForThisAdmin();
      this.setSelectedTrainingPlanUser(id);
    },
    setSelectedTrainingPlanUser(id) {
      let arrayResult = this.coachesUsersData.filter((selectedTrainingUser) => {
        return selectedTrainingUser.id == id;
      });
      if (arrayResult.length !== 0) {
        this.selectedTrainingUserData = arrayResult[0];
      }
    },
    setSelectedUserOfTrainingPlan(e) {
      let userId = null;
      this.filterdCoachesUsersData = [];
      e.target === undefined ? (userId = e) : (userId = e.target.value);

      this.coachesUsersData.length > 0
        ? this.setSelectedTrainingPlanUser(userId)
        : this.setCoachesUsersData(userId);
      // const selectedUser = e;
      this.selectedUseChanged({ ...e });
    },
    lookUpSearch(value) {
      if (value.length > 3) {
        this.filterdCoachesUsersData = [];

        const results = Array.from(value).reduce(
          (a, v, i) => `${a}[^${value.substr(i)}]*?${v}`,
          ""
        );
        const regEx = RegExp(results);

        this.coachesUsersData.filter((v) => {
          if (v.name.match(regEx)) {
            this.filterdCoachesUsersData.push(v);
          }
        });
      } else {
        this.filterdCoachesUsersData = [];
      }
    },
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
    newPageCheck(height, dynamicHeight, pdf) {
      if (height >= "210") {
        pdf.addPage();
        return 10;
      } else {
        height = Math.round(dynamicHeight.h + height + 10);
        return height;
      }
    },
    addEducation(pdf, height) {
      pdf.setFontSize(10).setFont(undefined, "normal");
      height = Math.round(height);

      this.playerCvData.education.map((item) => {
        let name = pdf.splitTextToSize(item.title, 50);
        pdf.text(5, height, name);
        pdf.text(70, height, this.formatDateForHuman(item.start_date));
        pdf.text(70, height + 5, this.formatDateForHuman(item.end_date));

        let paraOneLines = pdf.splitTextToSize(item.more_info, 90);
        pdf.text(115, height, paraOneLines);

        let dynamicHeight = pdf.getTextDimensions(paraOneLines);
        height = this.newPageCheck(height, dynamicHeight, pdf);
      });

      return height;
    },
    addPlayingExperience(pdf, height) {
      pdf.setFontSize(10).setFont(undefined, "normal");
      height = height + 85;
      height = Math.round(height);

      this.playerCvData.playingExperence.map((item) => {
        let name = pdf.splitTextToSize(item.club, 50);

        pdf.text(5, height, name);
        pdf.text(70, height, this.formatDateForHuman(item.start_date_at_club));
        pdf.text(
          70,
          height + 5,
          this.formatDateForHuman(item.end_date_at_club)
        );

        let paraOneLines = pdf.splitTextToSize(item.playing_info, 90);
        pdf.text(115, height, paraOneLines);

        let dynamicHeight = pdf.getTextDimensions(paraOneLines);
        height = this.newPageCheck(height, dynamicHeight, pdf);
      });

      return height;
    },
    addInterest(pdf, height) {
      pdf.setFontSize(10).setFont(undefined, "normal");
      height = Math.round(height);

      this.playerCvData.interests_hobbies.map((item) => {
        let interest = pdf.splitTextToSize(item.interests, 50);

        pdf.text(5, height, interest);
        let paraOneLines = pdf.splitTextToSize(item.more_info, 130);
        pdf.text(70, height, paraOneLines);

        let dynamicHeight = pdf.getTextDimensions(paraOneLines);
        height = this.newPageCheck(height, dynamicHeight, pdf);
      });

      return height;
    },
    addPlayerVideos(pdf, height) {
      pdf.setFontSize(10).setFont(undefined, "normal");
      height = Math.round(height);

      this.playerCvData.videos.map((item, index) => {
        let videoIcon = document.getElementById("video-icon");
        pdf.addImage(videoIcon, "PNG", 5, height - 7, 10, 10);

        pdf.textWithLink(item.video_name, 20, height, { url: item.video_url });
        pdf.textWithLink(item.video_name, 20, height, { url: item.video_url });
        height = height + 10;

        if (index !== 0 && height >= "210") {
          pdf.addPage();
          height = 10;
        }
      });

      return height;
    },
    addPlayingPostions(pdf, height) {
      pdf.setFontSize(10).setFont(undefined, "normal");

      this.gamePerformanceData.postion_played.map((item, index) => {
        if (item === "Goalkeeper") {
          pdf.text(158, 201, "GK");
        }

        if (item === "Right Full-back (Wingback)") {
          pdf.text(188.5, 189, "RB");
        }

        if (item === "Center-back left") {
          pdf.text(147, 189, "CB");
        }

        if (item === "Center-back right") {
          pdf.text(167, 189, "CB");
        }

        if (item === "Defensive Midfielder") {
          pdf.text(147, 152, "CM");
        }

        if (item === "Attacking Midfielder") {
          pdf.text(167, 152, "CM");
        }

        if (item === "Right Midfielder (Winger)") {
          pdf.text(188.5, 152, "RW");
        }

        if (item === "Left Midfielder (Winger)") {
          pdf.text(127.5, 152, "LW");
        }

        if (item === "Center Forward (Striker) left") {
          pdf.text(147, 119, "CF");
        }

        if (item === "Center Forward (Striker) right") {
          pdf.text(169, 119, "CF");
        }

        if (item === "Left Full-back (Wingback)") {
          pdf.text(127.5, 189, "LB");
        }
      });

      return height;
    },
    getTheLastPlayerPerformanceReview() {
      return this.allplayerPerformanceReportData[
        this.allplayerPerformanceReportData.length - 1
      ];
    },
    buildThePlayerCoachReportPDF() {
      let previewContainer = document.getElementById(
        "thePlayerCoachReviewPDF_preview"
      );

      // load player image //
      let img = document.querySelector("#user_photo");
      let pdf = new JsPDF("p", "mm", "a4");

      pdf.setFontSize(20).setFont(undefined, "bold");
      pdf.text(
        3,
        18,
        `Performance review - ${this.playerCoachSignedPerformanceReportData.created_date}`
      );

      pdf.setFontSize(18).setFont(undefined, "bold");
      pdf.text(3, 28, "Player review");

      pdf.addImage(img, "PNG", 1, 40, "40", "40");

      pdf.setFontSize(12).setFont(undefined, "bold");
      pdf.text(3, 55, `Name: ${this.selectedUserData.name}`);
      pdf.text(3, 60, `Phone: ${this.selectedUserData.phone}`);
      pdf.text(3, 65, `Email: ${this.selectedUserData.email}`);
      pdf.text(
        3,
        70,
        `DOB: ${this.formatDateForHuman(this.selectedUserData.dob)}`
      );
      pdf.text(3, 75, `Position: ${this.selectedUserData.position}`);

      pdf.text(3, 85, "Technical");
      let paraOne =
        this.playerCoachSignedPerformanceReportData.technical_player_comments;

      let paraOneLines = pdf.splitTextToSize(paraOne, 185);
      pdf.text(3, 90, paraOneLines);

      let dynamicHeight = pdf.getTextDimensions(paraOneLines);
      let height = this.newPageCheck(90, dynamicHeight, pdf);

      //-------------------------------------------------------------------------

      pdf.text(3, height + 10, "Tactical");
      let tactical =
        this.playerCoachSignedPerformanceReportData.tactical_player_comments;

      let tacticalLines = pdf.splitTextToSize(tactical, 185);
      pdf.text(3, height + 15, tacticalLines);

      let dynamicTacticalHeight = pdf.getTextDimensions(tacticalLines);
      let heightTwo = this.newPageCheck(
        height + 15,
        dynamicTacticalHeight,
        pdf
      );

      //-------------------------------------------------------------------------

      pdf.text(3, heightTwo + 10, "Physical");
      let physical =
        this.playerCoachSignedPerformanceReportData.physical_player_comments;

      let physicalLines = pdf.splitTextToSize(physical, 185);
      pdf.text(3, heightTwo + 15, physicalLines);

      let dynamicPhysicalHeight = pdf.getTextDimensions(physicalLines);
      let heightThree = this.newPageCheck(
        heightTwo + 15,
        dynamicPhysicalHeight,
        pdf
      );

      //-------------------------------------------------------------------------

      pdf.text(3, heightThree + 10, "Mental");
      let physcological =
        this.playerCoachSignedPerformanceReportData
          .physcological_player_comments;

      let physcologicalLines = pdf.splitTextToSize(physcological, 185);
      pdf.text(3, heightThree + 15, physcologicalLines);

      let physcologicalHeight = pdf.getTextDimensions(physcologicalLines);
      let heightFour = this.newPageCheck(
        heightThree + 15,
        physcologicalHeight,
        pdf
      );

      //-------------------------------------------------------------------------

      pdf.text(3, heightFour + 10, "Player comments");
      let playerComments =
        this.playerCoachSignedPerformanceReportData.player_comments;

      let playerCommentsLines = pdf.splitTextToSize(playerComments, 185);
      pdf.text(3, heightFour + 15, playerCommentsLines);

      let playerCommentsHeight = pdf.getTextDimensions(physicalLines);
      let heightFive = this.newPageCheck(
        heightFour + 15,
        playerCommentsHeight,
        pdf
      );

      //-------------------------------------------------------------------------

      /*if (this.playerCoachSignedPerformanceReportData.parentReportSignature !== undefined) {
        pdf.text(3, dynamicHeight.h + 155, "Player Signature");
        let playerSignature = document.querySelector("#player_signature_full");
        console.log(playerSignature);
        //playerSignature.src = `images/${this.playerCoachSignedPerformanceReportData.parentReportSignature}`;
        if(playerSignature !== null) {
          pdf.addImage(playerSignature, 'JPG', dynamicHeight.h + 155, "300", "150");
        }
  
        //pdf.addImage(playerSignature, "JPEG", 1, dynamicHeight.h + 155, "300", "150");
      } */

      //-------------------------------------------------------------------------

      pdf.setFontSize(18).setFont(undefined, "bold");
      pdf.text(3, heightFive + 10, "Coach review");

      //pdf.addImage(img, "PNG", 1, 40, "40", "40");

      pdf.setFontSize(12).setFont(undefined, "bold");
      pdf.text(
        3,
        heightFive + 20,
        `Coach name: ${this.playerCoachSignedPerformanceReportData.name}`
      );

      //-------------------------------------------------------------------------

      pdf.text(3, heightFive + 30, "Technical");
      let coachParaOne =
        this.playerCoachSignedPerformanceReportData.technical_coach_comments;

      let coachParaOneLines = pdf.splitTextToSize(coachParaOne, 185);
      pdf.text(3, heightFive + 37, paraOneLines);

      let coachDynamicHeight = pdf.getTextDimensions(coachParaOneLines);
      let coachHeight = this.newPageCheck(
        heightFive + 37,
        coachDynamicHeight,
        pdf
      );

      //-------------------------------------------------------------------------

      pdf.text(3, coachHeight + 10, "Tacticaljhjkhkjhkkjh");
      let coachTactical =
        this.playerCoachSignedPerformanceReportData.tactical_coach_comments;

      let coachTacticalLines = pdf.splitTextToSize(coachTactical, 185);
      pdf.text(3, coachHeight + 15, coachTacticalLines);

      let coachDynamicTacticalHeight =
        pdf.getTextDimensions(coachTacticalLines);
      let coachHeightTwo = this.newPageCheck(
        coachHeight + 15,
        coachDynamicTacticalHeight,
        pdf
      );

      //-------------------------------------------------------------------------

      pdf.text(3, coachHeightTwo + 10, "Physical");
      let coachPhysical =
        this.playerCoachSignedPerformanceReportData.physical_coach_comments;

      let coachPhysicalLines = pdf.splitTextToSize(coachPhysical, 185);
      pdf.text(3, heightTwo + 15, coachPhysicalLines);

      let coachDynamicPhysicalHeight = pdf.getTextDimensions(physicalLines);
      let coachHeightThree = this.newPageCheck(
        coachHeightTwo + 15,
        coachDynamicPhysicalHeight,
        pdf
      );

      //-------------------------------------------------------------------------

      pdf.text(3, coachHeightThree + 10, "Mental");
      let coachPhyscological =
        this.playerCoachSignedPerformanceReportData
          .physcological_coach_comments;

      let coachPhyscologicalLines = pdf.splitTextToSize(
        coachPhyscological,
        185
      );
      pdf.text(3, coachHeightThree + 15, coachPhyscologicalLines);

      let coachPhyscologicalHeight = pdf.getTextDimensions(
        coachPhyscologicalLines
      );
      let coachHeightFour = this.newPageCheck(
        coachHeightThree + 15,
        coachPhyscologicalHeight,
        pdf
      );

      //-------------------------------------------------------------------------

      pdf.text(3, coachHeightFour + 10, "Coach comments");
      let coachComments =
        this.playerCoachSignedPerformanceReportData.coach_comments;

      let coachCommentsLines = pdf.splitTextToSize(coachComments, 185);
      pdf.text(3, coachHeightFour + 15, coachCommentsLines);

      let coachCommentsHeight = pdf.getTextDimensions(coachCommentsLines);
      let coachHeightFive = this.newPageCheck(
        coachHeightFour + 15,
        coachCommentsHeight,
        pdf
      );

      //-------------------------------------------------------------------------

      /*if (document.querySelector("#playerReportSignature") !== null) {
        pdf.text(3, dynamicHeight.h + 155, "Player Signature");
        let playerSignature = document.querySelector("#playerReportSignature");
      }*/

      return { pdfData: pdf, previewLink: previewContainer };
    },
    buildThePlayerCVPDF() {
      let previewContainer = document.getElementById("thePlayerCVPDF_preview");

      // load player image //
      let img = document.querySelector("#user_photo");
      let pdf = new JsPDF("p", "mm", "a4");

      pdf.setTextColor(3, 86, 158);
      pdf.addImage(img, "PNG", 1, 0, "40", "40");

      pdf.setFontSize(23).setFont(undefined, "bold");
      pdf.text(45, 18, this.selectedUserData.name, { maxWidth: 70 });

      pdf.setFontSize(16);
      pdf.text(5, 55, this.selectedUserData.dob);

      pdf.setFontSize(16).setFont(undefined, "bold");
      pdf.text(5, 65, "Personal profile");

      pdf.setFontSize(14).setFont(undefined, "normal");

      let paraOne = this.selectedUserData.personal_profile;

      let paraOneLines = pdf.splitTextToSize(paraOne, 185);
      pdf.text(5, 75, paraOneLines);

      let dynamicHeight = pdf.getTextDimensions(paraOneLines);

      dynamicHeight.h = dynamicHeight.h + 10;
      pdf.setFontSize(16).setFont(undefined, "bold");
      pdf.text(5, 75 + dynamicHeight.h, "Playing experience");

      dynamicHeight.h = dynamicHeight.h + 10;
      pdf.setFontSize(12).setFont(undefined, "bold");
      pdf.text(5, 75 + dynamicHeight.h, "Club");
      pdf.text(70, 75 + dynamicHeight.h, "Time at the club");
      pdf.text(115, 75 + dynamicHeight.h, "Playing stats");

      dynamicHeight.h = this.addPlayingExperience(pdf, dynamicHeight.h);

      pdf.setFontSize(16).setFont(undefined, "bold");
      pdf.text(5, dynamicHeight.h + 10, "Education");

      dynamicHeight.h = this.addEducation(pdf, dynamicHeight.h + 20);

      pdf.setFontSize(16).setFont(undefined, "bold");
      pdf.text(5, dynamicHeight.h + 10, "Interest hobbies");

      dynamicHeight.h = dynamicHeight.h + 20;
      pdf.setFontSize(12).setFont(undefined, "bold");
      pdf.text(5, dynamicHeight.h, "Interest hobbies");
      pdf.text(70, dynamicHeight.h, "More information");

      dynamicHeight.h = this.addInterest(pdf, dynamicHeight.h + 10);

      dynamicHeight.h = dynamicHeight.h + 20;
      pdf.setFontSize(16).setFont(undefined, "bold");
      pdf.text(5, dynamicHeight.h, "Playing videos");

      dynamicHeight.h = this.addPlayerVideos(pdf, dynamicHeight.h + 10);

      return { pdfData: pdf, previewLink: previewContainer };
    },
    buildTheAcademyReportPDF() {
      let previewContainer = document.getElementById(
        "theAcademyReportPdf_preview"
      );
      let shirtImage = document.getElementById("small_shirt");
      // load player image //
      let img = document.querySelector("#user_photo");
      let pdf = new JsPDF("p", "mm", "a4");

      pdf.setFillColor(241, 246, 249);
      pdf.rect(0, 0, 300, 120, "F");

      pdf.setTextColor(3, 86, 158);
      pdf.addImage(img, "PNG", 1, 0, "40", "40");

      pdf.setFontSize(23).setFont(undefined, "bold");
      pdf.text(45, 18, this.selectedUserData.name, { maxWidth: 70 });

      pdf.setFontSize(16);
      pdf.text(5, 55, "Season: Aug 2022 - May 2023");

      pdf.setFontSize(14).setFont(undefined, "normal");
      pdf.text(5, 65, "Appearances:");
      pdf.text(75, 65, `${this.gamePerformanceData.gamesPlayed}`);

      pdf.text(5, 75, "Goals:");
      pdf.text(75, 75, `${this.gamePerformanceData.goals}`);

      pdf.text(5, 85, "Assists:");
      pdf.text(75, 85, `${this.gamePerformanceData.assists}`);

      pdf.text(5, 95, "Player performace rating:");
      let playerSelfRating;
      this.gamePerformanceData.player_self_rating === null
        ? (playerSelfRating = 0)
        : (playerSelfRating = this.gamePerformanceData.player_self_rating);
      pdf.text(75, 95, `${playerSelfRating}`);

      pdf.text(5, 105, "Game time:");
      let timeOnPitch;
      this.gamePerformanceData.timeOnPitch === null
        ? (timeOnPitch = 0)
        : (timeOnPitch = this.gamePerformanceData.timeOnPitch);
      pdf.text(75, 105, `${timeOnPitch} mins`);

      pdf
        .text(
          125,
          10,
          `Dob: ${this.formatDateForHuman(this.selectedUserData.dob)}`
        )
        .setFont(undefined, "normal");
      pdf
        .text(125, 20, `Position: ${this.selectedUserData.position}`)
        .setFont(undefined, "normal");
      pdf
        .text(
          125,
          30,
          `Height: ${this.playerPhysicalPerformanceData.height} cm`
        )
        .setFont(undefined, "normal");
      pdf
        .text(
          125,
          40,
          `Weight:  ${this.playerPhysicalPerformanceData.weight} kg`
        )
        .setFont(undefined, "normal");
      pdf
        .text(125, 50, `Age: ${this.selectedUserData.age_group}`)
        .setFont(undefined, "normal");
      pdf
        .text(
          125,
          60,
          `Signed: ${this.formatDateForHuman(
            this.selectedUserData.joined_date
          )}`
        )
        .setFont(undefined, "normal");

      let pitchBackground = document.getElementById("pitch_potions");
      pdf.addImage(pitchBackground, "PNG", 120, 90, 80, 120);

      let height = this.addPlayingPostions(pdf, 85);

      pdf.setFontSize(14).setFont(undefined, "bold");
      pdf.text(125, height, "PLAYING POSITIONS");

      pdf.text(125, height + 135, "PHYSICAL PERFORMANCE");

      pdf.setFontSize(12).setFont(undefined, "normal");
      pdf.text(125, height + 143, "10m SPRINT");
      const tenMsprintTime =
        this.playerPhysicalPerformanceData.tenM_sprint_time;
      if (tenMsprintTime !== undefined) {
        pdf.text(183, height + 143, tenMsprintTime);
      }

      pdf.text(125, height + 163, "30m SPRINT");
      const fortyMSprintTime =
        this.playerPhysicalPerformanceData.fortyM_sprint_time;
      if (fortyMSprintTime !== undefined) {
        pdf.text(183, height + 163, `${fortyMSprintTime}s`);
      }

      pdf.text(125, height + 183, "Countermovement jump");
      const cuntermovementJump =
        this.playerPhysicalPerformanceData.cuntermovement_jump;
      if (cuntermovementJump !== undefined) {
        pdf.text(183, height + 183, `${cuntermovementJump}cm`);
      }

      pdf.text(125, height + 203, "Reactive strength index");
      const reactiveStrengthindex =
        this.playerPhysicalPerformanceData.reactive_strength_index;
      if (reactiveStrengthindex !== undefined) {
        pdf.text(183, height + 203, `${reactiveStrengthindex}`);
      }

      pdf.text(125, height + 243, "Fitness Test Time");
      const thirty15IntermittentFitnessTest =
        this.playerPhysicalPerformanceData.reactive_strength_index;
      if (thirty15IntermittentFitnessTest !== undefined) {
        pdf.text(183, height + 243, `${thirty15IntermittentFitnessTest}`);
      }

      pdf.setFontSize(16).setFont(undefined, "bold");
      pdf.text(5, 130, "COACHES COMMENTS");

      pdf.setFontSize(12).setFont(undefined, "normal");

      //let playerPerformanceReview = this.getTheLastPlayerPerformanceReview();
      let paraOneLines = "Please add comments";

      var paraOne = this.academyReport.coaches_comments;
      paraOneLines = pdf.splitTextToSize(paraOne, 112);

      pdf.text(5, 139, paraOneLines);
      let dynamicHeight = pdf.getTextDimensions(paraOneLines);

      pdf.setFontSize(16).setFont(undefined, "bold");
      pdf.text(5, dynamicHeight.h + 150, "Playing videos");
      dynamicHeight.h = this.addPlayerVideos(pdf, dynamicHeight.h + 160);

      return { pdfData: pdf, previewLink: previewContainer };
    },
    produceThePlayerCoachReportPDF(review) {
      this.playerCoachSignedPerformanceReportData = review;
      this.getPlayerCoachSignedPerformanceSignatures(review.id),
        this.getPlayerCoachSignedPerformanceReport();
      this.$bvModal.show("viewPlayerCoachReviewPDF");
      setTimeout(() => {
        let pdf = this.buildThePlayerCoachReportPDF();
        pdf.previewLink.src = pdf.pdfData.output("datauristring");
        this.showPlayerCoachReportPDFReport = true;
      }, 500);
    },
    produceThePlayerCV() {
      this.$bvModal.show("viewPlayersCV");
      setTimeout(() => {
        let pdf = this.buildThePlayerCVPDF();
        pdf.previewLink.src = pdf.pdfData.output("datauristring");
        this.showCVReport = true;
      }, 500);
    },
    printPlayerCoachAssementPDF() {
      setTimeout(() => {
        let pdf = this.buildTheAcademyReportPDF();
        pdf.previewLink.src = pdf.pdfData.output("datauristring");
        this.showReport = true;
      }, 500);
    },
    produceTheAcademyReport() {
      this.showReport = true;
      this.academyReport.player_id = this.selectedUserValue;
      this.academyReport.seasion = "2023";
      this.loadPlayerPhysicalPerformanceData();

      setTimeout(() => {
        let pdf = this.buildTheAcademyReportPDF();
        pdf.previewLink.src = pdf.pdfData.output("datauristring");
        this.showReport = true;
      }, 500);
    },
    openCreateAcademyReportModal() {
      this.$bvModal.show("academyReportModal");
    },
    performanceTargetsModal(data, modelName, typeOfTarget) {
      this.selectedPerformanceTarget = data;
      this.selectedPerformanceTarget.typeOfTarget = typeOfTarget;
      this.$bvModal.show(modelName);
    },
    addVideo(category) {
      if (!category || typeof category !== "string") {
        console.error("Invalid category provided.");
        return;
      }

      const videoData = {
        id: null,
        player_id: this.selectedUserValue || null,
        video_name: "New Video",
        category: category.trim(),
        document_id: this.newHomeWorkData.id,
        video_url: "",
      };

      this.newHomeWorkData.videos = [...this.newHomeWorkData.videos, videoData];
    },
    removeVideo(index) {
      this.newHomeWorkData.videos.splice(index, 1);
    },
    addItem(targetText) {
      const playerPerformanceTarget = {
        player_id: this.selectedUserValue,
        performance_report_id: 0,
        idp_id: 0,
        target: targetText,
        complete: 0,
        category: "Attitude",
        created_date: moment().format("YYYY-MM-DD"),
      };
      this.playerPerformanceTargets.push(playerPerformanceTarget);
    },
    removeItem(index) {
      this.playerPerformanceTargets.splice(index, 1);
    },
    openPhysicalPerformanceHistoryModal() {
      this.$bvModal.show("physicalPerformanceHistoryModal");
    },
    checkIfNewValueisBetterOrWorse() {
      if (this.allPlayerPhysicalPerformanceData.length < 2) {
        return false;
      }
      const lastItem =
        this.allPlayerPhysicalPerformanceData[
        this.allPlayerPhysicalPerformanceData.length - 1
        ];
      const secondLastItem =
        this.allPlayerPhysicalPerformanceData[
        this.allPlayerPhysicalPerformanceData.length - 2
        ];

      if (lastItem.tenM_sprint_time > secondLastItem.tenM_sprint_time) {
        lastItem.tenM_sprint_time_icon = "worse";
      }
      if (lastItem.tenM_sprint_time < secondLastItem.tenM_sprint_time) {
        lastItem.tenM_sprint_time_icon = "better";
      }
      if (lastItem.tenM_sprint_time === secondLastItem.tenM_sprint_time) {
        lastItem.tenM_sprint_time_icon = "same";
      }

      if (lastItem.tenM_sprint_time_1 > secondLastItem.tenM_sprint_time_1) {
        lastItem.tenM_sprint_time_icon_1 = "worse";
      }
      if (lastItem.tenM_sprint_time_1 < secondLastItem.tenM_sprint_time_1) {
        lastItem.tenM_sprint_time_icon_1 = "better";
      }
      if (lastItem.tenM_sprint_time_1 === secondLastItem.tenM_sprint_time_1) {
        lastItem.tenM_sprint_time_icon_1 = "same";
      }

      if (lastItem.fortyM_sprint_time > secondLastItem.fortyM_sprint_time) {
        lastItem.fortyM_sprint_time_icon = "worse";
      }
      if (lastItem.fortyM_sprint_time < secondLastItem.fortyM_sprint_time) {
        lastItem.fortyM_sprint_time_icon = "better";
      }
      if (lastItem.fortyM_sprint_time === secondLastItem.fortyM_sprint_time) {
        lastItem.fortyM_sprint_time_icon = "same";
      }

      if (lastItem.fortyM_sprint_time_1 > secondLastItem.fortyM_sprint_time_1) {
        lastItem.fortyM_sprint_time_icon_1 = "worse";
      }
      if (lastItem.fortyM_sprint_time_1 < secondLastItem.fortyM_sprint_time_1) {
        lastItem.fortyM_sprint_time_icon_1 = "better";
      }
      if (
        lastItem.fortyM_sprint_time_1 === secondLastItem.fortyM_sprint_time_1
      ) {
        lastItem.fortyM_sprint_time_icon_1 = "same";
      }

      if (lastItem.cuntermovement_jump > secondLastItem.cuntermovement_jump) {
        lastItem.cuntermovement_jump_icon = "better";
      }
      if (lastItem.cuntermovement_jump < secondLastItem.cuntermovement_jump) {
        lastItem.cuntermovement_jump_icon = "worse";
      }
      if (lastItem.cuntermovement_jump === secondLastItem.cuntermovement_jump) {
        lastItem.cuntermovement_jump_icon = "same";
      }

      if (
        lastItem.cuntermovement_jump_2 > secondLastItem.cuntermovement_jump_2
      ) {
        lastItem.cuntermovement_jump_icon_2 = "better";
      }
      if (
        lastItem.cuntermovement_jump_2 < secondLastItem.cuntermovement_jump_2
      ) {
        lastItem.cuntermovement_jump_icon_2 = "worse";
      }
      if (
        lastItem.cuntermovement_jump_2 === secondLastItem.cuntermovement_jump_2
      ) {
        lastItem.cuntermovement_jump_icon_2 = "same";
      }

      if (
        lastItem.reactive_strength_index >
        secondLastItem.reactive_strength_index
      ) {
        lastItem.reactive_strength_index_icon = "better";
      }
      if (
        lastItem.reactive_strength_index <
        secondLastItem.reactive_strength_index
      ) {
        lastItem.reactive_strength_index_icon = "worse";
      }
      if (
        lastItem.reactive_strength_index ===
        secondLastItem.reactive_strength_index
      ) {
        lastItem.reactive_strength_index_icon = "same";
      }

      if (
        lastItem.reactive_strength_index_2 >
        secondLastItem.reactive_strength_index_2
      ) {
        lastItem.reactive_strength_index_icon = "better";
      }
      if (
        lastItem.reactive_strength_index_2 <
        secondLastItem.reactive_strength_index_2
      ) {
        lastItem.reactive_strength_index_icon_2 = "worse";
      }
      if (
        lastItem.reactive_strength_index_2 ===
        secondLastItem.reactive_strength_index_2
      ) {
        lastItem.reactive_strength_index_icon_2 = "same";
      }

      if (
        lastItem.reactive_strength_index_3 >
        secondLastItem.reactive_strength_index_3
      ) {
        lastItem.reactive_strength_index_icon_3 = "better";
      }
      if (
        lastItem.reactive_strength_index_3 <
        secondLastItem.reactive_strength_index_3
      ) {
        lastItem.reactive_strength_index_icon_3 = "worse";
      }
      if (
        lastItem.reactive_strength_index_3 ===
        secondLastItem.reactive_strength_index_3
      ) {
        lastItem.reactive_strength_index_icon_3 = "same";
      }

      if (
        lastItem.reactive_strength_index_4 >
        secondLastItem.reactive_strength_index_4
      ) {
        lastItem.reactive_strength_index_icon_4 = "better";
      }
      if (
        lastItem.reactive_strength_index_4 <
        secondLastItem.reactive_strength_index_4
      ) {
        lastItem.reactive_strength_index_icon_4 = "worse";
      }
      if (
        lastItem.reactive_strength_index_4 ===
        secondLastItem.reactive_strength_index_4
      ) {
        lastItem.reactive_strength_index_icon_4 = "same";
      }

      if (
        lastItem.reactive_strength_index_5 >
        secondLastItem.reactive_strength_index_5
      ) {
        lastItem.reactive_strength_index_icon_5 = "better";
      }
      if (
        lastItem.reactive_strength_index_5 <
        secondLastItem.reactive_strength_index_5
      ) {
        lastItem.reactive_strength_index_icon_5 = "worse";
      }
      if (
        lastItem.reactive_strength_index_5 ===
        secondLastItem.reactive_strength_index_5
      ) {
        lastItem.reactive_strength_index_icon_5 = "same";
      }

      if (
        lastItem.reactive_strength_index_average >
        secondLastItem.reactive_strength_index_average
      ) {
        lastItem.reactive_strength_index_average_icon = "better";
      }
      if (
        lastItem.reactive_strength_index_average <
        secondLastItem.reactive_strength_index_average
      ) {
        lastItem.reactive_strength_index_average_icon = "worse";
      }
      if (
        lastItem.reactive_strength_index_average ===
        secondLastItem.reactive_strength_index_average
      ) {
        lastItem.reactive_strength_index_average_icon = "same";
      }

      if (
        lastItem.thirty_15_intermittent_fitness_test >
        secondLastItem.thirty_15_intermittent_fitness_test
      ) {
        lastItem.thirty_15_intermittent_fitness_test_icon = "worse";
      }
      if (
        lastItem.thirty_15_intermittent_fitness_test <
        secondLastItem.thirty_15_intermittent_fitness_test
      ) {
        lastItem.thirty_15_intermittent_fitness_test_icon = "better";
      }
      if (
        lastItem.thirty_15_intermittent_fitness_test ===
        secondLastItem.thirty_15_intermittent_fitness_test
      ) {
        lastItem.thirty_15_intermittent_fitness_test_icon = "same";
      }

      if (lastItem.weight > secondLastItem.weight) {
        lastItem.weight_icon = "better";
      }
      if (lastItem.weight < secondLastItem.weight) {
        lastItem.weight_icon = "worse";
      }
      if (lastItem.weight === secondLastItem.weight) {
        lastItem.weight_icon = "same";
      }

      if (lastItem.height > secondLastItem.height) {
        lastItem.height_icon = "better";
      }
      if (lastItem.height < secondLastItem.height) {
        lastItem.height_icon = "worse";
      }
      if (lastItem.height === secondLastItem.height) {
        lastItem.height_icon = "same";
      }

      if (lastItem.sitting_height > secondLastItem.sitting_height) {
        lastItem.height_icon_sitting = "better";
      }
      if (lastItem.sitting_height < secondLastItem.sitting_height) {
        lastItem.height_icon_sitting = "worse";
      }
      if (lastItem.sitting_height === secondLastItem.sitting_height) {
        lastItem.height_icon_sitting = "same";
      }

      if (lastItem.bronco_time_mins > secondLastItem.bronco_time_mins) {
        lastItem.bronco_time_mins_icon = "better";
      }
      if (lastItem.bronco_time_mins < secondLastItem.bronco_time_mins) {
        lastItem.bronco_time_mins_icon = "worse";
      }
      if (lastItem.bronco_time_mins === secondLastItem.bronco_time_mins) {
        lastItem.bronco_time_mins_icon = "same";
      }

      if (lastItem.bronco_time_sec > secondLastItem.bronco_time_sec) {
        lastItem.bronco_time_sec_icon = "better";
      }
      if (lastItem.bronco_time_sec < secondLastItem.bronco_time_sec) {
        lastItem.bronco_time_sec_icon = "worse";
      }
      if (lastItem.bronco_time_sec === secondLastItem.bronco_time_sec) {
        lastItem.bronco_time_sec_icon = "same";
      }
    },
    async addPhysicalPerformanceValue() {
      this.playerPhysicalPerformanceData.date = moment().format("YYYY-MM-DD");
      const userData = {
        date: this.playerPhysicalPerformanceData.date,
        player_id: this.selectedUserValue,
        tenM_sprint_time:
          this.playerPhysicalPerformanceData.tenM_sprint_time || 0,
        tenM_sprint_time_1:
          this.playerPhysicalPerformanceData.tenM_sprint_time_1 || 0,
        fortyM_sprint_time:
          this.playerPhysicalPerformanceData.fortyM_sprint_time || 0,
        fortyM_sprint_time_1:
          this.playerPhysicalPerformanceData.fortyM_sprint_time_1 || 0,
        cuntermovement_jump:
          this.playerPhysicalPerformanceData.cuntermovement_jump || 0,
        cuntermovement_jump_2:
          this.playerPhysicalPerformanceData.cuntermovement_jump_2 || 0,
        cuntermovement_jump_3:
          this.playerPhysicalPerformanceData.cuntermovement_jump_3 || 0,
        reactive_strength_index:
          this.playerPhysicalPerformanceData.reactive_strength_index || 0,
        reactive_strength_index_2:
          this.playerPhysicalPerformanceData.reactive_strength_index_2 || 0,
        reactive_strength_index_3:
          this.playerPhysicalPerformanceData.reactive_strength_index_3 || 0,
        reactive_strength_index_4:
          this.playerPhysicalPerformanceData.reactive_strength_index_4 || 0,
        reactive_strength_index_5:
          this.playerPhysicalPerformanceData.reactive_strength_index_5 || 0,
        reactive_strength_index_average:
          this.playerPhysicalPerformanceData.reactive_strength_index_average ||
          0,
        thirty_15_intermittent_fitness_test:
          this.playerPhysicalPerformanceData
            .thirty_15_intermittent_fitness_test || 0,
        weight: this.playerPhysicalPerformanceData.weight || 0,
        height: this.playerPhysicalPerformanceData.height || 0,
        sitting_height: this.playerPhysicalPerformanceData.sitting_height || 0,
        bronco_time_mins:
          this.playerPhysicalPerformanceData.bronco_time_mins || 0,
        bronco_time_sec:
          this.playerPhysicalPerformanceData.bronco_time_sec || 0,
        action: "addPlayerPhysicalPerformanceData",
      };

      const responce = await PlayerPhysicalPerformance.find(userData).catch(
        (error) => {
          console.log(error);
        }
      );

      if (responce.data === "New player physical performance results added.") {
        await this.checkIfNewValueisBetterOrWorse();
        this.loadPlayerPhysicalPerformanceData();
        this.$bvModal.hide("addNewPhysicalPerformanceTimeModal");

        this.$bvToast.toast("Player Physical Performance saved.", {
          title: "saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    addNewPhysicalPerformanceTimeModal() {
      this.$bvModal.show("addNewPhysicalPerformanceTimeModal");
    },
    async saveSignatureWithDocumentId(signatureName, id) {
      signatureName =
        this.playerCoachSignedPerformanceReportData[signatureName] ||
        signatureName;

      var _this = this;
      var jpeg = _this.$refs[signatureName].save("image/jpeg");

      const userData = {
        fileName: signatureName,
        imageFile: jpeg,
        document_id: id,
        action: "saveSignatureWithDocumentId",
        created_date: moment().format("YYYY-MM-DD"),
        type: signatureName,
      };

      const responce = await Documents.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.getPlayerCoachSignedPerformanceSignatures(id);
        this.getPlayerCoachSignedPerformanceReport();
        this.$bvToast.toast("Signature saved.", {
          title: "saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async saveSignatureCall(image, signatureName) {
      const { player_signature, parent_signature } = this.charterSignature;

      if (signatureName === "parentSignature") {
        this.charterSignature.parent_signature !== null;
        signatureName = this.charterSignature.parent_signature || signatureName;
      } else {
        this.charterSignature.player_signature !== null;
        signatureName = this.charterSignature.player_signature || signatureName;
      }

      const userData = {
        fileName: signatureName,
        imageFile: image,
        player_id: this.selectedUserData.id,
        action: "saveSignatureCall",
        charterId: this.charterSignature.id,
        created_date: moment().format("YYYY-MM-DD"),
        player_signature: player_signature,
        parent_signature: parent_signature,
      };

      const responce = await Documents.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.$bvToast.toast("Signature saved.", {
          title: "saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async getSignatures() {
      const userData = {
        player_id: this.selectedUserData.id,
        action: "getSignatures",
      };

      const responce = await Documents.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200 && typeof responce.data !== "boolean") {
        this.charterSignature = responce.data;
      }
    },
    changeSignature(signatureName, id) {
      id !== undefined
        ? (this.charterSignature[signatureName] = true)
        : (this.coachSignedPerformanceReportSignature[signatureName] = true);
    },
    saveSignature(signatureName) {
      var _this = this;
      var jpeg = _this.$refs[signatureName].save("image/jpeg");
      this.saveSignatureCall(jpeg, signatureName);
    },
    clearSignature(signatureName) {
      var _this = this;
      _this.$refs[signatureName].clear();
    },
    openAllPlayerSelfAssessmentModal() {
      this.getAllPlayerAssesments();
      this.$bvModal.show("playerAssesmentModalView");
    },
    openAllGamegReportsModal(type) {
      this.$bvModal.show(type);
    },
    openAllTrainingReportsModal(type) {
      this.$bvModal.show(type);
    },
    getPlayerPhysicalPerformanceData() {
      return this.allPlayerPhysicalPerformanceData.reduce((a, b) => {
        return new Date(a.date) > new Date(b.date) ? a : b;
      });
    },
    getSelectedPerformanceReportTargets(reportId) {
      if (this.playerPerformanceReviewTargetsData.length === 0) {
        return this.playerPerformanceReviewTargetsData;
      }

      return this.playerPerformanceReviewTargetsData.filter(
        (element) => element.performance_report_id == reportId
      );
    },
    getPlayersCurrentPerformanceTargetsForCategory(categoryName) {
      if (this.playerPerformanceReviewTargetsData.length === 0) {
        return this.playerPerformanceReviewTargetsData;
      }

      const currentReport =
        this.allplayerPerformanceReportData[
        this.allplayerPerformanceReportData.length - 1
        ];

      return this.playerPerformanceReviewTargetsData.filter(
        (element) =>
          element.performance_report_id == currentReport.id &&
          element.category === categoryName
      );
    },
    getPlayersCurrentPerformanceTargets() {
      if (this.playerPerformanceReviewTargetsData.length === 0) {
        return this.playerPerformanceReviewTargetsData;
      }

      const currentReport =
        this.allplayerPerformanceReportData[
        this.allplayerPerformanceReportData.length - 1
        ];
      return this.playerPerformanceReviewTargetsData.filter(
        (element) => element.performance_report_id == currentReport.id
      );
    },
    setPlayerPerformanceReviewData(reviewId) {
      return this.allplayerPerformanceReportData.filter(
        (review) => review.id == reviewId
      );
    },
    openPlayerPerformanceReview(reviewId) {
      const review = this.setPlayerPerformanceReviewData(reviewId);
      this.playerPerformanceReportData = review[0];
      this.playerPerformanceReportData.attitude = Number(
        this.playerPerformanceReportData.attitude
      );
      this.playerPerformanceReportData.leadership = Number(
        this.playerPerformanceReportData.leadership
      );
      this.playerPerformanceReportData.mentality = Number(
        this.playerPerformanceReportData.mentality
      );
      this.playerPerformanceReportData.physique = Number(
        this.playerPerformanceReportData.physique
      );
      this.playerPerformanceReportData.professionalism = Number(
        this.playerPerformanceReportData.professionalism
      );
      this.playerPerformanceReportData.skill = Number(
        this.playerPerformanceReportData.skill
      );

      this.$bvModal.show("playerPerformanceReportVewDetailsModal");
    },
    formatDateForHuman(date) {
      return date !== undefined && moment(date).isValid()
        ? moment(date).format("MMMM Do YYYY")
        : "";
    },
    checkSignedDate(date) {
      return date === null || date === undefined || date === "" ? false : true;
    },
    converBoolean(boolean) {
      return boolean === "0" ? "No" : "Yes";
    },
    getImgUrl(pic) {
      return pic !== undefined && pic !== "" ? `/images/${pic}` : "";
    },
    resetAllPlayerData() {
      this.resetGamePerformanceData();
      this.resetcoachGamePerformanceData();
      this.resetplayerTrainingData();
      this.reSetNewInjuryInformation();
      this.resetPlayersMoodData();
    },
    resetPlayersMoodData() {
      this.playerMoodData = {};
      this.wellnessQuestions = {
        sleep: 0,
        stress: 0,
        happyness: 0,
        fatigue: 0,
        muscleSoreness: 0,
      };
    },
    resetplayerTrainingData() {
      this.trainingPerformanceData = {};
      this.newTrainingPerformanceData = {};
    },
    reSetNewInjuryInformation() {
      this.playerInjuryData = [];
      this.newInjuryInformation = {
        injury_type: null,
        more_detail: null,
        return_time: null,
        injury_status: null,
        contact: null,
      };
    },
    resetcoachGamePerformanceData() {
      this.coachGamePerformanceData = [];
    },
    resetGamePerformanceData() {
      this.gamePerformanceData = {
        gameId: null,
        postion_played: [],
        goals: 0,
        assists: 0,
        clean_sheet: null,
        player_self_rating: null,
        yellowCardTotal: 0,
        redCardTotal: 0,
        redCardTotal: 0,
        timeOnPitch: null,
        gamesPlayed: null,
      };
    },
    hidePlayerInjuryReportModel() {
      this.$bvModal.hide("playerInjuryModal");
      this.reSetNewInjuryInformation();
      this.getPlayerInjuryInformation();
      this.getPlayerInjuryReport();
      this.getAllPlayerInjuryReports();
      this.getDocumentUploadsCall();
    },
    resetPlayerInjuaryNumbers() {
      this.playerInjuryHistoryAmounts = {
        headNeckInjury: 0,
        shoulderInjury: 0,
        armInjury: 0,
        wristAndHandInjury: 0,
        hipInjury: 0,
        groinInjury: 0,
        quadInjury: 0,
        hamstringInjury: 0,
        kneeInjury: 0,
        calfInjury: 0,
        ankleInjury: 0,
        footInjury: 0,
      };
    },
    download_csv(data, sensor) {
      let csvHeader = Object.keys(data[0]).join(",") + "\n"; // header row
      let csvBody = data.map((row) => Object.values(row).join(",")).join("\n");

      var hiddenElement = document.createElement("a");
      hiddenElement.href =
        "data:text/csv;charset=utf-8," + encodeURI(csvHeader + csvBody);
      hiddenElement.target = "_blank";
      hiddenElement.download = sensor + ".csv";
      hiddenElement.click();
    },
    populatePlayerInjuryNumbers() {
      this.resetPlayerInjuaryNumbers();
      const injuryTypes = {
        "Head injury": "headNeckInjury",
        "Left Shoulder injury": "shoulderInjury",
        "Right Shoulder injury": "shoulderInjury",
        "Left Arm injury": "armInjury",
        "Right Arm injury": "armInjury",
        "Left Wrist and hand injury": "wristAndHandInjury",
        "Right Wrist and hand injury": "wristAndHandInjury",
        "Left Hip injury": "hipInjury",
        "Right Hip injury": "hipInjury",
        "Left Groin injury": "groinInjury",
        "Right Groin injury": "groinInjury",
        "Left Quad injury": "quadInjury",
        "Right Quad injury": "quadInjury",
        "Left hamstring injury": "hamstringInjury",
        "Right hamstring injury": "hamstringInjury",
        "Left Knee injury": "kneeInjury",
        "Right Knee injury": "kneeInjury",
        "Left Calf injury": "calfInjury",
        "Right Calf injury": "calfInjury",
        "Left ankle injury": "ankleInjury",
        "Right ankle injury": "ankleInjury",
        "Left Foot injury": "footInjury",
        "Right Foot injury": "footInjury",
      };

      this.allPlayerInjuryReports.forEach((injury) => {
        const injuryType = injuryTypes[injury.injury_type];
        if (injuryType) {
          this.playerInjuryHistoryAmounts[injuryType]++;
        }
      });
    },
    async getAllPlayerInjuryReports() {
      const userData = {
        player_id: this.selectedUserValue,
        action: "getAllPlayerInjuryReports",
      };

      const responce = await PlayerInjury.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.allPlayerInjuryReports = responce.data;
        this.populatePlayerInjuryNumbers();
      }
    },
    async getPlayerInjuryReport() {
      this.playerInjuryData = [];
      const userData = {
        player_id: this.selectedUserValue,
        year: this.currentPlayingSeason,
        action: "getPlayerInjuryReport",
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };

      const responce = await PlayerInjury.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.playerInjuryReportsData = responce.data;
      }
    },
    async getPlayerInjuryInformation() {
      this.playerInjuryData = [];
      const userData = {
        player_id: this.selectedUserValue,
        year: this.currentPlayingSeason,
        action: "getPlayerInjuryInformation",
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };

      const responce = await PlayerInjury.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.playerInjuryData = responce.data;
      }
    },
    async deletePlayerInjuryReport(id) {
      this.loadingStuff = true;
      const userData = {
        id: id,
        action: "deletePlayerInjuryReport",
      };
      const responce = await PlayerInjury.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data === "Injury Report removed") {
        this.reSetNewInjuryInformation();
        this.getPlayerInjuryInformation();
        this.getPlayerInjuryReport();
        this.highlightInjuredPlayers();
        this.loadingStuff = false;

        this.$bvToast.toast("Player injury report removed", {
          title: "Removed",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    loadAllInjuryReportDependencies() {
      this.$bvModal.hide("editPlayerInjuryModal");
      this.reSetNewInjuryInformation();
      this.getPlayerInjuryInformation();
      this.getPlayerInjuryReport();
      this.getAllPlayerInjuryReports();
    },
    async updatePlayerInjuryReport() {
      const userData = {
        ...this.editInjuryReport,
        action: "updatePlayerInjuryReport",
      };
      const responce = await PlayerInjury.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data === "Player injury updated.") {
        this.loadAllInjuryReportDependencies();
        this.loadingStuff = false;

        this.$bvToast.toast("Player injury report updated", {
          title: "Updated",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async updatePlayerInjuryInformation() {
      const userData = {
        ...this.newInjuryInformation,
        action: "updatePlayerInjuryInformation",
      };
      const responce = await PlayerInjury.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data === "Player injury updated.") {
        this.loadAllInjuryReportDependencies();
        this.loadingStuff = false;

        this.$bvToast.toast("Player injury report updated", {
          title: "Updated",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async savePlayerInjuryReport() {
      const userData = {
        ...this.newInjuryInformation,
        player_id: this.selectedUserValue,
        clubId: this.userData.clubId,
        created_date: moment().format("YYYY-MM-DD"),
        action: "savePlayerInjuryReport",
      };
      const responce = await PlayerInjury.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        /*Promise.all([
          this.showAllInjuryReports(null, this.newInjuryInformation.id),
        ]).then(() => {*/
        this.getPlayerInjuryInformation();
        this.getPlayerInjuryReport();
        this.getAllPlayerInjuryReports();
        this.getDocumentUploadsCall();
        this.reSetNewInjuryInformation();
        this.loadingStuff = false;

        this.$bvModal.hide("playerInjuryModal");
        this.$bvModal.hide("allplayerInjuryReportsModal");

        this.$bvToast.toast("Player injury report saved", {
          title: "Saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
          /* });*/
        });
      }
    },
    resetPlayerCoachSignedData() {
      this.playerCoachSignedPerformanceReportData = {
        created_date: null,
        user_id: "",
        coach_id: null,
        technical_player_comments: "",
        technical_coach_comments: "",
        tactical_player_comments: "",
        tactical_coach_comments: "",
        physical_player_comments: "",
        physical_coach_comments: "",
        physcological_player_comments: "",
        physcological_coach_comments: "",
        player_comments: "",
        coach_comments: "",
        player_signature_id: null,
        parent_signature_id: null,
        coach_signature_id: null,
        published: 0,
        status: "Draft",
      };
    },
    async getPlayerCoachSignedPerformanceSignatures(id) {
      const userData = {
        id: id,
        action: "getPlayerCoachSignedPerformanceSignatures",
      };
      const responce = await Documents.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        if (responce.data.length > 0) {
          responce.data.find((signature) => {
            if (signature.type === "coachSignature") {
              this.coachSignedPerformanceReportSignature.coach_signature_change = false;
              this.playerCoachSignedPerformanceReportData.coach_signature_id =
                signature.id;
              this.playerCoachSignedPerformanceReportData.coachSignature =
                signature.signature_url;
            }
          });

          responce.data.find((signature) => {
            if (signature.type === "playerReportSignature") {
              this.coachSignedPerformanceReportSignature.player_signature_change = false;
              this.playerCoachSignedPerformanceReportData.player_signature_id =
                signature.id;
              this.playerCoachSignedPerformanceReportData.playerReportSignature =
                signature.signature_url;
            }
          });

          responce.data.find((signature) => {
            if (signature.type === "parentReportSignature") {
              this.coachSignedPerformanceReportSignature.parent_signature_change = false;
              this.playerCoachSignedPerformanceReportData.parent_signature_id =
                signature.id;
              this.playerCoachSignedPerformanceReportData.parentReportSignature =
                signature.signature_url;
            }
          });
        }
      }
    },
    openPlayerCoachSignedPerformanceModal(modelId, type, id = null) {
      this.playerCoachsignedReportReady = false;
      this.resetPlayerCoachSignedData();
      this.playerSignedPerformanceReportSelected = type;
      if (id) {
        this.playerCoachSignedPerformanceReportData =
          this.allPlayerCoachSignedPerformanceReportData.find(
            (review) => review.id === id
          );

        const fetchReportData = async () => {
          try {
            await Promise.all([
              this.getPlayerCoachSignedPerformanceSignatures(id),
              this.getPlayerCoachSignedPerformanceReport(),
            ]);
            this.playerCoachsignedReportReady = true;
            this.showModal(modelId);
          } catch (error) {
            console.error("Failed to load report data:", error);
            this.$toast.error("Unable to load report details");
          }
        };
        fetchReportData();
      } else {
        if (type === "Coach") {
          this.playerCoachSignedPerformanceReportData.coach_id =
            this.userData.id;
          this.playerCoachSignedPerformanceReportData.userImage =
            this.userData.userImage;
        }

        this.playerCoachSignedPerformanceReportData.created_date =
          moment().format("YYYY-MM-DD");
        this.playerCoachsignedReportReady = true;
        this.showModal(modelId);
      }
    },
    openPlayerPerformanceModal(modelId) {
      this.playerPerformanceReportData = {};
      this.playerPerformanceReportData.review_date =
        moment().format("YYYY-MM-DD");
      this.showModal(modelId);
    },
    openPlayerInjuryModal(modelId, selectedInjury) {
      this.reSetNewInjuryInformation();
      if (selectedInjury !== undefined) {
        this.newInjuryInformation.id = selectedInjury.id;
        this.newInjuryInformation.injury_type = selectedInjury.injury_type;
        this.newInjuryInformation.injury_status = selectedInjury.injury_status;
        this.newInjuryInformation.player_id = selectedInjury.player_id;
        this.newInjuryInformation.more_detail = selectedInjury.more_detail;
        this.newInjuryInformation.diagnosis = selectedInjury.diagnosis;
        this.newInjuryInformation.contact = selectedInjury.contact;
      }
      this.showModal(modelId);
    },
    editInjuryModal(modelId, injuryData) {
      this.newInjuryInformation = injuryData;
      this.showModal(modelId);
    },
    checkUserPosition() {
      return Object.keys(this.selectedUserData).length === 0
        ? this.userData.position
        : this.selectedUserData.position;
    },
    checkDateTimeFromNow(date) {
      let now = new Date();
      let otherDate = new Date(date);
      new Date(now.setHours(0, 0, 0, 0)).toISOString();
      new Date(otherDate.setHours(0, 0, 0, 0)).toISOString();
      const diffTime = Math.abs(
        new Date(now.setHours(0, 0, 0, 0)) -
        new Date(otherDate.setHours(0, 0, 0, 0))
      );
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      return diffDays > 7 ? "red-warning" : "";
    },
    openTrainingPlanPage() {
      if (this.selectedUserValue === "") {
        this.selectedUserValue = this.userData.id;
      }
      this.$router.push({
        name: "trainingPlans",
        params: { trainingPlanUserId: this.selectedUserValue },
      });

      /* if (userType === "User") {
        this.$store.dispatch(POPULATE_SELECTED_USER_DATA, userId);
        this.$router.push({
          name: "trainingPlans",
          params: { trainingPlanUserId: 0 }
        });
      } else {
        this.$router.push({
          name: "trainingPlans",
          params: { trainingPlanUserId: userId }
        });
      } */
    },
    performanceTargetsHistoryModal() {
      this.showModal("performanceTargetsHistoryModal");
    },
    getPlayerById(id) {
      return this.allUserDataBeforFilterApplied.find(
        (player) => Number(player.id) === Number(id)
      );
    },
    async selectedUseChanged(player, type) {
      if (type !== undefined) {
        player = this.getPlayerById(player.target.value);
      }

      if (this.userHasMoreThanOneAccount !== null) {
        this.poupulateUserData(player);
        this.userData = player;
        const userData = player;
        this.selectedUserData = player;
      }
      await this.getTeamsDataById(player.teamId);
      player.selectedUser === undefined
        ? (this.selectedUserValue = player.id)
        : (this.selectedUserValue = player.selectedUser);

      this.selectedUserDataForHeader = player;

      this.under16 = this.showIfUnderU16(player);
      this.over14 = this.showIfOverU11(this.userData);

      if (!this.checkSignedDate(this.selectedUserDataForHeader.joined_date)) {
        this.showModal("missingDataModal");
      }
      this.loadPlayersProfilePerformanceData();
      this.getPlayerUserDataId(this.selectedUserValue);
      this.getPlayerPerformanceReviews();
      if (
        this.userData.userType === "Admin" ||
        this.userData.userType === "Coach"
      ) {
        this.getAllGamesEventsForPlayer(player);
      }
      this.getGameTargets();
      this.getPlayerCoachSignedPerformanceReport();
    },
    async getTeamsDataById(teamId) {
      const userData = {
        id: teamId,
        action: "getTeamDataById",
      };

      if (
        this.userData.userType === "Admin" ||
        this.userData.userType === "Coach"
      ) {
        userData.clubId = this.userData.clubId;
      }
      const responce = await Teams.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data !== null) {
        localStorage.setItem(
          "currentPlayingseasonStartDate",
          responce.data.season_start_date
        );
        localStorage.setItem(
          "currentPlayingseasonEndDate",
          responce.data.season_end_date
        );
        (this.season_start_date = responce.data.season_start_date),
          (this.season_end_date = responce.data.season_end_date);
        return true;
      }
    },
    async loadPlayerPhysicalPerformanceData() {
      const userData = {
        player_id: this.selectedUserValue,
        action: "loadPlayerPhysicalPerformanceDataById",
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };
      const responce = await PlayerPhysicalPerformance.find(userData).catch(
        (error) => {
          console.log(error);
        }
      );

      if (responce.data.length > 0) {
        this.allPlayerPhysicalPerformanceData = responce.data;
        this.playerPhysicalPerformanceData =
          this.getPlayerPhysicalPerformanceData();
        this.checkIfNewValueisBetterOrWorse();
        this.populatePhysicalPerformanceLineChartData();
      }
    },
    loadPlayersProfilePerformanceData() {
      this.loadingStuff = true;
      this.resetAllPlayerData();
      this.getAllGamesAttendedById();
      this.getThisSeasonsgamePerformanceReportsForUser();
      this.getAllTraningAttendedById();
      this.getThisSeasonsTraningPerformanceReportsForUser();
      //this.loadPlayerPhysicalPerformanceData();
      this.getAllgoalsScoredByPlayerId();
      this.getAllgoalAssistsByPlayerId();
      this.loadPlayerMinsPerformanceData();
      this.getCoachesGamePerformanceReport();
      this.getThisSeasionsCoachesGamePerformanceReports();
      this.getAllHomeworkForPlayer();
      if (this.under16 === true) {
        this.getPlayerMinsPerformanceDataForLastGameSessionPlayMaker();
        this.getPlayerMinsPerformanceDataForLastTrainingSessionPlayMaker();
      } else {
        this.getPlayerMinsPerformanceDataForLastGameSession();
        this.getPlayerMinsPerformanceDataForLastTrainingSession();
      }
      this.loadingStuff = false;
    },
    prePopulatePlayerPerformanceValues() {
      this.playerPerformanceReportData.userImage =
        this.selectedUserData.userImage;
      this.playerPerformanceReportData.name = this.selectedUserData.name;
      this.playerPerformanceReportData.userImage =
        this.selectedUserData.userImage;
      this.playerPerformanceReportData.phone = this.selectedUserData.phone;
      this.playerPerformanceReportData.email = this.selectedUserData.email;
      this.playerPerformanceReportData.clubId = this.selectedUserData.clubId;
      this.playerPerformanceReportData.teamId = this.selectedUserData.teamId;
      this.playerPerformanceReportData.userId = this.selectedUserData.id;
      this.playerPerformanceReportData.dob = this.selectedUserData.dob;
      this.playerPerformanceReportData.position =
        this.selectedUserData.position;
      this.playerPerformanceReportData.preferred_foot =
        this.selectedUserData.preferred_foot;
      this.playerPerformanceReportData.coachId = this.userData.id;
    },
    async savePlayerPerformanceReview() {
      this.prePopulatePlayerPerformanceValues();
      const userData = {
        ...this.playerPerformanceReportData,
        performanceTargets: this.playerPerformanceTargets,
        action: "addPerformanceReview",
      };
      const responce = await PerformanceReview.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data === "New performance review added.") {
        this.getPlayerPerformanceReviews();
        this.$bvModal.hide("playerPerformanceReportModal");
        this.$bvToast.toast("Performance review added", {
          title: "Saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    checkIntTargetValue(value) {
      if (value !== undefined && value != 0) {
        return 1;
      } else {
        return 0;
      }
    },
    makeTheNewPerformanceReportTargetObject() {
      this.selectedPerformanceTarget.complete = this.checkIntTargetValue(
        this.selectedPerformanceTarget.complete
      );
      let performanceId = null;
      let idpId = null;

      if (this.selectedPerformanceTarget.typeOfTarget === "game target") {
        idpId = this.playerIDPdata.id || null;
      } else {
        performanceId =
          this.selectedPerformanceTarget.performance_report_id || null;
      }

      return {
        player_id: this.selectedUserValue,
        performance_report_id: performanceId,
        idp_id: idpId,
        target: this.selectedPerformanceTarget.target,
        complete: this.selectedPerformanceTarget.complete,
        category: this.selectedPerformanceTarget.category,
        created_date: moment().format("YYYY-MM-DD"),
        possession: this.selectedPerformanceTarget.possession,
        action: "addNewPerformanceReportTarget",
      };
    },
    async addNewPerformanceReportTarget() {
      const userData = this.makeTheNewPerformanceReportTargetObject();

      const responce = await PerformanceReview.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data === "Performance report target added") {
        this.getPerformanceReviewTargets();
        this.getGameTargets();
        this.$bvModal.hide("newPerformanceTargetsModal");
        this.$bvToast.toast("Performance report target added", {
          title: "added",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });

        this.sendFBNotificationMessage(
          "Coach added a performance report target",
          "You have a new performance report target to review.",
          [],
          this.selectedUserValue
        );
      }
    },
    async deletePlayerCoachAssessment(id) {
      const userData = {
        id: id,
        action: "deletePlayerCoachAssessment",
      };

      const responce = await PerformanceReview.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data === "Player coach assessment deleted") {
        this.getPlayerCoachSignedPerformanceReport();
        this.$bvToast.toast("Performance review deleted", {
          title: "Updated",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async deletePerformanceReview(id) {
      const userData = {
        id: id,
        action: "deletePerformanceReview",
      };

      const responce = await PerformanceReview.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data === "Performance review deleted") {
        this.getPerformanceReviewTargets();
        this.getPlayerPerformanceReviews();
        this.$bvToast.toast("Performance review deleted", {
          title: "Updated",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async deletePerformanceReportTarget() {
      const userData = {
        id: this.selectedPerformanceTarget.id,
        action: "deletePerformanceReportTarget",
      };

      const responce = await PerformanceReview.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data === "Performance report target deleted") {
        this.getPerformanceReviewTargets();
        this.getGameTargets();
        this.$bvModal.hide("performanceTargetsModal");
        this.$bvToast.toast("Performance report target deleted", {
          title: "Updated",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async updatePerformanceReportTarget() {
      const userData = {
        ...this.selectedPerformanceTarget,
        action: "updatePerformanceReportTarget",
      };
      const responce = await PerformanceReview.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data === "Performance report target updated") {
        this.getPerformanceReviewTargets();
        this.getGameTargets();
        this.$bvModal.hide("performanceTargetsModal");
        this.$bvToast.toast("Performance report target updated", {
          title: "Updated",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
        this.sendFBNotificationMessage(
          "Coach updated a performance report target",
          "You have a performance report target to review.",
          [],
          this.selectedUserValue
        );
      }
    },
    async getGameTargets() {
      const userData = {
        userId: this.selectedUserValue,
        action: "getGameTargets",
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };
      const responce = await PerformanceReview.find(userData).catch((error) => {
        console.log(error);
      });

      this.playerGameTargetsData = responce.data;
      this.selectQuaterDataForGameTargets();
    },
    async getPerformanceReviewTargets() {
      const userData = {
        userId: this.selectedUserValue,
        action: "getPerformanceReviewTargets",
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };
      const responce = await PerformanceReview.find(userData).catch((error) => {
        console.log(error);
      });

      this.playerPerformanceReviewTargetsData = responce.data;
    },
    async getAllgoalsScoredByPlayerId() {
      const data = {
        action: "getAllgoalsScoredByPlayerId",
        userId: this.selectedUserValue,
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };
      const responce = await Games.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.data) {
        this.gamePerformanceData.goals = responce.data;
      }
    },
    async getAllgoalAssistsByPlayerId() {
      const data = {
        action: "getAllgoalAssistsByPlayerId",
        userId: this.selectedUserValue,
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };
      const responce = await Games.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.data) {
        this.gamePerformanceData.assists = responce.data;
      }
    },
    async getPlayerPerformanceReviews() {
      const userData = {
        userId: this.selectedUserValue,
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
        action: "getPerformanceReviews",
      };
      const responce = await PerformanceReview.find(userData).catch((error) => {
        console.log(error);
      });

      this.allplayerPerformanceReportData = responce.data;
      this.getPerformanceReviewTargets();
    },
    async getThisSeasionsCoachesGamePerformanceReports() {
      const userData = {
        user_id: this.selectedUserValue,
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
        action: "getThisSeasionsCoachesGamePerformanceReports",
      };
      const responce = await Games.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data !== null) {
        this.filterOutAllGamesWithACoachPerformanceReport(responce.data);
      }
    },
    async getCoachesGamePerformanceReport() {
      const userData = {
        user_id: this.selectedUserValue,
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
        action: "getLastestCoachGamePerformanceReportsForUser",
      };
      const responce = await Games.find(userData).catch((error) => {
        console.log(error);
      });

      if (
        responce.data !== null &&
        responce.data !== false &&
        responce.data.length > 0
      ) {
        this.coachGamePerformanceData = responce.data;
        this.getCoachById(this.coachGamePerformanceData[0].coachId);
      }
    },
    async saveTraningPerformanceReport() {
      if (this.selectedUserValue === "") {
        this.selectedUserValue = this.userData.id;
      }
      this.loadingStuff = true;
      const userData = {
        ...this.newTrainingPerformanceData,
        user_id: this.selectedUserValue,
        created_date: moment().format("YYYY-MM-DD"),
        action: "saveUserTraningPerformanceReport",
      };
      const responce = await Games.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data === "Traning Performance Report added") {
        this.getAllTraningAttendedById();
        this.getThisSeasonsTraningPerformanceReportsForUser();
        this.$bvModal.hide("trainingPerformanceModal");
        this.loadingStuff = false;
        this.$bvToast.toast("Traning Performance Report added", {
          title: "Saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
        this.sendFBNotificationMessage(
          `${this.userData.name} added a Traning performance report`,
          `You have a new raning performance report to review from ${this.userData.name} - ${this.userData.age_group}`,
          [],
          this.userData.coach_id
        );
      }

      if (responce.data === "Training Performance Report already exists") {
        this.$bvModal.hide("trainingPerformanceModal");
        this.loadingStuff = false;
        this.$bvToast.toast("Training Performance Report already exists", {
          title: "Error",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "warning",
        });
      }
    },
    async coachesGamePerformanceReport() {
      const userData = {
        ...this.coachGamePerformanceData,
        coachId: this.userData.id,
        user_id: this.selectedUserValue,
        created_date: moment().format("YYYY-MM-DD"),
        action: "saveCoachesGamePerformanceReport",
      };
      const responce = await Games.find(userData).catch((error) => {
        console.log(error);
      });

      this.getCoachesGamePerformanceReport();
      this.getThisSeasionsCoachesGamePerformanceReports();

      this.showGamePerformanceToast(responce.data, "coachGamePerformanceModal");
      this.sendFBNotificationMessage(
        "Coach added a game performance report",
        "You have a new game performance report to review.",
        [],
        this.selectedUserValue
      );
    },
    populatePlayerSelfRating() {
      let result = this.seasonsgamePerformanceReportsForUser.reduce((a, b) =>
        a.date > b.date ? a : b
      );
      this.gamePerformanceData.gameId = result.gameId;
      this.gamePerformanceData.player_self_rating = result.player_self_rating;
      this.gamePerformanceData.created_date = result.created_date;
    },
    populateGameStatsForThisSeason(typeOfData) {
      this.gamePerformanceData[typeOfData] =
        this.seasonsgamePerformanceReportsForUser.reduce(function (
          accumulator,
          player
        ) {
          return accumulator + Number(player[typeOfData]);
        },
          0);
    },
    populateTraningStatsForThisSeason(typeOfData) {
      this.trainingPerformanceData[typeOfData] =
        this.seasonsTraningPerformanceReportsForUser.reduce(function (
          accumulator,
          player
        ) {
          return accumulator + Number(player[typeOfData]);
        },
          0);
    },
    populatePostionsPlayed() {
      if (this.seasonsgamePerformanceReportsForUser.length > 0) {
        this.gamePerformanceData.postion_played = [];
        let array = [];
        this.seasonsgamePerformanceReportsForUser.filter(function (postion) {
          array.push(postion.postion_played);
        });
        Object.keys(this.selectedUserData).length === 0
          ? this.gamePerformanceData.postion_played.push(this.userData.position)
          : this.gamePerformanceData.postion_played.push(
            this.selectedUserData.position
          );
        this.gamePerformanceData.postion_played = array;
      } else {
        Object.keys(this.selectedUserData).length === 0
          ? this.gamePerformanceData.postion_played.push(this.userData.position)
          : this.gamePerformanceData.postion_played.push(
            this.selectedUserData.position
          );
      }
    },
    pupulatePlayerCards() {
      this.seasonsgamePerformanceReportsForUser.forEach((player) => {
        if (player.card === "Yellow") {
          this.gamePerformanceData.yellowCardTotal =
            this.gamePerformanceData.yellowCardTotal + 1;
        }
        if (player.card === "Red" || player.card === "two Yellows (red)") {
          this.gamePerformanceData.redCardTotal =
            this.gamePerformanceData.redCardTotal + 1;
        }
      });
    },
    setCurrentTraningSessionRating() {
      return this.seasonsTraningPerformanceReportsForUser.reduce((a, b) =>
        a.created_date > b.created_date ? a : b
      );
    },
    async getThisSeasonsTraningPerformanceReportsForUser() {
      if (this.selectedUserValue === "") {
        this.selectedUserValue = this.userData.id;
      }
      if (localStorage.getItem("currentPlayingSeason") !== null) {
        this.currentPlayingSeason = localStorage.getItem(
          "currentPlayingSeason"
        );
      }

      const userData = {
        user_id: this.selectedUserValue,
        action: "getThisSeasonsTraningPerformanceReportsForUser",
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };
      const responce = await Games.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.seasonsTraningPerformanceReportsForUser = responce.data;
        this.trainingPerformanceData = {
          ...this.setCurrentTraningSessionRating(),
          ...this.trainingPerformanceData,
        };
        let effortData = this.populateTrainingPerformanceDataBarChart(
          "seasonsTraningPerformanceReportsForUser",
          "effort"
        );
        this.barChartDataSet(effortData, [
          "Amazing",
          "Good",
          "Ok",
          "Did not enjoy it",
        ]);
      }
    },
    barChartDataSet(effortData, labelsData) {
      this.trainingPerformanceBarChartDataSet = {
        labels: labelsData,
        datasets: [
          {
            label: "Effort",
            borderColor: "rgba(50, 115, 220, 0.5)",
            backgroundColor: "rgba(50, 115, 220, 0.1)",
            data: effortData,
          },
        ],
      };
    },
    populateBarChartWithData(arrayName, value) {
      let test = [];
      arrayName.filter((i) => {
        test.push(i[value]);
      });

      return test;
    },
    populateTrainingPerformanceDataBarChart(arrayName, value) {
      let test = [];
      this[arrayName].filter((i) => {
        test.push(i[value]);
      });

      return test;
    },
    barChartDataForLoad() {
      this.playerLoadChartDataSet = {
        labels: this.populateTrainingPerformanceDataBarChart(
          "allPlayerLoadReporsData",
          "session_date"
        ),
        datasets: [
          {
            label: "Session mins",
            borderColor: "rgba(50, 115, 220, 0.5)",
            backgroundColor: "rgba(50, 115, 220, 0.1)",
            data: this.populateTrainingPerformanceDataBarChart(
              "allPlayerLoadReporsData",
              "session_mins"
            ),
          },
        ],
      };
    },
    barChartDataForPlayerMood() {
      this.playerMoodBarChartDataSet = {
        labels: this.populateTrainingPerformanceDataBarChart(
          "thisSeasionsPlayerMoodData",
          "date"
        ),
        datasets: [
          {
            label: "fatigue",
            borderColor: "rgba(50, 115, 220, 0.5)",
            backgroundColor: "rgba(50, 115, 220, 0.1)",
            data: this.populateTrainingPerformanceDataBarChart(
              "thisSeasionsPlayerMoodData",
              "fatigue"
            ),
          },
          {
            label: "Happiness",
            borderColor: "#4caf50",
            backgroundColor: "#4caf50",
            data: this.populateTrainingPerformanceDataBarChart(
              "thisSeasionsPlayerMoodData",
              "happyness"
            ),
          },
          {
            label: "muscle soreness",
            borderColor: "#ff9f00",
            backgroundColor: "#ff9f00",
            data: this.populateTrainingPerformanceDataBarChart(
              "thisSeasionsPlayerMoodData",
              "muscle_soreness"
            ),
          },
          {
            label: "sleep",
            borderColor: "#b605ff",
            backgroundColor: "#b605ff",
            data: this.populateTrainingPerformanceDataBarChart(
              "thisSeasionsPlayerMoodData",
              "sleep"
            ),
          },
          {
            label: "stress",
            borderColor: "#e53c3c",
            backgroundColor: "#e53c3c",
            data: this.populateTrainingPerformanceDataBarChart(
              "thisSeasionsPlayerMoodData",
              "stress"
            ),
          },
        ],
      };
    },
    populateFullSeasonStrainingAndGamesstatsChartDataSetsData(
      dataName,
      playerId,
      date,
      labelName
    ) {
      const data = this.playerDailyMins.find((data) => {
        if (
          data.player_id === playerId &&
          data.session_date === date &&
          data.session_type === "Session"
        ) {
          if (data[dataName] !== undefined) {
            return data[dataName];
          }
        }
      });

      if (data !== undefined) {
        return [
          {
            label: labelName,
            backgroundColor: "#ffde00",
            data: [data[dataName]],
          },
        ];
      } else {
        return {};
      }
    },
    populateFullSeasonStrainingAndGamesstatsPlaymakerChartDataSetsData(
      dataName,
      playerId,
      date,
      labelName
    ) {
      const data = this.playerPlayMakerGpsData.find((data) => {
        if (data.player_id === playerId && data.session_date === date) {
          if (data[dataName] !== undefined) {
            return data[dataName];
          }
        }
      });

      if (data[dataName] !== 0) {
        return [
          {
            label: labelName,
            backgroundColor: "#ffde00",
            data: [data[dataName]],
          },
        ];
      } else {
        return {};
      }
    },
    populateFullSeasonStrainingAndGamesstatsAllPlaymakerChartDataSetsData(
      dataName,
      playerId,
      date,
      labelName
    ) {
      let datasetsData = [];
      const colours = [
        "#41B883",
        "#E46651",
        "#00D8FF",
        "#f7615d",
        "#41b883",
        "#16dddb",
        "#dd8b16",
        "#cddd16",
      ];

      const data = this.playerPlayMakerGpsData.filter((data, index) => {
        if (data.player_id === playerId && data.session_date === date) {
          if (data[dataName] !== undefined) {
            datasetsData.push({
              label: data.session_type,
              backgroundColor: colours[index],
              data: [data[dataName]],
            });
          }
        }
      });

      return datasetsData;
    },
    populateSeasonStrainingAndGamesPlaymakerstatsChartDataSetsData(
      dataName,
      playerId,
      date
    ) {
      return this.playerPlayMakerGpsData.map((data) => {
        if (data.player_id === playerId && data.session_date === date) {
          return data[dataName];
        }
      });
    },
    populateSeasonStrainingAndGamesstatsChartDataSetsData(
      dataName,
      playerId,
      date
    ) {
      return this.playerDailyMins.map((data) => {
        if (data.player_id === playerId && data.session_date === date) {
          return data[dataName];
        }
      });
    },
    populateSeasonStrainingAndGamesstatsChartDataLabels(
      dataLable,
      playerId,
      date
    ) {
      return this.playerDailyMins.map((data) => {
        if (data.player_id === playerId && data.session_date === date) {
          return data[dataLable];
        }
      });
    },

    populateSeasonStrainingAndGamesstatsChartPieDataPlayMakerSetsData(
      playerId,
      date,
      dataSetName
    ) {
      let labelsData = [];
      let datasetsData = [];

      this.playerPlayMakerGpsData.map((data) => {
        if (data["player_id"] === playerId && data["session_date"] === date) {
          labelsData.push(data["session_type"]);
          if (data[dataSetName] !== 0) {
            datasetsData.push(data[dataSetName]);
          }
        }
      });

      if (datasetsData.length > 0) {
        return {
          labels: labelsData,
          datasets: [
            {
              backgroundColor: [
                "#41B883",
                "#E46651",
                "#00D8FF",
                "#DD1B16",
                "#41b883",
                "#16dddb",
                "#dd8b16",
                "#cddd16",
              ],
              data: datasetsData,
            },
          ],
        };
      } else {
        return {};
      }
    },

    populateSeasonStrainingAndGamesstatsChartPieDataSetsData(
      playerId,
      date,
      dataSetName
    ) {
      let labelsData = [];
      let datasetsData = [];

      this.playerDailyMins.map((data) => {
        if (data["player_id"] === playerId && data["session_date"] === date) {
          labelsData.push(data["session_type"]);
          if (data[dataSetName] !== 0) {
            datasetsData.push(data[dataSetName]);
          }
        }
      });

      if (datasetsData.length > 0) {
        return {
          labels: labelsData,
          datasets: [
            {
              backgroundColor: [
                "#41B883",
                "#E46651",
                "#00D8FF",
                "#DD1B16",
                "#41b883",
                "#16dddb",
                "#dd8b16",
                "#cddd16",
              ],
              data: datasetsData,
            },
          ],
        };
      } else {
        return {};
      }
    },
    populatePysicalPerformanceLineChartDataSetsData(dataName) {
      return this.allPlayerPhysicalPerformanceData.map(
        (data) => data[dataName]
      );
    },

    populatePysicalPerformanceLineChartLabels() {
      return this.allPlayerPhysicalPerformanceData.map((data) => data.date);
    },
    populatePysicalPerformanceLineChartDataSets() {
      return [
        {
          label: "10m SPRINT",
          backgroundColor: "#91afea",
          data: this.populatePysicalPerformanceLineChartDataSetsData(
            "tenM_sprint_time"
          ),
        },
        {
          label: "30m SPRINT",
          backgroundColor: "#c791ea",
          data: this.populatePysicalPerformanceLineChartDataSetsData(
            "fortyM_sprint_time"
          ),
        },
      ];
    },
    populatePysicalPerformanceSingleDataPointChartSets(
      lableName,
      colour,
      typeOfData
    ) {
      return [
        {
          label: lableName,
          backgroundColor: colour,
          data: this.populatePysicalPerformanceLineChartDataSetsData(
            typeOfData
          ),
        },
      ];
    },
    populateSeasonStrainingAndGamesstatsChartDataPointChartSets(
      lableName,
      colour,
      typeOfData
    ) {
      return [
        {
          label: lableName,
          backgroundColor: colour,
          data: this.populateSeasonStrainingAndGamesstatsChartDataSetsData(
            typeOfData
          ),
        },
      ];
    },
    populateSeasonTrainingAndGamesstatsAccelerationsAndDecelerationsChart(
      playerid,
      date
    ) {
      return [
        {
          label: "Accelerations",
          backgroundColor: "#00ff2a",
          data: this.populateSeasonStrainingAndGamesstatsChartDataSetsData(
            "accelerations_total_efforts",
            playerid,
            date
          ),
        },
        {
          label: "Decelerations",
          backgroundColor: "#ff4e00",
          data: this.populateSeasonStrainingAndGamesstatsChartDataSetsData(
            "decelerations_total_efforts",
            playerid,
            date
          ),
        },
      ];
    },
    populateFullSessionTrainingAndGamesstatsBarChart(
      label,
      typeOfData,
      date,
      playerid
    ) {
      return [
        {
          label: label,
          backgroundColor: "#00ff2a",
          data: this.populateSeasonStrainingAndGamesstatsChartDataSetsData(
            typeOfData,
            playerid,
            date
          ),
        },
      ];
    },
    populatePhysicalPerformanceLineChartData() {
      this.physicalPerformanceLineChartData = {
        labels: this.populatePysicalPerformanceLineChartLabels(),
        datasets: this.populatePysicalPerformanceLineChartDataSets(),
      };

      this.physicalPerformanceLineCountermovementJumpChartData = {
        labels: this.populatePysicalPerformanceLineChartLabels(),
        datasets: this.populatePysicalPerformanceSingleDataPointChartSets(
          "Countermovement jump",
          "#91afea",
          "cuntermovement_jump"
        ),
      };

      this.physicalPerformanceLineReactiveStrengthIndexChartData = {
        labels: this.populatePysicalPerformanceLineChartLabels(),
        datasets: this.populatePysicalPerformanceSingleDataPointChartSets(
          "Reactive strength index",
          "#91afea",
          "reactive_strength_index"
        ),
      };

      this.physicalPerformanceLineThirtyFithteenIntermittentFitnessTestChartData =
      {
        labels: this.populatePysicalPerformanceLineChartLabels(),
        datasets: this.populatePysicalPerformanceSingleDataPointChartSets(
          "30-15 Intermittent Fitness Test",
          "#91afea",
          "thirty_15_intermittent_fitness_test"
        ),
      };

      this.physicalPerformanceLineHeightChartData = {
        labels: this.populatePysicalPerformanceLineChartLabels(),
        datasets: this.populatePysicalPerformanceSingleDataPointChartSets(
          "Height",
          "#91afea",
          "height"
        ),
      };

      this.physicalPerformanceLineWeightChartData = {
        labels: this.populatePysicalPerformanceLineChartLabels(),
        datasets: this.populatePysicalPerformanceSingleDataPointChartSets(
          "Weight",
          "#91afea",
          "weight"
        ),
      };
    },
    async getThisSeasonsgamePerformanceReportsForUser() {
      this.seasonsgamePerformanceReportsForUser = [];
      if (this.selectedUserValue === "") {
        this.selectedUserValue = this.userData.id;
      }
      if (localStorage.getItem("currentPlayingSeason") !== null) {
        this.currentPlayingSeason = localStorage.getItem(
          "currentPlayingSeason"
        );
      }
      const userData = {
        user_id: this.selectedUserValue,
        action: "getThisSeasonsgamePerformanceReportsForUser",
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };
      const responce = await Games.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.seasonsgamePerformanceReportsForUser = responce.data;
        this.populatePlayerSelfRating();
        this.populateGameStatsForThisSeason("goals");
        this.populateGameStatsForThisSeason("assists");
        this.populateGameStatsForThisSeason("clean_sheet");
        this.populateGameStatsForThisSeason("timeOnPitch");
        this.pupulatePlayerCards();
      }

      this.populatePostionsPlayed();
    },
    showGamePerformanceToast(responce, modelId) {
      if (responce === "Game Performance Report added") {
        this.$bvModal.hide(modelId);
        this.$bvToast.toast("Game Performance Report added", {
          title: "Saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }

      if (responce === "Game Performance Report already exists") {
        this.$bvModal.hide(modelId);
        this.$bvToast.toast("Game Performance Report already exists", {
          title: "Error",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "warning",
        });
      }
    },
    async saveUserGamePerformanceReport() {
      if (this.newGamePerformanceData.player_self_rating === null) {
        this.newGamePerformanceData.player_self_rating = "Good";
      }
      this.loadingStuff = true;
      const userData = {
        ...this.newGamePerformanceData,
        user_id: this.userData.id,
        created_date: moment().format("YYYY-MM-DD"),
        action: "saveUserGamePerformanceReport",
      };
      const responce = await Games.find(userData).catch((error) => {
        console.log(error);
      });
      this.loadPlayersProfilePerformanceData();
      this.editDayMinsOnlyData();
      this.loadingStuff = false;
      this.showGamePerformanceToast(responce.data, "performanceModal");
      this.sendFBNotificationMessage(
        `${this.userData.name} added a game performance report`,
        `You have a new game performance report to review from ${this.userData.name} - ${this.userData.age_group}`,
        [],
        this.userData.coach_id
      );
    },
    async getAllGameReportsById() {
      if (this.selectedUserValue === "") {
        this.selectedUserValue = this.userData.id;
      }
      const userData = {
        user_id: this.selectedUserValue,
        action: "getAllGameReportsById",
      };
      const responce = await Games.find(userData).catch((error) => {
        console.log(error);
      });
    },
    SetTrainingHoursThisSeason() {
      return this.trainingPerformanceData.traningAttended !== 0
        ? (this.trainingPerformanceData.time =
          this.trainingPerformanceData.traningAttended * 60)
        : (this.trainingPerformanceData.time = 0);
    },
    async getAllTraningAttendedById() {
      if (this.selectedUserValue === "") {
        this.selectedUserValue = this.userData.id;
      }
      const userData = {
        user_id: this.selectedUserValue,
        action: "getAllTraningAttendedById",
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };
      const responce = await Games.find(userData).catch((error) => {
        console.log(error);
      });

      this.trainingPerformanceData.traningAttended = responce.data;
      this.SetTrainingHoursThisSeason();
    },
    async getAllGamesAttendedById() {
      if (this.selectedUserValue === "") {
        this.selectedUserValue = this.userData.id;
      }
      if (localStorage.getItem("currentPlayingSeason") !== null) {
        this.currentPlayingSeason = localStorage.getItem(
          "currentPlayingSeason"
        );
      }
      const userData = {
        user_id: this.selectedUserValue,
        action: "getAllGamesAttendedById",
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),

        year: this.currentPlayingSeason,
      };
      const responce = await Games.find(userData).catch((error) => {
        console.log(error);
      });

      this.gamePerformanceData.gamesPlayed = responce.data;
    },
    editPlayerProfileInSettings() {
      this.$router.push("/settings").catch(() => { });
    },
    hideCoachPerformanceModel() {
      this.getCoachesGamePerformanceReport();
    },
    openTrainingPerformanceModal() {
      this.getPastTrainingSessionsInfo();
      this.showModal("trainingPerformanceModal");
    },
    openGamePerformanceModal(modelId) {
      if (modelId === "coachGamePerformanceModal") {
        this.coachGamePerformanceData = [];
      }
      this.getPastGamesInfo();
      this.showModal(modelId);
    },
    getPlayerImage() {
      if (
        this.userData.userType === "Admin" ||
        this.userData.userType === "Coach"
      ) {
        return this.selectedUserDataForHeader.userImage !== "" &&
          this.selectedUserDataForHeader.userImage !== null
          ? this.getImgUrl(this.selectedUserDataForHeader.userImage)
          : this.getImgUrl("default-image.jpg");
      }

      if (this.userData.userType === "User") {
        return this.userData.userImage !== "" &&
          this.userData.userImage !== null
          ? this.getImgUrl(this.userData.userImage)
          : this.getImgUrl("default-image.jpg");
      }
    },
    filterOutAllTrainingSessionsWithAPerformanceReport() {
      let trainingSessions = [...this.pastTrainingSessionsData];

      this.pastTrainingSessionsDataFiltered = trainingSessions.map(
        (trainingSession) => {
          let hasPerformanceReport =
            this.seasonsTraningPerformanceReportsForUser.some(
              (report) => report.traningId === trainingSession.id
            );

          return {
            ...trainingSession,
            disabled: hasPerformanceReport,
          };
        }
      );
    },
    filterOutAllGamesWithACoachPerformanceReport(data) {
      const coachesReports = data.map((report) => report.gameId);
      console.log(coachesReports);
      this.pastGameDataFilteredCoach =
        this.seasonsgamePerformanceReportsForUser.map((performanceReport) => {
          return {
            ...performanceReport,
            disabled: coachesReports.includes(performanceReport.gameId),
          };
        });
    },
    filterOutAllGamesWithAPerformanceReport(data) {
      const reportsIds = this.seasonsgamePerformanceReportsForUser.map(
        (performanceReport) => performanceReport.gameId
      );
      this.pastGameDataDataFiltered = data.map((gameData) => {
        return {
          ...gameData,
          disabled: reportsIds.includes(gameData.id),
        };
      });
    },
    async getPastTrainingSessionsInfo() {
      if (this.selectedUserValue === "") {
        this.selectedUserValue = this.userData.id;
      }
      if (localStorage.getItem("currentPlayingSeason") !== null) {
        this.currentPlayingSeason = localStorage.getItem(
          "currentPlayingSeason"
        );
      }

      const userData = {
        user_id: this.selectedUserValue,
        action: "getPastTrainingSessionsInfo",
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };
      const responce = await Games.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.pastTrainingSessionsData = this.filterOutGames(responce.data);
        this.filterOutAllTrainingSessionsWithAPerformanceReport();
      }
    },
    filterOutGames(data) {
      return data.filter(function (item) {
        return item.gameType === "Training";
      });
    },
    async getAllGamesEventsForPlayer(player) {
      const userData = {
        teamId: player.teamId,
        action: "getAllGamesEventsForPlayer",
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };
      const responce = await Games.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.allGameData = responce.data;
      }
    },
    async getPastGamesInfo() {
      if (this.selectedUserValue === "") {
        this.selectedUserValue = this.userData.id;
      }
      if (localStorage.getItem("currentPlayingSeason") !== null) {
        this.currentPlayingSeason = localStorage.getItem(
          "currentPlayingSeason"
        );
      }

      const userData = {
        user_id: this.selectedUserValue,
        action: "getPastGamesEvents",
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };
      const responce = await Games.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        const cleanedData = await this.removeOtherteamsGames(responce.data);

        this.filterOutAllGamesWithAPerformanceReport(cleanedData);
        this.pastGameData = cleanedData;
        //this.pastGameDataDataFiltered = responce.data;
      }
    },
    getGamesWithTeamDetails(teams, gameData) {
      const teamIds = teams.map((team) => team.id.toString());

      return gameData
        .filter((game) => teamIds.includes(game.teamId))
        .map((game) => {
          const matchingTeam = teams.find(
            (team) => team.id.toString() === game.teamId
          );
          return {
            ...game,
            teamDetails: matchingTeam,
          };
        });
    },
    async removeOtherteamsGames(gamesData) {
      let updatedTeamsData = [];
      const userData = {
        userId: this.selectedUserValue,
        action: "getTeamsDataById",
      };

      const responce = await Teams.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        const gamesWithTeamDetails = this.getGamesWithTeamDetails(
          responce.data,
          gamesData
        );
        return gamesWithTeamDetails;

        /*updatedTeamsData = responce.data.map(team => 
          gamesData.filter(game => game.teamId === team.id.toString())
        );
  
        console.log(updatedTeamsData);*/

        return updatedTeamsData[0];
      }
    },
    async getLatestPlayerMoodData() {
      if (this.selectedUserValue === "") {
        this.selectedUserValue = this.userData.id;
      }
      const userData = {
        player_id: this.selectedUserValue,
        action: "getLatestPlayerMoodData",
      };
      const responce = await Wellness.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data !== undefined) {
        this.playerMoodData = responce.data;
        this.getThisSeasionsPlayerMoodData();
      }
    },
    async getThisSeasionsPlayerMoodData() {
      const userData = {
        player_id: this.selectedUserValue,
        action: "getThisSeasionsPlayerMoodData",
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };
      const responce = await Wellness.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.thisSeasionsPlayerMoodData = responce.data;
        this.barChartDataForPlayerMood();
        this.populatePlayerWellnesData();
      }
    },
    showModal(modelId) {
      this.$bvModal.show(modelId);
    },
    setMonToSundayWeekDates() {
      const year = JSON.stringify(moment().year());
      const monday = moment()
        .year(year)
        .isoWeek(this.weekSelectedNumber)
        .day("monday")
        .startOf("day");
      this.selectedWeekMonSunday =
        monday.format("MMM D - ") + monday.add(6, "days").format("D YYYY");
      // this.depatchMonToSundayWeekDatesInLocalStorage();
    },
    mondaySunDatesChanged(data) {
      this.selectedWeekMonSunday = data.monToFri;
    },
    calendarDateChanged(date) {
      this.weeklyDate = date;
    },
    weekSelectedChanged(number) {
      this.weekSelectedNumber = number.weekSelectedNumber;
    },
    tabChange(tabValue, selectedTabValue) {
      this[tabValue] = selectedTabValue;
      if (selectedTabValue === "payments-tab") {
        this.getPlayersPaymentInformation();
      }

      if (selectedTabValue === "players-reports-tab") {
        this.getAllBookingsMadeInlastTwoMonths();
      }

      if (selectedTabValue === "injury-tab") {
        this.getPlayerInjuryInformation();
        this.getPlayerInjuryReport();
        this.getAllPlayerInjuryReports();
        this.getDocumentUploadsCall();
      }

      if (selectedTabValue === "wellness-tab") {
        this.getLatestPlayerMoodData();
      }

      if (selectedTabValue === "learning-development-plan-tab") {
        this.getallQuatersPlayerIDPscores();
        this.getGameTargets();
        this.getIDPGoals();
      }

      if (selectedTabValue === "player-documents-tab") {
        this.getDocumentUploadsByUserId();
        this.getCharterData();
        this.getSignatures();
      }

      if (selectedTabValue === "homework-tab") {
        this.getAllHomeworkForTeam();
      }

      if (selectedTabValue === "review-tab") {
        this.loadPlayerPhysicalPerformanceData();
      }
    },
    async getAllBookingsMadeInlastTwoMonths() {
      this.loadingStuff = true;
      const data = {
        action: "getAllBookingsMadeInlastTwoMonths",
        clubId: this.userData.clubId,
        lastPaymentDate: moment().subtract(2, "months").format("YYYY-MM-DD"),
      };

      const responce = await Payments.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.allBookingData = responce.data;
        this.checkPlayersPaymentStatus();

        this.loadingStuff = false;
      }
    },
    checkPlayersPaymentStatus() {
      this.playersWithoutLiveSubscriptions = this.coachesUsersData.filter(
        (player) => {
          return !this.allBookingData.some(
            (booking) => booking.user_id === player.id
          );
        }
      );
    },
    async getPlayersPaymentInformation() {
      const data = {
        action: "getPlayersBookingsById",
        userId: this.selectedUserValue,
      };

      const responce = await Payments.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.playersPayment = responce.data;
      }
    },
    async getUserByIdReturnData(clientId) {
      const userData = {
        clientId: clientId,
        action: "getUserByID",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        return responce.data[0];
      }
    },
    async getPlayerUserDataId(clientId) {
      const userData = {
        clientId: clientId,
        action: "getUserByID",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        this.selectedUserData = responce.data[0];
        this.selectedTeamId = this.selectedUserData.teamId;
        this.populatePlayerCV(this.selectedUserData);
        this.populateAcademyReport();
        this.getPlayerCoachSignedPerformanceReport();
      }
    },
    async getUserById(clientId) {
      const userData = {
        clientId: clientId,
        action: "getUserByID",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        this.userData = responce.data[0];
        const userData = responce.data[0];
        this.selectedUserData = responce.data[0];
        this.$store.dispatch(USER_POPULATE, { userData });
        this.populatePlayerCV(this.selectedUserData);
        this.populateAcademyReport();
        this.getPlayerCoachSignedPerformanceReport();
        if (
          this.userData.userType === "User" &&
          !this.checkSignedDate(this.userData.joined_date)
        ) {
          this.showModal("missingDataModal");
        }
        return true;
      }
    },
    setUser() {
      if (this.getProfile !== null) {
        this.userData = this.getProfile;
      }
    },
    async saveHowYouFeel() {
      const today = moment();
      let todayFormatted = moment(today).format("YYYY-MM-DD");
      if (this.wellnessDaySelected !== null) {
        todayFormatted = moment(this.wellnessDaySelected.date).format(
          "YYYY-MM-DD"
        );
      }
      const userData = {
        player_id: this.userData.id,
        ...this.wellnessQuestions,
        date: todayFormatted,
        action: "savePlayerMood",
      };

      const responce = await Wellness.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data === "Your mood has been saved.") {
        this.getLatestPlayerMoodData();
        this.$bvModal.hide("viewDailyWellnessReport");
        this.$bvToast.toast("Your mood has been saved.", {
          title: "Saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }

      if (
        responce.data === "You have already submitted your daily mood today."
      ) {
        this.$bvModal.hide("viewDailyWellnessReport");
        this.$bvToast.toast(
          "You have already submitted your daily mood today.",
          {
            title: "Info",
            toaster: "b-toaster-bottom-right",
            solid: true,
            appendToast: true,
            variant: "warning",
          }
        );
      }
    },
    async getCoachById(id) {
      const userData = {
        id: id,
        action: "getCoachById",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.selectedCoachImageURL =
          responce.data[0].userImage !== "" &&
            responce.data[0].userImage !== null
            ? this.getImgUrl(responce.data[0].userImage)
            : this.getImgUrl("default-image.jpg");
      }
    },
    addInjuredPlayerInfo(data) {
      this.coachesUsersData = this.coachesUsersData.map((player) => {
        const hasInjury = data.some(
          (injury) =>
            player.id == injury.player_id &&
            injury.injury_status !== "Fully recoverd"
        );
        player.injury = hasInjury;
        return player;
      });
    },
    async highlightInjuredPlayers() {
      const userData = {
        clubId: this.userData.clubId,
        action: "getInjuryedPlayersByClubId",
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };
      const responce = await PlayerInjury.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.addInjuredPlayerInfo(responce.data);
      }
    },
    async getAllGoalKeepersForCoach() {
      const userData = {
        clubId: this.userData.clubId,
        action: "getAllGoalKeepersForCoach",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.populatePlayerDataFromAllUsersCall(responce.data);
      }
    },
    async getAllUsersForCoach() {
      const userData = {
        clubId: this.userData.clubId,
        coach_id: this.userData.id,
        action: "getAllUsersForCoach",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.populatePlayerDataFromAllUsersCall(responce.data);
      }
    },
    async getTeamsByCoachid() {
      const data = {
        action: "getTeamsByCoachid",
        coach_id: this.userData.id,
      };

      const responce = await Teams.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.clubTeams = this.removeDuplicates(responce.data, "id");
      }
    },
    async getTeamsByUserId() {
      if (this.userData.userType === "Coach") {
        this.getTeamsByCoachid();
      } else {
        const clientId = JSON.parse(
          localStorage.getItem("the_w_selectedClientId")
        );
        const data = {
          action: "getTeamsDataById",
          userId: clientId,
        };

        if (this.userData.userType === "Admin") {
          data.clubId = this.userData.clubId;
        }
        const response = await Teams.find(data).catch((error) => {
          console.log(error);
        });
        this.clubTeams = this.removeDuplicates(response.data, "id");
      }
    },
    populatePlayerDataFromAllUsersCall(data) {
      this.coachesUsersData = data;
      this.highlightInjuredPlayers();
      this.$store.dispatch(POPULATE_COACHES_USER_DATA, this.coachesUsersData);
      localStorage.setItem(
        "coachesUsersData",
        JSON.stringify(this.coachesUsersData)
      );
      this.allUserDataBeforFilterApplied = this.coachesUsersData;
      this.getTeamsByUserId();
      if (this.$route.params.playerData !== undefined) {
        const data = this.allUserDataBeforFilterApplied.filter((player) => {
          return player.id === this.$route.params.playerData.id;
        });
        this.selectedUseChanged(data[0]);
      }
    },
    async getAllUsersForClubId() {
      const userData = {
        clubId: this.userData.clubId,
        action: "getUserByClubId",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.populatePlayerDataFromAllUsersCall(responce.data);
      }
    },
    async getAllUsersForThisAdmin() {
      const userData = {
        coachId: this.userData.id,
        action: "getUserByCoachId",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        this.populatePlayerDataFromAllUsersCall(responce.data);
      }
    },
    checkForCoachesUsersData() {
      if (this.getCoachesUsersData.length > 0) {
        this.coachesUsersData = this.getCoachesUsersData;
      } else {
        if (
          this.userData.userType === "Admin" ||
          this.userData.userType === "Coach"
        ) {
          this.getAllUsersForThisAdmin();
        }
      }
    },
    pounds(amount) {
      const numberWithRemovedZeros = amount / 100;
      if (amount !== undefined) {
        return Intl.NumberFormat("en-GB", {
          style: "currency",
          currency: "GBP",
        }).format(numberWithRemovedZeros);
      }
    },
    formatDate(date) {
      if (date === null) {
        return "No Date";
      }
      return moment(date).format("MMMM Do YYYY");
    },
    formatDates(date) {
      return moment(date).format("DD MMM YYYY");
    },
    formatISOStringDateAndTime(timeDateString) {
      return moment(timeDateString).utc().format("MMMM Do YYYY h:mm a");
    },
    formatDateForDB(date) {
      return moment(date).format("YYYY-MM-DD");
    },
  },
};
</script>
<style scoped lang="scss">
.game-stats {
  margin-bottom: 1rem;
}

.metrics-wrapper {
  padding: 2rem 0;
  width: 100%;

  h4 {
    text-transform: uppercase;
    width: 100%;
  }

  .metrics-item {
    border-bottom: 1px dashed #a9b3bc;
    margin-bottom: 1rem;

    .session-date {
      font-size: 0.8rem;
      margin: 0;
    }

    .title {
      font-size: 0.8rem;
      text-transform: uppercase;
      margin: 0;
    }

    .value {
      font-size: 2.5rem;
      font-weight: bold;
      margin: 0;

      span {
        font-size: 0.8rem;
        font-weight: normal;
        margin: 0;
      }
    }
  }
}

.split-module {
  border-top: 1px dashed #a9b3bc;
  border-bottom: 1px dashed #a9b3bc;
  margin-bottom: 2rem;
  padding-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
  min-height: 19rem;
}

.visual-split-fade {
  background: linear-gradient(to bottom, #fafafa 0%, #ffffff 100%);
  padding: 1rem;
}

.doc-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 1rem;

  svg {
    border: 1px solid #c3cdd5;
    padding: 0.3rem;
    font-size: 1.7rem;

    &:hover,
    &:focus {
      cursor: pointer;
      background: #eaeff3;
    }
  }
}

.uploaded-documents-wrapper {
  border-top: 1px solid #dee2e6;
  padding-top: 1rem;
  margin-top: 1rem;
}

.document-upload-physio-report {
  border: 1px solid #dee2e6;
  padding: 1rem;
  margin-bottom: 1rem;
  background: #f2f5f7;
}

.attached-reports-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: 2rem;
  margin-top: 2rem;
  border-top: 1px dashed #dee2e6;
  padding-top: 2rem;
}

.player-coach-signed-reviews {
  margin-bottom: 2rem;
}

.buttons-wrapper {
  margin: 1rem 0;
  display: flex;
  gap: 0.5rem;
}

.block-item {
  display: block !important;
}

.select-user {
  margin-bottom: 2rem;

  .form__label {
    margin-right: 1rem;
  }
}

.home-work-tab.tab-content {
  overflow: scroll;
}

.player-homework,
.team-homework {
  width: 100%;
  overflow: scroll;

  .homework-table {
    width: 95rem;
  }
}

.physical-performance-table {
  width: 100%;
  max-height: 59rem;
  overflow-y: scroll;

  .physicalPerformanceTable {
    width: 144rem;
  }
}

.physical-performance-trends {
  overflow-x: scroll;
}

.col-wrapper-charts {
  width: 100%;
  height: 100%;

  .cols {
    padding: 2rem;
  }
}

section.player-self-assessment-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  h3 {
    width: 100%;
  }
}

.physical-performance-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  width: 100%;
}

.charter {
  border: 1px solid #d6dadd;
  padding: 2rem;
  margin-bottom: 1rem;

  .signatureWrapper {
    div.player-game-responce {
      border: none;
    }
  }
}

.tick-wellness {
  color: #4cae50;
  margin: 0;
  padding: 89% 0px;
}

button.mins-button {
  width: 100%;
}

.goal-item {
  padding: 1rem 0;
}

.player-game-targets {
  border-top: 1px solid #a9b3bc;
  padding: 2rem 0;
  margin-top: 1rem;

  .game-target {
    border: 1px solid #efefef;
    padding: 1rem;
    border-radius: 4px;
    min-width: 18rem;
  }

  .in-possession,
  .out-of-possession {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1rem;

    .targets {
      min-width: 16rem;

      &:first-child {
        min-width: 16rem;

        h4 {
          font-size: 1rem;
          text-align: center;
        }

        svg {
          margin: 0 auto;
          margin: 1rem auto;
          display: block;
        }
      }

      border: 1px solid #efefef;
      padding: 1rem;
      border-radius: 4px;

      h4 {
        font-size: 1rem;
      }

      .target-outline {
        img {
          width: 2rem;
        }

        button {
          margin-top: 1rem;
          display: block;
        }
      }
    }
  }
}

.idp-game-targets {
  border-top: 1px dashed #dee2e6;
  margin-top: 2rem;
  padding-top: 2rem;

  .label {
    .value {
      width: 10rem;
      display: inline-block;
      font-weight: normal;
      font-size: 1.2rem;
    }
  }
}

.target-outline {
  margin-bottom: 0.5rem;
  border-bottom: 1px dashed #c0c4c8;
  padding-bottom: 1rem;
  margin-bottom: 1rem;

  .target-wrapper {}

  span:first-of-type {
    display: inline-block;
    margin-bottom: 1rem;
    margin-right: 1rem;
  }

  span.complete {
    border: 1px solid #08a10f;
    padding: 0.3rem;
    border-radius: 4px;
    color: #08a10f;
  }

  span.not-complete {
    border: 1px solid #d40035;
    padding: 0.3rem;
    border-radius: 4px;
    color: #d40035;
  }

  img {
    width: 2rem;
  }
}

.player-coach-scores-idp {
  .scores-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    .score-item {
      min-width: 17rem;
      padding: 1rem;
      border: 1px solid #efefef;
      border-radius: 4px;

      .green,
      .yellow,
      .red {
        h4 {
          padding: 1rem;
        }
      }

      .green {
        h4 {
          background: #57b88b;
          color: white;
        }

        p {
          color: #198754;
        }
      }

      .yellow {
        h4 {
          background: #fbd055;
          color: white;
        }

        p {
          color: #c89a15;
        }
      }

      .red {
        h4 {
          color: white;
          background: #e06670;
        }

        p {
          color: #b91c29;
        }
      }

      p {
        color: auto;
      }
    }
  }

  .idp-comments {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1rem;

    .comments-item {
      margin-top: 1rem;
      border: 1px solid #efefef;
      padding: 1rem;
      border-radius: 4px;
    }
  }
}

.development-plan-info {
  padding: 2rem;
  width: 100%;
}

.homework-links {
  margin-bottom: 1rem;
}

.areas-wrapper {
  border: 1px solid #dee2e6;
  padding: 1rem;
  margin-bottom: 1rem;
}

.document {
  max-width: 14rem;
  text-align: center;
  padding: 1rem;
  margin: 1rem;
  border-radius: 4px;
  box-shadow: 0 4px 16px rgba(138, 138, 138, 0.3);
  border: 1px solid transparent;

  &:hover,
  &:focus {
    border: 1px solid #dcca0d;
  }

  h3 {
    margin-top: 1rem;
    font-size: 1rem;
    overflow: hidden;
    white-space: nowrap;
    text-transform: inherit;
  }

  img {
    width: 40%;
  }
}

.player-weight-height-stats {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  h3 {
    width: 100%;
  }
}

.game-assesment-reports-table {
  max-height: 10rem;
  overflow-x: scroll;
}

.full-player-report {
  .label {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .value {
    display: block;
    font-weight: normal;
  }

  .player-assesment-report,
  .coach-assesment-report {
    border-top: 1px dashed #dee2e6;
    margin-top: 2rem;
    padding-top: 2rem;
  }
}

.gps-data-for-same-date {
  border-top: 1px dashed #dee2e6;
  margin-top: 2rem;
  padding-top: 2rem;
}

.player-game-report {
  border: 1px solid #dee2e6;
  padding: 2rem;
  width: 100%;

  h3 {
    margin-bottom: 2rem;
  }
}

.performance-targets {
  width: 100%;

  .target-categories-wrapper {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    justify-content: space-between;

    .target-categories {
      min-width: 14.8rem;
      border-right: 1px solid #a9b3bc;
      border-left: 1px solid #a9b3bc;
      padding: 1rem;
    }
  }
}

.show-imported-CSV-data {
  margin-top: 1rem;
  padding: 1rem;
  background: #e6e8e9;
  height: 20rem;
  overflow-x: scroll;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.player-stats-gps-wrapper {
  .player-session-details {
    .player-name {
      font-size: 2rem;
      font-weight: bold;
      margin: 0;
    }

    .session-name {
      p {
        margin: 0;

        span {
          width: 8rem;
          display: inline-block;
        }
      }
    }

    .main-session .wrapper-mins-stuff {
      background: #f2f5f7;
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      padding: 1rem;

      .mins-from-session {
        padding: 1rem;
        border: 1px solid #c7ccd0;
        min-width: 12rem;

        span {}

        p {
          font-size: 3rem;
          font-weight: bold;
          line-height: 2.7rem;
          margin: 0;

          span {
            font-size: 1rem;
          }
        }
      }
    }
  }
}

.mins-stats-wrapper {
  width: 99%;
  border-top: 1px solid #c3cdd5;
  padding-top: 2rem;
  overflow-x: scroll;

  .totals {
    display: flex;
    gap: 1rem;

    .total {
      border: 1px solid #c3cdd5;
      margin: 1rem 0;
      padding: 1rem;
      min-width: 10rem;

      span {}

      p {
        font-size: 2rem;
        display: block;
        font-weight: bold;
        line-height: 2rem;
      }
    }
  }

  .date-year {
    display: block;
    text-align: center;
    margin: 0.5rem;
    width: 100%;
  }

  .mins-stats-view {
    display: flex;
    width: 99%;
    width: 127rem;

    .mins-stats-col-one {
      background: green;
      width: 10rem;
      height: 100%;
    }

    .mins-stats-col-day-wrapper {
      .head {
        white-space: nowrap;
      }

      .body {

        &:hover,
        &:focus {
          background: #eeeeee;
          cursor: pointer;
        }

        .buttons-wrapper {
          display: flex;
          gap: 0.3rem;
          padding: 0;
          margin: 0;
        }

        .main-button {
          margin: 0;
          padding: 0.3rem;
        }
      }

      .head,
      .body {
        border: 1px solid #c3cdd5;
        text-align: center;
        padding: 0.5rem;
        font-size: 0.8rem;
      }
    }
  }
}

#personal_profile_text_box {
  margin-bottom: 1rem;
}

.cv-section {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px dashed #dddddd;
}

.each-target.playing-experence-item {
  border-bottom: 1px dashed #dddddd;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
}

.playing-experence-wrapper {
  padding-top: 1rem;

  .playing-experiance-add-button {
    margin-right: 1rem;
  }

  label {
    margin-right: 1rem;
  }
}

.player-cv-content {
  margin-top: 1rem;
  width: 100%;
  height: 50rem;

  iframe {
    width: 100%;
    height: 100%;
  }

  .form__item {
    display: grid;
  }
}

.performance-targets-display {
  display: block !important;

  .performance-target-item {
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;

    .target-wrapper {
      display: flex;

      img {
        width: 1rem;
        margin-right: 0.5rem;
      }
    }
  }
}

.performance-target-modal-content {
  .custom-checkbox {
    border: 1px solid #dee2e6;
    padding: 1rem;
    margin: 1rem 0;

    label.custom-control-label {
      margin-left: 0.5rem;
    }
  }
}

.target-info {
  border-bottom: 1px dotted #a9b3bc;
  margin-bottom: 1rem;
  margin-top: 1rem;

  h4 {
    img {
      width: 1rem;
    }
  }

  .target-content {
    display: flex;
    justify-content: space-between;

    p {
      max-width: 20rem;
    }
  }
}

.perfomance-targets {
  border-top: 1px solid #e4e4e4;
  padding: 1rem 0;

  label {
    button {
      margin-right: 0.5rem;
    }
  }

  .each-target {
    border-top: 1px dashed #e4e4e4;
    padding: 1rem 0;
    margin-top: 1rem;
  }
}

.player-profile-details.injury {
  .user-photo-info {
    border: 1px solid red !important;
    background: #ffeeeb;
  }
}

.headding-space {
  border-top: none;
  padding-top: 0px !important;
}

.tabs {
  .tab-item {
    min-width: auto;
  }
}

.user-info {
  p {
    img {
      width: 1.5rem;
    }
  }
}

.two-col-pop {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #dee2e6;
}

span.vue-star-rating-rating-text {
  font-size: 2rem;
  padding: 0.5rem 1rem 0 1rem;
}

.user-details-pop p span {
  font-weight: bold;
  display: inline-block;
  min-width: 8rem;
}

.td-no-padding {
  padding: 0;
}

.comments-box {
  border: 1px solid #dee2e6;
  margin-top: 1rem;
  padding: 0.5rem;
}

.table-scroll {
  width: 100%;
  overflow: scroll;

  .w-a-style {
    width: 84rem;
  }
}

.table-scroll.performance-reviews-data {
  width: 100%;
  overflow: scroll;

  .w-a-style {
    width: 61rem;
  }
}

.player-performance-review-info,
.player-performance-review-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  gap: 3rem;
  border-bottom: 1px solid #a9b3bc;
  padding-bottom: 2rem;
  width: 100%;
}

.review-average-score {
  min-width: 20rem;
}

.physical-performance-wrapper {
  .physical-performance-stats {
    ul {
      li {
        display: flex;
        font-weight: bold;
        margin-bottom: 0.5rem;
        position: relative;
        padding-right: 2.7rem;
        align-items: start;
        border-bottom: 1px solid #a9b3bc;
        margin-bottom: 1rem;
        padding-bottom: 0.5rem;

        span {
          font-weight: normal;
          width: 17rem;
        }

        span.time-value {
          width: 6rem !important;
          padding: 0 0.5rem;
        }

        button {
          margin-left: 0.5rem;
          margin-bottom: 0;
        }

        img {
          width: 1.3rem;
          margin: 0.5rem 0.5rem 0 0.5rem;
        }
      }
    }
  }
}

.small-image-name-wrapper {
  margin-bottom: 1rem;
}

.small-image {
  border: 1px solid #a9b3bc;
  width: 4rem;
  border-radius: 100px;
  margin-right: 1rem;
}

.session-enjoyment-wrapper {
  ul {
    li {
      margin-bottom: 1rem;
    }
  }
}

.all-training-reports {
  a {
    border: 1px solid #038cfc;
    padding: 0.5rem 1rem;
    display: block;
    margin-bottom: 1rem;
    border-radius: 4px;
    font-weight: bold;
    text-decoration: none;

    &:hover,
    &:focus {
      background: #47a9f9;
      color: white;
    }
  }
}

.signatureWrapper {
  margin-bottom: 2rem;

  div {
    border: 1px solid #d6dadd;
    margin-bottom: 1rem;
  }
}

.players-code {
  margin-bottom: 2rem;
}

.daily-planner-wrapper {
  width: 100%;
  padding: 0 2rem;
}

.completed {
  color: #08a10f;
}

.injury-table {
  tbody {
    tr.full-recoverd {
      border-left: 2px solid #08a10f;

      .injury-status {
        color: #08a10f;
      }
    }

    tr {
      border-left: 2px solid #d85c64;
    }
  }
}

button.btn.btn-outline-warning,
button.btn.btn-outline-danger {
  background: white;

  &:hover,
  &:focus {
    background: #ffc10c;
    color: white !important;
  }
}

table {
  tr {
    td {

      button.btn.btn-outline-warning,
      button.btn.btn-outline-danger {
        background: white;
      }

      button.btn.btn-outline-warning {

        &:hover,
        &:focus {
          color: #ffc109;
        }
      }

      button.btn.btn-outline-danger {

        &:hover,
        &:focus {
          color: #d85c64;
        }
      }
    }
  }
}

.players-without-subscriptions {
  margin: 1rem;
}

span.unpaid {
  background: #d85c64;
  border-radius: 4px;
  text-align: center;
  margin: 0 auto;
  display: block;
  color: white;
  padding: 0.1rem 1rem;
  font-weight: bold;
}

ul.injury-list {
  list-style-type: none;
  margin: 0;
  padding: 0;

  span {
    font-weight: bold;
    float: right;
  }
}

.modal-container {
  margin-top: 1rem;
  width: 100%;
  height: 50rem;

  iframe {
    border: 0;
    width: 100%;
    height: 50rem;
  }
}

.visually-hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.mood-chart {
  margin-top: 2rem;
  flex-grow: 1;
  min-height: 0;

  > div {
    position: relative;
    height: 100%;
  }
}

.player-cv {
  border: 1px solid #a9b3bc;
  max-width: 1000px;
  padding: 2rem;
  overflow: scroll;

  .sessions-info {
    display: flex;
    flex-wrap: wrap;

    .video-wrapper {
      margin-bottom: 1rem;
    }

    .session-card {
      padding: 1rem;
      margin-bottom: 1rem;
      display: block;
    }
  }
}

.modal-container.performance-report {
  img {
    width: 100%;
  }
}

.payments-tab.tab-content {
  display: block;
  padding: 1rem;
}

.player-cv-content {
  .cv-header {
    display: flex;
    margin-bottom: 2rem;

    .user-photo-info {
      display: flex;

      h1 {
        margin-left: 1rem;
      }

      img {
        border-radius: 100px;
        border: 1px solid #c3cdd5;
        width: 150px;
        height: 150px;
      }
    }

    .cv-address {
      ul {
        list-style: none;

        li {
          padding: 0.2rem 0;
        }
      }
    }
  }

  .cv-body {
    .Personal-profile {
      margin: 3rem 0;
    }

    .players-videos {
      .sessions-info {
        .session-card {
          width: 47%;
        }
      }
    }
  }
}

.education {
  margin: 2rem 0;

  ul {
    li {
      margin-bottom: 1rem;

      .title {
        font-weight: bold;
        display: block;
        margin-bottom: 0.5rem;
      }
    }
  }
}

.create-academy-report-button {
  margin-top: 2rem;
  margin-bottom: 0;
}

.filters {
  width: 100%;
  transition: all 0.4s ease-in-out;
}

.filters-sort {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;
}

.search-small {
  max-width: 20rem;
}

.filter-small {
  height: 2.5rem;
  border: transparent;
  color: #9ea7b0;

  &:hover,
  &:focus {
    background: transparent;
    color: #7c838a;
    border: transparent;
    outline: transparent;
    box-shadow: 0 0 0 0rem;
  }
}

span.paid {
  background: #00cf63;
  border-radius: 4px;
  text-align: center;
  margin: 0 auto;
  display: block;
  color: white;
  padding: 0.1rem;
  font-weight: bold;
}

.remove-icon-button {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
}

.green-totals {
  color: #05a110;
}

.amber-totals {
  color: #ffc107;
}

.red-totals {
  color: #ff2407;
}

@media screen and (min-width: 480px) {}

@media screen and (min-width: 720px) {}

@media screen and (min-width: 960px) {}

@media screen and (min-width: 1024px) {
  .metrics-wrapper {
    padding: 2rem 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;

    .metrics-item {
      border: 1px dashed #a9b3bc;
      margin-bottom: 1rem;
      padding: 1rem;
      border-radius: 4px;
    }
  }

  .signed-report {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;

    .two-col-pop {
      min-height: 13rem;
    }

    .player-signed-report,
    .coach-signed-report {
      width: 48%;
      border: 1px solid #dee2e6;
      padding: 2rem;
      background: #f9f9f9;

      .editr--content {
        background: white;
      }

      .players-charter {
        overflow: scroll;
      }
    }
  }

  .col-wrapper-charts {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    width: 100%;
    height: 100%;

    .cols {
      width: 49%;
      padding: 2rem;
    }
  }

  .full-player-report {
    .value {
      display: inline-block;
      width: 10rem;
    }
  }

  .target-outline {
    .target-wrapper {}

    span:first-of-type {
      display: inline-block;
      min-width: 22rem;
    }
  }

  .player-stats-gps-wrapper {
    .player-session-details {
      .main-session .wrapper-mins-stuff {
        .mins-from-session {
          min-width: 20rem;
        }
      }
    }
  }

  .table-scroll.performance-reviews-data {
    width: auto;
  }

  .physical-performance-stats {
    flex: 1;
  }

  .physical-performance-trends {
    flex: 4;
  }

  .player-cv {
    .sessions-info {
      .video-wrapper {
        width: 48%;
      }
    }
  }

  .all-game-reports {
    display: flex;
    gap: 1rem;

    .game-assesment-reports-table {
      min-width: 29rem;
    }
  }

  .development-plan-wrapper {
    .development-plan-main-links {
      .development-plan-item {
        max-width: 20rem;
      }
    }
  }

  .player-coach-scores-idp {
    .scores-wrapper {
      .score-item {
        min-width: 31rem;
      }
    }
  }

  .player-game-targets {

    .in-possession,
    .out-of-possession {
      min-width: 36%;

      .targets {
        min-width: 35%;
      }
    }
  }

  .player-coach-scores-idp {
    .idp-comments {
      .comments-item {
        width: 44%;
      }
    }
  }

  form.form.performance-form h3 {
    border-bottom: 2px solid #e4e2e2;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
  }

  .head-style {
    border-top: 2px solid #e4e2e2;
    padding-top: 2rem;
  }
}
</style>
