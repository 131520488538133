<template>
  <div>
    <spinner-loader :loadingStuff="loadingStuff" />
    <app-template />
    <div class="uk-container uk-container-large">
      <h2>{{ clubName }} Trialists</h2>

      <ul class="tabs">
        <li class="tab-item">
          <a
            :class="
              selectedTab === 'trialist-tab' ? 'tab-link active' : 'tab-link'
            "
            @click="tabChange('selectedTab', 'trialist-tab')"
            href="javascript:;"
            >Trial sign up</a
          >
        </li>
      </ul>

      <template>
        <div class="tabs-wrapper">
          <div
            class="current-week-tab trialist-tab tab-content"
            v-if="selectedTab === 'trialist-tab'"
          >
            <div class="add-trialist">
              <form class="form" @submit.prevent="addTrialistDetailsSubmit()">
                <div class="form__item">
                  <label class="form__label" for="name">Player name</label>
                  <input
                    class="form__input"
                    type="text"
                    v-model="selectedTrialistsData.name"
                    id="name"
                  />
                </div>

                <div class="form__item">
                  <label class="form__label" for="phone">Phone</label>
                  <input
                    type="number"
                    v-model="selectedTrialistsData.phone"
                    id="phone"
                  />
                </div>

                <div class="form__item">
                  <label class="form__label" for="email">Email Address</label>
                  <input
                    :class="
                      validationValues.email.state === true
                        ? 'danger-outline form__input'
                        : 'form__input'
                    "
                    @change="
                      checkChangeVlaidation(
                        selectedTrialistsData.email,
                        'email'
                      )
                    "
                    type="email"
                    v-model="selectedTrialistsData.email"
                    id="email"
                  />
                  <p
                    class="invalid-feedback"
                    v-if="validationValues.email.state === true"
                  >
                    {{ validationValues.email.text }}
                  </p>
                </div>

                <div class="form__item">
                  <label class="form__label" for="password">Password</label>
                  <input
                    type="password"
                    v-model="selectedTrialistsData.password"
                    id="password"
                  />
                </div>

                <div class="form__item uk-form dob">
                  <label class="form__label" for="dob">DOB</label>
                  <datepicker
                    v-model="selectedTrialistsData.dob"
                    name="dob"
                    id="dob"
                  />
                </div>

                <ImageUploader
                  @populateImageObjectFromUpload="
                    imageUploadFileDataChnged($event)
                  "
                  @populateImageFileDataFromUpload="
                    imageUploadObjectDataChanged($event)
                  "
                  @clearImageDataFromUpload="clearImageDataFromUpload()"
                  :imageURL="selectedTrialistsData.userImage"
                />

                <div class="form__item">
                  <label class="form__label" for="position">Position</label>
                  <select
                    id="position"
                    v-model="selectedTrialistsData.position"
                  >
                    <option disabled value="">Please select a position</option>
                    <option value="Goalkeeper">Goalkeeper</option>
                    <option value="Right Full-back (Wingback)">
                      Right Full-back (Wingback)
                    </option>
                    <option value="Left Full-back (Wingback)">
                      Left Full-back (Wingback)
                    </option>
                    <option value="Center-back left">Center-back left</option>
                    <option value="Center-back right">Center-back right</option>
                    <option value="Defensive Midfielder">
                      Defensive Midfielder
                    </option>
                    <option value="Centre midfield">Centre midfield</option>
                    <option value="Centre Defensive Midfield">
                      Centre Defensive Midfield
                    </option>
                    <option value="Right Midfielder (Winger)">
                      Right Midfielder (Winger)
                    </option>
                    <option value="Left Midfielder (Winger)">
                      Left Midfielder (Winger)
                    </option>
                    <option value="Attacking Midfielder">
                      Attacking Midfielder
                    </option>
                    <option value="Center Forward (Striker) left">
                      Center Forward (Striker) left
                    </option>
                    <option value="Center Forward (Striker) right">
                      Center Forward (Striker) right
                    </option>
                    <option value="I played more than one position">
                      I played more than one position
                    </option>
                  </select>
                </div>

                <div class="form__item">
                  <label class="form__label" for="coachSelect"
                    >Select team</label
                  >
                  <select
                    v-model="selectedTrialistsData.teamId"
                    id="coachSelect"
                    @change="
                      selectTeamSelectCoach(
                        'selectedTrialistsData',
                        selectedTrialistsData.teamId
                      )
                    "
                  >
                    <option disabled value="">Please select a team</option>
                    <option
                      v-for="team in teamData"
                      :value="team.id"
                      :key="team.id"
                    >
                      {{ team.teamName }}
                    </option>
                  </select>
                </div>

                <!--<div class="form__item">
            <label class="form__label" for="coachSelect">Select coach</label>
            <select v-model="selectedTrialistsData.coach_id" id="coachSelect">
              <option disabled value="">Please select a coach</option>
              <option
                v-for="coach in coachData"
                :value="coach.id"
                :key="coach.id"
              >
                {{ coach.name }}
              </option>
            </select>
          </div>-->

                <div class="form__item uk-form address">
                  <label class="form__label" for="address">Address</label>

                  <b-form-textarea
                    id="address"
                    v-model="selectedTrialistsData.address"
                    placeholder=""
                    rows="8"
                  ></b-form-textarea>
                </div>

                <div class="form__item uk-form post_code">
                  <label class="form__label" for="post_code">Post code</label>

                  <input
                    class="form__input"
                    type="text"
                    v-model="selectedTrialistsData.post_code"
                    id="post_code"
                  />
                </div>

                <div class="form__item uk-form place_of_birth">
                  <label class="form__label" for="place_of_birth"
                    >Place of birth</label
                  >

                  <input
                    class="form__input"
                    type="text"
                    v-model="selectedTrialistsData.place_of_birth"
                    id="place_of_birth"
                  />
                </div>

                <div class="form__item uk-form place_of_birth">
                  <label class="form__label" for="country_of_birth"
                    >Country of birth</label
                  >

                  <input
                    class="form__input"
                    type="text"
                    v-model="selectedTrialistsData.country_of_birth"
                    id="place_of_birth"
                  />
                </div>

                <div class="form__item uk-form nationality">
                  <label class="form__label" for="nationality"
                    >Nationality</label
                  >

                  <input
                    class="form__input"
                    type="text"
                    v-model="selectedTrialistsData.nationality"
                    id="nationality"
                  />
                </div>

                <div class="form__item uk-form previous_club">
                  <label class="form__label" for="nationality"
                    >Current club</label
                  >

                  <input
                    class="form__input"
                    type="text"
                    v-model="selectedTrialistsData.previous_club"
                    id="previous_club"
                  />
                </div>

                <div class="form__item">
                  <label class="form__label" for="parent_name"
                    >Parent name</label
                  >
                  <input
                    class="form__input"
                    type="text"
                    v-model="selectedTrialistsData.parent_name"
                    id="parent_name"
                  />
                </div>

                <div class="form__item">
                  <label class="form__label" for="emergency_contact_one"
                    >Emergency Contact</label
                  >
                  <input
                    class="form__input"
                    type="text"
                    v-model="selectedTrialistsData.emergency_contact_one"
                    id="emergency_contact_one"
                  />
                </div>

                <div class="form__item">
                  <label class="form__label" for="emergency_contact_two"
                    >Emergency Contact two</label
                  >
                  <input
                    class="form__input"
                    type="text"
                    v-model="selectedTrialistsData.emergency_contact_two"
                    id="emergency_contact_two"
                  />
                </div>

                <div class="form__item">
                  <label class="form__label" for="position">Permission</label>
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="selectedTrialistsData.permission"
                    name="checkbox-1"
                  >
                    Permission to take photographs
                  </b-form-checkbox>
                </div>

                <div class="form__item">
                  <label class="form__label" for="position"
                    >Medical information</label
                  >

                  <b-form-textarea
                    id="textarea"
                    v-model="selectedTrialistsData.medical"
                    placeholder=""
                    rows="8"
                  ></b-form-textarea>
                </div>

                <div class="form__item">
                  <label class="form__label" for="coachSelect"
                    >Select trial length</label
                  >
                  <select
                    v-model="selectedTrialistsData.trial_length"
                    id="trialLengthSelect"
                  >
                    <option disabled value="">
                      Please select a trial length
                    </option>
                    <option value="3">3 weeks</option>
                    <option value="6">6 weeks</option>
                    <option value="12">12 weeks</option>
                  </select>
                </div>

                <div class="form__item uk-form dob">
                  <label class="form__label" for="trialStartDate"
                    >Trial start date</label
                  >
                  <datepicker
                    v-model="selectedTrialistsData.trial_start_date"
                    name="trialStartDate"
                    id="trialStartDate"
                  />
                </div>

                <div class="form__item">
                  <label class="form__label" for="preferredFootSelect"
                    >Select preferred foot</label
                  >
                  <select
                    v-model="selectedTrialistsData.preferred_foot"
                    id="preferredFootSelect"
                  >
                    <option disabled value="">
                      Please select a preferred foot
                    </option>
                    <option value="Right">Right</option>
                    <option value="Left">Left</option>
                    <option value="Both">Both</option>
                  </select>
                </div>

                <div class="form__item">
                  <button type="submit" class="green-button">
                    Add trialist
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import SpinnerLoader from "../../components/LoadingSpinning";
import AppTemplate from "../layouts/App";
import { mapGetters } from "vuex";
import { Users } from "@/services/user-service.js";
import { Trialists } from "@/services/trialist-service.js";
import Datepicker from "vuejs-datepicker";
import { Teams } from "@/services/teams-service.js";
import ImageUploader from "../../components/ImageUploader";
import moment from "moment";
import removeDuplicatesMixin from "@/mixin/removeDuplicates.js";

export default {
  name: "TrialistsExternalSignUp",
  mixins: [removeDuplicatesMixin],
  components: {
    AppTemplate,
    SpinnerLoader,
    Datepicker,
    ImageUploader,
  },
  data() {
    return {
      loadingStuff: false,
      needsValidation: [],
      validationMessage: null,
      validationValues: {
        name: {
          state: false,
          text: "Please add a name please!",
        },
        email: {
          state: false,
          text: "Please add an email address",
        },
        password: {
          state: false,
          text: "Please add password please!",
        },
        dob: {
          state: false,
          text: "Please add a date of birth please!",
        },
      },
      trialistsData: [],
      liveTrialistsData: [],
      signedtrialistsData: [],
      archivedtrialistsData: [],
      coachesUsersData: [],
      selectedTab: "trialist-tab",
      teamData: [],
      clubTeams: [],
      userData: {
        name: "",
        userType: "",
      },
      userHeaderData: {
        name: "",
        userType: "",
      },
      selectedUserDataForHeader: {},
      selectedUserValue: "",
      weekSelectedNumber: "",
      selectedTrialistsData: {
        dob: "",
        trial_length: "6",
        trial_start_date: "",
        preferred_foot: "Right",
        email: "",
        name: "",
        position: "",
        attitude: 0,
        skill: 0,
        physique: 0,
        mentality: 0,
        leadership: 0,
        professionalism: 0,
        comments: "",
        outcome: "0",
        clubId: "",
        teamId: "",
        userType: "User",
      },
      imageFile: null,
      imageToBeRemoved: null,
      imageURL: "",
      image: {
        imageName: "",
        path: "",
        file: null,
      },
      registerUser: {
        img: "",
        name: "",
        email: "",
        password: "",
        dob: "",
        position: "",
        phone: "",
        emergency_contact_one: "",
        userType: "User",
        action: "register",
        teamId: 0,
        permission: 0,
        clubId: null,
        coach_id: 0,
        team_module: 1,
        preferred_foot: 0,
        joined_date: "",
        medical: "",
      },
      coachData: [],
      clubName: "",
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(["getProfile", "getCoachesUsersData"]),
  },
  async created() {
    const now = moment().format("MM-DD-YYYY");
    if ((await this.checkTeamIdexists()) === true) {
      this.clubId = this.$route.params.clubId;
      this.coachData = await this.populateCoachesData();
      this.coachId = this.coachData[0].id;
      this.teamData = await this.populateTeamData();
      this.teamId = this.teamData[0].id;
      this.setClubName();
    }
  },
  methods: {
    async getAllCoachesAttachedToTeam(teamId) {
      const data = {
        teamId: teamId,
        action: "getAllCoachesAttachedToTeam",
      };

      const responce = await Teams.find(data).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        return responce.data;
      }
    },
    async selectTeamSelectCoach(key, teamId) {
      const coaches = await this.getAllCoachesAttachedToTeam(teamId);
      this[key].coach_id = coaches[0].id;
    },
    setClubName() {
      if (this.$route.params.clubId === "surfSoccer-2085XH") {
        this.clubName = "The big test";
      }

      if (this.$route.params.clubId === "ptfc-2085XH") {
        this.clubName = "Partick Thistle";
      }

      if (this.$route.params.clubId === "hafc-2085XH") {
        this.clubName = "Hamilton Academical";
      }

      if (this.$route.params.clubId === "kfc-2085XH") {
        this.clubName = "kilmarnock fc";
      }
    },
    async checkTeamIdexists() {
      const userData = {
        clubId: this.$route.params.clubId,
        action: "checkTeamIdexists",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });

      return responce.data.length > 0 ? true : false;
    },
    async checkIfEmailExists(value) {
      const userData = {
        email: value,
        action: "checkEmailexists",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.validationValues.email.state = true;
        this.validationValues.email.text =
          "Email address aready exits, please change it";
      } else {
        this.validationValues.email.state = false;
        this.validationValues.email.text = "Please add an email address";
      }
    },
    requiredEmailValidation(value) {
      var validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      return validRegex.test(value);
    },
    checkChangeVlaidation(value, elementName) {
      if (elementName === "email") {
        if (value !== "") {
          this.checkIfEmailExists(value);
        } else {
          this.validationValues.email.state = false;
          this.validationValues.email.text = "Please add an email address";
        }
        const validated = this.requiredEmailValidation(value);
        this.validationValues[elementName].state = validated !== true;
      } else {
        JSON.stringify(value).length > 0
          ? (this.validationValues[elementName].state = false)
          : (this.validationValues[elementName].state = true);
      }
    },
    getCoachesName(coach_id) {
      let name = "";
      this.coachData.map((coach) => {
        if (coach.id === coach_id) {
          name = coach.name;
        }
      });
      return name;
    },
    async populateTeamData() {
      const teamData = {
        clubId: this.clubId,
        action: "populateTeamData",
      };

      const responce = await Teams.find(teamData).catch((error) => {
        console.log(error);
      });

      return responce.data.length > 0
        ? this.removeDuplicates(responce.data, "id")
        : [];
    },
    async populateCoachesData() {
      const coachesData = {
        clubId: this.clubId,
        action: "populateCoachesData",
      };

      const responce = await Users.find(coachesData).catch((error) => {
        console.log(error);
      });

      return responce.data.length > 0 ? responce.data : [];
    },
    getTeamName(teamId) {
      if (teamId === "") {
        return teamId;
      }
      var selectedTeam = this.clubTeams.filter(function (team) {
        return team.id == teamId;
      });

      return selectedTeam[0].teamName;
    },
    trialLength(number) {
      if (number == "6") {
        return "6 weeks";
      }
      if (number == "12") {
        return "12 weeks";
      }
    },
    tabChange(tabValue, selectedTabValue) {
      this[tabValue] = selectedTabValue;
    },
    getImgUrl(pic) {
      return pic !== undefined && pic !== "" ? `/images/${pic}` : "";
    },
    clearImageDataFromUpload() {
      (this.imageURL = ""),
        (this.image = {
          imageName: "",
          path: "",
          file: null,
        });
    },
    imageUploadObjectDataChanged(data) {
      this.image = data;
    },
    imageUploadFileDataChnged(data) {
      this.imageFile = data;
    },
    async saveTrialistPerformanceReview() {
      this.loadingStuff = true;
      const userData = {
        action: "updateTrialist",
        ...this.selectedTrialistsData,
      };

      const responce = await Trialists.find(userData).catch((error) => {
        console.log(error);
      });

      this.getAllTrialists();
      this.hideModal("ViewTrialist");
      this.loadingStuff = false;
      this.$bvToast.toast("Trialist updated.", {
        title: "Updated",
        toaster: "b-toaster-bottom-right",
        solid: true,
        appendToast: true,
        variant: "success",
      });
    },
    async addTrialistDetailsSubmit() {
      if (this.$route.params.trialistId !== "0") {
        this.selectedTrialistsData.leadId = this.$route.params.trialistId;
      } else {
        this.selectedTrialistsData.leadId === 0;
      }

      this.selectedTrialistsData.attitude = 0;
      this.selectedTrialistsData.skill = 0;
      this.selectedTrialistsData.physique = 0;
      this.selectedTrialistsData.mentality = 0;
      this.selectedTrialistsData.leadership = 0;
      this.selectedTrialistsData.professionalism = 0;
      this.selectedTrialistsData.comments = "";
      this.selectedTrialistsData.outcome = 0;
      this.selectedTrialistsData.clubId = this.$route.params.clubId;

      this.loadingStuff = true;
      if (this.image.imageFile !== null && this.imageFile !== null) {
        this.selectedTrialistsData.fileName = this.imageFile.imageName;
        this.selectedTrialistsData.imageFile = this.image;
      }
      this.selectedTrialistsData.trial_start_date = this.fotmatDatesForDb(
        this.selectedTrialistsData.trial_start_date
      );
      this.selectedTrialistsData.age_group = this.formatAgeGroupForSQL(
        this.selectedTrialistsData.dob
      );

      this.selectedTrialistsData.coach_name = this.getCoachesName(
        this.selectedTrialistsData.coach_id
      );
      this.selectedTrialistsData.permission = true ? 1 : 0;
      this.selectedTrialistsData.userType = "User";

      const userData = {
        ...this.selectedTrialistsData,
        registerUser: {
          img: "",
          name: this.selectedTrialistsData.name,
          email: this.selectedTrialistsData.email,
          password: this.selectedTrialistsData.password,
          dob: this.selectedTrialistsData.dob,
          position: this.selectedTrialistsData.position,
          phone: this.selectedTrialistsData.phone,
          emergency_contact_one:
            this.selectedTrialistsData.emergency_contact_one,
          userType: this.selectedTrialistsData.userType,
          action: "register",
          teamId: this.selectedTrialistsData.teamId,
          permission: 0,
          clubId: this.selectedTrialistsData.clubId,
          coach_id: this.selectedTrialistsData.coach_id,
          team_module: 1,
          preferred_foot:
            this.selectedTrialistsData.preferred_foot === "Left" ? 0 : 1,
          joined_date: this.selectedTrialistsData.trial_start_date,
          medical: this.selectedTrialistsData.medical,
          parent_name: this.selectedTrialistsData.parent_name,
          age_group: this.selectedTrialistsData.age_group,
          emergency_contact_two:
            this.selectedTrialistsData.emergency_contact_two,
          address: this.selectedTrialistsData.address,
          place_of_birth: this.selectedTrialistsData.place_of_birth,
          country_of_birth: this.selectedTrialistsData.country_of_birth,
          nationality: this.selectedTrialistsData.nationality,
          previous_club: this.selectedTrialistsData.previous_club,
          post_code: this.selectedTrialistsData.post_code,
        },
        action: "addTrialist",
      };
      const responce = await Trialists.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.loadingStuff = false;
        this.$bvToast.toast("Trialist added.", {
          title: "Added",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
        this.$router.push("/login");
      }
    },
    resetSelectedCoach() {
      this.selectedTrialistsData = {
        dob: "",
        trial_length: "6",
        trial_start_date: "",
        preferred_foot: "Right",
        email: "",
        name: "",
        position: "",
        attitude: 0,
        skill: 0,
        physique: 0,
        mentality: 0,
        leadership: 0,
        professionalism: 0,
        comments: "",
        outcome: "0",
        clubId: "",
        teamId: "",
      };
    },
    fotmatDatesForDb(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    formatDateForHuman(date) {
      return moment(date).format("MMMM Do YYYY");
    },
    formatDatesForSQL(date) {
      return moment(date).format(moment.HTML5_FMT.DATE);
    },
    formatAgeGroupForSQL(dob) {
      const d = new Date(dob);
      return d.getFullYear();
    },
  },
};
</script>
<style lang="scss" scoped>
.two-col-pop {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #dee2e6;
}

span.vue-star-rating-rating-text {
  font-size: 2rem;
  padding: 0.5rem 1rem 0 1rem;
}

.user-details-pop p span {
  font-weight: bold;
  display: inline-block;
  min-width: 8rem;
}

.out-come-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;

  button {
    min-width: 9rem;
  }

  .red-button {
    background: #d85c64;
  }

  .yellow-button {
    background: #ffc107;
  }
}

.outCome-Under {
  border: 1px solid #004ca3;
  padding: 0.5rem !important;
  margin: 0.5rem 0 !important;
  color: #008afc;
  background: #e8f5ff;
}

.outCome-Signed {
  border: 1px solid #18a300;
  padding: 0.5rem !important;
  margin: 0.5rem 0 !important;
  color: #18a300;
  background: #ecffe8;
}

.outCome-Rejected {
  border: 1px solid #d85c64;
  padding: 0.5rem !important;
  margin: 0.5rem 0 !important;
  color: #d85c64;
  background: #ffe8ec;
}

.outCome-Waiting {
  border: 1px solid #ffc107;
  padding: 0.5rem !important;
  margin: 0.5rem 0 !important;
  color: #ffc107;
  background: #fffbe8;
}

.trialist-tab {
  padding: 2rem;
}
</style>
