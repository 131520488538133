export default {
  methods: {
    extendSeasonEndDateToFullYear() {
      const startDate = localStorage.getItem("currentPlayingseasonStartDate");
      const endDate = localStorage.getItem("currentPlayingseasonEndDate");

      const month = startDate.substring(5, 7);
      const year = endDate.substring(0, 4);
      const day = startDate.substring(12, 8);

      return `${year}-${month}-${day}`;
    },
  },
};
