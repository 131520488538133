<template>
  <div class="archive training-plans tab-content">
    <spinner-loader :loadingStuff="loadingStuff" />

    <button
      @click="showEditAddProgrammeModal()"
      v-if="userData.userType === 'Admin'"
      class="green-button add-new-programme"
    >
      Add new programme
    </button>

    <div class="search-filter">
      <div v-if="trainingPlanData.length > 0">
        <!-- <b-input-group size="sm" class="mb-2 autocomplete">
        <b-input-group-prepend is-text>
          <b-icon icon="search"></b-icon>
        </b-input-group-prepend>
        <b-form-input type="search" placeholder="Search" @input="lookUpSearch($event)"></b-form-input>
      </b-input-group>-->

        <sorting-by-created-date
          :arrayDataForSorting="trainingPlanData"
          sortingByValue="created_date"
          @sorted-data-call="sortedData"
        />
      </div>
    </div>
    <template v-if="trainingPlanData.length > 0">
      <div
        v-for="session in trainingPlanData"
        :value="session.id"
        :key="session.id"
        class="training-session-entry"
      >
        <div class="session-content" @click="openTrainingInfoModel(session)">
          <div class="session-image-wrapper">
            <img
              v-if="session.imageURL !== ''"
              :src="getImgUrl(session.imageURL)"
              alt=""
              class="session-image"
            />
            <img
              v-else
              :src="getImgUrl('/test-image.jpg')"
              alt=""
              class="session-image"
            />
          </div>
          <div class="session-data">
            <h3>{{ session.name }}</h3>
            <p class="training-type-text">
              {{ session.section }} - id: {{ session.id }}
            </p>
            <p class="week-text">
              Week number: {{ session.week }} | {{ session.year }}
            </p>
            <p>
              {{ session.introText }}
            </p>
          </div>
        </div>
        <div v-if="userData.userType === 'Admin'" class="action-buttons">
          <b-button
            @click="showEditAddProgrammeModal('edit', session)"
            variant="outline-primary"
            >Edit</b-button
          >
          <b-button
            @click="deletetrainingPlan(session.id, session.imageURL)"
            variant="outline-danger"
            >Delete</b-button
          >
        </div>
      </div>
    </template>
    <template v-else>
      <div class="empty-state">
        <img src="./img/emptyStateIllustration.svg" />
        <p>No Programmes for this week</p>
      </div>
    </template>

    <b-modal
      id="sessionModal"
      size="lg"
      :title="sessionDataModal.name"
      :hide-footer="Boolean(true)"
    >
      <ul class="tabs">
        <li class="tab-item">
          <a
            :class="
              selectedSessionTab === 'details' ? 'tab-link active' : 'tab-link'
            "
            @click="tabChange('selectedSessionTab', 'details')"
            href="javascript:;"
            >Details</a
          >
        </li>
        <li class="tab-item">
          <a
            :class="
              selectedSessionTab === 'comments' ? 'tab-link active' : 'tab-link'
            "
            @click="tabChange('selectedSessionTab', 'comments')"
            href="javascript:;"
            ><span class="number-alert" v-if="commentsData.length > 0">{{
              commentsData.length
            }}</span
            >Comments</a
          >
        </li>
      </ul>

      <div class="tabs-wrapper">
        <div
          class="tab-content no-flex"
          v-if="selectedSessionTab === 'details'"
        >
          <div
            v-if="sessionDataModal.imageURL !== ''"
            class="large-training-image-wrapper"
          >
            <img
              class="large-training-image"
              :src="getImgUrl(sessionDataModal.imageURL)"
              alt=""
            />
          </div>

          <div class="training-details-content">
            <h4>Description</h4>
            <p>{{ sessionDataModal.introText }}</p>
            <div v-html="sessionDataModal.details"></div>

            <div class="notes">
              <h4>Notes</h4>
              <p>{{ sessionDataModal.notes }}</p>
            </div>
          </div>
        </div>
        <div class="tab-content" v-if="selectedSessionTab === 'comments'">
          <CommentsComp
            @getComments="getComments"
            :commentsData="commentsData"
            :userAndTypeIds="{
              user_id: sessionDataModal.userId,
              type_id: sessionDataModal.id,
              teamId: 0,
              clubId: userData.clubId,
            }"
          />
        </div>
      </div>
    </b-modal>

    <b-modal
      id="addEditProgramme"
      size="lg"
      :title="editMode === false ? 'Add new programme' : 'Edit programme'"
      :hide-footer="Boolean(true)"
      @hide="resetUserIdList()"
    >
      <form class="form" @submit.prevent="addEditProgrammeSubmit()">
        <div class="form__item">
          <label class="form__label" for="selectSection"
            >Select type of programme</label
          >
          <select v-model="newSessionData.section" id="selectSection">
            <option disabled value="">Please select a programme</option>
            <option value="Training Plan">Training Plan</option>
            <option value="Mental training">Mental training</option>
            <option value="Nutrition">Nutrition</option>
            <option value="Recovery">Recovery</option>
          </select>
        </div>
        <div class="form__item">
          <label class="form__label" for="userSelect"
            >Who is the programme for</label
          >

          <div class="user-select-wrapper">
            <div v-for="user in coachesUsersData" :key="user.id">
              <input
                @change="userIdsAddRemove($event)"
                type="checkbox"
                :id="user.id"
                :name="user.id"
                :value="user.name"
                :checked="user.selected"
              />
              <label :for="user.id">{{ user.name }}</label
              ><br />
            </div>
          </div>
        </div>

        <div class="form__item">
          <label class="form__label" for="imageURL">Upload image</label>
          <input
            accept="image/jpeg|image/jpg|image/heic|.jpeg"
            @change="imageChange($event, 'imageFile', 'image', 'fileOne')"
            type="file"
            class="form__input"
            ref="fileOne"
            id="imageURL"
          />
        </div>
        <div v-if="image.path !== ''" class="image-preview-wrapper">
          <img class="image-preview" :src="getPath(image.path, 'image')" />
          <button @click="removeFileFromUI()" class="remove-file-button">
            Remove flie
          </button>
        </div>
        <div class="form__item">
          <label class="form__label" for="weeklyDate">Select Day</label>
          <datepicker
            v-model="weeklyDate"
            name="weeklyDate"
            id="weeklyDate"
            @selected="setTheWeekNumber"
          />
        </div>
        <div class="form__item">
          <label class="form__label" for="sessionName"
            >Session Name <span class="uk-text-danger">*</span></label
          >
          <input
            :class="
              validationValues.name.state === true ? 'danger-outline' : ''
            "
            @change="checkChangeVlaidation(newSessionData.name, 'name')"
            name="sessionName"
            class="form__input"
            type="text"
            v-model="newSessionData.name"
            id="sessionName"
          />
          <p
            class="invalid-feedback"
            v-if="validationValues.name.state === true"
          >
            {{ validationValues.name.text }}
          </p>
        </div>
        <div class="form__item">
          <label class="form__label" for="sessionIntroText"
            >Session intro Text <span class="uk-text-danger">*</span></label
          >
          <textarea
            :class="
              validationValues.introText.state === true ? 'danger-outline' : ''
            "
            @change="
              checkChangeVlaidation(newSessionData.introText, 'introText')
            "
            id="sessionIntroText"
            v-model="newSessionData.introText"
            placeholder="Add text"
            name="sessionIntroText"
          ></textarea>
          <p
            class="invalid-feedback"
            v-if="validationValues.introText.state === true"
          >
            {{ validationValues.introText.text }}
          </p>
        </div>

        <div class="form__item">
          <label class="form__label" for="sessionDetails"
            >Session details</label
          >
          <wysiwyg v-model="newSessionData.details" />
        </div>

        <div class="form__item">
          <label class="form__label" for="notes">Notes</label>
          <textarea
            id="notes"
            v-model="newSessionData.notes"
            placeholder="Add a note"
            name="notes"
          ></textarea>
        </div>

        <div v-if="editMode === false" class="form__item">
          <button type="submit" class="green-button">Add programme</button>
        </div>
        <div v-else class="form__item">
          <button type="submit" class="green-button">Edit programme</button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import { TrainingPlan } from "@/services/training-plan-service.js";
import { Comments } from "@/services/comments-service.js";
import SortingByCreatedDate from "../../components/Sorting";
import CommentsComp from "../../components/Comments";
import SpinnerLoader from "../../components/LoadingSpinning";
import moment from "moment";
import Datepicker from "vuejs-datepicker";

export default {
  name: "TrainingPlanLibrary",
  components: { Datepicker, SortingByCreatedDate, SpinnerLoader, CommentsComp },
  data() {
    return {
      loadingStuff: false,
      filterSelectedValue: [
        "Training Plan",
        "Mental Training",
        "Nutrition",
        "Recovery",
      ],
      imageFile: null,
      imageToBeRemoved: null,
      imageURL: "",
      editMode: false,
      image: {
        imageName: "",
        path: "",
        file: null,
      },
      trainingPlanData: [],
      coachesUsersData: [],
      sessionDataModal: {},
      needsValidation: [],
      validationMessage: null,
      validationValues: {
        name: {
          state: false,
          text: "Please add a name",
        },
        introText: {
          state: false,
          text: "Please add session intro Text",
        },
      },
      weeklyDate: "",
      selectedSessionTab: "details",
      newSessionData: {
        userId: "",
        section: "",
        details: "",
        weekSelectedNumber: "",
        name: "",
        introText: "",
        notes: "",
        day: "",
        year: "",
        week: "",
      },
      listOfSelectedUserIds: [],
      listOfSelectedUserIdsToRemove: [],
      commentsData: [],
    };
  },
  props: {
    trainingTypeAndUserData: {
      type: Object,
      default: null,
    },
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(["getProfile", "getCoachesUsersData"]),
  },
  watch: {
    trainingTypeAndUserData: function () {
      this.getTrainingPlansData();
    },
  },
  created() {
    this.userData = this.getProfile;
    this.getTrainingPlansData();
  },
  methods: {
    resetUserIdList() {
      this.listOfSelectedUserIds = [];
      this.listOfSelectedUserIdsToRemove = [];
    },
    addTolistOfSelectedUserIds(id) {
      this.listOfSelectedUserIds.push(id);
      this.listOfSelectedUserIdsToRemove =
        this.listOfSelectedUserIdsToRemove.filter((item) => item !== id);
    },
    removeFromlistOfSelectedUserIds(id) {
      this.listOfSelectedUserIdsToRemove.push(id);
      this.listOfSelectedUserIds = this.listOfSelectedUserIds.filter(
        (item) => item !== id
      );
    },
    userIdsAddRemove(data) {
      data.target.checked === true
        ? this.addTolistOfSelectedUserIds(data.target.id)
        : this.removeFromlistOfSelectedUserIds(data.target.id);
    },
    imageChange(event, imageFileName, imageNumber, inputRef) {
      const file = event.currentTarget.files[0];
      this.createObjectURLCheck(file, imageFileName, imageNumber);
      this.image.file = file;
      this.image.imageName = file.name;

      var files = event.target.files || event.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      var image = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.readAsDataURL(file);
      reader.onload = (event) => {
        this.imageFile = event.target.result;
      };
    },
    createObjectURLCheck(file, imageFileName, imageNumber) {
      if (file.type === "image/heic") {
        this.convertHeicToJpg(file, imageFileName, imageNumber);
      }
      "srcObject" in this
        ? (this.srcObject = file)
        : (this.image.path = URL.createObjectURL(file));
    },
    async convertHeicToJpg(file, imageFileName, imageNumber) {
      let base64 = URL.createObjectURL(file);
      fetch(base64)
        .then((res) => res.blob())
        .then((blob) => heic2any({ blob, toType: "image/jpeg" }))
        .then((conversionResult) => {
          let base64 = URL.createObjectURL(conversionResult);
          this[imageNumber].path = base64;
          this[imageFileName] = new File([conversionResult], "newimage", {
            type: "image/jpeg",
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    removeFileFromUI() {
      (this.imageURL = ""),
        (this.image = {
          imageName: "",
          path: "",
          file: null,
        });
    },
    sortedData(sortedDataArray) {
      console.log(sortedDataArray);
    },
    showEditAddProgrammeModal(type, session) {
      this.image.path = "";
      this.editMode = false;

      if (type === "edit") {
        this.editMode = true;
        this.newSessionData = session;

        if (session.imageURL !== "") {
          this.image.path = this.getImgUrl(session.imageURL);
        }

        this.gettrainingPlanByTrainingPlanId(session.id);
      } else {
        this.newSessionObjectReset();
        let today = moment();
        this.weeklyDate = today._d;
        this.setTheWeekNumber(this.weeklyDate);
        this.newSessionData.userId = this.selectedUserValue;
      }
      this.coachesUsersData = this.getCoachesUsersData;
      this.showModal("addEditProgramme");
    },
    checkforDotAtTheStartOfUrl(p) {
      return p.startsWith(".") ? p.substring(1) : p;
    },
    checkTheimageForBlob(p, imageNumber) {
      if (p !== null) {
        var URL = this.checkforDotAtTheStartOfUrl(p);
      }
      switch (typeof URL) {
        case "string":
          URL.search("blob:") !== -1
            ? p
            : `${window.location.protocol}//${window.location.host}${URL}`;
          return URL.search("blob:") !== -1
            ? p
            : `${window.location.protocol}//${window.location.host}${URL}`;
        case "object":
          return this[imageNumber].path;
      }
    },
    getPath(p, imageNumber) {
      if (p === undefined) {
        return;
      }
      return this.checkTheimageForBlob(p, imageNumber);
    },
    getImgUrl(pic) {
      return pic !== undefined && pic !== "" ? `/images/${pic}` : "";
    },
    tabChange(tabValue, selectedTabValue) {
      this[tabValue] = selectedTabValue;
    },
    openTrainingInfoModel(sessionData) {
      this.sessionDataModal = sessionData;
      this.getComments(sessionData.id);
      this.showModal("sessionModal");
    },
    showModal(modelId) {
      this.$bvModal.show(modelId);
    },
    hideModal(modelId) {
      this.$bvModal.hide(modelId);
    },
    getTheUserId() {
      if (
        this.userData.userType === "Admin" ||
        this.userData.userType === "Coach"
      ) {
        return this.trainingTypeAndUserData.userId;
      } else {
        return this.userData.id;
      }
    },
    getTrainingPlansByUserIdAndWeekSetUpCall(traningType) {
      if (this.getTheUserId() !== undefined) {
        this.gettrainingPlansByUserIdAndWeek(this.getTheUserId(), traningType);
      }
    },
    async getTrainingPlansData() {
      this.trainingTypeAndUserData.trainingData === "library"
        ? this.getTrainingPlanLibraryData(
            this.trainingTypeAndUserData.trainingType
          )
        : this.getTrainingPlansByUserIdAndWeekSetUpCall(
            this.trainingTypeAndUserData.trainingType
          );
    },
    setSelectedCheckDefaults() {
      let arrayWithSelectedDefaults = this.coachesUsersData.map((user) => {
        return { ...user, selected: false };
      });
      this.coachesUsersData = arrayWithSelectedDefaults;
    },
    addCheckedValuetoUsers(ids) {
      this.setSelectedCheckDefaults();

      let arrayWithSelectedTrue;
      ids.map((id) => {
        arrayWithSelectedTrue = this.coachesUsersData.map((user) => {
          if (user.id == id.user_id) {
            user.selected = true;
          }
          return user;
        });
      });

      this.coachesUsersData = arrayWithSelectedTrue;
    },
    async gettrainingPlanByTrainingPlanId(trainingPalnId) {
      const data = {
        trainingPalnId: trainingPalnId,
        action: "gettrainingPlanByTrainingPlanId",
      };
      const responce = await TrainingPlan.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.addCheckedValuetoUsers(responce.data);
      }
    },
    async getTrainingPlanLibraryData(trainingType) {
      const trainingPlanData = {
        trainingType: trainingType,
        clubId: this.userData.clubId,
        action: "gettrainingPlanByClubId",
      };
      const responce = await TrainingPlan.find(trainingPlanData).catch(
        (error) => {
          console.log(error);
        }
      );

      responce.data.length > 0
        ? (this.trainingPlanData = responce.data)
        : (this.trainingPlanData = []);
    },
    async gettrainingPlansByUserIdAndWeek(clientId, trainingType) {
      const now = moment().format("MM-DD-YYYY");
      this.loadingStuff = false;
      const trainingPlanData = {
        userId: clientId,
        trainingType: trainingType,
        week: moment(now).isoWeek(),
        action: "getTrainingPlanByUserIdAndWeek",
      };
      const responce = await TrainingPlan.find(trainingPlanData).catch(
        (error) => {
          console.log(error);
        }
      );

      responce.data.length > 0
        ? (this.trainingPlanData = responce.data)
        : (this.trainingPlanData = []);

      this.loadingStuff = false;
    },
    newSessionObjectReset() {
      this.weeklyDate = "";
      this.newSessionData = {
        userId: "",
        section: "Training Plan",
        details: "",
        weekSelectedNumber: "",
        name: "",
        introText: "",
        notes: "",
        day: "",
        year: "",
        week: "",
        clubId: this.userData.clubId,
      };
    },
    setTheWeekNumber(date) {
      this.weekSelectedNumber = moment(date).isoWeek();
      this.newSessionData.week = moment(date).isoWeek();
      this.newSessionData.day = moment(date, "DD MMM YYYY").day();
      this.newSessionData.year = moment(date, "DD MMM YYYY").year();
    },
    async deletetrainingPlan(id, imageURL) {
      const data = {
        id: id,
        imageURL: imageURL,
        action: "deletetrainingPlan",
      };

      const responce = await TrainingPlan.find(data).catch((error) => {
        console.log(error);
      });

      if (
        responce.data ===
        "file and training session removedFile and session was deleted successfully."
      ) {
        this.makeToast("Remove", "Deleted successfully.", "success");
        this.$bvToast.toast("Deleted successfully.", {
          title: "Remove",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
        this.getTrainingPlansData();
      }
    },
    checkChangeVlaidation(value, nameOfElementToValidate) {
      if (value.length > 0) {
        this.validationValues[nameOfElementToValidate].state = false;
      }
    },
    setValidationMessage() {
      if (this.needsValidation.length === 0) {
        this.validationMessage = null;
        this.needsValidation = [];
      } else {
        this.validationMessage =
          "Please fix the validation errors before you can proceed.";
        this.isLoading = false;
      }
    },
    reCheckValidation() {
      this.setValidationMessage();
      return this.needsValidation.length === 0;
    },
    populateValidationArray(validationData) {
      return validationData.map((validation) => {
        return validation === true;
      });
    },
    filterValidationResults(validationResults) {
      return validationResults.filter((result) => {
        return result === true;
      });
    },
    togglevalaidationErrors(name, validationState) {
      this.validationValues[name].state = validationState;
    },
    requiredValidation(value) {
      return !value || value.length === 0;
    },
    setUpValidation(validationValue, validationName) {
      const validationstate = this.requiredValidation(validationValue);
      this.togglevalaidationErrors(validationName, validationstate);
      return validationstate;
    },
    validationCheck(event) {
      let validationData = [];
      this.needsValidation = [];
      validationData.push(
        this.setUpValidation(this.newSessionData.name, "name")
      );
      validationData.push(
        this.setUpValidation(this.newSessionData.introText, "introText")
      );
      let validationResults = this.populateValidationArray(validationData);
      this.needsValidation = this.filterValidationResults(validationResults);
      return this.reCheckValidation();
    },
    makeToast(title, message, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    addEditProgrammeSubmit(e) {
      if (this.editMode === false) {
        if (this.validationCheck(e) === true) {
          this.savetrainingNewPlan();
        }
      } else {
        if (this.validationCheck(e) === true) {
          this.edittrainingPlan();
        }
      }
    },
    async savetrainingNewPlan() {
      this.loadingStuff = true;
      const trainingPlanData = this.makeTraingDataObject(
        "savetrainingPlanToDd"
      );
      const responce = await TrainingPlan.store(trainingPlanData).catch(
        (error) => {
          console.log(error);
        }
      );
      if (responce.data === "Training Plan added.") {
        this.actionsAfterFitnessSessionEditCreate(
          "Created",
          "Created successfully."
        );
      }
    },
    makeTraingDataObject(actionName) {
      let data = this.newSessionData;
      data.complete = false;
      data.action = actionName;
      data.listOfSelectedUserIds = this.listOfSelectedUserIds;
      data.listOfSelectedUserIdsToRemove = this.listOfSelectedUserIdsToRemove;

      if (this.image.file !== null) {
        data.imageFile = this.imageFile;
        data.fileName = this.image.imageName;
      }
      return data;
    },
    actionsAfterFitnessSessionEditCreate(toastTitle, toastMessage) {
      this.hideModal("addEditProgramme");
      this.getTrainingPlansData();
      this.loadingStuff = false;
      this.$bvToast.toast(toastMessage, {
        title: toastTitle,
        toaster: "b-toaster-bottom-right",
        solid: true,
        appendToast: true,
        variant: "success",
      });
    },
    async edittrainingPlan() {
      this.loadingStuff = true;
      const trainingPlanData = this.makeTraingDataObject("edittrainingPlan");
      const responce = await TrainingPlan.store(trainingPlanData).catch(
        (error) => {
          console.log(error);
        }
      );

      if (responce.data === "Training session updated") {
        this.actionsAfterFitnessSessionEditCreate(
          "Updated",
          "updated successfully."
        );
        this.editMode = false;
      }
    },
    async getComments(type_id) {
      this.commentsData = [];
      const data = {
        type_id: type_id,
        teamId: 0,
        action: "getComments",
      };

      const responce = await Comments.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.commentsData = responce.data;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.session-image-wrapper {
  background: #f2f5f7;

  img.session-image {
    display: block;
    margin: 0 auto;
  }
}
.search-filter {
  border-bottom: 1px dashed #c3cdd5;

  .filter-options {
    margin-bottom: 1rem;
  }

  label.custom-control-label {
    margin-left: 0.5rem;
  }

  .custom-checkbox {
    margin-right: 1rem;
  }

  #checkbox-group-filter {
    display: flex;
    flex-wrap: wrap;
  }

  .autocomplete {
    max-width: 28rem;
  }
}

.empty-state {
  height: auto;
}

.tabs-wrapper .tab-content {
  height: 100%;
  width: 100%;
}
</style>
