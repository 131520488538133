<template>
  <div class="percent-wrapper">
    <div
      v-if="circularType === 'player-summary-stats'"
      class="player-summary-stats"
    >
      <h3>Average scores</h3>
      <div class="performance-bar">
        <p>
          <span
            :class="setBarVariant(playerPerformanceReportDataSummary.attitude)"
            >{{ playerPerformanceReportDataSummary.attitude }}</span
          >
          Attitude
        </p>

        <b-progress
          :value="playerPerformanceReportDataSummary.attitude"
          :variant="setBarVariant(playerPerformanceReportDataSummary.attitude)"
          max="5"
          class="mb-3"
        ></b-progress>
      </div>

      <div class="performance-bar">
        <p>
          <span
            :class="setBarVariant(playerPerformanceReportDataSummary.skill)"
            >{{ playerPerformanceReportDataSummary.skill }}</span
          >
          Skill
        </p>
        <b-progress
          :value="playerPerformanceReportDataSummary.skill"
          :variant="setBarVariant(playerPerformanceReportDataSummary.skill)"
          max="5"
          class="mb-3"
        ></b-progress>
      </div>

      <div class="performance-bar">
        <p>
          <span
            :class="setBarVariant(playerPerformanceReportDataSummary.physique)"
            >{{ playerPerformanceReportDataSummary.physique }}</span
          >
          Physique
        </p>
        <b-progress
          :value="playerPerformanceReportDataSummary.physique"
          :variant="setBarVariant(playerPerformanceReportDataSummary.physique)"
          max="5"
          class="mb-3"
        ></b-progress>
      </div>

      <div class="performance-bar">
        <p>
          <span
            :class="setBarVariant(playerPerformanceReportDataSummary.mentality)"
            >{{ playerPerformanceReportDataSummary.mentality }}</span
          >
          Mentality
        </p>
        <b-progress
          :value="playerPerformanceReportDataSummary.mentality"
          :variant="setBarVariant(playerPerformanceReportDataSummary.mentality)"
          max="5"
          class="mb-3"
        ></b-progress>
      </div>

      <div class="performance-bar">
        <p>
          <span
            :class="
              setBarVariant(playerPerformanceReportDataSummary.leadership)
            "
            >{{ playerPerformanceReportDataSummary.leadership }}</span
          >
          Leadership
        </p>
        <b-progress
          :value="playerPerformanceReportDataSummary.leadership"
          :variant="
            setBarVariant(playerPerformanceReportDataSummary.leadership)
          "
          max="5"
          class="mb-3"
        ></b-progress>
      </div>

      <div class="performance-bar">
        <p>
          <span
            :class="
              setBarVariant(playerPerformanceReportDataSummary.professionalism)
            "
            >{{ playerPerformanceReportDataSummary.professionalism }}</span
          >
          Professionalism
        </p>
        <b-progress
          :value="playerPerformanceReportDataSummary.professionalism"
          :variant="
            setBarVariant(playerPerformanceReportDataSummary.professionalism)
          "
          max="5"
          class="mb-3"
        ></b-progress>
      </div>
    </div>
    <div v-if="circularType === 'single'" class="single-chart">
      <svg viewBox="0 0 36 36" class="circular-chart" :class="clourValue">
        <path
          class="circle-bg"
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path
          class="circle"
          :stroke-dasharray="`${percentageNumber}, 100`"
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <text x="18" y="20.35" class="percentage">
          {{ totalScore(review) }} / 30
        </text>
      </svg>
    </div>

    <div v-if="circularType === 'muilti'" class="single-chart">
      <svg viewBox="0 0 36 36" class="circular-chart" :class="clourValue">
        <path
          class="circle-bg"
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path
          class="circle"
          :stroke-dasharray="`${percentageNumber}, 100`"
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <text x="18" y="20.35" class="percentage">
          {{ totalScore(playerPerformanceReportDataSummary) }}
        </text>
      </svg>
      <p>Players average performance score</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "circularPercentage",
  data() {
    return {
      clourValue: "blue",
      percentageNumber: 0,
      playerPerformanceReportDataSummaryArrays: {
        attitude: [],
        skill: [],
        physique: [],
        mentality: [],
        leadership: [],
        professionalism: [],
      },
      playerPerformanceReportDataSummary: {
        attitude: 0,
        skill: 0,
        physique: 0,
        mentality: 0,
        leadership: 0,
        professionalism: 0,
      },
    };
  },
  props: {
    circularType: {
      type: String,
      default: null,
    },
    review: {
      type: Object,
      default: null,
    },
    allPlayerReviews: {
      type: Array,
      default: null,
    },
  },
  computed: {},
  watch: {
    allPlayerReviews: {
      immediate: true,
      handler() {
        if (
          this.allPlayerReviews !== null &&
          this.allPlayerReviews.length > 0
        ) {
          this.setPlayersAvragePerformanceReportsScore();
        }
      },
    },
  },
  methods: {
    setBarVariant(value) {
      if (value > 0 && value <= 2) {
        return "danger";
      }
      if (value > 2 && value <= 3) {
        return "warning";
      }
      if (value > 3 && value <= 4) {
        return "primary";
      }
      if (value > 4 && value <= 5) {
        return "success";
      }
    },
    totalScore(playerPerformanceReportData) {
      let values = [
        playerPerformanceReportData.attitude,
        playerPerformanceReportData.skill,
        playerPerformanceReportData.physique,
        playerPerformanceReportData.mentality,
        playerPerformanceReportData.leadership,
        playerPerformanceReportData.professionalism,
      ];

      var totalScoreValue = values.reduce(function (accumulator, value) {
        return accumulator + +value;
      }, 0);

      this.setColourValue(totalScoreValue);

      return Math.round(totalScoreValue * 100) / 100;
    },
    setColourValue(totalScoreValue) {
      if (totalScoreValue <= 10) {
        this.clourValue = "red";
        this.percentageNumber = 10;
      }

      if (totalScoreValue > 10 && totalScoreValue < 15) {
        this.percentageNumber = 30;
        this.clourValue = "red";
      }

      if (totalScoreValue >= 15 && totalScoreValue < 20) {
        this.percentageNumber = 35;
        this.clourValue = "orange";
      }

      if (totalScoreValue >= 20 && totalScoreValue < 25) {
        this.percentageNumber = 40;
        this.clourValue = "green";
      }

      if (totalScoreValue >= 25 && totalScoreValue < 30) {
        this.clourValue = "green";
        this.percentageNumber = 90;
      }

      if (totalScoreValue === 30) {
        this.clourValue = "green";
        this.percentageNumber = 100;
      }
    },
    setAverage(type) {
      this.playerPerformanceReportDataSummary[type] = this.average(
        this.playerPerformanceReportDataSummaryArrays[type]
      );
    },
    average(list) {
      return (
        list.reduce((prev, curr) => Number(prev) + Number(curr)) / list.length
      ).toFixed(2);
    },
    makeListsOfPerformanceReportsScore(type, value) {
      this.playerPerformanceReportDataSummaryArrays[type].push(value);
    },
    setPlayersAvragePerformanceReportsScore() {
      this.allPlayerReviews.map((report) => {
        this.makeListsOfPerformanceReportsScore("attitude", report.attitude);
        this.makeListsOfPerformanceReportsScore("skill", report.skill);
        this.makeListsOfPerformanceReportsScore("physique", report.physique);
        this.makeListsOfPerformanceReportsScore("mentality", report.mentality);
        this.makeListsOfPerformanceReportsScore(
          "leadership",
          report.leadership
        );
        this.makeListsOfPerformanceReportsScore(
          "professionalism",
          report.professionalism
        );
      });

      this.setAverage("attitude");
      this.setAverage("skill");
      this.setAverage("physique");
      this.setAverage("mentality");
      this.setAverage("mentality");
      this.setAverage("leadership");
      this.setAverage("professionalism");
    },
  },
};
</script>
<style scoped lang="scss">
.single-chart {
  width: 100%;
  justify-content: space-around;
}

.circular-chart {
  display: block;
  margin: 10px auto;
  max-width: 80%;
  max-height: 250px;
}

.circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.circular-chart.orange .circle {
  stroke: #ff9f00;
}

.circular-chart.green .circle {
  stroke: #4cc790;
}

.circular-chart.red .circle {
  stroke: #e53c3c;
}

.percentage {
  fill: #666;
  font-family: sans-serif;
  font-size: 0.5em;
  text-anchor: middle;
}

.performance-bar span {
  font-weight: bold;
  border-radius: 100px;
}
</style>
