var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"space"},[_c('div',{staticClass:"form__item"},[(_vm.showHomeTeamsPiches === true)?_c('label',{staticClass:"form__label",attrs:{"for":"selectpitchType"}},[_vm._v("Select Pitch")]):_c('label',{staticClass:"form__label",attrs:{"for":"selectpitchType"}},[_vm._v("Add a Pitch")]),(_vm.showHomeTeamsPiches === true)?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.pitch_id),expression:"pitch_id"}],attrs:{"id":"selectpitch"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.pitch_id=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.pitchChangeCall($event)}]}},[_c('option',{attrs:{"disabled":"","value":""}},[_vm._v("Please select a pitch")]),_vm._l((_vm.pitches),function(pitch){return _c('option',{key:pitch.id,domProps:{"value":pitch.id}},[_vm._v(" "+_vm._s(pitch.name)+" ")])})],2):_vm._e(),(_vm.showHomeTeamsPiches === true)?_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.defaultPitchInputsCall('newGameData')}}},[_vm._v("Or enter new pitch details")]):_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){_vm.showHomeTeamsPiches = true}}},[_vm._v("View teams Piches")])]),(
      _vm.gameData.matchDate !== '' &&
      _vm.pitch_id !== '' &&
      _vm.gameData.gameType === 'Training'
    )?_c('div',{staticClass:"pitch-avaiability"},[_c('p',[_vm._v("Booked times")]),_vm._l((_vm.selectedGameTrainingEvent),function(game){return _c('p',{key:game.id,attrs:{"value":game.id}},[(
          game.pitch_id === _vm.pitch_id &&
          game.matchDate === _vm.formatMatchDate(_vm.gameData.matchDate)
        )?_c('span',{staticClass:"booked-time"},[_vm._v(_vm._s(_vm.getDayAndDate(game.matchDate))+" "+_vm._s(_vm.formatISOStringDateAndTime(game.kickOffTime))+" "+_vm._s(_vm.checkEndTime(game.kickOffTime, game.endTime))+" - "+_vm._s(_vm.setPitchZoneText(game)))]):_vm._e()])}),(
        _vm.showPitchSectionsSelectInfo === true &&
        _vm.selectedPitch[0].pitch_size === '11-a-side'
      )?_c('div',{staticClass:"pitchSectionsSelectInfo"},[_c('p',[_vm._v("Select full pitch or sections of the pitch.")]),(
          _vm.pitchBookedState.sectionOne === false ||
          _vm.pitchBookedState.sectionTwo === false ||
          _vm.pitchBookedState.sectionThree === false
        )?_c('div',[_c('input',{attrs:{"type":"checkbox","id":"fullPitch","name":"fullPitch"},domProps:{"checked":_vm.pitch.fullPitch},on:{"change":function($event){return _vm.pitchSelectToggle('fullPitch', _vm.pitch.pitchId)}}}),_c('label',{attrs:{"for":"fullPitch"}},[_vm._v("Select full pitch")]),_c('br')]):_c('div',[_c('input',{attrs:{"type":"checkbox","id":"fullPitch","name":"fullPitch","disabled":"true"},domProps:{"checked":false}}),_c('label',{attrs:{"for":"fullPitch"}},[_vm._v("Select full pitch")]),_c('br')]),_c('img',{attrs:{"src":require("../../views/img/pitch.svg"),"alt":""}}),(_vm.pitchBookedState.sectionOne === true)?_c('div',{staticClass:"areaSelected pitchSectionSelector selectonOne"},[_c('div',[_c('input',{attrs:{"type":"checkbox","id":"selectonOne","name":"selectonOne","disabled":"true"},domProps:{"checked":true}}),_c('label',{attrs:{"for":"selectonOne"}},[_vm._v("Zone one")]),_c('br')])]):_c('div',{class:_vm.pitch.sectionOne === true
            ? 'areaSelected pitchSectionSelector selectonOne'
            : 'pitchSectionSelector selectorOne'},[_c('div',[_c('input',{attrs:{"type":"checkbox","id":"selectonOne","name":"selectonOne"},domProps:{"checked":_vm.pitch.sectionOne},on:{"change":function($event){return _vm.pitchSelectToggle('sectionOne', _vm.pitch.pitchId)}}}),_c('label',{attrs:{"for":"selectonOne"}},[_vm._v("Zone one")]),_c('br')])]),(_vm.pitchBookedState.sectionTwo === true)?_c('div',{staticClass:"areaSelected pitchSectionSelector selectonTwo"},[_c('div',[_c('input',{attrs:{"type":"checkbox","id":"selectonTwo","name":"selectonTwo","disabled":"true"},domProps:{"checked":true}}),_c('label',{attrs:{"for":"selectonTwo"}},[_vm._v("Zone two")]),_c('br')])]):_c('div',{class:_vm.pitch.sectionTwo === true
            ? 'areaSelected pitchSectionSelector selectonTwo'
            : 'pitchSectionSelector selectonTwo'},[_c('div',[_c('input',{attrs:{"type":"checkbox","id":"selectonTwo","name":"selectonTwo"},domProps:{"checked":_vm.pitch.sectionTwo},on:{"change":function($event){return _vm.pitchSelectToggle('sectionTwo', _vm.pitch.pitchId)}}}),_c('label',{attrs:{"for":"selectonTwo"}},[_vm._v("Zone Two")]),_c('br')])]),(_vm.pitchBookedState.sectionThree === true)?_c('div',{staticClass:"areaSelected pitchSectionSelector selectonThree"},[_c('div',[_c('input',{attrs:{"type":"checkbox","id":"selectonThree","name":"selectonThree","disabled":"true"},domProps:{"checked":true}}),_c('label',{attrs:{"for":"selectonThree"}},[_vm._v("Zone two")]),_c('br')])]):_c('div',{class:_vm.pitch.sectionThree === true
            ? 'areaSelected pitchSectionSelector selectonThree'
            : 'pitchSectionSelector selectonThree'},[_c('div',{class:{ areaSelected: _vm.pitch.sectionThree }},[_c('input',{attrs:{"type":"checkbox","id":"selectonThree","name":"selectonThree"},domProps:{"checked":_vm.pitch.sectionThree},on:{"change":function($event){return _vm.pitchSelectToggle('sectionThree', _vm.pitch.pitchId)}}}),_c('label',{attrs:{"for":"selectonThree"}},[_vm._v("Zone Three")]),_c('br')])])]):_vm._e()],2):_c('div',[(_vm.showHomeTeamsPiches === true)?_c('p',[_vm._v(" Choose a date and pitch to see pitch avaiablity. ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }