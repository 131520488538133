import { HTTP } from "./http";
let API_SERVER = "";
if (window.location.href.indexOf("8080") > -1) {
  API_SERVER =
    "http://www.localhost:8888/back-end-endpoint-fireBase-SDK-admin.php";
} else {
  API_SERVER = "/personal-training-BE/back-end-endpoint-fireBase-SDK-admin.php";
}

const PushNotifications = {
  send(currentToken, authToken) {
    HTTP.interceptors.request.use((config) => {
      config.headers["Authorization"] =
        "Bearer '7d4f393c2989721a4a00240adb1a9bf5b2c506e1'";
      config.headers["Content-Type"] = "application/json";
      return config;
    });

    const params = {
      message: {
        token: currentToken,
        topic: "Big test",
        notification: {
          title: "Background Message Title",
          body: "Background message body",
        },
      },
    };
    return HTTP.post(
      "https://fcm.googleapis.com/v1/projects/chatmessages-fc29c/messages:send",
      params
    );
  },
  find(params) {
    return HTTP.post(API_SERVER, params);
  },

  show(id) {
    return HTTP.get(`users/${id}`);
  },

  store(post) {
    return HTTP.post(API_SERVER, post);
  },

  update(post, id) {
    return HTTP.put(`users/${id}`, post);
  },

  delete(params) {
    return HTTP.delete(API_SERVER, params);
  },
};

export { PushNotifications };
