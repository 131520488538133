<template>
  <div>
    <spinner-loader :loadingStuff="loadingStuff" />
    <app-template />
    <div class="uk-container uk-container-large">
      <section>
        <p>
          Forgot to add something to your cart? Shop around then come back to
          pay!
        </p>
      </section>
    </div>
  </div>
</template>
<script>
import SpinnerLoader from "../../../components/LoadingSpinning";
import AppTemplate from "../../layouts/App";

export default {
  name: "paymentCanceled",
  components: { AppTemplate, SpinnerLoader },
  data() {
    return {
      loadingStuff: false,
    };
  },
};
</script>
<style lang="scss" scoped></style>
