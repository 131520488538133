<template>
  <div>
    <app-template />
    <div class="uk-container uk-container-large">
      <template v-if="passwordUpDated !== true">
        <div v-if="notFount === true">
          <h2>Looks like you have entered an invalid URL.</h2>
          <p>
            The link is invalid/expired. Either you did not copy the correct
            link from the email, or you have already used the key in which case
            it is deactivated.
          </p>
        </div>
        <div v-else>
          <div class="intro-header">
            <div class="header-buttons">
              <h2>Password reset</h2>
            </div>
          </div>

          <template>
            <div>
              <p>Enter your new password.</p>
              <div class="form__item">
                <label class="form__label" for="password"></label>
                <input
                  class="form__input"
                  type="password"
                  v-model="password"
                  id="password"
                />
              </div>

              <button @click="resetPassword()" class="green-button">
                Reset password
              </button>
            </div>
          </template>
        </div>
      </template>
      <template v-else>
        <div class="intro-header">
          <div class="header-buttons">
            <h2>Password reset sucessfully</h2>
            <router-link :to="{ name: 'login' }"> Login </router-link>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import AppTemplate from "../layouts/App";
import { Mail } from "@/services/mail-service.js";

export default {
  name: "passwordRecover",
  components: { AppTemplate },
  data() {
    return {
      password: "",
      notFount: false,
      resetData: {},
      passwordUpDated: false,
    };
  },
  created() {
    this.checkURL();
  },
  methods: {
    checkURL() {
      if (
        Object.keys(this.$route.query).length === 0 ||
        this.$route.query.action === undefined ||
        this.$route.query.key === undefined ||
        this.$route.query.email === undefined
      ) {
        this.notFount = true;
      } else {
        this.storeThePassedData();
        this.notFount = false;
      }
    },
    async resetPassword() {
      const data = {
        action: "resetPasswordFromRecoveryPage",
        action: this.resetData.action,
        key: this.resetData.key,
        email: this.resetData.email,
        newPassword: this.password,
      };

      const responce = await Mail.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.passwordUpDated = true;
      }
    },
    storeThePassedData() {
      this.resetData = this.$route.query;
    },
  },
};
</script>
<style lang="scss" scoped></style>
