<template>
  <div>
    <spinner-loader :loadingStuff="loadingStuff" />
    <app-template />
    <div class="uk-container uk-container-large container">
      <user-header
        :userDataForHeader="userData"
        @weekSelectedChanged="weekSelectedChanged"
        @calendarDateChanged="calendarDateChanged"
        @mondaySunDatesChanged="mondaySunDatesChanged"
        @callToOpenTrainingPlan="callToOpenTrainingPlan"
        :coachesUsersData="coachesUsersData"
      />
      <div class="sessions-week-layout">
        <template
          v-if="userData.userType === 'Admin' || userData.userType === 'Coach'"
        >
          <div class="sessions-week-layout">
            <ul class="tabs">
              <li class="tab-item">
                <a
                  :class="
                    selectedTab === 'archive' ? 'tab-link active' : 'tab-link'
                  "
                  @click="tabChange('selectedTab', 'archive')"
                  href="javascript:;"
                  >Training Plan library</a
                >
              </li>
              <li class="tab-item">
                <a
                  :class="selectedTab === 'mental' ? 'tab-link' : 'tab-link'"
                  @click="tabChange('selectedTab', 'mental')"
                  href="javascript:;"
                  >Mental training library</a
                >
              </li>
              <li class="tab-item">
                <a
                  :class="selectedTab === 'mental' ? 'tab-link' : 'tab-link'"
                  @click="tabChange('selectedTab', 'nutrition')"
                  href="javascript:;"
                  >Nutrition training library</a
                >
              </li>
              <li class="tab-item">
                <a
                  :class="selectedTab === 'mental' ? 'tab-link' : 'tab-link'"
                  @click="tabChange('selectedTab', 'recovery')"
                  href="javascript:;"
                  >Recovery training library</a
                >
              </li>
            </ul>
            <div class="tabs-wrapper">
              <div
                class="archive training-plans tab-content"
                v-if="selectedTab === 'archive'"
              >
                <training-plan-library
                  :trainingTypeAndUserData="{
                    trainingType: 'Training Plan',
                    userId: $route.params.trainingPlanUserId,
                    trainingData: 'library',
                  }"
                />
              </div>

              <div
                class="mental training-plans tab-content"
                v-if="selectedTab === 'mental'"
              >
                <training-plan-library
                  :trainingTypeAndUserData="{
                    trainingType: 'Mental Training',
                    userId: $route.params.trainingPlanUserId,
                    trainingData: 'library',
                  }"
                />
              </div>

              <div
                class="nutrition training-plans tab-content"
                v-if="selectedTab === 'nutrition'"
              >
                <training-plan-library
                  :trainingTypeAndUserData="{
                    trainingType: 'Nutrition',
                    userId: $route.params.trainingPlanUserId,
                    trainingData: 'library',
                  }"
                />
              </div>

              <div
                class="recovery training-plans tab-content"
                v-if="selectedTab === 'recovery'"
              >
                <training-plan-library
                  :trainingTypeAndUserData="{
                    trainingType: 'Recovery',
                    userId: $route.params.trainingPlanUserId,
                    trainingData: 'library',
                  }"
                />
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <TrainingPlansLinks :selectedUserData="userData" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import {
  CHECK_PROFILE_HAS_DATA,
  USER_POPULATE,
  POPULATE_COACHES_USER_DATA,
  POPULATE_SELECTED_USER_DATA,
} from "@/store/actions/user.js";
import { Users } from "@/services/user-service.js";
import { TrainingPlan } from "@/services/training-plan-service.js";
import AppTemplate from "../layouts/App";
import moment from "moment";
import UserHeader from "../../components/UserHeader";
import SpinnerLoader from "../../components/LoadingSpinning";
import TrainingPlanLibrary from "../../components/trainingPlanLibrary";
import TrainingPlansLinks from "../training-plans/training-plans-links";
import VueGoodTable from "vue-good-table";
import VTooltip from "v-tooltip";
import "vue-good-table/dist/vue-good-table.css";

Vue.use(VueGoodTable);
Vue.use(VTooltip);

export default {
  name: "Home",
  components: {
    AppTemplate,
    UserHeader,
    SpinnerLoader,
    TrainingPlanLibrary,
    TrainingPlansLinks,
  },
  data() {
    return {
      loadingStuff: false,
      selectedTab: "archive",
      trainingPlanLibraryData: [],
      selectedSessionTab: "details",
      sessionDataModal: {},
      weekSelectedNumber: null,
      selectedWeekMonSunday: "",
      weeklyDate: "",
      userData: {
        name: "",
      },
      columns: [
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Position",
          field: "position",
        },
        {
          label: "Dob",
          field: "dob",
        },
        {
          label: "Actions",
          width: "6rem",
          field: "actions",
        },
      ],
      coachesUsersData: [],
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(["getProfile", "getWeekNumber", "getCoachesUsersData"]),
  },
  async created() {
    this.loadingStuff = true;
    const now = moment().format("MM-DD-YYYY");
    this.weekSelectedNumber = moment(now).isoWeek();
    this.weeklyDate = now;

    const response = await this.$store.dispatch(CHECK_PROFILE_HAS_DATA);

    if (response === true) {
      this.setUser();
      if (
        this.userData.userType === "Admin" ||
        this.userData.userType === "Coach"
      ) {
        this.getAllUsersForThisAdmin();
      }
      this.loadingStuff = false;
    } else {
      const clientId = JSON.parse(
        localStorage.getItem("the_w_selectedClientId")
      );

      this.getUserById(clientId);
      if (
        this.userData.userType === "Admin" ||
        this.userData.userType === "Coach"
      ) {
        this.checkForCoachesUsersData();
      }
      this.loadingStuff = false;
    }
  },
  methods: {
    /*openTrainingInfoModel(sessionData) {
      this.sessionDataModal = sessionData;
      this.showModal("sessionModal");
    },*/
    getImgUrl(pic) {
      return pic !== undefined && pic !== "" ? `/images/${pic}` : "";
    },
    async getTrainingPlanLibraryData() {
      const trainingPlanData = {
        clubId: this.userData.clubId,
        action: "gettrainingPlanByClubId",
      };
      const responce = await TrainingPlan.find(trainingPlanData).catch(
        (error) => {
          console.log(error);
        }
      );

      if (responce.data.length > 0) {
        this.trainingPlanLibraryData = responce.data;
      }
    },
    tabChange(tabValue, selectedTabValue) {
      this[tabValue] = selectedTabValue;
    },
    callToOpenTrainingPlan(data) {
      this.openTrainingPlanPage(data[0], data[1], data[2]);
    },
    mondaySunDatesChanged(data) {
      this.selectedWeekMonSunday = data.monToFri;
    },
    calendarDateChanged(date) {
      this.weeklyDate = date;
    },
    weekSelectedChanged(number) {
      this.weekSelectedNumber = number.weekSelectedNumber;
    },
    openTrainingPlanPage(userId, userType, planType) {
      if (userType === "User") {
        this.$store.dispatch(POPULATE_SELECTED_USER_DATA, userId);
        this.$router.push({
          name: "trainingPlans",
          params: { trainingPlanUserId: 0, trainingPlanType: planType },
        });
      } else {
        this.$router.push({
          name: "trainingPlans",
          params: { trainingPlanUserId: userId, trainingPlanType: planType },
        });
      }
    },
    async getUserById(clientId) {
      const userData = {
        clientId: clientId,
        action: "getUserByID",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        this.userData = responce.data[0];
        const userData = responce.data[0];
        this.$store.dispatch(USER_POPULATE, { userData });
        if (
          this.userData.userType === "Admin" ||
          this.userData.userType === "Coach"
        ) {
          this.getAllUsersForThisAdmin();
        }
      }
    },
    setUser() {
      if (this.getProfile !== null) {
        this.userData = this.getProfile;
      }
    },
    async getAllUsersForThisAdmin() {
      const userData = {
        coachId: this.userData.id,
        action: "getUserByCoachId",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        this.coachesUsersData = responce.data;
        this.$store.dispatch(POPULATE_COACHES_USER_DATA, this.coachesUsersData);
      }
    },
    formatDates(date) {
      return moment(date).format("DD MMM YYYY");
    },
    checkForCoachesUsersData() {
      this.coachesUsersData = this.getCoachesUsersData;
    },
  },
};
</script>
