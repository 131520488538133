<template>
  <div>
    <spinner-loader :loadingStuff="loadingStuff" />
    <app-template />
    <div class="uk-container uk-container-large">
      <section v-if="contactSupport === false && paymentComplete === true">
        <div class="payment-details">
          <div
            class="subscription-text"
            v-if="paymentSessionData.mode === 'subscription'"
          >
            <h1>Your subscription payment was sucessfull</h1>
            <p>
              You are now subscribed to pay
              {{ pounds(paymentSessionData.amount_total) }} each month. You can
              cancel this subscription at any time, by emailing support on
              <a href="mailto:ryan@thewacademy.co.uk">ryan@thewacademy.co.uk</a>
              or vistining
              <a :href="paymentSessionData.cancel_url">Cancel payments</a>.
            </p>
          </div>

          <div
            class="subscription-text"
            v-if="paymentSessionData.mode === 'payment'"
          >
            <h1>Your payment was sucessfull</h1>
          </div>
        </div>

        <h2>Payment details</h2>

        <div
          v-for="sessionsLineItem in sessionsLineItemsData"
          :key="sessionsLineItem.id"
          class="product-details"
        >
          <h3>{{ sessionsLineItem.description }}</h3>
          <p>
            {{ pounds(sessionsLineItem.amount_total) }}
            {{ sessionsLineItem.price.recurring.interval }}
          </p>
          <p v-if="sessionsLineItem.price.recurring.interval === 'month'">
            This payment will be deducted from your account every month until
            you cancel the direct debit.
          </p>
        </div>

        <p>
          <a :href="invoiceData.hosted_invoice_url"
            >View invoice and payment details</a
          >
        </p>
        <p>
          We appreciate your business! Should you have any inquiries, please
          feel free to reach out via email.
          <a href="mailto:ryan@thewacademy.co.uk">ryan@thewacademy.co.uk</a>.
        </p>
      </section>
      <section v-else>
        <p>
          It appears there is an issue with your payment. Kindly reach out to
          our support team via email, referencing your customer number.
          <span
            >Support email:
            <a href="mailto:ryan@thewacademy.co.uk"
              >ryan@thewacademy.co.uk</a
            ></span
          >
          <span>Your customer number: {{ paymentSessionData.customer }}</span>
        </p>
      </section>
    </div>
  </div>
</template>
<script>
import SpinnerLoader from "../../../components/LoadingSpinning";
import AppTemplate from "../../layouts/App";
import { Payments } from "@/services/stripe-service.js";
import moment from "moment";

export default {
  name: "paymentSuccess",
  components: { AppTemplate, SpinnerLoader },
  data() {
    return {
      loadingStuff: false,
      paymentSessionData: {},
      sessionsLineItemsData: {},
      contactSupport: false,
      paymentComplete: false,
      invoiceData: {},
    };
  },
  created() {
    this.loadingStuff = true;
    this.getTransactionDetails();
  },
  methods: {
    async getInvoices() {
      const data = {
        action: "getInvoices",
        invoice_id: this.paymentSessionData.invoice,
      };

      const responce = await Payments.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.invoiceData = responce.data;
      }
    },
    async getTransactionDetails() {
      const data = {
        action: "getTransactionDetails",
        session_id: this.$route.params.session_id,
      };

      const responce = await Payments.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.paymentSessionData = responce.data;
        this.paymentStatusCheck();
        this.updatebookingDetails();
        this.getInvoices();
        this.loadingStuff = false;
      }
    },
    createBookingUpdateArray() {
      return this.paymentSessionData.metadata.map((bookingId) => {
        return {
          id: bookingId,
          payment_state: this.paymentSessionData.payment_status,
          booking_status: this.paymentSessionData.status,
        };
      });
    },
    async updatebookingDetails() {
      const data = {
        action: "updateBooking",
        bookings: this.createBookingUpdateArray(),
      };

      const responce = await Payments.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
      }
    },
    pounds(amount) {
      const numberWithRemovedZeros = amount / 100;
      if (amount !== undefined) {
        return Intl.NumberFormat("en-GB", {
          style: "currency",
          currency: "GBP",
        }).format(numberWithRemovedZeros);
      }
    },
    formatUnixDate(date) {
      return moment.unix(date).format("MMMM Do YYYY");
    },
    paymentStatusCheck() {
      switch (this.paymentSessionData.payment_status) {
        case "unpaid":
          this.contactSupport = true;
          break;
        case "paid":
        case "no_payment_required":
          this.paymentComplete = true;
          this.getSessionsLineItems();
          break;
        default:
          this.contactSupport = true;
      }
    },
    async getSessionsLineItems() {
      const data = {
        action: "getSessionsLineItems",
        session_id: this.$route.params.session_id,
      };

      const responce = await Payments.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.sessionsLineItemsData = responce.data.data;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.product-details {
  background: white;
  padding: 2rem;
  margin-bottom: 1rem;
}
</style>
