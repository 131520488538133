export default {
  methods: {
    fixwizywigLinkButton() {
      setTimeout(() => {
        const wizywigLinkButton = document.querySelector(".vw-btn-link");

        if (!wizywigLinkButton) {
          console.warn("fixwizywigLinkButton: Button not found.");
          return;
        }

        wizywigLinkButton.addEventListener("click", () => {
          document
            .querySelectorAll(".editr--toolbar .dashboard form")
            .forEach((item) => {
              item.addEventListener("click", (e) => {
                e.target.focus();
              });
            });
        });
      }, 500); // Reduced timeout for better responsiveness
    },
  },
};
