var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('spinner-loader',{attrs:{"loadingStuff":_vm.loadingStuff}}),_c('app-template'),_c('div',{staticClass:"uk-container uk-container-large container"},[_c('user-header',{attrs:{"userDataForHeader":_vm.userData,"coachesUsersData":_vm.coachesUsersData},on:{"weekSelectedChanged":_vm.weekSelectedChanged,"calendarDateChanged":_vm.calendarDateChanged,"mondaySunDatesChanged":_vm.mondaySunDatesChanged,"callToOpenTrainingPlan":_vm.callToOpenTrainingPlan}}),_c('div',{staticClass:"sessions-week-layout"},[(_vm.userData.userType === 'Admin' || _vm.userData.userType === 'Coach')?[_c('div',{staticClass:"sessions-week-layout"},[_c('ul',{staticClass:"tabs"},[_c('li',{staticClass:"tab-item"},[_c('a',{class:_vm.selectedTab === 'archive' ? 'tab-link active' : 'tab-link',attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.tabChange('selectedTab', 'archive')}}},[_vm._v("Training Plan library")])]),_c('li',{staticClass:"tab-item"},[_c('a',{class:_vm.selectedTab === 'mental' ? 'tab-link' : 'tab-link',attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.tabChange('selectedTab', 'mental')}}},[_vm._v("Mental training library")])]),_c('li',{staticClass:"tab-item"},[_c('a',{class:_vm.selectedTab === 'mental' ? 'tab-link' : 'tab-link',attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.tabChange('selectedTab', 'nutrition')}}},[_vm._v("Nutrition training library")])]),_c('li',{staticClass:"tab-item"},[_c('a',{class:_vm.selectedTab === 'mental' ? 'tab-link' : 'tab-link',attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.tabChange('selectedTab', 'recovery')}}},[_vm._v("Recovery training library")])])]),_c('div',{staticClass:"tabs-wrapper"},[(_vm.selectedTab === 'archive')?_c('div',{staticClass:"archive training-plans tab-content"},[_c('training-plan-library',{attrs:{"trainingTypeAndUserData":{
                  trainingType: 'Training Plan',
                  userId: _vm.$route.params.trainingPlanUserId,
                  trainingData: 'library',
                }}})],1):_vm._e(),(_vm.selectedTab === 'mental')?_c('div',{staticClass:"mental training-plans tab-content"},[_c('training-plan-library',{attrs:{"trainingTypeAndUserData":{
                  trainingType: 'Mental Training',
                  userId: _vm.$route.params.trainingPlanUserId,
                  trainingData: 'library',
                }}})],1):_vm._e(),(_vm.selectedTab === 'nutrition')?_c('div',{staticClass:"nutrition training-plans tab-content"},[_c('training-plan-library',{attrs:{"trainingTypeAndUserData":{
                  trainingType: 'Nutrition',
                  userId: _vm.$route.params.trainingPlanUserId,
                  trainingData: 'library',
                }}})],1):_vm._e(),(_vm.selectedTab === 'recovery')?_c('div',{staticClass:"recovery training-plans tab-content"},[_c('training-plan-library',{attrs:{"trainingTypeAndUserData":{
                  trainingType: 'Recovery',
                  userId: _vm.$route.params.trainingPlanUserId,
                  trainingData: 'library',
                }}})],1):_vm._e()])])]:[_c('TrainingPlansLinks',{attrs:{"selectedUserData":_vm.userData}})]],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }