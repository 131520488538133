<template>
  <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :style="myStyles"
  />
</template>

<script>
import { Bar } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ChartDataLabels
);

export default {
  name: "BarChart",
  components: {
    Bar,
  },
  props: {
    chartData: {
      type: Object,
      default: () => {},
    },
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
    height: {
      type: String,
      default: "300px",
    },
  },
  computed: {
    myStyles() {
      return {
        width: "100%",
        position: "relative",
      };
    },
  },
  data() {
    return {
      chartOptions: {
        maintainAspectRatio: true,
        indexAxis: "y",
        plugins: {
          legend: {
            labels: false,
          },
        },
        options: {
          plugins: {
            datalabels: {
              anchor: "end",
              align: "end",
              formatter: (val) => val,
              font: {
                weight: "bold",
              },
            },
          },
          legend: {
            display: false,
          },
          scales: {
            x: {
              grid: {
                display: false,
                drawBorder: false,
              },
              ticks: {
                display: false,
              },
            },
            y: {
              grid: {
                display: false,
                drawBorder: false,
              },
              ticks: {
                display: false,
              },
            },
          },
        },
      },
    };
  },
};
</script>
