<template>
  <div>
    <b-modal
      id="uploadStrengthDataCSV"
      size="lg"
      :hide-footer="Boolean(true)"
      @close="resetGPSUploadModal"
    >
      <div>
        <h2>Import CSV file</h2>
        <hr />

        <div class="type-of-csv-import">
          <h3>Upload Physical Performance Tests</h3>
        </div>

        <div
          class="upload-wrapper"
          v-if="gpsUploadDataTypeSelected !== undefined"
        >
          <label
            >File
            <input type="file" @change="importCSV($event)" />
          </label>

          <div v-if="csvDataImported !== null" class="show-imported-CSV-data">
            <pre>{{ csvDataImported }}</pre>
          </div>

          <button
            v-if="csvDataImported !== null"
            v-on:click="importUploadedCSVDataFile()"
          >
            Save
          </button>
        </div>
        <p class="days-red-warning" v-if="dataCSVErrorMessage === null">
          <b-icon icon="exclamation-circle" aria-hidden="true"></b-icon>
          {{ dataCSVErrorMessage }}
        </p>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { PlayerPhysicalPerformance } from "@/services/player-physical-performance-service.js";
export default {
  name: "StrengthTestUploader",
  data() {
    return {
      gpsUploadDataTypeSelected: "",
      dataCSVErrorMessage: null,
      csvDataImported: null,
    };
  },
  props: {
    openStrengthTestModal: {
      type: Boolean,
      default: false,
    },
    gameTrainingData: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    openStrengthTestModal: function (newVal) {
      if (newVal === true) {
        this.$bvModal.show("uploadStrengthDataCSV");
      }
    },
  },
  created() {},
  methods: {
    async importCSV(e) {
      const file = e.target.files.item(0);
      const csv = await file.text();
      var lines = csv.split("\n");

      var result = [];

      var headers = lines[0].split(",");

      for (var i = 1; i < lines.length; i++) {
        var obj = {};
        var currentline = lines[i].split(",");

        if (currentline.length === 1) {
          console.log(currentline);
        } else {
          for (var j = 0; j < headers.length; j++) {
            console.log(currentline[j]);
            let str1 = currentline[j].replace(/\n|\r/g, "");
            let str2 = headers[j].replace(/\n|\r/g, "");
            obj[str2] = str1;
          }
          result.push(obj);
        }
      }
      this.convertHeightToCM(result);
      //this.csvDataImported = result;
    },
    resetGPSUploadModal() {
      this.$emit("openStrengthTestModalCall", false);
    },
    async importUploadedCSVDataFile() {
      this.saveCSVStrengthTestData();
    },
    checkCSVdata() {
      return this.csvDataImported[0].hasOwnProperty(
        "Countermovement Jump (cm)"
      );
    },
    csvErrorMessage() {
      this.dataCSVErrorMessage =
        "Please verify that your CSV upload template is correct and that you have selected the appropriate upload option.";
      setTimeout(() => (this.dataCSVErrorMessage = null), 2000);
    },
    convertHeightToCM(data) {
      this.csvDataImported = data.map((item) => {
        let heightStr = item["Standing Height (ft)"]?.trim(); // Handle missing data safely

        if (!heightStr || !heightStr.includes("'")) return item; // Skip if the format is incorrect

        const parts = heightStr.split("'");
        const feet = parseInt(parts[0], 10) || 0;
        const inches = parseInt(parts[1], 10) || 0;

        const heightCM = feet * 30.48 + inches * 2.54;

        return {
          ...item,
          "Standing Height (ft)": Math.round(heightCM).toString(), // Store result in a new key
        };
      });
    },
    async saveCSVStrengthTestData() {
      const dataIsGood = this.checkCSVdata();

      if (dataIsGood === true) {
        const data = {
          action: "importUploadedStrenghtTestCSVDataFile",
          data: this.csvDataImported,
        };
        const responce = await PlayerPhysicalPerformance.find(data).catch(
          (error) => {
            console.log(error);
          }
        );
        if (responce.status === 200) {
          this.$bvModal.hide("openStrengthTestModalCall");

          this.$bvToast.toast("CSV Strength data added.", {
            title: "saved",
            toaster: "b-toaster-bottom-right",
            solid: true,
            appendToast: true,
            variant: "success",
          });
        }
      } else {
        this.csvErrorMessage();
      }
    },
    fixDateForImport(date) {
      return date.substring(0, 10);
    },
  },
};
</script>
