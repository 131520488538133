<template>
  <div>
    <spinner-loader :loadingStuff="loadingStuff" />
    <app-template />

    <div class="uk-container uk-container-large">
      <ul class="tabs">
        <li class="tab-item">
          <a
            :class="
              selectedTab === 'schedule-tab' ? 'tab-link active' : 'tab-link'
            "
            @click="tabChange('selectedTab', 'schedule-tab')"
            href="javascript:;"
            >Schedule</a
          >
        </li>
        <li class="tab-item">
          <a
            :class="
              selectedTab === 'pitch-tab' ? 'tab-link active' : 'tab-link'
            "
            @click="tabChange('selectedTab', 'pitch-tab')"
            href="javascript:;"
            >Pitch booking</a
          >
        </li>
      </ul>

      <div class="tabs-wrapper">
        <div
          class="current-week-tab tab-content"
          v-if="selectedTab === 'schedule-tab'"
        >
          <div class="mins-stats-wrapper visual-split-fade">
            <div class="buttons-wrapper">
              <button
                @click="openAddEventModal()"
                v-if="
                  userData.userType === 'Admin' || userData.userType === 'Coach'
                "
                class="green-button add-new-programme"
                v-b-tooltip.hover
                title="Add event"
              >
                <b-icon icon="calendar3" aria-hidden="true"></b-icon>
              </button>
              <button
                @click="showAddGamesModal()"
                v-if="
                  userData.userType === 'Admin' || userData.userType === 'Coach'
                "
                class="green-button add-new-programme"
                v-b-tooltip.hover
                title="Add Game / Training"
              >
                <b-icon icon="plus" aria-hidden="true"></b-icon>
              </button>
              <button
                @click="showPrintScheduleModal()"
                class="green-button add-new-programme"
                v-b-tooltip.hover
                title="Print Schedule"
              >
                <b-icon icon="printer" aria-hidden="true"></b-icon>
              </button>
            </div>

            <div class="cal-buttons-wrapper">
              <a
                class="cal-buttons"
                href="javascript:;"
                @click="changeMonth(-1, 'minsData')"
                >Previous month</a
              >
              <a
                class="cal-buttons"
                href="javascript:;"
                @click="changeMonth(1, 'minsData')"
                >Next month</a
              >
            </div>
            <div class="date-year">
              {{ getMonthName(minsData.month) }} | {{ minsData.year }}
            </div>

            <div class="mins-stats-view">
              <section class="col-one-teams">
                <div class="body">Team</div>
                <div
                  :class="`body team-${team.id}`"
                  v-for="team in clubTeams"
                  :key="team.id"
                >
                  {{ team.teamName }}
                </div>
              </section>

              <section class="events">
                <div
                  v-for="(weekDay, index) in minsData.weekDays"
                  :key="index"
                  class="mins-stats-col-day-wrapper"
                >
                  <div class="mins-stats-col-day">
                    <div class="head">{{ getDayFromDate(weekDay.date) }}</div>
                    <div
                      class="body"
                      :class="`body team-${team.id}`"
                      v-for="team in clubTeams"
                      :key="team.id"
                    >
                      <div v-for="game in scheduleData" :key="game.id">
                        <div
                          v-if="
                            team.id == game.teamId &&
                            formatDateForDB(weekDay.date) === game.date
                          "
                        >
                          <div
                            v-if="game.gameType === 'Training'"
                            class="event-wrapper training-style"
                          >
                            <span
                              class="event-text"
                              v-b-tooltip.hover
                              :title="game.title"
                              >{{ game.title }}
                            </span>
                            <span
                              ><span v-if="game.pitch_zone !== null"
                                >Pitch zone - {{ game.pitch_zone }}</span
                              >
                              {{ game.time }}</span
                            >
                          </div>

                          <div
                            v-if="game.gameType === 'League game'"
                            class="event-wrapper league-style"
                          >
                            <span
                              class="event-text"
                              v-b-tooltip.hover
                              :title="game.title"
                              >{{ game.title }}
                            </span>
                            <span
                              ><span v-if="game.pitch_zone !== null"
                                >Pitch zone - {{ game.pitch_zone }}</span
                              >
                              {{ game.time }}</span
                            >
                          </div>
                          <div
                            v-if="game.gameType === 'Friendly'"
                            class="event-wrapper league-style"
                          >
                            <span
                              class="event-text"
                              v-b-tooltip.hover
                              :title="game.title"
                              >{{ game.title }}
                            </span>
                            <span
                              ><span v-if="game.pitch_zone !== null"
                                >Pitch zone - {{ game.pitch_zone }}</span
                              >
                              {{ game.time }}</span
                            >
                          </div>
                          <div
                            v-if="game.gameType === 'Cup game'"
                            class="event-wrapper cup-style"
                          >
                            <span
                              class="event-text"
                              v-b-tooltip.hover
                              :title="game.title"
                              >{{ game.title }}
                            </span>
                            <span
                              ><span v-if="game.pitch_zone !== null"
                                >Pitch zone - {{ game.pitch_zone }}</span
                              >
                              {{ game.time }}</span
                            >
                          </div>

                          <div
                            v-if="game.gameType === 'Other'"
                            class="event-wrapper"
                          >
                            <span
                              class="event-text"
                              v-b-tooltip.hover
                              :title="game.title"
                              >{{ game.title }}
                            </span>
                            <span
                              >{{ changeTimeFrom24To12jours(game.time) }} -
                              {{
                                changeTimeFrom24To12jours(game.endTime)
                              }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      <div
        class="current-week-tab tab-content"
        v-if="selectedTab === 'pitch-tab'"
      >
        <Calendar />
      </div>
    </div>

    <b-modal
      id="addEvent"
      size="lg"
      title="Add an event"
      :hide-footer="Boolean(true)"
    >
      <div class="event-schedual-club">
        <div class="form__item">
          <label class="form__label" for="name">Title</label>
          <input
            class="form__input"
            type="text"
            v-model="newEventData.title"
            id="name"
          />
        </div>

        <div class="form__item">
          <label class="form__label" for="start">Start time</label>
          <input
            class="form__input"
            type="time"
            v-model="newEventData.start_time"
            min="06:00"
            max="23:00"
            id="start"
          />
        </div>

        <div class="form__item">
          <label class="form__label" for="end">End time</label>
          <input
            class="form__input"
            type="time"
            v-model="newEventData.end_time"
            min="06:00"
            max="23:00"
            id="end"
          />
        </div>

        <div class="form__item uk-form dob">
          <label class="form__label" for="event_date">event Date</label>
          <datepicker
            v-model="newEventData.date"
            name="event_date"
            id="event_date"
          />
        </div>

        <div class="form__item">
          <label class="form__label" for="userSelect">Select team</label>

          <div class="user-select-wrapper">
            <div v-for="team in clubTeams" :key="team.id">
              <input
                @change="ageGroupAddRemove($event)"
                type="checkbox"
                :id="team.id"
                :name="team.teamName"
                :value="team.id"
                :checked="team.selected"
              />
              <label :for="team.id">{{ team.teamName }}</label
              ><br />
            </div>
          </div>
        </div>

        <button @click="saveEvent()" class="green-button add-new-programme">
          Add event
        </button>
      </div>
    </b-modal>

    <b-modal
      id="addEditGames"
      size="lg"
      title="Add new Game"
      :hide-footer="Boolean(true)"
    >
      <form class="form" @submit.prevent="addEditProgrammeSubmit()">
        <div class="form__item" v-if="clubTeams.length > 1">
          <label class="form__label" for="userSelect">Select team </label>
          <select @change="setSelectedTeam($event)" id="userSelect">
            <option
              v-for="(clubTeam, index) in clubTeams"
              :value="clubTeam.teamName"
              :key="clubTeam.id + index"
            >
              {{ clubTeam.teamName }}
            </option>
          </select>
        </div>

        <div class="form__item">
          <label class="form__label" for="selectSection"
            >Select type of game</label
          >
          <b-form-select
            id="compressureType"
            text="select type"
            v-model="newGameData.gameType"
            @change="populateFirstTeamSelectedData($event)"
          >
            <option
              v-for="(i, index) in selectTypeOfGameoptions"
              :key="index"
              :value="i.value"
            >
              {{ i.text }}
            </option>
          </b-form-select>
        </div>
        <div class="form__item">
          <label class="form__label" for="homeTeamSection">Selected team</label>
          <span>{{ newGameData.teamName }}</span>
        </div>
        <div class="form__item" v-if="showTeamChange === true">
          <label class="form__label" for="homeTeamSection">Change team</label>

          <select @change="setSelectedTeam($event)" id="userSelect">
            <option disabled value="">Please select a team</option>
            <option
              v-for="(clubTeam, index) in clubTeams"
              :value="clubTeam.teamName"
              :key="clubTeam.id + index"
            >
              {{ clubTeam.teamName }}
            </option>
          </select>
        </div>

        <div v-if="newGameData.gameType !== 'Training'" class="form__item">
          <label
            v-if="
              leagueTeams.length > 0 && newGameData.gameType === 'League game'
            "
            class="form__label"
            for="competitionSection"
            >Select opposition team</label
          >

          <label v-else class="form__label" for="competitionSection"
            >Add opposition team</label
          >

          <select
            v-if="
              leagueTeams.length > 0 && newGameData.gameType === 'League game'
            "
            @change="populateSelectedTeamVenue($event, 'newGameData')"
            id="competitionSection"
          >
            <option disabled value="">Please select an opposition team</option>
            <option
              v-for="team in leagueTeams"
              v-bind:key="team.id"
              :value="team.id"
            >
              {{ team.teamName }}
            </option>
          </select>
          <input
            v-else
            id="competitionSection"
            type="text"
            v-model="newGameData.opposition"
            name="competitionSection"
          />
        </div>
        <div class="form__item">
          <label class="form__label" for="matchDate">
            <span>{{ newGameData.gameType }}</span>
            Date</label
          >
          <datepicker
            wrapper-class="fullscreen-when-on-mobile"
            :disabled-dates="disabledDates"
            v-model="newGameData.matchDate"
            name="matchDate"
            id="matchDate"
          />
        </div>
        <div class="form__item">
          <label class="form__label" for="kickOff">
            <span v-if="newGameData.gameType === 'Training'">Start</span>
            <span v-else>Kick off</span>
            time</label
          >
          <input
            id="kickOff"
            type="time"
            min="06:00"
            max="23:00"
            v-model="newGameData.kickOffTime"
            name="kickOff"
          />
        </div>
        <div class="form__item" v-if="newGameData.gameType === 'Training'">
          <label class="form__label" for="endTime">End time</label>
          <input
            id="endTime"
            type="time"
            min="06:00"
            max="23:00"
            v-model="newGameData.endTime"
            name="endTime"
          />
        </div>

        <PitchSelect
          :pitches="pitches"
          @pitchChange="pitchChange"
          @default-pitch-inputs="defaultPitchInputs"
          :gameData="newGameData"
        />

        <template v-if="togglePitchDetails === false">
          <div class="form__item">
            <label class="form__label" for="location">Location name</label>
            <input
              id="location"
              type="text"
              v-model="newGameData.locationName"
              name="location"
            />
          </div>
          <div class="form__item">
            <label class="form__label" for="address">Location address</label>
            <input
              id="address"
              type="text"
              v-model="newGameData.address"
              name="address"
            />
          </div>
          <div class="form__item">
            <label class="form__label" for="postCode">Location postCode</label>
            <input
              id="postCode"
              type="text"
              v-model="newGameData.postCode"
              name="postCode"
            />
          </div>
          <div class="form__item">
            <label class="form__label" for="selectpitchType"
              >Select Pitch type</label
            >
            <select v-model="newGameData.pitchType" id="selectpitchType">
              <option disabled value="">Please select a pitch type</option>
              <option value="4G">4G</option>
              <option value="3G">3G</option>
              <option value="Grass">Grass</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </template>

        <template v-else>
          <div class="form__item">
            <label class="form__label" for="location">Location name</label>
            <input
              id="location"
              type="text"
              v-model="newGameData.locationName"
              name="location"
              disabled="true"
            />
          </div>
          <div class="form__item">
            <label class="form__label" for="address">Location address</label>
            <input
              id="address"
              type="text"
              v-model="newGameData.address"
              name="address"
              disabled="true"
            />
          </div>
          <div class="form__item">
            <label class="form__label" for="postCode">Location postCode</label>
            <input
              id="postCode"
              type="text"
              v-model="newGameData.postCode"
              name="postCode"
              disabled="true"
            />
          </div>
          <div class="form__item">
            <label class="form__label" for="selectpitchType"
              >Select Pitch type</label
            >
            <input
              id="selectpitchType"
              type="text"
              v-model="newGameData.pitchType"
              name="selectpitchType"
              disabled="true"
            />
          </div>
        </template>

        <div class="form__item" v-if="newGameData.gameType !== 'Training'">
          <label class="form__label" for="stripType">Strip type</label>
          <input
            id="stripType"
            type="text"
            v-model="newGameData.stripType"
            name="stripType"
          />
        </div>
        <div class="form__item" v-if="newGameData.gameType !== 'Training'">
          <label class="form__label" for="meetTime">Meet time</label>
          <input
            id="meetTime"
            type="time"
            min="06:00"
            max="23:00"
            v-model="newGameData.meetTime"
            name="meetTime"
          />
        </div>

        <div v-if="newGameData.gameType !== 'Training'" class="form__item">
          <label class="form__label" for="notes">Home game</label>
          <b-form-checkbox
            id="home-game-event-checkBox"
            v-model="newGameData.home_team"
            name="home-game-event-checkBox"
          >
          </b-form-checkbox>
        </div>
        <div class="form__item">
          <label class="form__label" for="notes">Notes</label>
          <textarea
            id="notes"
            type="text"
            v-model="newGameData.notes"
            name="notes"
          ></textarea>
        </div>

        <div
          v-if="newGameData.gameType === 'Training'"
          class="form__item recurring-event-checkBox-class"
        >
          <b-form-checkbox
            id="recurring-event-checkBox"
            v-model="recurringEvent"
            name="recurring-event-checkBox"
            @change="toggleRecurringEvent()"
          >
            <span class="check-box-text">Make event recurring</span>
          </b-form-checkbox>
        </div>

        <div class="form__item" v-if="recurringEvent === true">
          <label class="form__label" for="recurringEventSelect"
            >Select recurring type</label
          >
          <select
            v-model="newGameData.recurringEventType"
            id="recurringEventSelect"
          >
            <option disabled value="">
              Please select a recurring event type
            </option>
            <option value="7">Weekly</option>
            <option value="14">By weekly</option>
          </select>
        </div>

        <div class="form__item">
          <button type="submit" class="green-button">Add game</button>
        </div>
      </form>
    </b-modal>

    <b-modal
      id="printSchedule"
      modal-class="modal-fullscreen"
      size="xl"
      title="Print Schedule"
      :hide-footer="Boolean(true)"
    >
      <div class="search-filter">
        <div class="form__item">
          <label class="form__label" for="picker">
            Select games date range</label
          >
          <date-range-picker
            ref="picker"
            :locale-data="dateRangePickerLocaleData"
            @update="dateRangeSelected()"
            v-model="dateRange"
          >
            <template v-slot:input="picker">
              <b-icon icon="calendar3"></b-icon>
              {{ formatMatchDate(picker.startDate) }} -
              <b-icon icon="calendar3"></b-icon>
              {{ formatMatchDate(picker.endDate) }}
            </template>
          </date-range-picker>
          <a
            class="clear-daterange"
            v-b-tooltip.hover
            title="Reset date range"
            @click="dateRangeReset()"
            href="javascript:;"
            ><b-icon icon="arrow-counterclockwise"></b-icon
          ></a>

          <b-button
            @click="produceTheAcademySchedulePDF()"
            class="edit-pitch-button"
            v-b-tooltip.hover
            title="Produce The Academy Schedule PDF"
            variant="outline-warning"
          >
            Print
          </b-button>
        </div>

        <div class="player-report-content">
          <iframe id="theAcademySchedulePdf_preview" src="" />
        </div>
      </div>
    </b-modal>

    <img alt="" class="visually-hidden" id="png-logo" />
  </div>
</template>
<script>
import SpinnerLoader from "../../components/LoadingSpinning";
import AppTemplate from "../layouts/App";
import { CHECK_PROFILE_HAS_DATA } from "@/store/actions/user.js";
import { mapGetters } from "vuex";
import Calendar from "../../components/Calendar";
import { Teams } from "@/services/teams-service.js";
import { Users } from "@/services/user-service.js";
import { Games } from "@/services/games-service.js";
import { Chat } from "@/services/chat-service.js";
import { Pitches } from "@/services/coaches-pitches.js";
import dayjs from "dayjs";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import DateRangePicker from "vue2-daterange-picker";
import extendSeasonEndDateToFullYearMixin from "@/mixin/extendSeasonEndDateToFullYear.js";
import PitchSelect from "../../components/PitchSelect";
import JsPDF from "jspdf";

export default {
  name: "Booking",
  mixins: [extendSeasonEndDateToFullYearMixin],
  data() {
    return {
      loadIngStuffText: "Loading",
      loadingStuff: false,
      selectedTab: "schedule-tab",
      selectedTeam: "",
      showReport: false,
      newEventData: {},
      leagueTeams: [],
      newGameData: {
        opposition: "",
        kickOffTime: "",
        matchDate: "",
        locationName: "",
        address: "",
        postCode: "",
        stripType: "",
        meetTime: "",
        endTime: "",
        notes: "",
        gameType: "Training",
        teamId: "",
        teamName: "",
        pitchType: "",
        recurringEventType: null,
        home_team_goals: 0,
        away_team_goals: 0,
        away_team_goal_scorers: null,
        home_team_goal_scorers: null,
        result_added: 0,
        pitch_id: "",
        pitch_zone: null,
        home_team: false,
      },
      dateRange: {
        startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
        endDate: moment().add(2, "months").format("YYYY-MM-DD"),
      },
      dateRangePickerLocaleData: {
        direction: "ltr",
        format: "mm/dd/yyyy",
        separator: " - ",
        applyLabel: "Apply",
        cancelLabel: "Cancel",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        monthNames: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        firstDay: 0,
      },
      selectedGamesEvents: [],
      recurringEvent: false,
      disabledDates: {
        to: new Date(Date.now() - 8640000),
      },
      pitches: [],
      selectTypeOfGameoptions: [
        { value: "Training", text: "Training" },
        { value: "League game", text: "League game" },
        { value: "Cup game", text: "Cup game" },
        { value: "Friendly", text: "Friendly" },
      ],
      populateSelectedTeamVenue(event, gameType) {
        const selectedTeam = this.leagueTeams.find(
          (team) => team.id == event.target.value
        );

        if (!selectedTeam) return; // Early return if no team is found
        const {
          teamName,
          address,
          name: locationName,
          postCode,
          pitchType,
          id,
        } = selectedTeam;

        Object.assign(this[gameType], {
          pitch_id: id,
          opposition: teamName,
          address,
          locationName,
          postCode,
          pitchType:
            pitchType === "Astro"
              ? "4G"
              : pitchType === "Astro/Grass"
              ? "Other"
              : pitchType,
        });
      },
      populateFirstTeamSelectedData(event) {
        if (event === "League game") {
          if (this.newGameData.opposition === "") {
            this.newGameData.opposition = this.leagueTeams[0].teamName;
            const otherEvent = {
              target: {
                value: this.leagueTeams[0].id,
              },
            };
            this.populateSelectedTeamVenue(otherEvent, "newGameData");
          }
        }
      },
      defaultPitchInputs(gameType) {
        this[gameType].pitch_id = "";
        this.newGameData.address = "";
        this.newGameData.locationName = "";
        this.newGameData.postCode = "";
        this.newGameData.pitchType = "";
        this.newGameData.pitch_zone = null;
        this.togglePitchDetails = false;
      },
      togglePitchDetails: false,
      editMode: false,
      showTeamChange: false,
      userData: {},
      clubTeams: [],
      allTeamDataBeforFilterApplied: [],
      teamGamesDataUnfilterded: [],
      teamGamesData: [],
      scheduleData: [],
      listOfSelectedYear: [],
      listOfSelectedYearToRemove: [],
      scheduleStartDate: "",
      scheduleEndDate: "",
      printScheduleData: {},
      minsData: {
        year: 2024,
        month: 5,
        weekDays: [
          {
            day: "",
            date: "",
          },
        ],
      },
    };
  },
  components: {
    AppTemplate,
    SpinnerLoader,
    Calendar,
    Datepicker,
    PitchSelect,
    DateRangePicker,
  },
  computed: {
    ...mapGetters(["getProfile"]),
  },
  async created() {
    this.minsData.weekDays = this.populateMinsCal(
      Number(dayjs().format("YYYY")),
      Number(dayjs().month()),
      "minsData"
    );
    const response = await this.$store.dispatch(CHECK_PROFILE_HAS_DATA);

    if (response === true) {
      this.setUser();
      this.getAllGamesEventsByClubId();
    } else {
      const clientId = JSON.parse(
        localStorage.getItem("the_w_selectedClientId")
      );
      this.getUserById(clientId);
    }

    let mainLogoSRC = document.getElementById("main-logo").src;
    this.base64SvgToBase64Png(mainLogoSRC, 200).then((pngSrc) => {
      document.getElementById("png-logo").src = pngSrc;
    });
  },
  mounted() {},
  methods: {
    produceTheAcademySchedulePDF() {
      setTimeout(() => {
        let pdf = this.buildTheAcademySchedulePDF();
        pdf.previewLink.src = pdf.pdfData.output("datauristring");
        this.showReport = true;
      }, 500);
    },
    base64SvgToBase64Png(originalBase64, width) {
      return new Promise((resolve) => {
        let img = document.createElement("img");
        img.onload = function () {
          document.body.appendChild(img);
          let canvas = document.createElement("canvas");
          let ratio = img.clientWidth / img.clientHeight || 1;
          document.body.removeChild(img);
          canvas.width = width;
          canvas.height = width / ratio;
          let ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          try {
            let data = canvas.toDataURL("image/png");
            resolve(data);
          } catch (e) {
            resolve(null);
          }
        };
        img.onerror = function () {
          resolve(null);
        };
        img.src = originalBase64;
      });
    },
    buildTheAcademySchedulePDF() {
      let previewContainer = document.getElementById(
        "theAcademySchedulePdf_preview"
      );
      let pdf = new JsPDF("l", "mm", "a4");
      let img = document.getElementById("png-logo");
      pdf.addImage(img, "PNG", 5, 4, 20, 20);

      pdf.setFontSize(20).setFont(undefined, "bold");
      pdf.text(30, 13, "Hamilton Academical youth academy training programme");

      pdf.setFontSize(16);
      pdf.text(
        30,
        20,
        `${this.formatDateForDayMonth(
          this.printScheduleData.weekStartDate
        )} - ${this.formatDateForDayMonth(this.printScheduleData.weekEndDate)}`
      );

      pdf.setFontSize(10);
      pdf.text(8, 36, "Age group");

      pdf.text(45, 36, "Monday");

      pdf.text(90, 36, "Tuesday");

      pdf.text(125, 36, "Wednesday");

      pdf.text(160, 36, "Thursday");

      pdf.text(200, 36, "Friday");

      pdf.text(235, 36, "Saturday");

      pdf.text(265, 36, "Sunday");

      this.pageLines(pdf, true);

      const academyScheduleDataToPopulate = this.populateTheAcademySchedule();
      this.populateTeamsAcademyScheduleData(academyScheduleDataToPopulate, pdf);

      return { pdfData: pdf, previewLink: previewContainer };
    },
    populateTeamsAcademyScheduleData(academyScheduleDataToPopulate, pdf) {
      let marginTop = 45;

      // Mapping of days to their horizontal positions
      const dayPositions = {
        Monday: 37,
        Tuesday: 85,
        Wednesday: 120,
        Thursday: 155,
        Friday: 192,
        Saturday: 230,
        Sunday: 260,
      };

      this.clubTeams.forEach((team) => {
        // Reset font style and check for page space
        pdf.setFontSize(7).setFont(undefined, "normal");
        pdf.setTextColor(64, 52, 54);
        pdf.setDrawColor(113, 113, 113);
        marginTop = this.newPageCheck(marginTop, null, pdf);

        // Print team name
        pdf.text(6, marginTop, team.teamName, { maxWidth: 30 });

        if (academyScheduleDataToPopulate.length > 0) {
          academyScheduleDataToPopulate.forEach((scheduleDataToPopulate) => {
            if (team.teamName === scheduleDataToPopulate.team) {
              const schedule = scheduleDataToPopulate.schedule;
              const day = moment(schedule.date, "YYYY-MM-DD").format("dddd");

              // Check if the day exists in the mapping
              if (dayPositions[day]) {
                const xPosition = dayPositions[day];

                // Helper function to add text to PDF
                const addScheduleText = (x, y, time, title) => {
                  pdf.setFontSize(8).setFont(undefined, "bold");
                  pdf.text(x, y, time);
                  pdf.setFontSize(7).setFont(undefined, "normal");
                  pdf.text(x, y + 3, title, { maxWidth: 30 });
                };

                console.log(schedule);

                // Add schedule details to PDF
                if (schedule.gameType === "League game") {
                  pdf.setTextColor(29, 164, 79);
                }

                if (schedule.gameType === "Training") {
                  pdf.setTextColor(20, 132, 151);
                }

                if (schedule.gameType === "Cup game") {
                  pdf.setTextColor(103, 81, 6);
                }

                if (schedule.gameType === "Other") {
                  pdf.setTextColor(45, 6, 103);
                }
                addScheduleText(
                  xPosition,
                  marginTop,
                  schedule.time,
                  schedule.title
                );
                pdf.setTextColor(100);
              }
            }
          });
        }

        // Add spacing for the next team and draw a line
        marginTop += 20;
        pdf.line(5, marginTop - 5, 290, marginTop - 5);
      });
    },
    pageLines(pdf, topLine) {
      let marginTop = 0;
      if (topLine === true) {
        marginTop = 30;
        pdf.line(5, 30, 290, 30); // top
        pdf.line(5, 40, 290, 40);
      } // bottom

      pdf.setLineWidth(0.3);
      //outerlines
      pdf.line(5, 290, 5, marginTop); // left

      pdf.line(35, 290, 35, marginTop); // left

      pdf.line(75, 290, 75, marginTop); // left

      pdf.line(120, 290, 120, marginTop); // left

      pdf.line(154, 290, 154, marginTop); // left

      pdf.line(190, 290, 190, marginTop); // left

      pdf.line(225, 290, 225, marginTop); // left

      pdf.line(260, 290, 260, marginTop); // left

      pdf.line(290, 290, 290, marginTop); // left
    },
    newPageCheck(height, dynamicHeight, pdf) {
      if (height >= "210") {
        pdf.addPage();
        this.pageLines(pdf);
        return 10;
      } else {
        //height = Math.round(dynamicHeight.h + height + 10);
        return height;
      }
    },
    selectedWeekDatesFormat() {
      const startOfWeek = moment().startOf("isoweek");
      const result = [];

      for (let i = 0; i < 7; i++) {
        result.push(startOfWeek.clone().add(i, "days").format("YYYY-MM-DD"));
      }

      return result;
    },
    populateTheAcademySchedule() {
      const selectedWeekDates = this.selectedWeekDatesFormat();
      const academySchedule = [];

      this.clubTeams.forEach((team) => {
        selectedWeekDates.forEach((date) => {
          this.scheduleData.forEach((schedule) => {
            if (schedule.date === date) {
              if (Number(team.id) === Number(schedule.teamId)) {
                academySchedule.push({
                  schedule: schedule,
                  team: team.teamName,
                });
              }
            }
          });
        });
      });

      return academySchedule;
    },
    async getSelectedTeamsEvents(startDate, endDate) {
      this.loadingStuff = true;
      const data = {
        action: "getAllGamesEventsById",
        teamId: this.selectedTeam.id,
        gameFromDate: startDate,
        gameToDate: endDate,
      };
      const responce = await Games.find(data).catch((error) => {
        console.log(error);
      });
      this.selectedGamesEvents = responce.data;
      console.log(this.selectedGamesEvents);
      this.loadingStuff = false;
    },
    dateRangeSelected() {
      this.getSelectedTeamsEvents(
        this.formatMatchDate(this.dateRange.startDate),
        this.formatMatchDate(this.dateRange.endDate)
      );
    },
    async getgadyflLeagueTableData() {
      const data = {
        action: "getAllOppositionTeams",
      };

      const responce = await Games.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.leagueTeams = responce.data;
      }
    },
    formatMatchDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    setSelectedTeam(e) {
      const teamData = e.target.value;

      let selectedTeamData = this.clubTeams.filter(function (team) {
        return team.teamName === teamData;
      });

      this.selectedTeam = selectedTeamData[0];
      localStorage.setItem(
        "currentPlayingseasonStartDate",
        this.selectedTeam.season_start_date
      );
      localStorage.setItem(
        "currentPlayingseasonEndDate",
        this.selectedTeam.season_end_date
      );
    },
    async sendFBNotificationMessage(title, body, ageGroup) {
      const data = {
        action: "sendFBNotificationMessage",
        title: title,
        body: body,
        link: `${window.location.origin}/login`,
        age_group: ageGroup,
      };
      const responce = await Chat.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.$bvToast.toast("Notification sent", {
          title: "Notification sent",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    changeTimeToTimeAndDate(timeString, date) {
      return `${date}T${timeString}:00.000Z`;
    },
    formatISOStringDateAndTime(timeDateString) {
      return moment(timeDateString).utc().format("h:mm a");
    },
    converTimesForDB(objectName) {
      if (this[objectName].endTime !== "") {
        this[objectName].endTime = this.changeTimeToTimeAndDate(
          this[objectName].endTime,
          this.formatMatchDate(this[objectName].matchDate)
        );
      }

      if (this[objectName].meetTime !== "") {
        this[objectName].meetTime = this.changeTimeToTimeAndDate(
          this[objectName].meetTime,
          this.formatMatchDate(this[objectName].matchDate)
        );
      }

      if (this[objectName].kickOffTime !== "") {
        this[objectName].kickOffTime = this.changeTimeToTimeAndDate(
          this[objectName].kickOffTime,
          this.formatMatchDate(this[objectName].matchDate)
        );
      }

      if (this[objectName].matchDate !== "") {
        this[objectName].matchDate = this.formatMatchDate(
          this[objectName].matchDate
        );
      }
    },
    async addEditProgrammeSubmit() {
      this.loadIngStuffText = "Saving";
      this.loadingStuff = true;
      this.hideModal("addEditGames");
      this.converTimesForDB("newGameData");
      const action = { action: "saveNewGame" };
      this.newGameData.home_team === true
        ? (this.newGameData.home_team = 1)
        : (this.newGameData.home_team = 0);

      this.newGameData.teamId = this.selectedTeam.id;
      this.newGameData.teamName = this.selectedTeam.teamName;

      const data = {
        ...this.newGameData,
        ...action,
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };
      const responce = await Games.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.data === "Game added.") {
        this.getAllGamesEventsByClubId();
        //this.getSelectedTeamsEvents(this.formatMatchDate(this.dateRange.startDate),this.formatMatchDate(this.dateRange.endDate));
        this.loadingStuff = false;
        let body = "";
        if (this.newGameData.gameType !== "Training") {
          body = `${this.newGameData.gameType} vs  ${
            this.newGameData.opposition
          }. Kickoff: ${this.formatISOStringDateAndTime(
            this.newGameData.kickOffTime
          )} Meet: ${this.time(this.newGameData.meetTime)} Venue: ${
            this.newGameData.locationName
          } ${this.newGameData.address} ${this.newGameData.postCode}`;
        } else {
          body = `${
            this.newGameData.gameType
          } Kickoff: ${this.formatISOStringDateAndTime(
            this.newGameData.kickOffTime
          )} Venue: ${this.newGameData.locationName} ${
            this.newGameData.address
          } ${this.newGameData.postCode}`;
        }
        this.sendFBNotificationMessage(this.newGameData.gameType, body, [
          this.selectedTeam.age_group,
        ]);
      }
    },
    changeTimeFrom24To12jours(time) {
      let hours = time.substr(0, 2);
      let mins = time.substr(3, 2);
      var AmOrPm = hours >= 12 ? "pm" : "am";
      hours = hours % 12 || 12;

      return `${hours}:${mins} ${AmOrPm}`;
    },
    async getEvents() {
      this.scheduleDatesDefault();
      const data = {
        action: "getEvents",
        season_start_date: this.scheduleStartDate,
        season_end_date: this.scheduleEndDate,
      };

      const responce = await Teams.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        if (responce.data.length > 0) {
          responce.data.map((item) => {
            this.scheduleData.push({
              title: item.title,
              date: item.date,
              time: item.start_time,
              endTime: item.end_time,
              teamId: item.teamId.toString(),
              pitch_zone: "",
              gameType: "Other",
            });
          });

          this.setSchedulerTableCellHeight();
        }
      }
    },
    setSchedulerTableCellHeight() {
      let colOneTeamName =
        document.getElementsByClassName("col-one-teams")[0].children;
      const eventsWithData = this.scheduleData.map((event) => ({
        date: event.date,
        teamId: event.teamId.toString(),
      }));

      for (let i = 1; i < colOneTeamName.length; i++) {
        // Start from 1 to skip the first row
        const className = colOneTeamName[i].getAttribute("class");
        const teamIdText = className.replace(/\D/g, ""); // Extract team ID

        this.minsData.weekDays.forEach((day) => {
          if (day.date) {
            const formattedDate = dayjs(day.date).format("YYYY-MM-DD");

            const matchingEvent = eventsWithData.find(
              (event) =>
                event.date === formattedDate && event.teamId === teamIdText
            );

            if (matchingEvent) {
              const colOneTeamTableCell = colOneTeamName[i];
              const relatedCells = document.getElementsByClassName(
                `body body team-${matchingEvent.teamId}`
              );

              Array.from(relatedCells).forEach((cell) => {
                if (
                  cell.className === `body body team-${matchingEvent.teamId}`
                ) {
                  cell.style.height = "10rem";
                  //cell.style.overflowY = "scroll";
                }
              });

              if (colOneTeamTableCell) {
                colOneTeamTableCell.style.height = "10rem";
              }
            }
          }
        });
      }
    },
    async saveEvent() {
      this.newEventData.date = this.formatDateForDB(this.newEventData.date);
      const data = {
        action: "saveEvent",
        listOfSelectedYear: this.listOfSelectedYear,
        ...this.newEventData,
      };

      const responce = await Teams.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.getAllGamesEventsByClubId();
        this.newEventData = {};
        (this.listOfSelectedYear = []),
          (this.listOfSelectedYearToRemove = []),
          this.$bvModal.hide("addEvent");
        this.$bvToast.toast("Event saved", {
          title: "Saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    ageGroupAddRemove(data) {
      data.target.checked === true
        ? this.addToListOfSelectedTeamAgeGroups(data.target.value)
        : this.removeFromListOfSelectedAgeGroups(data.target.value);
    },
    removeFromListOfSelectedAgeGroups(year) {
      this.listOfSelectedYear = this.listOfSelectedYear.filter(
        (item) => item !== year
      );
    },
    addToListOfSelectedTeamAgeGroups(year) {
      if (!this.listOfSelectedYear.includes(year)) {
        this.listOfSelectedYear.push(year);
      }
    },
    openAddEventModal() {
      this.$bvModal.show("addEvent");
    },
    tabChange(tabValue, selectedTabValue) {
      this[tabValue] = selectedTabValue;
    },
    changeMonth(value, dataType) {
      let changeNumber = 0;
      let yearNumber = this[dataType].year;

      if (value === 1) {
        changeNumber = Number(dayjs().month(this[dataType].month).format("M"));
        if (this[dataType].month === 11) {
          changeNumber = 0;
          yearNumber = dayjs().year(yearNumber).add(1, "year").format("YYYY");
        }
      } else {
        changeNumber = Number(
          dayjs().month(this[dataType].month).subtract(2, "month").format("M")
        );

        if (this[dataType].month === 1) {
          changeNumber = 0;
          yearNumber = dayjs().year(yearNumber).format("YYYY");
        }

        if (this[dataType].month === 0) {
          yearNumber = dayjs()
            .year(yearNumber)
            .subtract(1, "year")
            .format("YYYY");
        }
      }

      this[dataType].weekDays = this.populateMinsCal(
        Number(yearNumber),
        Number(changeNumber),
        dataType
      );

      if (changeNumber === 0) {
        changeNumber = 1;
      }

      const currentDate = `${yearNumber}-${changeNumber}-01`;

      this.scheduleStartDate = moment(currentDate)
        .subtract(1, "months")
        .format("YYYY-MM-DD");
      this.scheduleEndDate = moment(currentDate)
        .add(2, "months")
        .format("YYYY-MM-DD");

      this.getAllGamesEventsByClubId();
    },
    formatDateForDB(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    formatDateForHuman(date) {
      return date !== undefined && moment(date).isValid()
        ? moment(date).format("MMMM Do YYYY")
        : "";
    },
    formatDateForHumanShort(date) {
      return date !== undefined && moment(date).isValid()
        ? moment(date).format("MMMM Do, h:mm a")
        : "";
    },
    formatTime(date) {
      return date !== undefined && moment(date).isValid()
        ? moment(date).utc().format("h:mm a")
        : "";
    },
    formatDateForDayMonth(date) {
      return moment(date).format("dddd Do MMMM");
    },
    populateSchedualDay() {
      this.scheduleData = [];
      this.teamGamesDataUnfilterded.map((game) => {
        let title = "";
        let time = "";
        let pitch_zone = null;

        if (game.gameType !== "Training") {
          title = `Vs ${game.opposition} At ${game.locationName} ${game.postCode}`;
          time = `${this.formatTime(game.kickOffTime)} KO `;
        } else {
          title = `${game.gameType} At ${game.locationName} ${game.postCode}`;
          time = `${this.formatTime(game.kickOffTime)} - ${this.formatTime(
            game.endTime
          )}`;
          pitch_zone = game.pitch_zone;
        }

        this.scheduleData.push({
          title: title,
          date: game.matchDate,
          time: time,
          teamId: game.teamId,
          pitch_zone: pitch_zone,
          gameType: game.gameType,
        });
      });

      this.getEvents();
    },
    scheduleDatesDefault() {
      if (this.scheduleStartDate === "" || this.scheduleEndDate === "") {
        this.scheduleStartDate = moment()
          .subtract(1, "months")
          .format("YYYY-MM-DD");
        this.scheduleEndDate = moment().add(2, "months").format("YYYY-MM-DD");
      }
    },
    async getAllGamesEventsByClubId() {
      this.scheduleDatesDefault();
      const data = {
        action: "getAllGamesEventsByClubId",
        season_start_date: this.scheduleStartDate,
        season_end_date: this.scheduleEndDate,
      };

      const responce = await Games.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.teamGamesDataUnfilterded = responce.data;
        this.teamGamesData = responce.data;

        this.populateSchedualDay();
      }
    },
    async getUserById(clientId) {
      const userData = {
        clientId: clientId,
        action: "getUserByID",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        this.userData = responce.data[0];
        this.getTeamsByUserId();
        this.getAllGamesEventsByClubId();
        this.getAllPitchesForClub();
        this.getgadyflLeagueTableData();
      }
    },
    setUser() {
      if (this.getProfile !== null) {
        this.userData = this.getProfile;
        this.getTeamsByUserId();
        this.getAllPitchesForClub();
        this.getgadyflLeagueTableData();
      }
    },
    populateMinsCal(year, month, dataType) {
      this[dataType].year = year;
      this[dataType].month = month;
      let date = new Date(year, month, 1);
      let dates = [];

      while (date.getMonth() === month) {
        const entry = {
          date: new Date(date),
          id: null,
        };

        if (dataType === "minsData") {
          entry.minsTotal = null;
        }

        dates.push(entry);
        date.setDate(date.getDate() + 1);
      }

      return dates;
    },
    getMonthName(dateNumber) {
      return dayjs().month(dateNumber).format("MMMM");
    },
    getDayFromDate(date) {
      return dayjs(date).format("ddd D");
    },
    async getTeamsByUserId() {
      const data = {
        action: "getTeamsDataById",
        userId: this.userData.id,
        clubId: this.userData.clubId,
      };
      const responce = await Teams.find(data).catch((error) => {
        console.log(error);
      });
      this.allTeamDataBeforFilterApplied = responce.data;
      this.clubTeams = responce.data;
      this.clubTeams.push({
        id: 1000,
        teamId: 1000,
        teamName: "GK (2016 - 2012)",
      });

      this.clubTeams.push({
        id: 1001,
        teamId: 1001,
        teamName: "GK (2011 - 2007)",
      });

      this.clubTeams.push({
        id: 1002,
        teamId: 1002,
        teamName: "Hamilton Women",
      });
    },
    showPrintScheduleModal() {
      this.printScheduleData = {
        weekStartDate: moment().startOf("isoweek"),
        weekEndDate: moment(moment().startOf("isoweek")).add(6, "days"),
        clubTeams: this.clubTeams,
      };
      this.showModal("printSchedule");
    },
    showAddGamesModal() {
      this.showTeamChange = false;
      this.emptyNewGameData();
      let today = moment();
      this.weeklyDate = today._d;
      this.showModal("addEditGames");
    },
    setFirstTeamNameAndId() {
      this.newGameData.teamName = this.clubTeams[0].teamName;
      this.newGameData.teamId = this.clubTeams[0].teamId;
    },
    emptyNewGameData() {
      this.newGameData = {
        opposition: "",
        kickOffTime: "",
        matchDate: "",
        locationName: "",
        address: "",
        postCode: "",
        stripType: "",
        meetTime: "",
        endTime: "",
        notes: "",
        gameType: "Training",
        teamId: "",
        teamName: "",
        pitchType: "",
        recurringEventType: null,
        home_team_goals: 0,
        away_team_goals: 0,
        away_team_goal_scorers: null,
        home_team_goal_scorers: null,
        result_added: 0,
        pitch_id: "",
        pitch_zone: null,
        home_team: false,
      };
      this.setFirstTeamNameAndId();
    },
    setPitchZones(pitchData, gameDataType) {
      if (
        pitchData.sectionOne !== false &&
        pitchData.sectionTwo === false &&
        pitchData.sectionThree === false
      ) {
        this[gameDataType].pitch_zone = "1";
      } else if (
        pitchData.sectionTwo !== false &&
        pitchData.sectionOne === false &&
        pitchData.sectionThree === false
      ) {
        this[gameDataType].pitch_zone = "2";
      } else if (
        pitchData.sectionThree !== false &&
        pitchData.sectionOne === false &&
        pitchData.sectionTwo === false
      ) {
        this[gameDataType].pitch_zone = "3";
      } else if (
        pitchData.sectionOne !== false &&
        pitchData.sectionTwo === false &&
        pitchData.sectionThree !== false
      ) {
        this[gameDataType].pitch_zone = "1,3";
      } else if (
        pitchData.sectionTwo !== false &&
        pitchData.sectionThree !== false &&
        pitchData.sectionOne === false
      ) {
        this[gameDataType].pitch_zone = "2,3";
      } else if (
        pitchData.sectionOne !== false &&
        pitchData.sectionThree === false &&
        pitchData.sectionTwo !== false
      ) {
        this[gameDataType].pitch_zone = "1,2";
      }
    },
    pitchChange(pitchData, gameType) {
      let seasonStartDate = moment().subtract(1, "months").format("YYYY-MM-DD");
      let seasonEndDate = moment().add(3, "months").format("YYYY-MM-DD");
      if (this.newGameData.matchDate !== "" || this.editGame.matchDate !== "") {
        if (this.newGameData.matchDate !== "") {
          this.setPitchZones(pitchData, "newGameData");
          seasonStartDate = moment(this.newGameData.matchDate)
            .subtract(1, "months")
            .format("YYYY-MM-DD");
          seasonEndDate = moment(this.newGameData.matchDate)
            .add(3, "months")
            .format("YYYY-MM-DD");
        }

        /*if (this.editGame.matchDate !== "") {
          this.setPitchZones(pitchData, "editGame");
          seasonStartDate = moment(this.editGame.matchDate).subtract(1, "months").format("YYYY-MM-DD");
          seasonEndDate = moment(this.editGame.matchDate).add(3, "months").format("YYYY-MM-DD");
        }*/
      }
      this[gameType].pitch_id = pitchData.pitchId || pitchData.id;
      const pitch = this.pitches.filter(
        (pitch) => pitch.id == pitchData.pitchId
      );
      this[gameType].address = pitch[0].address;
      this[gameType].locationName = pitch[0].name;
      this[gameType].postCode = pitch[0].postCode;
      this[gameType].pitchType = pitch[0].pitchType;
      this.togglePitchDetails = true;
    },
    async getAllPitchesForClub() {
      const userData = {
        clubId: this.userData.clubId,
        action: "getAllPitchesForClub",
      };
      const responce = await Pitches.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.pitches = responce.data;
      }
    },
    showModal(modelId) {
      this.$bvModal.show(modelId);
    },
    hideModal(modelId) {
      this.$bvModal.hide(modelId);
    },
  },
};
</script>
<style lang="scss" scoped>
.visually-hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.player-report-content {
  margin-top: 1rem;
  width: 100%;
  height: 50rem;

  iframe {
    width: 100%;
    height: 100%;
  }

  .form__item {
    display: grid;
  }
}

.buttons-wrapper {
  display: flex;
  gap: 0.3rem;
}

.event-schedual-club {
  .form__input {
    width: 100%;
  }
}

.mins-stats-wrapper {
  border-top: 1px solid #c3cdd5;
  padding: 2rem;
  overflow: hidden;

  .totals {
    display: flex;
    gap: 1rem;

    .total {
      border: 1px solid #c3cdd5;
      margin: 1rem 0;
      padding: 1rem;
      min-width: 10rem;

      span {
      }

      p {
        font-size: 2rem;
        display: block;
        font-weight: bold;
        line-height: 2rem;
      }
    }
  }

  .date-year {
    display: block;
    text-align: center;
    margin: 0.5rem;
    width: 100%;
  }

  .mins-stats-view {
    display: flex;
    background: white;
    border: 2px solid #c3cdd5;
    overflow-x: scroll;

    .col-one-teams {
      .body:first-of-type {
        height: auto;
      }

      .body {
        border: 1px solid #c3cdd5;
        width: 11rem;
        padding: 0.5rem;
        font-size: 0.8rem;
        height: 5rem;
      }
    }

    .mins-stats-col-one {
      background: green;
      width: 10rem;
      height: 100%;
    }

    .events {
      display: flex;
      overflow-x: scroll;
      overflow-y: hidden;

      .mins-stats-col-day-wrapper {
        .head {
          white-space: nowrap;
        }

        .body {
          &:hover,
          &:focus {
            background: #eeeeee;
            cursor: pointer;
          }

          .buttons-wrapper {
            display: flex;
            gap: 0.3rem;
            padding: 0;
            margin: 0;
          }

          .main-button {
            margin: 0;
            padding: 0.3rem;
          }
        }

        .head,
        .body {
          border: 1px solid #c3cdd5;
          text-align: center;
          padding: 0.5rem 3rem;
          font-size: 0.8rem;
        }

        .body {
          min-height: 2.325rem;
          border: 1px solid #e9edf0;
          height: 5rem;
          padding: 0.5rem;

          .event-wrapper.training-style {
            border-left: 3px solid #3c5746;
            background: rgb(179, 220, 244);
          }

          .event-wrapper.league-style {
            border-left: 3px solid #3c5746;
            background: rgb(179, 244, 203);
          }

          .event-wrapper {
            margin-bottom: 0.5rem;
            margin-bottom: 0.5rem;
            border-left: 3px solid;
            background: #e9edf0;
            padding: 0.2rem;

            .event-text {
              display: block;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              max-width: 188px;
            }
          }
        }
      }
    }
  }
}
</style>
