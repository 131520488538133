var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('spinner-loader',{attrs:{"loadingStuff":_vm.loadingStuff}}),_c('app-template'),(_vm.userData.name !== '')?_c('div',{staticClass:"uk-container uk-container-large"},[_c('ul',{staticClass:"breadcrumb"},[_c('li',[_c('router-link',{attrs:{"to":{ name: 'home' }}},[_c('b-icon',{attrs:{"icon":"house-door","aria-hidden":"true"}})],1)],1),_vm._v(" → "),_c('li',[_c('router-link',{attrs:{"to":{ name: 'ptHome' }}},[_vm._v("PT Dashboard")])],1),_vm._v(" → "),_c('li',[_vm._v("Training Plan")])]),[_c('user-header',{attrs:{"userDataForHeader":_vm.userHeaderData,"coachesUsersData":_vm.coachesUsersData},on:{"weekSelectedChanged":_vm.weekSelectedChanged,"selectedUseChanged":_vm.selectedUseChanged}})],_c('div',{staticClass:"sessions-week-layout"},[_c('ul',{staticClass:"tabs"},[_c('li',{staticClass:"tab-item"},[_c('a',{class:_vm.selectedTab === 'current-week-tab'
                ? 'tab-link active'
                : 'tab-link',attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.tabChange('selectedTab', 'current-week-tab')}}},[_vm._v("This weeks training")])]),_c('li',{staticClass:"tab-item"},[_c('a',{class:_vm.selectedTab === 'archive' ? 'tab-link active' : 'tab-link',attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.tabChange('selectedTab', 'archive')}}},[_vm._v("Training library")])])]),_c('div',{staticClass:"tabs-wrapper"},[(_vm.selectedTab === 'current-week-tab')?_c('div',{staticClass:"current-week-tab training-plans tab-content"},[_c('training-plan-library',{attrs:{"trainingTypeAndUserData":{
              trainingType: _vm.getRouteParams(),
              userId: _vm.$route.params.trainingPlanUserId,
              trainingData: 'currentWeek',
            }}})],1):_vm._e(),(_vm.selectedTab === 'archive')?_c('div',{staticClass:"archive training-plans tab-content"},[_c('training-plan-library',{attrs:{"trainingTypeAndUserData":{
              trainingType: _vm.getRouteParams(),
              userId: _vm.$route.params.trainingPlanUserId,
              trainingData: 'library',
            }}})],1):_vm._e()])]),_c('div')],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }