import firebase from "firebase/compat/app";
import { getMessaging } from "firebase/messaging";
import "firebase/compat/firestore";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";

const firebaseConfig = {
  //Get this from your firebase console
  apiKey: "AIzaSyDtQOl0oba690X6tsFz32PxiOzr_Sn09V8",
  authDomain: "chatmessages-fc29c.firebaseapp.com",
  projectId: "chatmessages-fc29c",
  storageBucket: "chatmessages-fc29c.appspot.com",
  messagingSenderId: "602129322649",
  appId: "1:602129322649:web:1d14f26ff34dd54105de08",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

const db = firebaseApp.firestore();
export default db;
