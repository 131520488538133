var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"intro-header"},[_c('img',{staticClass:"main-image-top",attrs:{"src":require("./img/pitch.svg"),"alt":"pitch"}}),_c('div',{staticClass:"user-photo-info"},[(
        _vm.selectedUserData !== null &&
        _vm.selectedUserData !== undefined &&
        Object.keys(_vm.selectedUserData).length !== 0
      )?[(
          _vm.selectedUserData.userImage !== '' &&
          _vm.selectedUserData.userImage !== null
        )?_c('img',{attrs:{"id":"user_photo","src":_vm.getImgUrl(_vm.selectedUserData.userImage),"alt":""}}):_c('img',{attrs:{"src":_vm.getImgUrl('default-image.png'),"alt":"","id":"user_photo"}})]:[(
          _vm.userDataForHeader.userImage !== '' &&
          _vm.userDataForHeader.userImage !== null
        )?_c('img',{attrs:{"id":"user_photo","src":_vm.getImgUrl(_vm.userDataForHeader.userImage),"alt":""}}):_c('img',{attrs:{"src":_vm.getImgUrl('default-image.png'),"alt":""}})],(
        _vm.selectedUserData !== null &&
        _vm.selectedUserData !== undefined &&
        Object.keys(_vm.selectedUserData).length !== 0
      )?[(_vm.selectedUserData.userType === 'User')?_c('h1',[(_vm.sectionSelected === 'Player Profile')?_c('span',[_vm._v(" Player Profile")]):_vm._e(),(_vm.sectionSelected === 'Training Plan')?_c('span',[_vm._v("Personal Programmes")]):_vm._e(),_c('br'),_vm._v(" for "+_vm._s(_vm.selectedUserData.name)+" ")]):_vm._e()]:[_c('h1',[_vm._v(_vm._s(_vm.userDataForHeader.name))])]],2),_c('div',{staticClass:"sessons-header"},[_c('div',{staticClass:"form"},[_c('div',{staticClass:"form__item"},[(
            (_vm.userDataForHeader.userType === 'Admin' ||
              _vm.userDataForHeader.userType === 'Coach') &&
            _vm.$route.name !== 'home' &&
            _vm.$route.name !== 'profile' &&
            _vm.$route.name !== 'ptHome' &&
            _vm.$route.name !== 'coaches' &&
            _vm.$route.name !== 'documents'
          )?_c('div',{staticClass:"select-user"},[_c('label',{staticClass:"form__label",attrs:{"for":"userSelect"}},[_vm._v("Change user")]),_c('select',{attrs:{"id":"userSelect"},on:{"change":function($event){return _vm.setSelectedUserOfTrainingPlan($event)}}},[_c('option',{attrs:{"value":"","disabled":"","selected":""}},[_vm._v("Select user")]),_vm._l((_vm.coachesUsersData),function(user){return _c('option',{key:user.id,domProps:{"value":user.id}},[_vm._v(" "+_vm._s(user.name)+" ")])})],2)]):_vm._e(),(
            (_vm.userDataForHeader.userType === 'Admin' ||
              _vm.userDataForHeader.userType === 'Coach') &&
            _vm.$route.name !== 'home' &&
            _vm.$route.name === 'profile' &&
            _vm.$route.name !== 'coaches' &&
            _vm.$route.name !== 'documents' &&
            _vm.$route.name !== 'ptHome'
          )?_c('div',{staticClass:"select-user"}):_vm._e(),(
            (_vm.userDataForHeader.userType === 'Admin' ||
              _vm.userDataForHeader.userType === 'Coach') &&
            _vm.$route.name === 'coaches'
          )?_c('div',{staticClass:"select-user"},[_c('h1',[_vm._v("Coaches")]),_c('b-input-group',{staticClass:"mb-2 autocomplete",attrs:{"size":"sm"}},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('b-icon',{attrs:{"icon":"search"}})],1),_c('b-form-input',{attrs:{"type":"search","placeholder":"Search coaches"},on:{"input":function($event){return _vm.lookUpSearch($event)}}}),(_vm.filterdCoachesData.length > 0)?_c('ul',{staticClass:"autocomplete-results"},_vm._l((_vm.filterdCoachesData),function(userData){return _c('li',{key:userData.id,staticClass:"autocomplete-result",on:{"click":function($event){return _vm.openTrainingPlanPageCall(userData.id)}}},[_vm._v(" "+_vm._s(userData.name)+" ")])}),0):_vm._e()],1)],1):_vm._e(),(
            (_vm.userDataForHeader.userType === 'Admin' ||
              _vm.userDataForHeader.userType === 'Coach') &&
            _vm.$route.name === 'documents'
          )?_c('div',{staticClass:"select-user"},[_c('h1',[_vm._v("Documents library")])]):_vm._e()])]),(_vm.userDataForHeader.userType === 'User')?_c('h2',[_vm._v(" "+_vm._s(_vm.sectionSelected)+" "),(_vm.sectionSelected !== 'Player Profile')?_c('span',{staticClass:"week-selected-dates-text"},[_vm._v(" Week number: "+_vm._s(_vm.weekSelectedNumber)+" | "+_vm._s(_vm.selectedWeekMonSunday))]):_vm._e(),(_vm.sectionSelected === 'Player Profile')?_c('div',{staticClass:"player-profile-header"},[_c('p',[_c('span',[_vm._v("Name:")]),_vm._v(" "+_vm._s(_vm.userDataForHeader.name))]),_c('p',[_c('span',[_vm._v("Dob:")]),_vm._v(" "+_vm._s(_vm.dayMonthYear(_vm.userDataForHeader.dob)))]),_c('p',[_c('span',[_vm._v("Position:")]),_vm._v(" "+_vm._s(_vm.userDataForHeader.position))]),_c('p',[_c('span',[_vm._v("Team:")]),_vm._v(" "+_vm._s(_vm.userDataForHeader.teamId))]),(_vm.checkSignedDate(_vm.userDataForHeader.joined_date))?_c('p',[_c('span',[_vm._v("Signed:")]),_vm._v(" "+_vm._s(_vm.dayMonthYear(_vm.userDataForHeader.joined_date))+" ")]):_vm._e(),(_vm.pathWayCheck(_vm.userDataForHeader.age_group) === 'Foundation')?_c('p',[_c('span',[_vm._v("Phase:")]),_vm._v(" "+_vm._s(_vm.pathWayCheck(_vm.userDataForHeader.age_group))+" ")]):_vm._e(),(
            _vm.pathWayCheck(_vm.userDataForHeader.age_group) === 'Youth Development'
          )?_c('p',[_c('span',[_vm._v("Phase:")]),_vm._v(" "+_vm._s(_vm.pathWayCheck(_vm.userDataForHeader.age_group))+" ")]):_vm._e(),(
            _vm.pathWayCheck(_vm.userDataForHeader.age_group) ===
            'Professional Development'
          )?_c('p',[_c('span',[_vm._v("Phase:")]),_vm._v(" "+_vm._s(_vm.pathWayCheck(_vm.userDataForHeader.age_group))+" ")]):_vm._e()]):_vm._e()]):_c('div',{staticClass:"header-buttons"},[_c('h2',[(_vm.sectionSelected === 'Player Profile')?_c('img',{attrs:{"id":"small_shirt","src":require("./img/player-shirt-small.jpg")}}):_vm._e(),_vm._v(" "+_vm._s(_vm.sectionSelected)+" ")]),(
          _vm.$route.name !== 'home' &&
          _vm.$route.name !== 'ptHome' &&
          _vm.$route.name !== 'coaches' &&
          _vm.$route.name !== 'documents'
        )?_c('span',{staticClass:"week-selected-dates-text"},[_c('span',{staticClass:"selected-user"},[_vm._v("Selected user: "),(
              _vm.selectedTrainingUserData !== null &&
              _vm.selectedTrainingUserData !== undefined
            )?_c('span',{staticClass:"highlighted-text"},[_vm._v(" "+_vm._s(_vm.selectedTrainingUserData.name)+" ")]):_vm._e()]),_c('span',{staticClass:"week-number"},[_vm._v(" Position: "+_vm._s(_vm.selectedTrainingUserData.position))]),_c('span',{staticClass:"week-number"},[_vm._v(" Week number: "+_vm._s(_vm.weekSelectedNumber)+" | "+_vm._s(_vm.selectedWeekMonSunday))]),(
            _vm.selectedUserData !== null &&
            _vm.selectedUserData !== undefined &&
            Object.keys(_vm.selectedUserData).length !== 0
          )?_c('span',{staticClass:"season-dates"},[_vm._v("Season: "+_vm._s(_vm.dayMonthYear(_vm.currentPlayingseasonStartDate()))+" - "+_vm._s(_vm.dayMonthYear(_vm.currentPlayingseasonEndDate())))]):_vm._e()]):_vm._e()])]),(
      _vm.$route.name !== 'ptHome' &&
      _vm.$route.name !== 'ptUserHome' &&
      _vm.$route.name !== 'trainingPlans' &&
      _vm.userDataForHeader.userType !== 'Admin' &&
      _vm.userDataForHeader.userType !== 'Coach'
    )?_c('div',{staticClass:"playerPerformaceScoreWrapper"}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }