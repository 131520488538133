export const USER_REQUEST = "USER_REQUEST";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_ERROR = "USER_ERROR";
export const USER_POPULATE = "USER_POPULATE";
export const CHECK_FOR_SELECTED_CLIENT_WITH_ID_WITH_LOCALSTORGE =
  "CHECK_FOR_SELECTED_CLIENT_WITH_ID_WITH_LOCALSTORGE";
export const UPDATE_SELECTED_CLIENT = "UPDATE_SELECTED_CLIENT";
export const CHECK_PROFILE_HAS_DATA = "CHECK_PROFILE_HAS_DATA";
export const GET_USER_WITH_ID = "GET_USER_WITH_ID";
export const POPULATE_WEEK_SELECTED_NUMBER = "POPULATE_WEEK_SELECTED_NUMBER";
export const POPULATE_COACHES_USER_DATA = "POPULATE_COACHES_USER_DATA";
export const POPULATE_SELECTED_USER_DATA = "POPULATE_SELECTED_USER_DATA";
export const POPULATE_SELECTED_CALENDAR_DATE =
  "POPULATE_SELECTED_CALENDAR_DATE";
export const POPULATE_MON_TO_FRI_DATE = "POPULATE_MON_TO_FRI_DATE";
