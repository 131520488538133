import { HTTP } from "./http";
let API_SERVER = "";
if (window.location.href.indexOf("8080") > -1) {
  API_SERVER = "http://www.localhost:8888/back-end-endpoint-wellness.php";
} else {
  API_SERVER = "/personal-training-BE/back-end-endpoint-wellness.php";
}

const Wellness = {
  find(params) {
    return HTTP.post(API_SERVER, params);
  },

  show(id) {
    return HTTP.get(`wellness/${id}`);
  },

  store(post) {
    return HTTP.post("wellness", post);
  },

  update(post, id) {
    return HTTP.put(`wellness/${id}`, post);
  },

  delete(id) {
    return HTTP.delete(`wellness/${id}`);
  },
};

export { Wellness };
